import { TV, TW, TH, FieldType } from '@buildhero/sergeant';

const PDFDocumentLayout = {
  layoutsList: ['default', 'pdf'],
  layouts: {
    pdf: {
      props: {
        title: `Receipt`,
        pageSize: 'letter'
      },
      contents: [
        // 1st row: logo image, company address and phone
        {
          props: {
            style: {
              flexDirection: 'row',
              justifyContent: 'space-between',
              pdf: {
                height: 36,
                marginBottom: 16
              },
              default: {
                height: 52,
                marginBottom: 20
              }
            }
          },
          contents: [
            {
              component: 'Image',
              source: 'senderLogoUrl',
              props: {
                style: {
                  height: '100%'
                }
              }
            },
            {
              props: {
                style: {
                  alignItems: 'flex-end'
                }
              },
              contents: [
                {
                  component: 'Typography',
                  source: 'senderAddress',
                  props: {
                    height: TH.COMFORTABLE,
                    style: {
                      width: 160,
                      textAlign: 'right'
                    },
                    default: { variant: TV.PRINT }
                  }
                },
                {
                  component: 'Typography',
                  source: 'senderPhoneNumber',
                  props: {
                    height: TH.COMFORTABLE,
                    default: { variant: TV.PRINT }
                  }
                }
              ]
            }
          ]
        },

        // 2nd row: po number and date
        {
          props: {
            style: {
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }
          },
          contents: [
            {
              props: {
                style: {
                  flexDirection: 'row',
                  alignItems: 'center'
                }
              },
              contents: [
                {
                  component: 'Typography',
                  props: {
                    weight: TW.BOLD,
                    value: 'Receipt',
                    default: { variant: TV.XL },
                    pdf: { variant: TV.BASE }
                  }
                },
                {
                  component: 'Typography',
                  source: 'receiptNumber',
                  props: {
                    default: { variant: TV.L, style: { marginLeft: 8 } },
                    pdf: { variant: TV.BASE, style: { marginLeft: 4 } }
                  }
                }
              ]
            },
            {
              component: 'Typography',
              source: 'date',
              props: {
                default: {
                  label: 'Issued By',
                  style: { width: 'auto', marginBottom: 12 },
                  variant: TV.L
                },
                pdf: { variant: TV.S2 }
              }
            }
          ]
        },
        // 3rd row: job/maintenance/project name
        {
          component: 'Typography',
          source: 'jobProjectOrMaintenanceName',
          props: {
            pdf: { style: { marginBottom: 24 }, variant: TV.S1 }
          }
        },

        // 4th row: Vendor and ship to address
        {
          props: {
            style: {
              flexDirection: 'row',
              justifyContent: 'flex-start',
              default: {
                marginBottom: 17
              },
              pdf: {
                marginBottom: 24
              }
            }
          },
          contents: [
            {
              props: {
                style: {
                  flexDirection: 'column',
                  marginRight: 16,
                  width: 100
                }
              },
              contents: [
                {
                  component: 'Typography',
                  props: {
                    weight: TW.BOLD,
                    value: 'Vendor',
                    default: {
                      variant: TV.BASE,
                      style: { marginBottom: 8 }
                    },
                    pdf: {
                      variant: TV.S2,
                      style: { marginBottom: 4 }
                    }
                  }
                },
                {
                  component: 'Typography',
                  source: 'vendorName',
                  props: {
                    pdf: {
                      weight: TW.BOLD,
                      multiline: false
                    }
                  }
                },
                {
                  component: 'Typography',
                  source: 'vendorAddress',
                  props: {
                    pdf: {
                      multiline: true
                    }
                  }
                }
              ]
            },
            {
              props: {
                style: {
                  flexDirection: 'column',
                  marginRight: 16,
                  width: 100
                }
              },
              contents: [
                {
                  component: 'Typography',
                  props: {
                    weight: TW.BOLD,
                    value: 'Ship To',
                    default: {
                      variant: TV.BASE,
                      style: { marginBottom: 8 }
                    },
                    pdf: {
                      variant: TV.S2,
                      style: { marginBottom: 4 }
                    }
                  }
                },
                {
                  component: 'Typography',
                  source: 'shippingAddress',
                  props: {
                    pdf: {
                      multiline: true
                    }
                  }
                }
              ]
            },
            {
              props: {
                style: {
                  flexDirection: 'column',
                  marginRight: 16,
                  width: 100
                }
              },
              contents: [
                {
                  component: 'Typography',
                  props: {
                    weight: TW.BOLD,
                    value: 'Issued By',
                    default: {
                      variant: TV.BASE,
                      style: { marginBottom: 8 }
                    },
                    pdf: {
                      variant: TV.S2,
                      style: { marginBottom: 4 }
                    }
                  }
                },
                {
                  component: 'Typography',
                  source: 'issuedBy',
                  props: {
                    pdf: {
                      multiline: true
                    }
                  }
                }
              ]
            },
            {
              props: {
                style: {
                  flexDirection: 'column',
                  width: 100
                }
              },
              contents: [
                {
                  component: 'Typography',
                  props: {
                    weight: TW.BOLD,
                    value: 'Due Date',
                    default: {
                      variant: TV.BASE,
                      style: { marginBottom: 8 }
                    },
                    pdf: {
                      variant: TV.S2,
                      style: { marginBottom: 4 }
                    }
                  }
                },
                {
                  component: 'Typography',
                  source: 'dueDate',
                  props: {
                    pdf: {
                      multiline: true
                    }
                  }
                }
              ]
            }
          ]
        },

        // 5th row: Description
        {
          component: 'Typography',
          props: {
            weight: TW.BOLD,
            value: 'Description',
            default: {
              variant: TV.BASE,
              style: { marginBottom: 8 }
            },
            pdf: {
              variant: TV.S2,
              style: { marginBottom: 4 }
            }
          }
        },
        {
          component: 'Typography',
          source: 'description',
          props: {
            pdf: {
              multiline: true,
              rows: 8,
              rowsMax: 30
            }
          }
        },

        // Divider
        {
          component: 'Divider'
        },

        // PO Items
        {
          component: 'Table',
          source: 'poItems',
          props: {
            title: 'PO Items',
            onMove: () => {},
            onCreate: () => {},
            onUpdate: () => {},
            onDelete: () => {},
            columns: [
              {
                id: 'itemName',
                label: 'Item Name',
                width: `${134 / 5.64}%`,
                weight: TW.BOLD
              },
              {
                id: 'description',
                label: 'Description',
                width: `${190 / 5.64}%`
              },
              {
                id: 'costCode',
                label: 'Cost Code',
                width: `${50 / 5.64}%`
              },
              {
                id: 'quantity',
                label: 'Quantity',
                type: FieldType.NUMBER,
                width: `${50 / 5.64}%`,
                numeric: true
              },
              {
                id: 'unitCost',
                label: 'Unit Price',
                type: FieldType.CURRENCY,
                width: `${70 / 5.64}%`
              },
              {
                id: 'totalCost',
                label: 'Subtotal',
                type: FieldType.CURRENCY,
                width: `${70 / 5.64}%`
              }
            ]
          }
        },

        // Divider
        {
          component: 'Divider'
        },

        // subtotal, freight, total rate, tax amount, total
        {
          props: {
            pdf: { wrap: false },
            style: {
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }
          },
          contents: [
            // titles
            {
              props: {
                style: {
                  textAlign: 'right',
                  default: { width: 105 },
                  pdf: { width: 78 }
                }
              },
              contents: [
                {
                  component: 'Typography',
                  props: {
                    value: 'Subtotal',
                    default: {
                      variant: TV.BASE,
                      style: { marginBottom: 32 }
                    },
                    pdf: {
                      variant: TV.S2,
                      style: { marginBottom: 16 }
                    }
                  }
                },
                {
                  component: 'Typography',
                  props: {
                    value: 'Freight',
                    default: {
                      variant: TV.BASE,
                      style: { marginBottom: 26 }
                    },
                    pdf: {
                      variant: TV.S2,
                      style: { marginBottom: 8 }
                    }
                  }
                },
                {
                  component: 'Typography',
                  props: {
                    value: 'Tax Rate',
                    default: {
                      variant: TV.BASE,
                      style: { marginBottom: 26 }
                    },
                    pdf: {
                      variant: TV.S2,
                      style: { marginBottom: 8 }
                    }
                  }
                },
                {
                  component: 'Typography',
                  props: {
                    value: 'Tax Amount',
                    default: {
                      variant: TV.BASE,
                      style: { marginBottom: 32 }
                    },
                    pdf: {
                      variant: TV.S2,
                      style: { marginBottom: 16 }
                    }
                  }
                },
                {
                  component: 'Typography',
                  props: {
                    weight: TW.BOLD,
                    value: 'Total',
                    default: {
                      variant: TV.BASE,
                      style: { marginBottom: 32 }
                    },
                    pdf: {
                      variant: TV.S2,
                      style: { marginBottom: 16 }
                    }
                  }
                }
              ]
            },

            // values
            {
              props: {
                style: {
                  pdf: {
                    minWidth: 67,
                    marginLeft: 32
                  },
                  default: {
                    minWidth: 90,
                    marginLeft: 40
                  },
                  alignItems: 'flex-end'
                }
              },
              contents: [
                {
                  component: 'Field',
                  source: 'subtotal',
                  props: {
                    variant: { default: TV.BASE, pdf: TV.S2 },
                    weight: TW.BOLD,
                    type: FieldType.CURRENCY
                  }
                },
                {
                  component: 'Divider',
                  props: {
                    color: '#000000',
                    margin: { default: 15.5, pdf: 7.5 },
                    alignSelf: 'stretch'
                  }
                },
                {
                  component: 'Field',
                  source: 'freightCost',
                  props: {
                    variant: { default: TV.BASE, pdf: TV.S2 },
                    weight: TW.BOLD,
                    type: FieldType.CURRENCY,
                    style: { marginBottom: 8 }
                  }
                },
                {
                  component: 'Field',
                  source: 'taxRate',
                  props: {
                    variant: { default: TV.BASE, pdf: TV.S2 },
                    weight: TW.BOLD,
                    type: FieldType.PERCENTAGE,
                    style: { marginBottom: 8 }
                  }
                },
                {
                  component: 'Field',
                  source: 'taxAmount',
                  props: {
                    variant: { default: TV.BASE, pdf: TV.S2 },
                    weight: TW.BOLD,
                    type: FieldType.CURRENCY
                  }
                },
                {
                  component: 'Divider',
                  props: {
                    color: '#000000',
                    margin: { default: 15.5, pdf: 7.5 },
                    alignSelf: 'stretch'
                  }
                },
                {
                  component: 'Field',
                  source: 'total',
                  props: {
                    variant: { default: TV.BASE, pdf: TV.S2 },
                    weight: TW.BOLD,
                    type: FieldType.CURRENCY,
                    style: { marginBottom: 16 }
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  }
};

export default PDFDocumentLayout;
