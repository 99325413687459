export const discountsFragment = `
items {
  id
  sortKey
  createdDateTime
  itemName
  reasonForDiscount
  departmentName
  accountingRefIdOfClass
  taxable
  version
  amount
  includeInInvoice
}`;

export const feesFragment = `
items {
  id
  sortKey
  createdDateTime
  itemName
  reasonForFee
  departmentName
  accountingRefIdOfClass
  taxable
  version
  amount
  includeInInvoice
}
`;

// update optimisticResponseFactory in useAddInventoryParts if you change this fragment
export const inventoryPartFragment = `
  id
  addedBy
  amount
  createdBy
  departmentName
  description
  includeInInvoice
  markup
  name: itemName
  quantity
  taxable
  unitCost
  unitPrice
  vendorName
  version
  costCode(entityConnection: "CostCode") {
    id
    name
  }
  jobCostType(entityConnection: "jobCostType") {
    id
    name
    type
  }
  priceBookEntry(entityConnection: "PriceBookEntry") {
    id
    product(entityConnection: "Product") {
      id
      unitOfMeasure(entityConnection: "UnitOfMeasure") {
        id
        name
      }
    }
  }
  revenueType(entityConnection: "revenueType") {
    id
    name
    type
  }
  truck(entityConnection: "Truck") {
    id
    name
  }
`;

export const inventoryPartsFragment = `
  items {
    ${inventoryPartFragment}
  }
`;

export const purchasedOrderFragment = `
  id
  vendorName
  receiptNumber
  poNumber
  createdBy
  dateOfPurchase
  totalAmountPreTax
  tax
  departmentName
  version
  purchaseOrderLines(entityConnection: "PurchaseOrderLine") {
    items {
      id
      itemName
      description
      quantity
      markup
      unitCost
      unitPrice
      taxable
      costCodeId
      jobCostTypeId
      revenueTypeId
      version
      parentId
      includeInInvoice
    }
  }
  purchaseOrderReceipts(entityConnection: "PurchaseOrderReceipt") {
    items {
      id
      parentId
      imageUrl
      fileName
      version
    }
  }
`;

export const formFragment = `
id
form {
  id
  name
  description
  viewType
  latestPublishedFormDefinition {
    id
    formDefinitionJson
  }
}
formDataJson
lastUpdatedDateTime
lastUpdatedBy
sortKey
version
`;

export const reportFragment = `
  id
  departmentName
  departmentId
  description
  entityType
  hierarchy
  createdDateTime
  lastUpdatedBy
  lastUpdatedDateTime
  partitionKey
  scheduledFor
  status
  submittedBy
  submittedDate
  visitNumber
  sortKey
  nonVisitEvents(entityConnection: "NonVisitEvent") {
    items {
      id
      name
      timekeepingLedgersView {
        items {
          id
          employeeName
          actualStartTimeUTC
          actualEndTimeUTC
        }
      }
      eventType {
        id
        name
        isBillable
      }
      timesheetNotes(entityConnection: "TimesheetNote") {
        items {
          id
          note
          subject
          createdBy
          lastUpdatedBy
          attachments(entityConnection: "Attachment") {
            items {
              fileUrl
              id
              _deleted
            }
          }
        }
      }
      timesheetEntries {
        items {
          cost
          timesheetPeriod {
            parentId
          }
          actualTotalDuration
          actualTotalDurationOverride
          manualApprovedDate
          manualApprovedBy
          manualStatus
          createdBy
          lastUpdatedBy
          lastUpdatedDateTime
          hourType {
            hourType
            id
            hourTypeAbbreviation
          }
        }
      }
    }
  }
  job: parentEntity {
    ... on Job {
      id
      jobNumber
      closeoutReport
      customIdentifier
      jobTypeName
      jobTypeInternal
      priceBookId
      totalBudgetedHours
      totalUsedTime
      issueDescription
      owner(entityConnection: "owner") {
        id
        name
      }
      billingCustomer(entityConnection: "BillingCustomer") {
        id
        customerName
        companyAddresses(entityConnection: "CompanyAddress") {
          items {
            id
            addressType
            addressLine1
            addressLine2
            billTo
            city
            isActive
            lastUpdatedDateTime
            state
            zipcode
          }
        }
      }
      customer {
        id
        customerName
        companyAddresses(entityConnection: "CompanyAddress") {
          items {
            id
            addressType
            addressLine1
            addressLine2
            city
            state
            zipcode
            billTo
          }
        }
      }
      invoices(entityConnection: "Invoice") {
        items {
          id
          invoiceNumber
          isActive
          status
          invoiceVisits(entityConnection: "InvoiceVisit") {
            items {
              visit(entityConnection: "visit") {
                id
              }
            }
          }
        }
      }
      jobTasks {
        items {
          id
          task {
            id
            version
            name
            description
            taskNumber
            status
            isActive
            visitId
            createdBy
            lastUpdatedBy
            formData {
              items {
                id
                formDefinition {
                  formDefinitionJson
                }
                form {
                  id
                  name
                  description
                }
                formDataJson
                lastUpdatedDate
                lastUpdatedBy
              }
            }
            taskEntries {
              items {
                id
                description
                quantity
                name
              }
            }
            asset(entityConnection: "PropertyAsset") {
              id
              assetName
            }
          }
        }
      }
      customerProperty(entityConnection: "CustomerProperty") {
        id
        companyName
        billTo
        companyAddresses(entityConnection: "CompanyAddress") {
          items {
            id
            addressType
            addressLine1
            addressLine2
            city
            state
            zipcode
            billTo
            latitude
            longitude
          }
        }
        tasks {
          items {
            id
            status
            taskTypeInternal
            visitId
            assetId
            quoteId
            parentId
            taskNumber
            name
            description
            createdBy
            lastUpdatedBy
            isActive
            version
            formData {
              items {
                id
                formDefinition {
                  formDefinitionJson
                }
                formDataJson
                lastUpdatedBy
                lastUpdatedDate
              }
            }
            jobs {
              id
              jobNumber
              customIdentifier
              jobTypeInternal
            }
            taskEntries {
              items {
                id
                quantity
                name
              }
            }
            asset(entityConnection: "PropertyAsset") {
              id
              assetName
            }
          }
        }
      }
    }
  }
  extraTechs(entityConnection: "VisitExtraTech") {
    items {
      id
      mappedEntity {
        ... on Employee {
          id
          name
        }
      }
    }
  }
  primaryTechs(entityConnection: "VisitPrimaryTech") {
    items {
      id
      mappedEntity {
        ... on Employee {
          id
          name
        }
      }
    }
  }
  notes(entityConnection: "Note") {
    items {
      id
      subject
      note
      lastUpdatedBy
      lastUpdatedDateTime
      addedBy: lastUpdatedBy
      addedDateTime: lastUpdatedDateTime
    }
  }
  summaries(entityConnection: "Summary") {
    items {
      id
      summary
      lastUpdatedBy
      lastUpdatedDateTime
    }
  }
  assets: visitAssets(
    entityConnection: "VisitAsset"
    filter: { propertyAssetId: { ne: "null" } }
  ) {
    items {
      id
      asset: propertyAsset(entityConnection: "PropertyAsset") {
        id
        assetName
        make
        modelNumber
        serialNo
        installDate
        propertyZone
        location
        imageUrl
      }
    }
  }
  formData(entityConnection: "FormData") {
    items {
      ${formFragment}
    }
  }
  attachments(entityConnection: "Attachment") {
    items {
      id
      tenantId
      fileName
      customFileName
      fileUrl
      type
      createdDate
      createdBy
      comment
    }
  }
  primaryTechs(entityConnection: "VisitPrimaryTech") {
    items {
      id
      mappedEntity {
        ... on Employee {
          id
          firstName
          lastName
          truckEmployeeView(entityConnection: "TruckEmployee") {
            items {
              id
              parentEntity {
                ... on Truck {
                  id
                  name
                  isActive
                }
              }
            }
          }
        }
      }
    }
  }
  extraTechs(entityConnection: "VisitExtraTech") {
    items {
      id
      mappedEntity {
        ... on Employee {
          id
          firstName
          lastName
          truckEmployeeView(entityConnection: "TruckEmployee") {
            items {
              id
              parentEntity {
                ... on Truck {
                  id
                  name
                  isActive
                }
              }
            }
          }
        }
      }
    }
  }
  customerSignatures(entityConnection: "CustomerSignature") {
    items {
      id
      version
      nameOfSignee
      signatureImageUrl
      includeInInvoice
      capturedBy
      signedDateTime
      createdDate
      createdBy
      visit {
        visitNumber
      }
    }
  }
  inventoryParts(entityConnection: "InventoryPart") {
    ${inventoryPartsFragment}
  }
  timesheetNotes(entityConnection: "TimesheetNote") {
    items {
      id
      note
      subject
      createdBy
      lastUpdatedBy
      attachments(entityConnection: "Attachment") {
        items {
          fileUrl
          id
          _deleted
        }
      }
    }
  }
  timeSheets(entityConnection: "TimeSheet") {
    items {
      id
      createdBy
      clockInTime
      clockOutTime
      totalDuration
      labourType
      lastUpdatedBy
      lastUpdatedDateTime
      gpsLocations(entityConnection: "GpsLocation") {
        items {
          id
          latitude
          longitude
          userAction
        }
      }
    }
  }
  timesheetEntries: timesheetEntriesView(entityConnection: "TimesheetEntry") {
    items {
      id
      reviewStatus
      approvalStatus
      actualStartTimeUTC
      actualStartTimeOverrideUTC
      actualEndTimeUTC
      actualEndTimeOverrideUTC
      actualTotalDuration
      actualTotalDurationOverride
      lastUpdatedBy
      lastUpdatedDateTime
      createdBy
      cost
      hourTypeId
      hourType {
        id
        hourType
        hourTypeAbbreviation
      }
      startLatitude
      startLongitude
      endLatitude
      endLongitude
      userActionType
      billableEntityId
      entryType
      manualStatus
      manualApprovedBy
      manualApprovedDate
      timesheetPeriod {
        parentId
      }
      timekeepingLedger(entityConnection: "TimekeepingLedger") {
        id
        employeeName
        billableEntityType
        eventType(entityConnection: "EventType") {
          id
          name
          isBillable
        }
        eventEntity(entityConnection: "EventEntity") {
          ... on NonVisitEvent {
            id
            name
            timesheetNotes(entityConnection: "TimesheetNote") {
              items {
                id
                note
                subject
                createdBy
                attachments(entityConnection: "Attachment") {
                  items {
                    fileUrl
                    id
                    _deleted
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  reviewReports(entityConnection: "ReviewReport") {
    items {
      id
    }
  }
  purchaseOrders(entityConnection: "PurchaseOrder") {
    items {
        ${purchasedOrderFragment}
    }
  }
`;

export const laborLineItemFragment = `
  id
  employeeId
  labourTypeId
  departmentId
  version
  createdDate
  createdDateTime
  labourRateBillingHourLines(entityConnection: "LabourRateBillingHourLine") {
    items {
      id
      rate
      version
      hourTypeId
      totalHours
      costCodeId
      revenueTypeId
    }
  }
`;

export const billLineFragment = `
  billLine {
    billId
    jobId
    invoicedStatus
    remainingQuantity
    product {
      description
      name
    }
    bill {
      billNumber
      purchaseOrderReceipt {
        imageUrl
        receiptNumber
      }
      purchaseOrder {
        addedBy
        createdBy
        receiptNumber
        vendorName
        poNumber
        dateOfPurchase
        totalAmountPreTax
      }
    }
  }
`;

export const reviewReportBillLinesFragment = `
  reviewReportBillItems(limit: 100000) {
    items {
      id
      billLineId
      quantity
      markup
      unitCost
      unitPrice
      createdBy
      createdDate
      description
      taxable
      costCodeId
      jobCostTypeId
      revenueTypeId
      ${billLineFragment}
    }
  }
`;

export const jobCostingFragment = `
  derivedCostCodeValues {
    costCodeName
    costCodeId
    costActual
    costBudgeted
    costVariance
    revenuePercentage
    revenueActual
    revenueBudgeted
    revenueVariance
    profitActual
    profitBudgeted
    profitVariance
    marginActual
    marginBudgeted
    marginVariance
  }
  derivedJobCostTypeValues {
    jobCostTypeName
    jobCostTypeId
    costActual
    costBudgeted
    costVariance
    revenuePercentage
    revenueActual
    revenueBudgeted
    revenueVariance
    profitActual
    profitBudgeted
    profitVariance
    marginActual
    marginBudgeted
    marginVariance
  }
  derivedJobCostingValues {
    costActual
    costBudgeted
    costVariance
    revenueActual
    revenueBudgeted
    revenueVariance
    profitActual
    profitBudgeted
    profitVariance
    marginActual
    marginBudgeted
    marginVariance
  }
  costItems {
    itemName
    costActual
    revenuePercentage
    departmentName
    costCodeName
    costCodeId
    jobCostTypeId
    jobCostTypeName
  }
`;
