import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  labelWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 160
  },
  label: {
    fontSize: 12,
    backgroundColor: 'white',
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    borderRadius: 4,
    border: 'solid gray 1px',
    maxHeight: 20,
    textAlign: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
}));
