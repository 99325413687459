import { makeStyles } from '@material-ui/core';

import { CONFIG } from '@dispatch/Dispatch.constants';
import techAvatarImg from '@dispatch/assets/images/avatar.png';

export const useStyles = makeStyles(theme => ({
  techPhoto: ({ profilePictureUrl }) => ({
    borderRadius: '100%',
    backgroundImage: `url('${
      profilePictureUrl ? CONFIG.CLOUDINARY_IMAGE_URL + profilePictureUrl : techAvatarImg
    }')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: 'white',
    height: 40,
    width: 40,
    boxShadow: theme.shadows[8]
  })
}));
