import React, { useState } from 'react';
import {
  TW,
  Button,
  ButtonType,
  Field,
  FieldType,
  CurrencyInput,
  ThemeProvider,
  ButtonSize
} from '@buildhero/sergeant';
import { roundCurrency, convertToCurrencyString, compareCurrencyFloats } from 'utils';
import { Tooltip } from '@material-ui/core';
import { TransactionType } from 'utils/constants';
import { min } from 'lodash';

function ApplicationCell({
  record,
  transactionType,
  onChange,
  applicationMapRef,
  availableAmountRef
}) {
  const [title, setTitle] = useState();
  const [amountToApply, setAmountToApply] = useState(
    applicationMapRef.current[record.id]?.appliedAmount
  );
  const [inputValue, setInputValue] = useState(applicationMapRef.current[record.id]?.appliedAmount);
  const [error, setError] = useState();

  const validate = () => {
    let err;
    const newAmount = roundCurrency(inputValue);
    // For refund, can apply up to invoice total amount. Otherwise, up to balance
    const maxAmount = min([
      availableAmountRef.current,
      transactionType === TransactionType.REFUND ? record.totalAmount : record.adjustedBalance
    ]);

    if (availableAmountRef.current === 0) {
      err = 'No available amount';
    } else if (compareCurrencyFloats(maxAmount, newAmount) < 0) {
      err = `Can't apply more than ${convertToCurrencyString(maxAmount)}`;
    } else if (!newAmount || newAmount < 0.01) {
      err = 'Must be greater than $0.01';
      if (inputValue === undefined)
        setTimeout(
          () => setError(prev => (prev === 'Must be greater than $0.01' ? undefined : prev)),
          3000
        );
    }
    setError(err);
    return err;
  };

  const handleApply = () => {
    if (validate()) return;
    const newAmount = roundCurrency(inputValue);
    // eslint-disable-next-line no-param-reassign
    applicationMapRef.current[record.id] = {
      ...applicationMapRef.current[record.id],
      transactionId: record.id,
      appliedAmount: newAmount
    };
    onChange(-newAmount);
    setAmountToApply(newAmount);
  };

  const handleUnapply = () => {
    const prev = applicationMapRef.current[record.id];
    if (prev?.id) {
      // adjustment transaction exists, so we put 0 to indicate delete
      prev.appliedAmount = 0;
    } else {
      // eslint-disable-next-line no-param-reassign
      delete applicationMapRef.current[record.id];
    }
    onChange(amountToApply);
    setAmountToApply(0);
  };

  return (
    <ThemeProvider>
      <div
        style={{
          display: 'flex',
          flex: 'auto',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: 8,
          padding: 4
        }}
      >
        {amountToApply ? (
          <>
            <Field weight={TW.BOLD} type={FieldType.CURRENCY} value={amountToApply || 0} />
            <Button
              type={ButtonType.SECONDARY}
              size={ButtonSize.SMALL}
              style={{ minWidth: 'fit-content' }}
              onClick={handleUnapply}
            >
              Cancel
            </Button>
          </>
        ) : (
          <>
            <Tooltip
              onMouseEnter={() => {
                if (error) validate();
                setTitle(`Available: ${convertToCurrencyString(availableAmountRef.current)}`);
              }}
              title={title}
              placement="top"
              PopperProps={{
                popperOptions: {
                  modifiers: {
                    flip: {
                      enabled: false
                    },
                    offset: {
                      enabled: true,
                      offset: '0,-10'
                    }
                  }
                }
              }}
            >
              <span>
                <CurrencyInput
                  error={!!error}
                  subtext={error}
                  value={inputValue}
                  onChange={v => setInputValue(v)}
                  onBlur={() => inputValue !== undefined && validate()}
                  localeStringOptions={{
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }}
                />
              </span>
            </Tooltip>
            <Button
              type={ButtonType.SECONDARY}
              size={ButtonSize.SMALL}
              style={{ minWidth: 'fit-content' }}
              onClick={handleApply}
            >
              Apply
            </Button>
          </>
        )}
      </div>
    </ThemeProvider>
  );
}

// export default ApplicationCell;
export default React.memo(ApplicationCell);
