import { isEmpty } from 'lodash';

export const APRIL_30_2021 = 1619827200; // date before which we are not concerned with corrupt timesheet entries.

// @TODO in BUOP-13578 consolidate with access constants in src/components/ResponsiveTable/ViewHelper.js
export const AccessConstants = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC'
};

export const AppConstants = {
  ONETOMANY: '1toM',
  MANYTOMANY: 'MtoM',
  ONETOONE: '1to1',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DEACTIVATED: 'deactivated',
  UPCOMING_EVENTS: ['Scheduled', 'Unassigned'],
  PAST_EVENTS: ['Complete', 'On hold'],
  DATETIME_FORMAT: 'MMM D, YYYY h:mma',
  DATETIME_FORMAT_WITH_TIMEZONE: 'MMM D, YYYY h:mma z',
  DATE_FORMAT: 'MMM D, YYYY',
  TIMESHEET_DATE_FORMAT: 'dddd MMM D, YYYY',
  ROUND_OFF_VALUE: 2,
  QUOTE_VERSIONS_DATE_FORMAT: 'hh:mmA - MMM D, YYYY'
};

export const VISIT_REVIEW_STATUS = {
  UNREVIEWED: 'UNREVIEWED',
  REVIEWED: 'REVIEWED'
};

export const LeaveDateModalStatus = 'leaveDateModalStatus';

export const getDateFormat = (dateType = 'date') => {
  if (dateType.toLowerCase?.() === 'datetime') return AppConstants.DATETIME_FORMAT;
  return AppConstants.DATE_FORMAT;
};

export const ProjectConstants = {
  Projects: 'Projects'
};

export const PriorityStatus = {
  URGENT: 'Urgent',
  HIGH: 'High',
  NORMAL: 'Normal',
  LOW: 'Low'
};

export const QuoteConstants = {
  Draft: 'Draft',
  Finalized: 'Finalized',
  SentToCustomer: 'Sent to customer',
  EmailRead: 'Email read',
  EmailBounced: 'Email bounced',
  CustomerViewed: 'Customer viewed',
  Rejected: 'Rejected',
  Approved: 'Approved',
  JobAdded: 'JobAdded',
  ProjectAdded: 'ProjectAdded',
  Cancelled: 'Cancelled',
  InQuote: 'In quote',
  Discarded: 'Discarded',
  Landline: 'Landline',
  VersionedQuote: 'VersionedQuote'
};

export const IntegrationConstants = {
  SAGE: 'SAGE',
  JOB_COST_ACCOUNTING_APPS: ['SAGE']
};

export const JobStatus = {
  OPEN: 'Open',
  IN_PROGRESS: 'In Progress',
  ON_HOLD: 'On Hold',
  CANCELED: 'Canceled',
  COMPLETE: 'Complete',
  SCHEDULED: 'Scheduled',
  UNSCHEDULED: 'Unscheduled',
  EXPORTED: 'Exported'
};

export const JobProcurementStatus = {
  POS_IN_DRAFT: 'POs in Draft',
  POS_ORDERED: 'POs Ordered',
  POS_PARTIALLY_RECEIVED: 'POs Partially Received',
  POS_RECEIVED: 'POs Received',
  NO_POS: 'No POs',
  POS_NEEDED: 'POs Needed'
};

export const jobQuoteStatus = {
  NO_QUOTES: 'No Quotes',
  QUOTE_NEEDED: 'Quote Needed',
  QUOTE_IN_DRAFT: 'Quote in Draft',
  QUOTE_SENT: 'Quote Sent',
  QUOTE_APPROVED: 'Quote Approved',
  QUOTE_REJECTED: 'Quote Rejected'
};

export const JOB_CLOSEOUT_STATUS = {
  REVIEWED: 'Reviewed',
  REVIEW_NEEDED: 'Review Needed',
  NO_REVIEW_NEEDED: 'No Review Needed'
};

export const JOB_REPORT_STATUS = {
  PROCESSING: 'Processing',
  READY: 'Ready',
  ERROR: 'Error'
};

export const QuoteStatus = {
  DRAFT: 'Draft',
  SENT_TO_CUSTOMER: 'SentToCustomer',
  EMAIL_READ: 'EmailRead',
  EMAIL_BOUNCED: 'EmailBounced',
  CUSTOMER_VIEWED: 'CustomerViewed',
  REJECTED: 'Rejected',
  APPROVED: 'Approved',
  JOB_ADDED: 'JobAdded',
  PROJECT_ADDED: 'ProjectAdded',
  CANCELLED: 'Cancelled',
  DISCARDED: 'Discarded'
};

export const QuoteBillingStatus = {
  BILLED: 'Billed'
};

export const QuoteAddedItem = {
  NONE: 'NONE',
  JOB: 'JOB',
  PROJECT: 'PROJECT'
};

export const BillLineItemStatus = {
  NOT_INVOICED: 'NotInvoiced',
  PARTIALLY_INVOICED: 'PartiallyInvoiced',
  INVOICED: 'Invoiced'
};

export const JobTypes = {
  JOB: 'Job',
  MAINTENANCE: 'Maintenance'
};

export const JobBillingStatus = {
  DO_NOT_INVOICE: 'DoNotInvoice',
  NOT_INVOICED: 'NotInvoiced',
  READY_TO_INVOICE: 'ReadyToInvoice',
  PARTIALLY_INVOICED: 'PartiallyInvoiced',
  FULLY_INVOICED: 'FullyInvoiced'
};

export const LineItemBillingStatus = {
  NOT_INVOICED: 'NotInvoiced',
  BILLED: 'Billed',
  DO_NOT_INVOICE: 'DoNotInvoice'
};

export const VisitStatus = {
  UNASSIGNED: 'Unassigned',
  SCHEDULED: 'Scheduled',
  TRAVELING: 'Traveling',
  WORKING: 'Working',
  ONHOLD: 'On hold',
  PAUSED: 'Paused',
  CANCELED: 'Canceled',
  COMPLETE: 'Complete',
  CLOSED: 'Closed',
  CONVERTED: 'Converted'
};

export const AttachmentTypes = {
  BEFORE: 'before',
  AFTER: 'after',
  CUSTOMER_SIGNATURE: 'customerSignature'
};

export const TechReportStatus = {
  PROCESSING: 'Processing',
  ERROR: 'Error'
};

export const ReviewReportStatus = {
  SUBMITTED: 'submitted',
  DRAFT: 'draft',
  DISMISSED: 'dismissed',
  INVOICED: 'invoiced'
};

export const TaskConstants = {
  OPEN: 'Open',
  PENDING: 'Pending',
  REJECTED: 'Rejected',
  CANCELLED: 'Cancelled',
  INQUOTE: 'InQuote',
  APPROVED: 'Approved',
  COMPLETED: 'Completed',
  INJOB: 'InJob'
};

export const TaskListType = {
  ALL: 'All',
  RECOMMENDED_TASKS: 'RecommendedTasks',
  RECOMMENDED_TASKS_QUOTED: 'RecommendedTasksQuoted',
  RECOMMENDED_TASKS_UNQUOTED: 'RecommendedTasksUnQuoted',
  READY_FOR_JOB: 'ReadyForJob',
  ADDED_TO_JOB: 'AddedToJob',
  COMPLETED_TASKS: 'CompletedTasks'
};

export const ProjectManagementSubmittalStatus = {
  TO_BE_SUBMITTED: 'To be Submitted',
  PENDING: 'Pending',
  PACKAGED: 'Packaged',
  SENT: 'Sent',
  REJECTED: 'Rejected',
  APPROVED: 'Approved',
  APPROVED_AS_NOTED: 'Approved as Noted',
  RESUBMIT: 'Resubmit'
};

export const ProjectManagementRFIStatus = {
  SENT: 'sent',
  DRAFT: 'draft',
  RESPONDED: 'responded',
  CLOSED: 'closed'
};

export const ProjectManagementPayApplicationStatus = {
  POSTED: 'Posted',
  VOID: 'Void',
  DRAFT: 'Draft'
};

export const ProcurementPurchaseOrderStatus = {
  DRAFT: 'Draft',
  ORDERED: 'Ordered',
  PARTIALLY_FULFILLED: 'Partially Fulfilled',
  FULFILLED: 'Fulfilled',
  VOID: 'Void'
};

export const TaskStatus = {
  IN_JOB: 'InJob',
  OPEN: 'Open',
  COMPLETED: 'Completed',
  IN_QUOTE: 'InQuote',
  APPROVED: 'Approved',
  REJECTED: 'Rejected'
};

export const TaskTypeInternal = {
  RECOMMENDED: 'Recommended',
  PENDING: 'Pending',
  GENERAL: 'General'
};

export const OpenTaskStatus = {
  OPEN: 'Open',
  ADDED_TO_WORK: 'Added To Work',
  // not a direct mapping to backend status, used for UI rendering with different text in select place
  ADDED_TO_QUOTE: 'Added To Quote', // InQuote in backend
  UNQUOTED: 'Unquoted' // Open status is backed
};
export const ProcurementPurchaseOrderReceiptStatus = {
  CLOSED: 'Closed',
  PENDING: 'Pending',
  POSTED: 'Posted',
  EXPORTED: 'Exported'
};

export const PermissionConstants = {
  DATA_PAYROLL_RATES_ON_REPORTS: 'Data_View Labor Rates',
  DATA_VIEW_COST_DATA: 'Data_View Cost Data',
  DATA_VIEW_PRICE_DATA: 'Data_View Price Data',
  DATA_VIEW_NTE_DATA: 'Data_View NTE Data',
  DATA_SHOW_FINANCIAL_DATA: 'Data_Show Financial Data',
  DATA_VIEW_EDIT_EMPLOYEE_HOURLY_RATES: 'Data_View and Edit Employee Hourly Rates',
  MENU_SETTINGS: 'Menu_Settings',
  MENU_DASHBOARD: 'Menu_Dashboard',
  MENU_FOLLOW_UPS: 'Menu_Follow ups',
  MENU_DISPATCH: 'Menu_Dispatch',
  MENU_LISTS: 'Menu_Lists',
  MENU_ACCOUNTING: 'Menu_Accounting',
  MENU_TIMESHEETS: 'Menu_Timesheets',
  MENU_WORKFLOWS: 'Menu_Workflows',
  MENU_PROCUREMENT: 'Menu_Procurement',
  MENU_INVENTORY: 'Menu_Inventory',
  MENU_SERVICE_AGREEMENTS: 'Menu_Service Agreements',
  MENU_INTEGRATIONS: 'Menu_Integrations',
  MENU_SERVICE_CHANNEL: 'Menu_Service Channel',
  MENU_PAYROLLSETTING: 'Menu_Payroll Settings',
  MENU_PROJECT_MANAGEMENT: 'Menu_Project Management',
  MENU_PRICEBOOKS: 'Menu_Pricebooks',
  MENU_REPORTING: 'Menu_Reporting',
  MENU_METABASE: 'Menu_Reports And Dashboards',
  FUNCTIONS_MOBILE: 'Functions_Mobile',
  FUNCTIONS_WEB: 'Functions_Web',
  FUNCTIONS_DISPATCHABLE: 'Functions_Dispatchable',
  FUNCTIONS_ADMIN: 'Functions_Admin',
  FUNCTIONS_TIMETRACKING_REPORT: 'Functions_TimeTrackingReport',
  FUNCTIONS_JOB_COMPLETE_MOBILE: 'Functions_Job complete from mobile',
  OBJECT_CUSTOMER: 'Object_Customer',
  OBJECT_PROPERTY: 'Object_Property',
  OBJECT_JOB: 'Object_Job',
  OBJECT_TASK: 'Object_Task',
  OBJECT_VISIT: 'Object_Visit',
  OBJECT_ASSET: 'Object_Asset',
  OBJECT_QUOTES: 'Object_Quotes',
  OBJECT_EMPLOYEE: 'Object_Employee',
  OBJECT_INVOICE: 'Object_Invoice',
  OBJECT_REVIEWREPORT: 'Object_ReviewReport',
  OBJECT_TIMESHEETS: 'Object_Timesheets',
  OBJECT_WORKFLOWS: 'Object_Workflows',
  OBJECT_INVENTORTY_TRUCK: 'Object_Truck',
  OBJECT_INVENTORTY: 'Object_Product',
  OBJECT_TIMETRACKINGSETTING: 'Object_TimetrackingSetting',
  OBJECT_SERVICE_AGREEMENT: 'Object_ServiceAgreement',
  OBJECT_PURCHASE_ORDER: 'Object_PurchaseOrder',
  OBJECT_PM_DASHBOARD: 'Object_PM Dashboard',
  OBJECT_PM_FIELD_REPORT: 'Object_PM FieldReport',
  OBJECT_PM_RFI: 'Object_PM RFI',
  OBJECT_PM_CHANGE_ORDER: 'Object_PM ChangeOrder',
  OBJECT_PM_SUBMITTALS: 'Object_PM Submittals',
  OBJECT_PM_PURCHASING: 'Object_PM Purchasing',
  OBJECT_PM_FINANCE: 'Object_PM Finance',
  OBJECT_PM_PROJECT_SETTINGS: 'Object_PM ProjectSettings',
  // For tenant admins, irrespective of casl key, it will always be true
  ONLY_ADMIN: 'TenantAdmin'
};

export const FeatureGateConstants = {
  ITEMS_USED: 'job_productLines',
  ASSET_TRACKING: 'assetTracking',
  SERVICE_AGREEMENTS: 'serviceAgreements',
  MULTIPLE_PRICE_BOOKS: 'multiplePricebooks'
};

// To be used only for dev purposes
// export const GOOGLE_MAPS_API_KEY = 'AIzaSyAvkqqmzIqdyCESmWIqUTgvDRCdUsxzUgU';

export const GOOGLE_MAPS_API_KEY = 'AIzaSyB-dxW5yrel4kY3Ma_5qAQMtHTK_P-xaJ0';

export const TimeTrackingSettings = {
  ALL_TIMESTAMPS: 'All timestamps',
  DAILY_TIMESTAMPS: 'Daily shift timestamps only',
  BILLABLE_NON_VISIT: 'BillableNonVisit',
  NON_BILLABLE: 'NonBillable'
};

export const EmployeeStatus = {
  ACTIVE: 'active',
  INVITED: 'invited',
  DEACTIVATED: 'deactivated',
  CREATED: 'created', // Employee entity was created but no login was created
  INACTIVE: 'inactive' // Deprecated employee status. used to be active vs inactive I think
};

export const EmployeeAction = {
  INVITE: 'invite',
  ENABLE_LOGIN: 'enableLogin',
  DISABLE_LOGIN: 'disableLogin',
  DEACTIVATE: 'deactivate',
  ACTIVATE: 'activate',
  RESEND_CONFIRMATION_EMAIL: 'resendConfirmationEmail'
};

export const TimesheetWorkTypes = {
  WORK: 'Work',
  TRAVEL: 'Travel',
  Pause: 'Pause',
  Break: 'Break',
  Event: 'billableEvents' // Frontend mapping for billable Non visit events
};

export const ManualTimesheetWorkTypes = {
  COMPLETE: 'Complete',
  WORKING: 'Working',
  ONABREAK: 'OnABreak',
  TRAVELING: 'Traveling',
  WORKDONE: 'WorkDone',
  PAUSED: 'Paused',
  NONVISIT: 'BillableNonVisit'
};

export const GoogleMapsComponentType = {
  STATE: 'administrative_area_level_1',
  COUNTY: 'administrative_area_level_2',
  CITY: 'locality',
  COUNTRY: 'country',
  ZIPCODE: 'postal_code'
};

export const GoogleMapsComponentTypeToAddress = {
  administrative_area_level_1: 'state',
  administrative_area_level_2: 'county',
  locality: 'city',
  country: 'country',
  postal_code: 'zipcode',
  street_number: 'streetNumber',
  route: 'street'
};

export const FormEntityType = {
  VISIT: 'Visit',
  JOB: 'Job'
};

export const MaintenanceStatus = {
  OVERDUE: 'Overdue',
  UNSCHEDULED: 'Unscheduled',
  SCHEDULED: 'Scheduled',
  SKIPPED: 'Skipped',
  COMPLETE: 'Complete'
};

export const ServiceAgreementStatus = {
  DRAFT: 'Draft',
  APPROVED: 'Approved',
  ACTIVE: 'Active',
  EXPIRED: 'Expired',
  CANCELED: 'Canceled',
  CANCELLED: 'Cancelled',
  ACTIVATING: 'Activating'
};

export const ActiveStatus = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
};

export const AddressType = {
  PROPERTY: 'propertyAddress',
  BILLING: 'billingAddress'
};

export const LayoutType = {
  MAIN: 'main',
  SIDE: 'side'
};

export const MultiSelectTypes = {
  PRIORITY_STATUS: 'PRIORITY_STATUS',
  JOB_TYPES: 'jobTypes',
  JOB_TAGS: 'jobTags',
  JOB_STATUS: 'JOB_STATUS',
  MAINTENANCE_STATUS: 'MAINTENANCE_STATUS',
  CUSTOMER_STATUS: 'CUSTOMER_STATUS',
  PROPERTY_STATUS: 'PROPERTY_STATUS',
  QUOTE_STATUS: 'quoteStatus',
  DEPARTMENTS: 'departments',
  CUSTOMER_TYPES: 'customerTypes',
  CUSTOMER_TAGS: 'customerTags',
  CUSTOMER_PROPERTY_TYPES: 'customerPropertyTypes',
  INVOICE_STATUS: 'invoiceStatus',
  INVOICE_BILLING_STATUS: 'INVOICE_BILLING_STATUS',
  VISIT_STATUS: 'visitStatus',
  VISIT_REVIEW_STATUS: 'VISIT_REVIEW_STATUS',
  REVIEW_REPORT_STATUS: 'reviewReportStatus',
  INVOICE_TAGS: 'invoiceTags',
  QUOTE_TAGS: 'quoteTags',
  PAYMENT_STATUS: 'paymentStatus',
  EXPORT_STATUS: 'exportStatus',
  WIP_REPORT_STATUS: 'wipReportStatus',
  SERVICE_AGREEMENT_STATUS: 'SERVICE_AGREEMENT_STATUS',
  PURCHASE_ORDER_STATUS: 'purchaseOrderStatus',
  PURCHASE_ORDER_TAGS: 'purchaseOrderTags',
  PURCHASE_ORDER_RECEIPT_STATUS: 'purchaseOrderReceiptStatus',
  SERVICE_CHANNEL_WORK_ORDER_STATUS: 'SERVICE_CHANNEL_WORK_ORDER_STATUS',
  OPEN_TASK_STATUS: 'OPEN_TASK_STATUS',
  JOB_PROCUREMENT_STATUS: 'JOB_PROCUREMENT_STATUS',
  JOB_BILLING_STATUS: 'JOB_BILLING_STATUS',
  JOB_CLOSEOUT_STATUS: 'JOB_CLOSEOUT_STATUS',
  JOB_QUOTE_STATUS: 'JOB_QUOTE_STATUS'
};

export const TagType = {
  CUSTOMER: {
    name: 'customer',
    namePlural: 'customers',
    entityId: 'id',
    entityIdPlural: 'customerTagIds',
    entity: 'mappedEntity',
    entityPlural: 'customerTags',
    deleteKey: 'sortKey',
    update: async (service, payload) => service.updateCustomerTags(payload),
    destructureToEntityEntityTags: response =>
      response?.data?.updateCustomerAndRelated[0]?.customerTags?.items,
    delete: async ({ service, tenantId, tagToDelete, tagId }) =>
      service.deleteCustomerTags(tenantId, tagToDelete, tagId),
    structurePayload: ({
      partitionKey,
      id,
      version,
      newEntityEntityTags,
      existingEntityEntityTags,
      extra
    }) => ({
      partitionKey,
      data: {
        ...extra,
        customers: [
          {
            id,
            version,
            customerTags: [
              ...newEntityEntityTags,
              ...existingEntityEntityTags.map(({ value }) => ({
                id: value
              }))
            ]
          }
        ]
      }
    })
  },
  JOB: {
    name: 'job',
    namePlural: 'jobs',
    entityId: 'jobTagId',
    entityIdPlural: 'jobTagIds',
    entity: 'jobTag',
    entityPlural: 'jobTags',
    deleteKey: 'id',
    update: (service, payload) => service.updateJobTags(payload),
    destructureToEntityEntityTags: response =>
      response?.data?.updateJobAndRelated[0]?.jobJobTags?.items,
    delete: ({ service, tenantId, tagToDelete }) => service.deleteJobJobTag(tenantId, tagToDelete),
    structurePayload: ({ partitionKey, id, version, newEntityEntityTags, extra }) => {
      const { customerPropertyId, departments } = extra;
      return {
        partitionKey,
        data: {
          customerPropertyId,
          jobs: [
            {
              id,
              version,
              jobJobTags: newEntityEntityTags,
              departments
            }
          ]
        }
      };
    }
  },
  PURCHASE_ORDER: {
    name: 'purchaseOrder',
    namePlural: 'purchaseOrders',
    entityId: 'purchaseOrderTagId',
    entityIdPlural: 'purchaseOrderTagIds',
    entity: 'purchaseOrderTag',
    entityPlural: 'purchaseOrderTags',
    deleteKey: 'purchaseOrderTag.id',
    update: async (service, payload) => {
      const response = await service(payload.data.id, {
        purchaseOrderTags: payload.data.purchaseOrderTags,
        status: payload.data.status
      });
      if (!isEmpty(response)) {
        const updatedPO = await payload.utils.getPurchaseOrderById(payload.data.id);
        payload.utils.frameDataReducer({
          type: 'updateAll',
          payload: updatedPO
        });
        payload.utils.setPurchaseOrder(updatedPO);
      }
      return response;
    },
    delete: async (service, tagToDelete, extra) => {
      const updatedTags = extra.poTags.filter(tag => tag !== tagToDelete).map(tag => ({ id: tag }));
      const response = await service(extra.id, {
        purchaseOrderTags: updatedTags,
        status: extra?.originalPO?.status
      });
      if (!isEmpty(response)) {
        const updatedPO = await extra.getPurchaseOrderById(extra.id);
        extra.frameDataReducer({
          type: 'updateAll',
          payload: updatedPO
        });
        extra.setPurchaseOrder(updatedPO);
      }
      return response;
    },
    structurePayload: ({ id, extra, newEntityEntityTags }) => {
      const updatedTags = extra?.poTags
        .map(tag => ({ id: tag }))
        .concat(newEntityEntityTags.map(tag => ({ id: tag.purchaseOrderTagId })));
      return {
        utils: {
          getPurchaseOrderById: extra?.getPurchaseOrderById,
          frameDataReducer: extra?.frameDataReducer,
          setPurchaseOrder: extra?.setPurchaseOrder
        },
        data: {
          id,
          status: extra?.originalPO?.status,
          purchaseOrderTags: updatedTags
        }
      };
    }
  },
  QUOTE: {
    name: 'quote',
    namePlural: 'quotes',
    entityId: 'quoteTagId',
    entityIdPlural: 'quoteTagIds',
    entity: 'quoteTag',
    entityPlural: 'quoteTags',
    deleteKey: 'id',
    update: (service, payload) => service.updateQuoteTags(payload),
    destructureToEntityEntityTags: response =>
      response?.data?.addQuoteQuoteTagsToQuote || response?.data?.addQuoteQuoteTagsToVersionedQuote,
    delete: ({ service, tenantId, tagToDelete, info }) =>
      service.deleteQuoteQuoteTag(tenantId, tagToDelete, info?.id),
    structurePayload: ({ partitionKey, id, newEntityEntityTags, extra }) => {
      const formattedNewEntityEntityTags = newEntityEntityTags.map(item => ({
        ...item,
        quoteId: id
      }));
      return {
        id,
        primaryQuoteId: extra?.primaryQuoteId,
        params: {
          partitionKey,
          data: { quoteId: id, quoteQuoteTags: formattedNewEntityEntityTags }
        }
      };
    }
  },
  INVOICE: {
    name: 'invoice',
    namePlural: 'invoices',
    entityId: 'invoiceTagId',
    entityIdPlural: 'invoiceTagIds',
    entity: 'invoiceTag',
    entityPlural: 'invoiceTags',
    deleteKey: 'id',
    update: async (service, payload) => {
      const { version, ...rest } = payload;
      const response = await service.updateInvoiceTags(rest);

      // @TODO remove this hack once once BUOP-7499 on BE is sorted out.
      // updating version here to trigger an update on invoice entity on BE which updates algolia search.
      await service.updateInvoice(rest.partitionKey, {
        version,
        id: rest.data.invoiceId
      });

      return response;
    },
    destructureToEntityEntityTags: response => response?.data?.addInvoiceInvoiceTagsToInvoice,
    delete: async ({ service, tenantId, tagToDelete, info }) => {
      const response = await service.deleteInvoiceInvoiceTag(tenantId, tagToDelete);

      // @TODO remove this hack once once BUOP-7499 on BE is sorted out.
      // updating version here to trigger an update on invoice entity on BE which updates algolia search.
      await service.updateInvoice(tenantId, {
        ...info
      });

      return response;
    },
    structurePayload: ({ partitionKey, id, newEntityEntityTags, version }) => ({
      partitionKey,
      data: {
        invoiceId: id,
        invoiceInvoiceTags: newEntityEntityTags
      },
      version // @TODO remove this hack once BUOP-7499 on BE is sorted out.
    })
  },
  SERVICE_AGREEMENT: {
    name: 'serviceAgreement',
    namePlural: 'serviceAgreements',
    entityId: 'serviceAgreementTagId',
    entityIdPlural: 'serviceAgreementTagIds',
    entity: 'serviceAgreementTag',
    entityPlural: 'serviceAgreementTags',
    deleteKey: 'id',
    update: async (service, payload) => service.addTagsToServiceAgreement(payload),
    destructureToEntityEntityTags: response => response?.data?.addTagsToServiceAgreement,
    delete: async ({ service, tenantId, tagToDelete }) => {
      const response = await service.deleteServiceAgreementServiceAgreementTag(
        tenantId,
        tagToDelete
      );
      return response;
    },
    structurePayload: ({ partitionKey, id, newEntityEntityTags }) => ({
      partitionKey,
      data: {
        serviceAgreementId: id,
        serviceAgreementTags: newEntityEntityTags
      }
    })
  }
};

export const TimesheetReviewStatusTypes = {
  PENDING: 'PENDING',
  REVIEWED: 'REVIEWED',
  APPROVED: 'APPROVED'
};

export const TimeCardStatusTypes = {
  PENDING: 'PENDING',
  SUBMITTED: 'SUBMITTED',
  DISPUTED: 'DISPUTED',
  APPROVED: 'APPROVED'
};

export const StatusValToLabelMapping = (type, val) => {
  const STATUS_VAL_LABEL_MAP = {
    JOB_STATUS: {
      [JobStatus.OPEN]: 'Open', // TODO: No Visits Scheduled ~ Once XGrid filtering labels are updated
      [JobStatus.IN_PROGRESS]: 'In Progress', // Job In Progress ~ Once XGrid filtering labels are updated
      [JobStatus.COMPLETE]: 'Complete', // Job Complete ~ Once XGrid filtering labels are updated
      [JobStatus.ON_HOLD]: 'On Hold', // Job On Hold ~ Once XGrid filtering labels are updated
      [JobStatus.CANCELED]: 'Canceled' // Job Canceled ~ Once XGrid filtering labels are updated
    },
    JOB_BILLING_STATUS: {
      [JobBillingStatus.DO_NOT_INVOICE]: 'Do Not Invoice',
      [JobBillingStatus.FULLY_INVOICED]: 'Fully Invoiced',
      [JobBillingStatus.PARTIALLY_INVOICED]: 'Partially Invoiced',
      [JobBillingStatus.READY_TO_INVOICE]: 'Ready To Invoice',
      [JobBillingStatus.NOT_INVOICED]: 'Not Invoiced'
    },
    VISIT_REVIEW_STATUS: {
      [VISIT_REVIEW_STATUS.REVIEWED]: 'Reviewed',
      [VISIT_REVIEW_STATUS.UNREVIEWED]: 'Review Needed'
    },
    INVOICE_BILLING_STATUS: {
      [JobBillingStatus.DO_NOT_INVOICE]: 'Do Not Invoice',
      [JobBillingStatus.FULLY_INVOICED]: 'Fully Invoiced',
      [JobBillingStatus.PARTIALLY_INVOICED]: 'Partially Invoiced',
      [JobBillingStatus.READY_TO_INVOICE]: 'Ready To Invoice',
      [JobBillingStatus.NOT_INVOICED]: 'Not Invoiced'
    }
  };

  return (STATUS_VAL_LABEL_MAP[type] || {})[val];
};

export const getTimesheetStatusPriorities = () => {
  const { PENDING, REVIEWED, APPROVED } = TimesheetReviewStatusTypes;
  return new Map(Object.entries({ [PENDING]: 2, [REVIEWED]: 1, [APPROVED]: 0 }));
};

export default AppConstants;
