import React, { useState } from 'react';

import { bool, func } from 'prop-types';

import SergeantModal from 'components/SergeantModal';

import { useSnackbar } from 'customHooks/useSnackbar';

import { Logger } from 'services/Logger';

import FileManagerFormComponentWrapper from '../FileManagerFormComponentWrapper';

import { fetchFiles } from './AddFilesModal.helpers';

import { addFilesLayout } from './AddFilesModal.layout';

const AddFilesModal = ({ open, onClose, onFilesSelected }) => {
  const snackbar = useSnackbar();
  const [selectedFiles, setSelectFiles] = useState([]);

  const onSelectFile = file => {
    setSelectFiles([...selectedFiles, file]);
  };

  const onUnselectFile = file => {
    setSelectFiles(selectedFiles.filter(item => item.s3Url !== file.s3Url));
  };

  return (
    <SergeantModal
      open={open}
      title="Select Files"
      dataType="Select Files"
      customPrimaryButtonLabel="Attach Files"
      layout={addFilesLayout({ onSelectFile, onUnselectFile })}
      handlePrimaryAction={async (_, stopLoading) => {
        try {
          const files = await fetchFiles(selectedFiles);
          await onFilesSelected(files);
        } catch (error) {
          snackbar('error', 'Can not upload selected files');
          Logger.error(error);
        } finally {
          stopLoading();
        }
      }}
      handleClose={() => {
        setSelectFiles([]);
        onClose();
      }}
      customComponents={{
        FilesManagerComponent: FileManagerFormComponentWrapper
      }}
      alignCloseRight
    />
  );
};

AddFilesModal.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  onFilesSelected: func.isRequired
};

export default AddFilesModal;
