import { PermissionConstants } from 'utils/AppConstants';
import { EnumType } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

export const quoteRowMeta = flags => [
  {
    id: 'displayQuoteNumber',
    bold: true,
    label: 'Quote',
    showLink: 'view',
    linkPath: '/quote',
    linkReference: 'id'
  },
  {
    id: 'name',
    label: 'Title'
  },
  {
    id: 'status',
    filterKey: 'Quote.status',
    filterType: 'string',
    label: 'Status',
    type: 'enum',
    enumType: EnumType.QUOTE_STATUS,
    internal: !flags[FeatureFlags.JOB_QUOTE_STATUS]
  },
  {
    id: 'createdDate',
    type: 'dateOnly',
    label: 'Created On'
  },
  {
    id: 'totalAmountQuoted',
    filterKey: 'Quote.totalAmountQuoted',
    filterType: 'number',
    type: 'currency',
    numeric: true,
    label: 'Amount',
    caslKey: [PermissionConstants.DATA_VIEW_PRICE_DATA, PermissionConstants.DATA_VIEW_COST_DATA],
    caslAction: 'allow'
  },
  {
    id: 'ownerValue',
    filterKey: 'Quote.ownerValue',
    filterType: 'string',
    label: 'Owner'
  }
];

export default quoteRowMeta;
