import React, { useMemo, useRef, useState } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SearchBar from '@pm/components/APISearchComponents/SearchBar';
import CustomInput from '@pm/components/CustomInput';
import { ChangeOrderStatus } from '@pm/constants';
import { DefaultButton, SergeantModal } from 'components';
import approveChangeOrderLayout from 'meta/ProjectManagement/ChangeOrder/approveChangeOrderForm';
import { changeOrderDetailSidebarForm } from 'meta/ProjectManagement/ChangeOrder/changeOrderDetailSidebarForm';
import { snackbarOn } from 'redux/actions/globalActions';
import DetailView from 'scenes/ProjectManagement/components/DetailView';
import { getEmployeeOrCustomerRep } from 'scenes/ProjectManagement/components/utils';
import { changeOrderChange } from 'services/API/changeorder';
import { getCompanyBytenantId } from 'services/API/companies';
import { scheduleOfValueCreate } from 'services/API/scheduleOfValue';
import { useGetChangeOrderById } from 'services/APIHooks';
import { AppConstants } from 'utils/AppConstants';

import GenerateChangeOrder from '../GenerateChangeOrder';

import { useFormatDataForPdf } from './ChangeOrderDetailView.hooks';
import { changeOrderFormatValues } from './components/ChangeOrderFormatModal/ChangeOrderFormatSelect.constants';
import PdfFormatSelect from './components/ChangeOrderFormatModal/ChangeOrderFormatSelect.container';
import { useGetChangeOrderPDFBlob } from './components/ChangeOrderPDF/ChangeOrderPDF.hook';

const ChangeOrderDetailView = ({ user, snackbar, ...props }) => {
  const { projectId, projectData, changeOrderId, open, handleClose } = props || {};

  const [companyData, setCompanyData] = useState();
  const [sendToPerson, setSendToPerson] = useState();
  const [returnToPerson, setReturnToPerson] = useState();
  const [attachments, setAttachments] = useState();
  const [isOpenedApproveModal, setIsOpenedApproveModal] = useState(false);
  const [title, setTitle] = useState('Change Order');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pdfFormatOption, setPdfFormatOption] = useState(changeOrderFormatValues.ALL_DETAILS);
  const pdfFormatOptionRef = useRef();
  pdfFormatOptionRef.current = pdfFormatOption;

  const [{ data: changeOrderData }, refetchChangeOrder] = useGetChangeOrderById(
    changeOrderId,
    {
      depends: [changeOrderId],
      onSuccess: data => {
        setTitle(`Change Order${data?.number ? ` ${data.number}` : ''}`);
        setAttachments(data?.ChangeOrderAttachment?.filter?.(element => !element._deleted) || []);
        getCompanyBytenantId(user.tenantId).then(company =>
          setCompanyData(Array.isArray(company) ? company[0] : company)
        );
        getEmployeeOrCustomerRep(data?.sentToId).then(sendTo => setSendToPerson(sendTo));
        getEmployeeOrCustomerRep(data?.returnToId).then(returnTo => setReturnToPerson(returnTo));
      }
    },
    { include: '*' }
  );

  const getFormattedApprovalData = approvalData => {
    return {
      dateApproved: approvalData?.dateApproved || moment().unix(),
      date: approvalData?.date ? moment(approvalData?.date).unix() : moment().format('L'),
      daysValid: Number(approvalData?.daysValid),
      number: approvalData?.number,
      ReturnTo: { name: approvalData?.ReturnTo || '' },
      scheduleExtensionRequested: Number(approvalData?.scheduleExtensionRequested),
      scopeOfWork: approvalData?.scopeOfWork,
      SendTo: { name: approvalData?.SendTo || '' },
      status: approvalData?.status,
      subject: approvalData?.subject,
      projectId: approvalData?.projectId || '',
      approvedBy: { name: approvalData?.approvedBy },
      amountApproved: approvalData?.revenue || 0,
      gcChangeOrderNumber: approvalData?.gcChangeOrderNumber,
      gcPurchaseOrderNumber: approvalData?.gcPurchaseOrderNumber
    };
  };

  const getSidebarData = item => {
    if (!item) return {};
    return {
      ...item,
      date: item?.date ? moment.unix(item?.date).format(AppConstants.DATE_FORMAT) : null,
      dateApproved: item?.dateApproved
        ? moment.unix(item?.dateApproved).format(AppConstants.DATE_FORMAT)
        : null,
      sentOn: item.sentOn ? moment.unix(item?.sentOn).format(AppConstants.DATE_FORMAT) : null
    };
  };

  const handleAttachmentUpload = allAttachments => {
    // NOTE: The PM Backend doesn't support removing relations when passed up in an update.
    // That means that deselecting attachments will NOT disassociate them from the ChangeOrder.
    changeOrderChange(changeOrderId, { ChangeOrderAttachment: allAttachments }).then(() =>
      refetchChangeOrder()
    );
  };

  const approveSave = async (approvalData, stopLoading) => {
    setIsSubmitting(true);
    changeOrderData.status = ChangeOrderStatus.APPROVED;
    const payload = {
      ...changeOrderData,
      dateApproved: approvalData?.dateApproved || moment().unix(),
      status: ChangeOrderStatus.APPROVED,
      approvedBy: approvalData?.approvedBy?.name,
      amountApproved: approvalData?.amountApproved,
      gcChangeOrderNumber: approvalData?.gcChangeOrderNumber,
      gcPurchaseOrderNumber: approvalData?.gcPurchaseOrderNumber
    };

    if (!projectData.ScheduleOfValue?.id) {
      await scheduleOfValueCreate({
        retainage: 0,
        projectId,
        changeOrderId
      });
    }

    await changeOrderChange(changeOrderData.id, payload).then(() => {
      setIsOpenedApproveModal(false);
      refetchChangeOrder();
      stopLoading();
    });
    setIsSubmitting(false);
  };

  const [dataForPdf] = useFormatDataForPdf(changeOrderData, projectData);

  const handleEmailSent = async () => {
    const status =
      changeOrderData.status === ChangeOrderStatus.DRAFT
        ? ChangeOrderStatus.SENT
        : changeOrderData.status;
    const sentOn = moment().unix();
    const coPayload = {
      status,
      sentOn
    };
    await changeOrderChange(changeOrderData.id, coPayload);
    refetchChangeOrder();
  };

  const PdfFormatComponent = useMemo(() => {
    return (
      <>
        <PdfFormatSelect
          onChange={event => {
            setPdfFormatOption(event.target.value);
          }}
          value={pdfFormatOption}
        />
      </>
    );
  }, [pdfFormatOption]);

  const getChangeOrderPDFBlob = useGetChangeOrderPDFBlob({
    companyInfo: companyData,
    returnTo: returnToPerson,
    projectData,
    sendTo: sendToPerson,
    changeOrderData: { ...changeOrderData, ...dataForPdf },
    attachments,
    pdfFormatOptionRef
  });

  return (
    <DetailView
      open={open}
      showChildSpacers={false}
      handleClose={handleClose}
      projectData={projectData}
      initialData={{ ...changeOrderData, SendTo: sendToPerson }}
      getPDFBlob={getChangeOrderPDFBlob}
      getPdfFormatComponent={() => PdfFormatComponent}
      attachments={attachments}
      onAttachmentsChange={handleAttachmentUpload}
      user={user}
      headerButtons={[
        ...(changeOrderData?.status !== ChangeOrderStatus.APPROVED
          ? [
              <DefaultButton
                key="approveChangeOrder"
                label="APPROVE"
                variant="containedPrimary"
                onClick={() => setIsOpenedApproveModal(true)}
              />
            ]
          : [])
      ]}
      editData={{
        label: 'EDIT',
        render: (isOpen, close) => (
          <GenerateChangeOrder
            changeOrderData={changeOrderData || {}}
            editMode
            open={isOpen}
            user={user}
            projectId={projectId}
            projectData={projectData}
            handleClose={() => {
              refetchChangeOrder();
              close();
            }}
          />
        )
      }}
      title={title}
      emailData={{
        modalTitle: 'New Change Order Email',
        body: `${sendToPerson?.name},<br/><br/>Please find the attached Change Order ${changeOrderData?.number}.<br/><br/>${user?.displayName}`,
        subject: `${projectData?.name || null} Change Order ${changeOrderData?.number}`
      }}
      isSubmitting={isSubmitting}
      sidebarConfiguration={changeOrderDetailSidebarForm}
      sidebarData={getSidebarData(changeOrderData)}
      onEmailSent={handleEmailSent}
    >
      <SergeantModal
        title="Approve Change Order"
        open={isOpenedApproveModal}
        mode="edit"
        dataType="Item"
        data={{
          ...getFormattedApprovalData(changeOrderData)
        }}
        customComponents={{ SearchBar, CustomInput }}
        handlePrimaryAction={approveSave}
        layout={approveChangeOrderLayout(projectData)}
        maxWidth={500}
        handleClose={() => setIsOpenedApproveModal(false)}
      />
    </DetailView>
  );
};

ChangeOrderDetailView.propTypes = {
  user: PropTypes.object.isRequired,
  snackbar: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  projectData: state?.pm.project
});
const mapDispatcherToProps = dispatch => ({
  snackbar: (mode, message) => dispatch(snackbarOn(mode, message))
});
const ReduxConnectedChangeOrderDetailView = connect(
  mapStateToProps,
  mapDispatcherToProps
)(ChangeOrderDetailView);
export default connect(mapStateToProps)(ReduxConnectedChangeOrderDetailView);
