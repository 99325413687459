import React, { useEffect, useRef, useState } from 'react';
import { number, string, shape, func } from 'prop-types';
import MarkerWithLabel from 'react-google-maps/lib/components/addons/MarkerWithLabel';

import { Typography } from '@buildhero/sergeant';

import { useGeocoder } from '../../MapView.hooks';

import { useMarkerIcon } from './CustomMarker.hooks';
import { selectAddressString } from './CustomMarker.selectors';
import { useStyles } from './CustomMarker.styles';

const CustomMarker = props => {
  const classes = useStyles();
  const { Point } = google.maps;
  const labelAnchor = useRef(new Point(80, 80));
  const [position, setPosition] = useState(props.position);
  const { loading, geocode } = useGeocoder();
  const icon = useMarkerIcon();

  useEffect(() => {
    setPosition(props.position);
  }, [props.position]);

  const handlePositionUpdate = newPosition => {
    setPosition(newPosition);
    props.updateBounds(newPosition);
  };

  if (!(position?.lat && position?.lng)) {
    if (!loading) geocode(selectAddressString(props.address), handlePositionUpdate);
    return null;
  }

  return (
    <MarkerWithLabel
      position={position}
      labelAnchor={labelAnchor.current}
      icon={icon}
      labelStyle={{ width: '160px', height: '160px' }}
      zIndex={props.zIndex}
    >
      <div className={classes.labelWrapper}>
        {props.children}
        {props.label && <Typography className={classes.label}>{props.label}</Typography>}
      </div>
    </MarkerWithLabel>
  );
};

const ADDRESS_PROP = shape({
  addressLine1: string,
  addressLine2: string,
  addressType: string,
  city: string,
  latitude: number,
  longitude: number,
  state: string,
  zipcode: string
});

CustomMarker.propTypes = {
  address: ADDRESS_PROP,
  label: string,
  position: shape({
    lat: number,
    lng: number
  }),
  updateBounds: func.isRequired,
  // eslint-disable-next-line react/require-default-props
  zIndex: number
};

CustomMarker.defaultProps = {
  address: {},
  label: '',
  position: {}
};

export default CustomMarker;
