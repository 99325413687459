import moment from 'moment';

import { handleError } from 'utils/handleError';

import { VisitStatuses } from '@dispatch/Dispatch.constants';
import { DragScenarios } from '@dispatch/dnd';
import { getState } from 'redux/store';

import { FeatureFlags } from 'utils/FeatureFlagConstants';

import { BASE_POLL_INTERVAL, RAND_MAX_POLL_INTERVAL } from './DispatchBoard.constants';

export const sortByStart = (a, b) => a.range.start.diff(b.range.start);

export const canDropVisit = ({
  primaryTech,
  dragScenario,
  techId,
  item,
  weekView,
  availableTime
}) => {
  const { srcTech, data } = item;

  switch (dragScenario) {
    case DragScenarios.MAN_DAY_START:
    case DragScenarios.MAN_DAY_END:
    case DragScenarios.EVENT_START:
    case DragScenarios.EVENT_END:
    case DragScenarios.VISIT_START:
    case DragScenarios.VISIT_END: {
      return srcTech === techId;
    }
    case DragScenarios.MAN_DAY_BOARD_TO_BOARD:
      return srcTech === techId || ![...data.technicianIds].includes(techId);
    case DragScenarios.NON_VISIT_BOARD_TO_BOARD: {
      const canMove = [
        VisitStatuses.SCHEDULED.value.serverValue,
        ...(getState()?.launchDarklyFlags?.flags?.[FeatureFlags.WRINKLE_IN_TIME]
          ? [VisitStatuses.WORKING.value.serverValue]
          : [])
      ].includes(data.status);

      if (weekView) {
        const eventDay = moment(data.range.start).startOf('day');
        const targetDay = moment(availableTime.range.start).startOf('day');
        const isSameDay = eventDay.isSame(targetDay);

        return canMove || (isSameDay && srcTech === techId);
      }

      return canMove || srcTech === techId;
    }
    case DragScenarios.UNASSIGNED_SINGLE_TECH: {
      return true;
    }
    case DragScenarios.FULLY_ASSIGNED_SINGLE_TECH: {
      return primaryTech === techId;
    }
    case DragScenarios.VISIT_BOARD_TO_BOARD: {
      return srcTech === techId || ![primaryTech, ...data.extraTechs].includes(techId);
    }
    case DragScenarios.FULLY_ASSIGNED_MULTI_TECH: {
      return [primaryTech, ...data.extraTechs].includes(techId);
    }
    default:
      return false;
  }
};

export const getPollInterval = () => {
  return getState()?.launchDarklyFlags?.flags?.[FeatureFlags.DISPATCH_BOARD_LONG_POLL]
    ? Math.floor(Math.random() * RAND_MAX_POLL_INTERVAL) + BASE_POLL_INTERVAL
    : 0;
};

export const trackDrop = ({ dragScenario, itemType }) => {
  try {
    pendo.track(`DROP_${dragScenario}`, {
      itemType
    });
  } catch (err) {
    handleError({
      err,
      errContext: 'pendo',
      hideError: true
    });
  }
};
