import React, { useEffect, useRef, useState } from 'react';
import { number, string, shape, arrayOf, func } from 'prop-types';
import { InfoWindow } from 'react-google-maps';
import MarkerWithLabel from 'react-google-maps/lib/components/addons/MarkerWithLabel';

import { Typography } from '@buildhero/sergeant';

import VisitCard from '@dispatch/components/VisitCard';
import { VISIT_DETAILS_PROP } from '@dispatch/queries';

import { useGeocoder } from '../../MapView.hooks';

import { markerShape } from './PropertyMarker.constants';
import { useMarkerIcon } from './PropertyMarker.hooks';
import { selectAddressString } from './PropertyMarker.selectors';
import { useStyles } from './PropertyMarker.styles';

const PropertyMarker = ({ property, updateBounds }) => {
  const scale = 0.6;
  const classes = useStyles();
  const { Point, Size } = google.maps;
  const labelAnchor = useRef(new Point(80, -20 * scale));
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState(property.position);
  const { loading, geocode } = useGeocoder();
  const icon = useMarkerIcon({ scale, visits: property.visits });

  useEffect(() => {
    setPosition(property.position);
  }, [property.position]);

  const handlePositionUpdate = newPosition => {
    setPosition(newPosition);
    updateBounds(newPosition);
  };

  if (!(position?.lat && position?.lng)) {
    if (!loading) geocode(selectAddressString(property.address), handlePositionUpdate);
    return null;
  }

  return (
    <>
      <MarkerWithLabel
        position={position}
        labelAnchor={labelAnchor.current}
        labelStyle={{ width: '160px' }}
        shape={markerShape}
        icon={icon}
        zIndex={20}
        onClick={() => setIsOpen(true)}
      >
        <div className={classes.labelWrapper}>
          <Typography className={classes.label}>{property.customerPropertyName}</Typography>
        </div>
      </MarkerWithLabel>
      {isOpen && (
        <InfoWindow
          onCloseClick={() => setIsOpen(false)}
          disableAutoPan
          position={position}
          options={{
            pixelOffset: new Size(0, -40 * scale)
          }}
        >
          <div>
            {property.visits.map(visit => (
              <VisitCard key={visit.id} visit={visit} />
            ))}
          </div>
        </InfoWindow>
      )}
    </>
  );
};

const ADDRESS_PROP = shape({
  addressLine1: string,
  addressLine2: string,
  addressType: string,
  city: string,
  latitude: number,
  longitude: number,
  state: string,
  zipcode: string
});

PropertyMarker.propTypes = {
  property: shape({
    id: string,
    address: ADDRESS_PROP,
    customerPropertyName: string,
    position: shape({
      lat: number,
      lng: number
    }),
    visits: arrayOf(VISIT_DETAILS_PROP)
  }).isRequired,
  updateBounds: func.isRequired
};

export default PropertyMarker;
