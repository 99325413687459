import * as moment from 'moment';
import _ from 'lodash';
import { getDateFormat } from 'utils/AppConstants';
import { camelCaseToTitleCase, isAmplifyBaseEntityField } from 'utils';

const hideChangesList = ['settingsJSON'];
const fieldNameMap = {
  settingsJSON: 'Settings'
};

function formatDate(dataType, value) {
  function getDateTimeFromSeconds(seconds) {
    // moment will return 'Invalid date' if the conversion fails at any point
    const formattedDate = moment.unix(parseInt(seconds, 10)).format(getDateFormat());
    return moment(formattedDate).isValid() ? formattedDate : 'Invalid Date';
  }

  if (dataType.includes('DateTime')) return getDateTimeFromSeconds(Math.floor(value / 1000));
  if (dataType.includes('Date')) return getDateTimeFromSeconds(value);
  if (dataType.includes('scheduledFor')) return getDateTimeFromSeconds(value);
  if (hideChangesList.includes(dataType)) return null;
  return value;
}

// For example: 'customerPONumber' --> 'Customer P.O. Number' or 'jobId' --> 'Job ID'
function formatFieldName(fieldName) {
  const mapping = fieldNameMap[fieldName];
  if (mapping) return mapping;

  const prettyId = camelCaseToTitleCase(fieldName).replace('Id', 'ID');
  const withSingleLettersAbbreviated = prettyId
    .split(' ')
    .map(word => (word.length === 1 ? `${word}.` : word))
    .join(' ')
    // e.g. Change 'Customer P. O. Number' to 'Customer P.O. Number'
    .replace(/([A-Z]\. )+/g, match => {
      return match.replace(/ /g, '').concat(' ');
    });
  return withSingleLettersAbbreviated;
}

function getEntityIdentifier(entity) {
  // Adam: more entity type entries could be added, but this is the only case I've encountered empirically
  const entityIdentifierByType = {
    Job: 'jobNumber.N'
  };

  if (!entity) return '';
  const entityType = entity.entityType?.S;
  return `${entityType} ${_.get(entity, entityIdentifierByType[entityType])}`;
}

export default function getChangesFromLog(log) {
  const changes = log.changeLog;
  let result;
  try {
    result = JSON.parse(changes);
  } catch (e) {
    // JSON parse failed
    return [];
  }
  if (!Array.isArray(result)) return [];
  return result
    .filter(change => !isAmplifyBaseEntityField(change.field))
    .map(change => {
      return {
        old: formatDate(change.field, change.old),
        new: formatDate(change.field, change.new),
        field: formatFieldName(change.field),
        entityField: change.field,
        hideChanges: hideChangesList.includes(change.field),
        newParent: change.field === 'parentEntity' && getEntityIdentifier(change.new),
        oldParent: change.field === 'parentEntity' && getEntityIdentifier(change.old)
      };
    });
}
