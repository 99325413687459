export const ShippingInformation = {
  JOB_SITE: 'Job Site',
  WAREHOUSE: 'Warehouse',
  VENDOR_PICKUP: 'Vendor Pickup',
  MANUALLY: 'Manually Enter Address'
};

export const ShippingInformationWithNoJob = {
  WAREHOUSE: 'Warehouse',
  VENDOR_PICKUP: 'Vendor Pickup',
  MANUALLY: 'Manually Enter Address'
};

export const ShipToNameTypes = {
  EMPLOYEE: 'Employee',
  OTHER: 'Other'
};

export const OrderQtyStatus = {
  FULFILLED: 'Fulfilled',
  PARTIALLY_FULFILLED: 'Partially Fulfilled',
  UNFULFILLED: 'Unfulfilled'
};

export const loadingParams = {
  leftSection: {
    variant: 'table',
    repeatCount: 5,
    paddingTop: 8,
    paddingLeft: 12,
    paddingRight: 12
  },
  mainSection: {
    paddingLeft: 8,
    paddingTop: 8
  }
};

export const CLOUDINARY_CLOUD_NAME = 'buildops';
export const CLOUDINARY_PROD_CLOUD_NAME = 'gobuildops';

export const SelectedItem = {
  JC_COST_TYPE: 'jcCostType',
  JC_PHASE: 'jcPhase',
  MATERIALS: 'materials',
  PHASE_DEPARTMENT: 'phaseDepartment',
  PROJECT_COST_CODE: 'projectCostCode',
  PROJECT_COST_TYPE: 'projectCostType',
  PROJECT_PHASE: 'projectPhase',
  QUANTITY: 'quantity',
  TOTAL_COST: 'totalCost',
  UNIT_COST: 'unitCost'
};

export const Timezone = {
  GMT: 'Etc/GMT'
};
