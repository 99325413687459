import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ThemeProvider, Divider } from '@buildhero/sergeant';
import Skeleton from 'react-loading-skeleton';
import { Tabs, Tab } from 'components';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { partition } from 'lodash';
import { JobService, VisitService } from 'services/core';
import { Logger, sentryException } from 'services/Logger';

import * as R from 'ramda';
import { isTenantSettingEnabled, capitalizeFirstLetter, processAddressArrayAsJson } from 'utils';
import { PermissionConstants } from 'utils/AppConstants';
import { AccountingApp } from 'utils/constants';
import { connect, useSelector } from 'react-redux';
import { snackbarOn } from 'redux/actions/globalActions';
import { makeStyles } from '@material-ui/core/styles';
import FormSection from 'components/FormSection';
import AttachmentSection from 'components/AttachmentSection';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getFetchErrorMessage } from '../formattingUtils';
import ReceiptSection from './ReceiptSection';
import ImageSection from './ImageSection';
import Quotes from './Quotes';
import Tasks from './Tasks';
import PartsPurchasing from './PartsPurchasing';
import InvoiceAndReport from './InvoiceAndReport';
import JobCostingReport from './JobCostingReport';
import VisitsTab from './VisitsTab';
import MaintenanceReport from '../MaintenanceTabs/MaintenanceReport';
import Vista from './ Vista';

const JobTabs = props => {
  const {
    jobData,
    snackbar,
    user,
    jobsVisitRef,
    visits,
    setVisits,
    isJobTypeInternal = false,
    hasLoaded,
    shouldDisallowEditing
  } = props;
  const flags = useFlags();

  const {
    sortKey: jobSortKey,
    id: jobId,
    jobTypeId,
    customerPropertyId,
    quoteId,
    quoteJobs
  } = jobData;
  const formattedQuoteJobs = (quoteJobs?.items || []).map(item => item.quote);

  const history = useHistory();

  const { id: jobNumberEncoded } = useParams();
  const jobNumber = decodeURIComponent(jobNumberEncoded);
  const isQuotesEnabled = isTenantSettingEnabled('quotes');

  const [invoiceData, setInvoiceData] = useState(null);
  const [attachments, setAttachments] = useState({ image: null, nonImage: null });
  const [techReports, setTechReports] = useState(null);
  const [reviewReports, setReviewReports] = useState(null);
  const [receipts, setReceipts] = useState(null);
  const [parts, setParts] = useState(null);
  const [propertyAssets, setPropertyAssets] = useState(null);
  const [timesheets, setTimesheets] = useState(null);
  const [isLoadingVisits, setIsLoadingVisits] = useState(false);
  const [tasksTouched, setTasksTouched] = useState(false);

  const visitSubscriptionRef = useRef();

  // Combine parts and receipts data for intelligible display in a table.
  // TODO: This is legacy code. Need to clean up the logic and document the entity relationships
  // that are being encapsulated by this mapping.
  const parsePartsQueryResult = queryResult => {
    // Result of this giant function
    const allParts = [];

    const visitsData = queryResult.parts?.visits?.items;
    const inventoryParts = queryResult.parts?.inventoryParts?.items;
    const purchaseOrders = queryResult.receipts?.purchaseOrders?.items;

    const visitIdNumberMap = new Map();
    const visitIdSortKeyMap = new Map();
    const revReportVisitMap = new Map();
    const revReportSortKeyMap = new Map();

    // Generating visit number & tech/review report sortkey map for display in tables
    visitsData.forEach(visit => {
      let isReportSubmitted = false;
      if (visit.reviewReports?.items) {
        const reviewReportList = visit.reviewReports.items;
        reviewReportList.forEach(reviewReport => {
          if (reviewReport.status && reviewReport.status.toLowerCase() === 'submitted') {
            revReportVisitMap.set(reviewReport.id, visit.visitNumber);
            revReportSortKeyMap.set(reviewReport.id, reviewReport.sortKey);
            isReportSubmitted = true;
          }
        });
      }
      if (!isReportSubmitted) {
        visitIdNumberMap.set(visit.id, visit.visitNumber);
        visitIdSortKeyMap.set(visit.id, visit.sortKey);
      }
    });

    // Traverse all parts returned by query, process and add to an array
    inventoryParts.forEach(inventoryPart => {
      let includeForDisplayinTable = false;
      if (inventoryPart) {
        const part = inventoryPart;
        // If the part is added from visit
        if (visitIdNumberMap.has(inventoryPart.parentId)) {
          part.visitNumber = `#${visitIdNumberMap.get(inventoryPart.parentId)}`;
          part.source = 'Technician Report';
          part.repoId = inventoryPart.parentId;

          includeForDisplayinTable = true;
        } else if (revReportVisitMap.has(inventoryPart.parentId)) {
          part.visitNumber = `#${revReportVisitMap.get(inventoryPart.parentId)}`;
          part.source = 'Reviewed Report';
          part.repoId = inventoryPart.parentId;

          includeForDisplayinTable = true;
        }

        part.origin = 'Inventory';
        if (inventoryPart.unitCost) {
          part.unitCost = parseFloat(inventoryPart.unitCost)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
        if (!inventoryPart.vendorName) {
          part.vendorName = '-';
        }

        if (inventoryPart && inventoryPart.quantity && inventoryPart.unitPrice) {
          part.amount = inventoryPart.quantity * inventoryPart.unitPrice;
          part.amount = inventoryPart.quantity * inventoryPart.unitPrice;
          part.amount = part.amount && part.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
        part.productCode = inventoryPart?.priceBookEntry?.product?.code || '';
        // if the items are in tech report while review report is in submitted state. Ignore them
        if (includeForDisplayinTable) {
          allParts.push(part);
        }
      }
    });

    // Traverse all purchaseLines returned by query, process and add to an array
    purchaseOrders.forEach(purchaseOrder => {
      const receipt = purchaseOrder;

      if (receipt.purchaseOrderLines && receipt.purchaseOrderLines.items) {
        const allPurchaseLines = receipt.purchaseOrderLines.items;

        allPurchaseLines.forEach(purchaseLine => {
          const purchasedPart = purchaseLine;
          let includeForDisplayinTable = false;
          if (visitIdNumberMap.has(purchaseOrder.parentId)) {
            purchasedPart.visitNumber = `#${visitIdNumberMap.get(purchaseOrder.parentId)}`;
            purchasedPart.source = 'Technician Report';
            purchasedPart.repoId = purchaseOrder.parentId;

            includeForDisplayinTable = true;
          } else if (revReportVisitMap.has(purchaseOrder.parentId)) {
            purchasedPart.visitNumber = `#${revReportVisitMap.get(purchaseOrder.parentId)}`;
            purchasedPart.source = 'Reviewed Report';
            purchasedPart.repoId = purchaseOrder.parentId;

            includeForDisplayinTable = true;
          }
          if (purchasedPart && purchasedPart.quantity && purchasedPart.unitPrice) {
            purchasedPart.amount = purchasedPart.quantity * purchasedPart.unitPrice;
            purchasedPart.amount = purchasedPart.amount && purchasedPart.amount.toFixed(2);
          }
          purchasedPart.origin = 'Receipt';
          purchasedPart.receiptNumber = receipt.receiptNumber || '-';
          if (receipt.vendorName) {
            purchasedPart.vendorName = receipt.vendorName;
          }
          if (!purchasedPart.description) purchasedPart.description = '-';

          // Added for displaying purchaseOrder receipt images
          if (purchaseOrder.purchaseOrderReceipts && purchaseOrder.purchaseOrderReceipts.items) {
            const formattedReceipts = [];
            purchaseOrder.purchaseOrderReceipts.items.forEach(item => {
              if (item.imageUrl) {
                const formattedReceipt = {};
                formattedReceipt.fileUrl = item.imageUrl;
                formattedReceipt.imageUrl = item.imageUrl;
                formattedReceipts.push(formattedReceipt);
              }
            });
            if (formattedReceipts.length > 0) {
              purchasedPart.receiptImages = formattedReceipts;
            }
          }
          if (includeForDisplayinTable) {
            allParts.push(purchasedPart);
          }
        });
      }
    });

    return allParts;
  };

  const fetchParts = useCallback(
    async jobNumber => {
      try {
        const JobServiceInstance = new JobService();
        const { data: partsData } = await JobServiceInstance.getReviewReportPartsByJobNumber(
          `${jobNumber}`
        );
        const { data: receiptsData } = await JobServiceInstance.getReviewReportReceiptsByJobNumber(
          `${jobNumber}`
        );
        const partsQueryResult = partsData.getJobByJobNumber;
        const receiptsQueryResult = receiptsData.getJobByJobNumber;
        const finalPartsData = parsePartsQueryResult({
          parts: partsQueryResult,
          receipts: receiptsQueryResult
        });
        setParts(finalPartsData);
      } catch (error) {
        Logger.error(error);
        snackbar('error', getFetchErrorMessage('review report', jobNumber), error);
      }
    },
    [snackbar]
  );

  const fetchInvoices = useCallback(
    async jobNumber => {
      try {
        const { data } = await new JobService().getInvoicesByJobNumber(`${jobNumber}`);
        const queryResult = data.getJobByJobNumber;
        const invoices = queryResult?.invoices?.items;

        const parsedInvoices = (invoices || []).map(item => {
          const invoice = { ...item };
          const { invoiceVisits } = invoice;
          const aggregatedVisitNumbers = (invoiceVisits?.items || []).reduce(
            (accumulator, invoiceVisit, index) => {
              return `${accumulator}${index > 0 ? ', ' : ''}${invoiceVisit?.visit?.visitNumber}`;
            },
            ''
          );
          invoice.status = capitalizeFirstLetter(invoice.status);
          invoice.includesVisit = aggregatedVisitNumbers;
          return invoice;
        });
        setInvoiceData(parsedInvoices);
      } catch (error) {
        Logger.error(error);
        snackbar('error', getFetchErrorMessage('invoices', jobNumber), error);
      }
    },
    [snackbar]
  );

  const fetchAttachments = useCallback(
    async jobNumber => {
      try {
        const { data } = await new JobService().getAllAttachmentsByJobNumber(`${jobNumber}`);
        const queryResult = data.getJobByJobNumber;

        const [imageAttachments, nonImageAttachments] = partition(
          queryResult?.allAttachments?.items || [],
          attachment => ['before', 'after'].includes(attachment?.type)
        );
        setAttachments({ image: imageAttachments, nonImage: nonImageAttachments });
      } catch (error) {
        Logger.error(error);
        snackbar('error', getFetchErrorMessage('visits and attachments', jobNumber), error);
      }
    },
    [snackbar]
  );

  const parseVisitMappedEntities = visit => {
    const result = { ...visit };

    function parseMappedEntity(field) {
      if (!field?.items) return field;
      const mappedItems = field?.items?.map(mappedItem => {
        if (mappedItem.mappedEntity) {
          return {
            ...mappedItem.mappedEntity,
            directSortKey: mappedItem.sortKey,
            invertedSortKey: mappedItem.invertedSortKey
          };
        }
        return null;
      });
      return R.reject(R.isNil, mappedItems);
    }

    result.tasks = parseMappedEntity(result.tasks);
    result.primaryTechs = parseMappedEntity(result.primaryTechs);
    result.extraTechs = parseMappedEntity(result.extraTechs);

    if (result.schedules && result.schedules.items) {
      result.schedules = result.schedules.items;
    }

    return result;
  };

  const fetchVisitsTechReviewReports = useCallback(
    async jobNumber => {
      try {
        setIsLoadingVisits(true);
        const { data } = await new JobService().getVisitsByJobNumber(`${jobNumber}`);
        const queryResult = data.getJobByJobNumber;
        const visitsData = queryResult?.visits?.items
          ?.map(visit => parseVisitMappedEntities(visit))
          .sort((left, right) => right.visitNumber - left.visitNumber);
        if (!visitsData) return;

        const techReportsData = visitsData.filter(
          visit =>
            !['scheduled', 'unassigned', 'traveling'].includes(
              visit.status && visit.status.toLowerCase()
            )
        );
        const reviewReportsData = [];
        techReportsData.forEach(report => {
          if (report.reviewReports?.items?.length > 0) {
            report.reviewReports.items.forEach(reviewReportItem => {
              reviewReportsData.push(reviewReportItem);
            });
          }
        });

        setVisits(visitsData);
        if (jobsVisitRef) {
          jobsVisitRef.current.visits = visitsData;
        }

        setTechReports(techReportsData);
        setReviewReports(reviewReportsData);
        setIsLoadingVisits(false);
      } catch (error) {
        Logger.error(error);
        snackbar('error', getFetchErrorMessage('technician report', jobNumber), error);
      }
    },
    [jobsVisitRef, snackbar]
  );

  const fetchReceipts = useCallback(
    async jobNumber => {
      try {
        const { data } = await new JobService().getReviewReportReceiptsByJobNumber(`${jobNumber}`);
        const queryResult = data.getJobByJobNumber;
        const receiptsData = queryResult?.purchaseOrders?.items;

        // TODO - refactor to backend
        // for each visit,
        //  if reviewed, show the purchaseOrders for that reviewReport
        //  else show the purchaseOrders for that visit

        // processedReceipts contains the receiptData with duplicates removed
        // duplicates would exist without this because purchase orders for the
        // review report for visit A AND for visit A itself would both be
        // displayed
        let processedReceiptsData = [];
        if (queryResult?.visits?.items) {
          // for each visit
          queryResult.visits.items.forEach(visit => {
            // if it has a review report, add all associated purchase orders
            const reviewReport = visit.reviewReports.items.find(
              report => report.status.toLowerCase() === 'submitted'
            );
            if (reviewReport?.id) {
              // find purchase orders where parentId = review report id
              processedReceiptsData = [
                ...processedReceiptsData,
                ...receiptsData
                  .filter(({ parentId }) => parentId === reviewReport.id)
                  .map(receiptData => ({
                    ...receiptData,
                    visitNumber: `#${visit.visitNumber}`
                  }))
              ];
            } else {
              // find purchase orders where parentId = visit id
              processedReceiptsData = [
                ...processedReceiptsData,
                ...receiptsData
                  .filter(({ parentId }) => parentId === visit.id)
                  .map(data => ({
                    ...data,
                    visitNumber: `#${visit.visitNumber}`
                  }))
              ];
            }
          });
        }

        if (queryResult?.purchaseOrderNumbers?.items) {
          queryResult.purchaseOrderNumbers.items.forEach(poNumberItem => {
            const { value: poNumber, ...attr } = poNumberItem;
            if (!processedReceiptsData.find(rdata => rdata.poNumber === poNumber)) {
              processedReceiptsData.push({ ...attr, poNumber });
            }
          });
        }

        setReceipts(processedReceiptsData);
      } catch (error) {
        Logger.error(error);
        snackbar('error', getFetchErrorMessage('review report receipts', jobNumber), error);
      }
    },
    [snackbar]
  );

  const fetchPropertyAssets = useCallback(
    async jobNumber => {
      try {
        const { data } = await new JobService().getPropertyInfoByJobNumber(`${jobNumber}`);
        /*
          https://buildops.atlassian.net/browse/BUOP-4029
          Soft delete Assets
        */
        const propertyAssetsData = data.getJobByJobNumber?.parentEntity?.propertyAssets?.items?.filter(
          item => item.isActive !== false
        );
        setPropertyAssets(propertyAssetsData);
      } catch (error) {
        Logger.error(error);
        snackbar('error', getFetchErrorMessage('property assets', jobNumber), error);
      }
    },
    [snackbar]
  );

  // query results will have the array of visits
  const parseTimesheets = (queryResult = []) => {
    const formattedTimesheets = [];
    queryResult.forEach(visit => {
      const manualTimesheets = visit?.timeCardLinesView?.items || [];
      const automatedTimesheets = visit?.timesheetEntriesView?.items || [];

      if (manualTimesheets.length) {
        manualTimesheets.forEach(timesheet => {
          const timeRecord = {};
          timeRecord.visitNumber = `#${visit?.visitNumber || ''}`;
          timeRecord.employeeName = timesheet?.employee?.name || '';
          timeRecord.labourType = timesheet?.employee?.labourType?.name || '';
          const timeInHrs = ((timesheet?.timeMinutes || 0) / 60).toFixed(2);
          timeRecord.timeInHrs = parseFloat(timeInHrs);
          const costsForThisTimesheetType = timesheet?.employee?.payrollCosts?.items?.filter(
            item => item?.hourType?.hourType === timesheet?.timeType
          );
          timeRecord.rate = costsForThisTimesheetType?.[0]?.cost;
          timeRecord.description = timesheet?.timeType;
          formattedTimesheets.push(timeRecord);
        });
      }

      if (automatedTimesheets.length) {
        automatedTimesheets.forEach(timesheet => {
          const timeRecord = {};
          timeRecord.visitNumber = `#${visit?.visitNumber || ''}`;
          timeRecord.employeeName = timesheet?.timekeepingLedger?.employee?.name || '';
          timeRecord.labourType = timesheet?.timekeepingLedger?.employee?.labourType?.name || '';
          const duration = Number.isInteger(timesheet?.actualTotalDurationOverride)
            ? timesheet.actualTotalDurationOverride
            : timesheet?.actualTotalDuration;
          const timeInHrs = ((duration || 0) / 60 / 60).toFixed(2);
          timeRecord.timeInHrs = parseFloat(timeInHrs);

          const costsForThisTimesheetType = timesheet?.timekeepingLedger?.employee?.payrollCosts?.items?.filter(
            item => item?.hourType?.hourType === timesheet?.hourType?.hourType
          );

          timeRecord.rate = costsForThisTimesheetType?.[0]?.cost;
          timeRecord.description = timesheet?.userActionType;
          formattedTimesheets.push(timeRecord);
        });
      }
    });
    return formattedTimesheets || [];
  };

  const fetchTimesheets = useCallback(
    async jobNumber => {
      try {
        const { data } = await new JobService().getTimesheetsByJobNumber(`${jobNumber}`);

        const processedTimesheets = parseTimesheets(data?.getJobByJobNumber?.visits?.items);
        setTimesheets(processedTimesheets);
      } catch (error) {
        Logger.error(error);
        snackbar('error', getFetchErrorMessage('timesheets', jobNumber), error);
      }
    },
    [snackbar]
  );

  useEffect(() => {
    if (!jobNumber) return;

    // queries specific to maintenance jobs
    if (isJobTypeInternal) {
      fetchTimesheets(jobNumber);
    }

    // queries common for both types of jobs
    fetchInvoices(jobNumber);
    fetchParts(jobNumber);
    fetchAttachments(jobNumber);
    fetchVisitsTechReviewReports(jobNumber);
    fetchReceipts(jobNumber);
    fetchPropertyAssets(jobNumber);
  }, [
    fetchParts,
    fetchInvoices,
    fetchAttachments,
    fetchVisitsTechReviewReports,
    fetchReceipts,
    fetchPropertyAssets,
    jobNumber,
    isJobTypeInternal,
    fetchTimesheets
  ]);

  const updateVisitsOnSubscription = useCallback(
    updatedVisit => {
      const localVisits = jobsVisitRef?.current?.visits;
      const visitIds = localVisits?.map(v => v.id);
      if (visitIds?.includes(updatedVisit?.id)) {
        fetchVisitsTechReviewReports(jobNumber);
      }
    },
    [fetchVisitsTechReviewReports, jobNumber, jobsVisitRef]
  );

  useEffect(() => {
    async function subscribeToAllVisits() {
      try {
        const subscriptionRes = (
          await new VisitService().subscribeToTenantVisits(user.tenantId)
        ).subscribe(result => {
          updateVisitsOnSubscription(result);
        });

        return subscriptionRes;
      } catch (error) {
        sentryException(error, { message: 'Unable to subscribe visit updates' });
      }
    }
    if (!visitSubscriptionRef.current && user.tenantId && jobNumber) {
      visitSubscriptionRef.current = subscribeToAllVisits();
    }

    return () => {
      if (visitSubscriptionRef?.current?.unsubscribe) visitSubscriptionRef.current.unsubscribe();
    };
  }, [jobNumber, updateVisitsOnSubscription, user.tenantId, visits]);

  const tenantAccountApp = useSelector(state => state.settings.accountingApp);

  return (
    <>
      <Tabs confirmSwitch={tasksTouched} onChange={() => setTasksTouched(false)}>
        <Tab label="Scheduling" caslKey={PermissionConstants.OBJECT_VISIT}>
          {hasLoaded ? (
            <VisitsTab
              propertyAssets={propertyAssets || []}
              jobData={jobData}
              tasksData={visits?.tasks || []}
              visitsData={visits || []}
              isLoading={isLoadingVisits}
              fetchVisits={() => {
                if (props.jobData?.jobNumber) {
                  fetchVisitsTechReviewReports(props.jobData?.jobNumber);
                }
              }}
            />
          ) : (
            <Skeleton />
          )}
        </Tab>
        {isQuotesEnabled && !isJobTypeInternal && (
          <Tab label="Quotes" caslKey={PermissionConstants.OBJECT_QUOTES}>
            {hasLoaded ? <Quotes data={formattedQuoteJobs} flags={flags} /> : <Skeleton />}
            {/** Currently one quote per job, infuture it can be many */}
          </Tab>
        )}

        <Tab label="Tasks" caslKey={PermissionConstants.OBJECT_TASK}>
          {hasLoaded ? (
            <Tasks
              jobTypeId={jobTypeId}
              customerPropertyId={customerPropertyId}
              jobData={jobData}
              jobId={jobId}
              quoteId={quoteId}
              setTouched={setTasksTouched}
              touched={tasksTouched}
              shouldDisallowEditing={shouldDisallowEditing}
            />
          ) : (
            <Skeleton />
          )}
        </Tab>
        <Tab label="Forms & Attachments">
          {hasLoaded ? (
            <FormsAttachments
              jobData={jobData}
              jobSortKey={jobSortKey}
              jobId={jobId}
              attachments={attachments}
              receipts={receipts}
              visits={visits}
              refetchAttachments={() => {
                if (props.jobData?.jobNumber) {
                  fetchAttachments(props.jobData?.jobNumber);
                }
              }}
              onGeneratePurchaseOrder={result => {
                setReceipts(receipts.concat(result));
              }}
              flags={flags}
            />
          ) : (
            <Skeleton />
          )}
        </Tab>

        <Tab label="Parts & Purchasing" caslKey={PermissionConstants.OBJECT_JOB}>
          {hasLoaded ? (
            <PartsPurchasing
              jobData={jobData}
              parts={parts}
              user={user}
              snackbarOn={props.snackbar}
              flags={flags}
              history={history}
              caslKey={PermissionConstants.OBJECT_JOB}
            />
          ) : (
            <Skeleton />
          )}
        </Tab>
        <Tab label="Job Costing">
          {hasLoaded ? (
            <JobCostingReport
              jobId={jobId}
              user={props.user}
              jobData={props.jobData}
              snackbarOn={props.snackbar}
            />
          ) : (
            <Skeleton />
          )}
        </Tab>
        <Tab
          label="Reports & Invoices"
          caslKey={[PermissionConstants.OBJECT_REVIEWREPORT, PermissionConstants.OBJECT_INVOICE]}
        >
          {hasLoaded ? (
            <InvoiceAndReport
              isJobTypeInternal={isJobTypeInternal}
              refetchInvoices={fetchInvoices}
              refetchVisits={fetchVisitsTechReviewReports}
              techReports={techReports || []}
              reviewReports={reviewReports || []}
              jobInfo={{
                id: jobId,
                jobNumber: jobData.jobNumber,
                sortKey: jobSortKey,
                tenantId: jobData.tenantId,
                tenantCompanyId: jobData.tenantCompanyId,
                jobTypeInternal: jobData.jobTypeInternal,
                closeoutReport: jobData.closeoutReport
              }}
              invoices={invoiceData}
              history={history}
            />
          ) : (
            <Skeleton />
          )}
        </Tab>

        {tenantAccountApp === AccountingApp.VISTA && (
          <Tab label="Vista">{hasLoaded ? <Vista jobId={jobId} /> : <Skeleton />}</Tab>
        )}

        {isJobTypeInternal && (
          <Tab label="Report">
            {hasLoaded ? (
              <MaintenanceReport
                visits={visits || []}
                attachments={attachments.image}
                parts={parts}
                timesheets={timesheets}
              />
            ) : (
              <Skeleton />
            )}
          </Tab>
        )}
      </Tabs>
      <ThemeProvider>
        <Divider />
      </ThemeProvider>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginBottom: theme.spacing(5)
    }
  }
}));

const FormsAttachments = props => {
  const {
    jobData,
    jobId,
    jobSortKey,
    attachments,
    receipts,
    visits,
    refetchAttachments,
    onGeneratePurchaseOrder,
    flags
  } = props;
  const classes = useStyles();

  const isDynamicFormsEnabled = isTenantSettingEnabled('dynamicForms');
  const billingCustomerAddresses = processAddressArrayAsJson(
    jobData?.billingCustomer?.companyAddresses.items
  );
  const customerPropertyAddresses = processAddressArrayAsJson(
    jobData?.customerProperty?.companyAddresses.items
  );
  const formSmartFieldData = {
    ...jobData,
    billingAddress: billingCustomerAddresses.billingAddress,
    propertyAddress: customerPropertyAddresses.propertyAddress,
    jobNumber: jobData.customIdentifier ?? jobData.jobNumber
  };

  return (
    <div className={classes.root}>
      <AttachmentSection
        isLoading={!attachments.nonImage}
        parent={jobData}
        data={{
          items:
            attachments.nonImage?.map(a => ({
              ...a,
              shareWithTechniciansOnMobile: a.hideFromTechniciansOnMobile ? 'No' : 'Yes'
            })) || []
        }}
        refresh={() => refetchAttachments()}
        mutationService={new JobService().mutateJobAttachment}
        hasShareWithTechsOption
      />
      {!flags.procurement && (
        <ReceiptSection
          isLoading={!receipts}
          parent={{ id: jobId }}
          receipts={receipts || []}
          onGeneratePurchaseOrder={onGeneratePurchaseOrder}
          caslKey={[PermissionConstants.OBJECT_JOB]}
        />
      )}
      {isDynamicFormsEnabled && (
        <FormSection parent={formSmartFieldData} caslKey={[PermissionConstants.OBJECT_JOB]} />
      )}
      <ImageSection visits={visits || []} attachments={attachments.image} />
    </div>
  );
};

const jobDataPropType = PropTypes.shape({
  sortKey: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  jobTypeId: PropTypes.string.isRequired,
  customerPropertyId: PropTypes.string.isRequired,
  quoteId: PropTypes.string.isRequired,
  quote: PropTypes.object.isRequired,
  quoteJobs: PropTypes.array.isRequired,
  jobNumber: PropTypes.string.isRequired,
  tenantId: PropTypes.string.isRequired,
  tenantCompanyId: PropTypes.string.isRequired,
  jobTypeInternal: PropTypes.string.isRequired
});

FormsAttachments.propTypes = {
  jobId: PropTypes.string.isRequired,
  jobSortKey: PropTypes.string.isRequired,
  attachments: PropTypes.shape({
    image: PropTypes.string,
    nonImage: PropTypes.bool
  }).isRequired,
  receipts: PropTypes.array.isRequired,
  visits: PropTypes.array,
  refetchAttachments: PropTypes.func.isRequired,
  onGeneratePurchaseOrder: PropTypes.func.isRequired,
  jobData: jobDataPropType.isRequired,
  flags: PropTypes.object
};

FormsAttachments.defaultProps = {
  visits: [],
  flags: {}
};

JobTabs.propTypes = {
  jobData: jobDataPropType.isRequired,
  jobNumber: PropTypes.string.isRequired,
  snackbar: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  jobsVisitRef: PropTypes.shape({ current: PropTypes.shape({ visits: PropTypes.array }) })
    .isRequired,
  isJobTypeInternal: PropTypes.bool
};

JobTabs.defaultProps = {
  isJobTypeInternal: false
};

export default connect(state => ({ user: state.user }), { snackbar: snackbarOn })(JobTabs);
