import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { merge, noop } from 'lodash';
import { SergeantModal, PageHeader, Spinner, UserPermission } from 'components';
import { MUIForm } from '@buildhero/sergeant';
import DefaultButton from 'components/Buttons/DefaultButton';
import PlacesSearch from 'components/BuildHeroFormComponents/PlacesSearch';
import { validations, CompanyService } from 'services/core';
import { Logger } from 'services/Logger';
import ErrorBoundaries from 'scenes/Error';
import configuration from 'meta/Settings/Company/CompanyInfo';
import { skillsModalLayout } from 'meta/Settings/Company/skillsModal';
import { snackbarOn } from 'redux/actions/globalActions';
import { processAddressArrayAsJson, mapAddressJsonToFields, logErrorWithCallback } from 'utils';
import Context from 'components/Context';
import { PermissionConstants } from 'utils/AppConstants';
import { Mode, FormVersion } from 'utils/constants';
import styles from './styles';
import SkillsEdit from './SkillsEdit';
import {
  ImageDisplay,
  Logo,
  LogoButtonWrapper as LogoButton,
  HighlightText,
  CombinedAddress
} from './CustomComponents';

class CompanySection extends Component {
  constructor(props) {
    super(props);
    this.mounted = props.mounted;
    this.CompanyService = new CompanyService();
    this.state = {
      companyData: '',
      isCompanyInfoSet: false,
      mode: '',
      modal: {
        open: false,
        data: null,
        dataType: 'Skills',
        mode: Mode.EDIT,
        handlePrimaryAction: () => console.log('no primary action set'),
        formVersion: FormVersion.EDIT
      },
      formService: null,
      submitting: false
    };
  }

  componentDidUpdate = prevProps => {
    // if department skills has been updated, fetch updated mappings
    if (prevProps.departmentsUpdatedCount !== this.props.departmentsUpdatedCount) {
      this.resetCompanyInfo();
    }
  };

  componentDidMount = () => {
    this.resetCompanyInfo();
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  submitCompanyInfo = async values => {
    const { user } = this.props;
    try {
      this.setState({ submitting: true });
      const response = await this.CompanyService.updateCompany({
        ...values,
        parent: {
          entityType: 'Tenant',
          id: user.tenantId
        }
      });
      const data = response.data || response;
      if (data) {
        this.props.snackbarOn('success', 'Successfully updated company info');
      }
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to edit company info, please try again later', error);
    } finally {
      await this.resetCompanyInfo();
      Context.setCompanyContext(
        user.tenantId,
        Context.generateCompanyContextSortKey(user),
        noop,
        true
      );
      this.setState({ submitting: false });
    }
  };

  overrideHeaderButtons = mode => {
    const { modal } = this.state;
    if (mode === Mode.VIEW)
      return (
        <Box>
          <UserPermission I={mode} action={PermissionConstants.ONLY_ADMIN}>
            <DefaultButton
              variant="outlined"
              onClick={() => this.setState({ modal: { ...modal, open: true } })}
              label="Edit Skills"
              style={{ marginRight: 8 }}
            />
          </UserPermission>
          <UserPermission I={mode} action={PermissionConstants.ONLY_ADMIN}>
            <DefaultButton
              color="primary"
              onClick={() => {
                const { companyData: data } = this.state;
                const companyData = { ...data };
                companyData.mode = Mode.EDIT;
                this.setState({ companyData, mode: Mode.EDIT });
              }}
              label="Edit Company Info"
              style={{ marginRight: 8 }}
            />
          </UserPermission>
        </Box>
      );
    if (mode === Mode.EDIT)
      return (
        <Box>
          {this.state.isCompanyInfoSet && (
            <UserPermission I={mode} action={PermissionConstants.ONLY_ADMIN}>
              <DefaultButton
                variant="outlined"
                disabled={this.state.submitting}
                onClick={() => {
                  this.state.formService.reset();
                  this.setState({ mode: Mode.VIEW });
                }}
                label="Cancel"
                style={{ marginRight: 8 }}
              />
            </UserPermission>
          )}
          <UserPermission I={mode} action={PermissionConstants.ONLY_ADMIN}>
            <DefaultButton
              color="primary"
              disabled={this.state.submitting}
              onClick={() => this.state.formService.submit()}
              label="Save Changes"
              style={{ marginRight: 8 }}
              showSpinner={this.state.submitting}
            />
          </UserPermission>
        </Box>
      );
  };

  resetCompanyInfo = async () => {
    const { modal } = this.state;
    if (!this.props.user.tenantId) {
      return;
    }
    const sortKey = `${this.props.user.tenantId}_Company_${this.props.user.tenantCompanyId}`;
    try {
      const { data } = await this.CompanyService.getCompanyInfo(
        `${this.props.user.tenantId}`,
        sortKey
      );
      if (data?.getCompany) {
        const companyData = data.getCompany;
        if (companyData && companyData !== '') {
          const combinedAddress = processAddressArrayAsJson(companyData.companyAddresses.items);
          merge(companyData, combinedAddress);
          const addressFields = mapAddressJsonToFields(companyData.companyAddresses.items);
          merge(companyData, addressFields);
          const isCompanyInfoSet = companyData.status === 'complete';
          if (this.mounted) {
            this.setState({
              companyData,
              isCompanyInfoSet,
              mode: isCompanyInfoSet ? Mode.VIEW : Mode.EDIT,
              modal: {
                ...modal,
                data: {
                  skills: companyData?.skills.items || []
                }
              }
            });
          }
        }
      }
    } catch (error) {
      Logger.error(`Error in fetching all company information ${JSON.stringify(error)}`);
      this.props.snackbarOn('error', 'Unable to fetch data, please try again later', error);
    }
  };

  contextUpdate = () => {
    Logger.debug('Context updated');
  };

  performDelete = async record => {
    if (!record) {
      return;
    }
    try {
      const { data } = await this.CompanyService.deleteDepartment(
        this.props.user.tenantId,
        record.sortKey
      );

      if (data) {
        Context.setCompanyContext(
          this.props.user.tenantId,
          Context.generateCompanyContextSortKey(this.props.user),
          this.contextUpdate,
          true
        );
        await this.resetCompanyInfo();
        this.props.snackbarOn(
          'success',
          `Successfully deleted department: ${record.departmentName}`
        );
      }
    } catch (error) {
      Logger.debug(error);
      this.props.snackbarOn('error', 'Unable to delete department, please try again later', error);
    }
  };

  handleOnComplete = () => {
    this.setState({ isCompanyInfoSet: true, mode: Mode.VIEW });
    this.componentDidMount();
  };

  handleModalClose = () => {
    const { modal } = this.state;
    const updatedModal = { ...modal };
    updatedModal.open = false;
    this.setState({ modal: updatedModal });
  };

  setCustomControls = () => {
    const skillsEdit = data => {
      return (
        <SkillsEdit
          snackbarOn={this.props.snackbarOn}
          form={data.form}
          updateContext={() => this.resetCompanyInfo()}
        />
      );
    };
    const customControls = {
      SkillsEdit: skillsEdit
    };
    return customControls;
  };

  processEditSkillsData = record => {
    const { tenantCompanyId } = this.props.user;
    const updatedSkills =
      record?.skills?.map(skill => ({
        id: skill.id,
        tagName: skill.tagName,
        tagType: 'skill'
      })) || [];
    return { companyId: tenantCompanyId, skills: updatedSkills };
  };

  handleEditSkills = async (record, stopLoadingModal) => {
    if (!record) {
      return;
    }
    try {
      const processedRecord = this.processEditSkillsData(record);
      const { data } = await this.CompanyService.updateCompanySkills(
        this.props.user.tenantId,
        processedRecord
      );
      if (data) {
        const sortKey = `${this.props.user.tenantId}_Company_${this.props.user.tenantCompanyId}`;
        Context.setCompanyContext(
          this.props.user.tenantId,
          Context.generateCompanyContextSortKey(this.props.user),
          this.contextUpdate,
          true
        );
        this.props.setCompanySkillsUpdatedCount();
        await this.resetCompanyInfo();
        this.props.snackbarOn('success', `Successfully updated company skills`);
      }
    } catch (error) {
      logErrorWithCallback(error, this.props.snackbarOn);
    }
    stopLoadingModal();
    this.handleModalClose();
  };

  render() {
    const { user } = this.props;
    const data = this.state.companyData;
    if (!data) {
      return <Spinner />;
    }
    const { mode, modal } = this.state;
    return (
      <ErrorBoundaries>
        <PageHeader
          pageMapKey="company"
          userLocale={user.locale}
          overrideHeaderButtons={this.overrideHeaderButtons(mode)}
        />
        <div className={this.props.classes.body}>
          <MUIForm
            data={data}
            configuration={configuration(user.locale)}
            layout={mode}
            onComplete={this.submitCompanyInfo}
            onCreateService={service => this.setState({ formService: service })}
            validationSchema={validations.companySchema}
            customComponents={{
              ImageDisplay,
              Logo,
              LogoButton,
              HighlightText,
              CombinedAddress,
              PlacesSearch
            }}
          />
        </div>
        <SergeantModal
          open={modal.open}
          layout={skillsModalLayout()}
          data={modal.data}
          dataType={modal.dataType}
          mode={modal.mode}
          customComponents={this.setCustomControls()}
          handlePrimaryAction={this.handleEditSkills}
          handleClose={this.handleModalClose}
          formVersion={modal.formVersion}
        />
      </ErrorBoundaries>
    );
  }
}

const styledCompanySection = withStyles(styles, { withTheme: true })(CompanySection);

const mapStateToProps = state => ({
  user: state.user,
  application: state.application,
  menu: state.menu
});

const mapNewCustomerToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const reduxConnectedCompanySection = connect(
  mapStateToProps,
  mapNewCustomerToProps
)(styledCompanySection);

export default reduxConnectedCompanySection;
