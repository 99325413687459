import React from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { connect } from 'react-redux';

import ResponsiveTable from 'components/ResponsiveTable';
import { quoteRowMeta } from 'meta/Jobs/quoteTable';
import { snackbarOn } from 'redux/actions/globalActions';

// seperated out as a seperate componenet for futuristic purpose
const QuoteList = ({ data }) => {
  const processedData = data.map(quote => ({
    ...quote,
    displayQuoteNumber: quote?.customIdentifier || quote?.quoteNumber,
    totalAmountQuoted: quote?.totalAmountQuotedOverride || quote?.totalAmountQuoted
  }));
  const flags = useFlags();

  return (
    <ResponsiveTable
      rowMetadata={quoteRowMeta(flags)}
      data={processedData}
      fullScreen
      noDataMsg="No quotes"
      disableFilter
      disableSort
    />
  );
};

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = { snackbarOn };

const reduxConnectedQuote = connect(mapStateToProps, mapDispatcherToProps)(QuoteList);

export default reduxConnectedQuote;
