import gql from 'graphql-tag';
import useExtendedQuery from 'customHooks/useExtendedQuery';
import {
  reportFragment,
  feesFragment,
  discountsFragment,
  inventoryPartsFragment,
  reviewReportBillLinesFragment,
  purchasedOrderFragment,
  laborLineItemFragment
} from './queryFragment';

import watchedQueries from './watchedQueries';

export const GET_REVIEW_REPORT_BY_ID = gql`
  query getReviewReportById($id: String!) {
    getReviewReportById(id: $id) {
      id
      createdBy
      createdDateTime
      status
      tenantCompanyId
      partitionKey
      priceBookId
      version
      discounts(entityConnection: "Discount") {
        ${discountsFragment}
      }
      fees(entityConnection: "Fee") {
        ${feesFragment}
      }
      inventoryParts(entityConnection: "InventoryPart") {
        ${inventoryPartsFragment}
      }
      purchaseOrders(entityConnection: "PurchaseOrder") {
        items {
          ${purchasedOrderFragment}
        }
      }
      customerSignatures(entityConnection: "CustomerSignature") {
        items {
          id
          version
          nameOfSignee
          signatureImageUrl
          includeInInvoice
          capturedBy
          signedDateTime
          createdDate
          createdBy
          visit {
            visitNumber
          }
        }
      }
      summaries(entityConnection: "Summary") {
        items {
          id
          summary
          includeInInvoice
          createdDateTime
          lastUpdatedBy
          lastUpdatedDateTime
          version
        }
      }
      visit: parentEntity {
        ... on Visit {
          ${reportFragment}
        }
      }
      technicianNotes(entityConnection: "TechnicianNoteConnection") {
        items {
          id
          subject
          note
          lastUpdatedBy
          lastUpdatedDateTime
          addedBy
          addedDateTime
          version
        }
      }
      attachments(entityConnection: "Attachment") {
        items {
          id
          customFileName
          fileName
          fileUrl
          type
          comment
          addedBy
          dateAdded
          createdBy
          createdDate
          createdDateTime
          includeInInvoice
          version
        }
      }
      labourRateLineItems(entityConnection: "LabourRateLineItem") {
        items {
          ${laborLineItemFragment}
        }
      },
      ${reviewReportBillLinesFragment}
    }
  }
`;

const transform = data => {
  return data?.getReviewReportById;
};

export const useReviewReport = id => {
  const variables = { id };

  watchedQueries.GET_REVIEW_REPORT_BY_ID = {
    query: GET_REVIEW_REPORT_BY_ID,
    variables
  };

  return useExtendedQuery(GET_REVIEW_REPORT_BY_ID, {
    variables,
    transform,
    defaultData: {
      job: {},
      visit: {}
    }
  });
};
