import React, { useState } from 'react';

import { bool, func, object } from 'prop-types';

import WarningIcon from '@material-ui/icons/Warning';
import { Checkbox, InlineAlert, InlineAlertTypes, } from '@buildhero/sergeant';
import usePricebookEntries from 'customHooks/usePricebookEntries';
import getJobIdentifier from 'utils/getJobIdentifier';

import { TimeAndMaterialsItemsTypes } from '../../CreateInvoice.constants';
import useCreateInvoiceFromTimeAndMaterialsJob from '../../hooks/useCreateInvoiceFromTimeAndMaterialsJob';
import CreateInvoiceModal from '../CreateInvoiceModal';
import InvoiceAmountFooter from '../InvoiceAmountFooter';
import JobLabel from '../JobLabel';

import ItemsTypeSelect from './components/ItemsTypeSelect';
import VisitsSelect from './components/VisitsSelect';
import {
  useGetVisitIds,
  useInvoiceTotal,
  useLineItemsByTypeAndVisits,
  useVisits
} from './TimeAndMaterialsJobCreateModal.hooks';

const TimeAndMaterialsJobCreateModal = ({ jobData, open, onClose, onSuccess }) => {
  const [selectedItemsType, setSelectedItemsType] = useState(TimeAndMaterialsItemsTypes.ENTIRE_JOB);
  const [selectedVisitIds, setSelectedVisitIds] = useState([]);
  const [createInvoice] = useCreateInvoiceFromTimeAndMaterialsJob();
  const [includeSummaries, setIncludeSummaries] = useState(false);
  const visits = useVisits(jobData);
  const selectedLineItems = useLineItemsByTypeAndVisits({
    jobData,
    visits,
    selectedItemsType,
    selectedVisitIds
  });
  const [pricebookEntries] = usePricebookEntries({
    pricebookId: jobData?.priceBookId,
    productSortKeys:
      selectedLineItems?.map(item => item?.product?.sortKey).filter(sortKey => Boolean(sortKey)) ||
      []
  });

  const invoiceAmount = useInvoiceTotal({ items: selectedLineItems, pricebookEntries });

  const handleItemsTypeSelect = type => setSelectedItemsType(type);

  const handleVisitsSelect = visitIds => setSelectedVisitIds(visitIds);
  const visitIds = useGetVisitIds(visits, selectedVisitIds, selectedItemsType);

  const handleSubmit = async () => {
    const lineItemIds = selectedLineItems.map(({ id }) => id);
    const summary = visits?.filter(({ id }) => 
      includeSummaries && (selectedItemsType === TimeAndMaterialsItemsTypes.ENTIRE_JOB || selectedVisitIds.includes(id)))
        ?.flatMap(({ amount, summaries }) => { 
          if (amount > 0) {
            return summaries?.items?.map(({ summary }) => summary)
          }
        })
        .join('\n');
    const response = await createInvoice({
      jobId: jobData?.id,
      lineItemIds,
      visitIds,
      summary
    });
    await onSuccess();
    return response?.data?.createInvoiceFromTimeAndMaterialsJob;
  };

  const jobIdentifier = getJobIdentifier(jobData);
  const canCreateInvoice =
    selectedItemsType !== TimeAndMaterialsItemsTypes.VISITS ||
    (selectedItemsType === TimeAndMaterialsItemsTypes.VISITS && selectedVisitIds.length);

  const renderBody = () => (
    <>
      <JobLabel jobIdentifier={jobIdentifier} />
      <ItemsTypeSelect selectedItemsType={selectedItemsType} onSelect={handleItemsTypeSelect} />
      {selectedItemsType === TimeAndMaterialsItemsTypes.VISITS && (
        <VisitsSelect visits={visits} onVisitsSelect={handleVisitsSelect} />
      )}
      <InvoiceAmountFooter invoiceAmount={invoiceAmount} />
      {(selectedItemsType === TimeAndMaterialsItemsTypes.VISITS || selectedItemsType === TimeAndMaterialsItemsTypes.ENTIRE_JOB) && (
        <>
          <Checkbox 
            checked={includeSummaries} 
            value={includeSummaries} 
            label="Include visit summaries in the invoice summary"
            horizontalLabel={true}
            onClick={() => setIncludeSummaries(prev => !prev)}
            style={{
              margin: "1em 0"
            }}
          />
          <InlineAlert Icon={WarningIcon} type={InlineAlertTypes.YELLOW}>
            Invoice will only include visit summaries for visits with associated costs
          </InlineAlert>
        </>
      )}
    </>
  );

  return (
    <CreateInvoiceModal
      open={open}
      onClose={onClose}
      canCreateInvoice={canCreateInvoice}
      onSubmit={handleSubmit}
      renderBody={renderBody}
    />
  );
};

TimeAndMaterialsJobCreateModal.propTypes = {
  jobData: object.isRequired,
  open: bool.isRequired,
  onClose: func.isRequired,
  onSuccess: func.isRequired
};

export default TimeAndMaterialsJobCreateModal;
