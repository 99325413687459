import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { pdf, PDFViewer } from '@react-pdf/renderer';
import { makeStyles } from '@material-ui/core/styles';
import FullScreenModal from 'components/FullScreenModal';
// eslint-disable-next-line import/no-cycle
import SplitButton from 'components/Buttons/SplitButton';
import DefaultButton from 'components/Buttons/DefaultButton';
import { PDFDocument } from '@buildhero/sergeant';
import { getCompanyBytenantId } from 'services/API/companies';
import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';
import { AppConstants } from 'utils/AppConstants';
import { formatAddress, parseFloatAndRound, roundCurrency } from 'utils';
import PDFDocumentLayout from 'meta/Procurement/PurchaseOrders/poGeneratePdf';
import { getCloudinaryImageUrl } from 'scenes/ProjectManagement/components/utils';
// eslint-disable-next-line import/no-cycle
import SendEmailPopUp from 'scenes/Procurement/component/SendEmailPopUp';
import { PurchaseOrderStatus } from 'utils/constants';
import { getJobProjectOrMaintenanceName } from '../../../../component/utils';

const splitButtons = {
  markAsOrdered: 'Mark as Ordered',
  orderViaEmail: 'Order Via Email'
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

const GeneratePdfModal = props => {
  const { open, user, pdfData, handleClose, handlePlaceOrder, status, purchaseOrder } = props;
  const classes = useStyles();
  const [companyInfo, setCompanyInfo] = useState({});
  const [isOpendEmailModal, setIsOpendEmailModal] = useState(false);
  const [uploadedPdfUrl, setUploadedPdfUrl] = useState('');
  const [uploadedPdfFileName, setUploadedPdfFileName] = useState('');
  const [orderEmailRecipient, setOrderEmailRecipient] = useState('');
  const [emailSubject, setEmailSubject] = useState('Purchase Order');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // grab the company data
    const getCompanyData = async () => {
      const companyData = await getCompanyBytenantId(user.tenantId);
      setCompanyInfo(companyData[0]);
    };
    getCompanyData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sanitizeItems = items => {
    if (!items) return [];

    const _items = [...items];
    return _items
      ?.sort((a, b) => a.lineNumber - b.lineNumber)
      .map(data => {
        return {
          itemName: data.itemName,
          description: data.description,
          taxable: data.taxable ? 'Yes' : 'No',
          quantity: data.quantity,
          unitCost: data.unitCost,
          totalCost: data.totalCost
        };
      });
  };

  const getFormattedData = () => {
    // calculate the subtotal
    const items =
      pdfData?.items?.map(item => ({
        ...item,
        totalCost: roundCurrency(item.quantity * item.unitCost)
      })) || [];
    const subTotal = items?.reduce((acc, next) => acc + next.totalCost, 0) || 0.0;

    // calculate the taxable
    const taxRate = parseFloatAndRound(pdfData?.costs?.taxRate?.taxRate, 3) / 100.0 || 0.0;

    // calculate the tax
    const taxNumber = pdfData?.costs?.taxRate?.taxRate || 0.0;

    // calculate the taxableTotal
    let taxableTotal =
      items
        ?.map(item => (item.taxable ? item.totalCost : 0.0))
        .reduce((acc, val) => acc + val, 0) || 0.0;

    taxableTotal = roundCurrency(taxableTotal * taxRate);
    // calculate the freight amount
    const freightTotal = pdfData?.costs?.freightCost || 0.0;

    // calculate the total tax
    const finalTotal = subTotal + taxableTotal + Number(freightTotal);

    // return back out results
    return {
      poNumber: pdfData.generalInfo?.poNumber || null,
      senderLogoUrl: getCloudinaryImageUrl(companyInfo?.logoUrl || null),
      senderAddress: formatAddress(companyInfo?.companyAddresses || null, true) || null,
      senderPhoneNumber: companyInfo?.phonePrimary || null,
      date: pdfData?.generalInfo?.date
        ? moment.utc(new Date(pdfData?.generalInfo?.date)).format(AppConstants.DATE_FORMAT)
        : null,
      jobProjectOrMaintenanceName: getJobProjectOrMaintenanceName(purchaseOrder?.topbar),
      vendorName: pdfData?.generalInfo?.vendor?.billTo || '',
      vendorAddress: formatAddress(pdfData?.generalInfo?.vendor, true) || null,
      vendorPhoneNumber: pdfData?.generalInfo?.vendor?.phone || null,
      shippingAddress: pdfData?.generalInfo?.shippingAddress || null,
      shippingPhoneNumber: null,
      description: pdfData?.generalInfo?.description || 'No description',
      poItems: sanitizeItems(items) || [{}],
      subtotal: parseFloatAndRound(subTotal, 2), // subtotal: parseFloatAndRound(pdfData?.costs?.subtotal, 2) || 0,
      freightCost: parseFloatAndRound(freightTotal, 2), // freightCost: parseFloatAndRound(pdfData?.costs?.freightCost, 2) || 0,
      taxRate: parseFloatAndRound(taxNumber, 3), // taxRate: parseFloatAndRound(pdfData?.costs?.taxRate?.taxRate, 3) || 0,
      taxAmount: parseFloatAndRound(taxableTotal, 2), // parseFloatAndRound(pdfData?.costs?.taxAmount, 2) || 0,
      total: parseFloatAndRound(finalTotal, 2) // total: parseFloatAndRound(pdfData?.costs?.newTotal, 2) || 0
    };
  };

  const renderPDF = () => {
    return (
      <PDFDocument
        layout="pdf"
        configuration={PDFDocumentLayout(pdfData.generalInfo?.poNumber || '-')}
        initialValues={getFormattedData()}
      />
    );
  };

  const uploadPDF = async pdfFile => {
    const currentDayFileFormat = moment().format('MM-DD-YYYY');
    const poUploadFileName = `Purchase_Order_${currentDayFileFormat}.pdf`;

    try {
      const storageService = new StorageService();
      const s3Response = await storageService.uploadFile(
        pdfFile,
        `${user.tenantId}/${poUploadFileName}`,
        e => e,
        'application/pdf'
      );
      setOrderEmailRecipient(pdfData?.generalInfo?.vendor?.email || '');

      let subject = 'Purchase Order';
      const companyName = companyInfo?.companyName;
      const poNumber = pdfData?.generalInfo.poNumber;
      if (companyName) {
        subject = subject.concat(` - ${companyName}`);
      }
      if (poNumber) {
        subject = subject.concat(` - ${poNumber}`);
      }
      setEmailSubject(subject);

      setUploadedPdfFileName(poUploadFileName);
      return s3Response;
    } catch (error) {
      Logger.error(error);
    }
  };

  const generateAndUploadPDF = async () => {
    const pdfDoc = renderPDF();
    const blobData = await pdf(pdfDoc).toBlob();
    if (blobData) {
      const pdfFileUrl = await uploadPDF(blobData);
      setUploadedPdfUrl(pdfFileUrl);
    }
    setIsSubmitting(false);
    setIsOpendEmailModal(true);
  };

  const handleSharePdf = () => {
    setIsSubmitting(true);
    generateAndUploadPDF();
  };

  const handleSendEmailModalClose = () => {
    setIsOpendEmailModal(false);
  };

  return (
    <div className={classes.root}>
      <FullScreenModal
        title="Purchase Order"
        open={open}
        handleClose={handleClose}
        style={{ display: 'flex', flex: '1 1 auto' }}
        contentStyle={{ marginTop: 0, marginBottom: 0, flex: '1 1 auto' }}
        modalHeaderButtons={[
          <DefaultButton
            key="generatePdfSharePdfBtn"
            label="Share PDF"
            variant="containedSecondary"
            style={{ marginRight: 8 }}
            showSpinner={isSubmitting}
            onClick={() => handleSharePdf()}
          />,
          status === PurchaseOrderStatus.DRAFT && (
            <SplitButton
              key="generatePdfSplitBtn"
              color="primary"
              label={splitButtons.markAsOrdered}
              onClick={handlePlaceOrder}
              options={[
                {
                  label: splitButtons.orderViaEmail,
                  onClick: () => handleSharePdf()
                }
              ]}
            />
          )
        ].filter(Boolean)}
      >
        <PDFViewer width="100%" height="100%">
          <PDFDocument
            layout="pdf"
            configuration={PDFDocumentLayout()}
            initialValues={getFormattedData()}
          />
        </PDFViewer>
      </FullScreenModal>
      <SendEmailPopUp
        open={isOpendEmailModal}
        title="Email Purchase Order"
        btnLabel="Send Email"
        pdfData={getFormattedData()}
        uploadedPdfUrl={uploadedPdfUrl}
        uploadedPdfFileName={uploadedPdfFileName}
        orderEmailRecipient={orderEmailRecipient}
        emailSubject={emailSubject}
        handleModalClose={handleSendEmailModalClose}
      />
    </div>
  );
};

GeneratePdfModal.propTypes = {
  open: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  pdfData: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handlePlaceOrder: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired
};

GeneratePdfModal.defaultProps = {
  pdfData: {}
};

export default GeneratePdfModal;
