import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { orderBy } from 'lodash';

import WrapTable from 'components/WrapTable';
import { ThemeProvider, PercentageInput, CurrencyInput } from '@buildhero/sergeant';
import {
  getJobCloseoutType,
  tableCurrencyFormatter,
  tablePercentageFormatter
} from 'scenes/JobCloseout/utils';
import { JobCloseoutTypes } from 'scenes/JobCloseout/constants';

const getColumns = (jobType, byCost, derivedJobCostingValues) => {
  const columns = [
    byCost === 'code' 
      ? { field: 'costCodeName', headerName: 'Cost Code', width: 150 }
      : { field: 'jobCostTypeName', headerName: 'Job Cost Type', width: 150 },
    { field: 'departmentName', headerName: 'Department', width: 200 },
    { field: 'itemName', headerName: 'Item', width: 200 },
    jobType === JobCloseoutTypes.QUOTED && { 
      field: 'costBudgeted', 
      headerName: 'Estimated Costs', 
      width: 200,
      valueFormatter: tableCurrencyFormatter,
      renderCell: ({ row }) => (
        <CurrencyInput 
          value={row?.costBudgeted}
          readOnly
          css={css`
            input {
              font-weight: ${row?.isDerivedCostValue ? 'bold' : 'initial'}
            }
          `}
        />
      ),
      renderTotal: () => (
        <CurrencyInput 
          value={derivedJobCostingValues?.costBudgeted}
          readOnly
        />
      )
    },
    { 
      field: 'costActual', 
      headerName: 'Actual Costs',
      width: 200,
      valueFormatter: tableCurrencyFormatter,
      renderCell: ({ row }) => (
        <CurrencyInput 
          value={row?.costActual}
          readOnly
          css={css`
            input {
              font-weight: ${row?.isDerivedCostValue ? 'bold' : 'initial'}
            }
          `}
        />
      ),
      renderTotal: () => (
        <CurrencyInput 
          value={derivedJobCostingValues?.costActual}
          readOnly
        />
      )
    },
    jobType === JobCloseoutTypes.QUOTED && { 
      field: 'costVariance', 
      headerName: 'Variance',
      width: 200,
      valueFormatter: tablePercentageFormatter,
      renderCell: ({ row }) => (
        <PercentageInput 
          value={row?.costVariance}
          readOnly
          css={{
            color: `${row?.costVariance > 0 ? '#ff0000' : '#33cc33'}`
          }}
        />
      ), 
      renderTotal: () => (
        <PercentageInput 
          value={derivedJobCostingValues?.costVariance}
          readOnly
          css={{
            color: `${derivedJobCostingValues?.costVariance > 0 ? '#ff0000' : '#33cc33'}`
          }}
        />
      )
    },
    jobType === JobCloseoutTypes.QUOTED && { 
      field: 'revenueBudgeted', 
      headerName: 'Estimated Revenue',
      width: 200,
      valueFormatter: tableCurrencyFormatter,
      renderCell: ({ row }) => (
        <CurrencyInput 
          value={row?.revenueBudgeted}
          readOnly
        />
      ),
      renderTotal: () => (
        <CurrencyInput 
          value={derivedJobCostingValues?.revenueBudgeted}
          readOnly
        />
      )
    },
    { 
      field: 'revenueActual', 
      headerName: 'Actual Revenue',
      width: 200,
      valueFormatter: tableCurrencyFormatter,
      renderCell: ({ row }) => (
        <CurrencyInput 
          value={row?.revenueActual}
          readOnly
        />
      ),
      renderTotal: () => (
        <CurrencyInput 
          value={derivedJobCostingValues?.revenueActual}
          readOnly
        />
      )
    },
    jobType === JobCloseoutTypes.QUOTED && { 
      field: 'revenueVariance',
      headerName: 'Variance',
      width: 200,
      valueFormatter: tablePercentageFormatter,
      renderCell: ({ row }) => (
        <PercentageInput 
          value={row?.revenueVariance}
          readOnly
          css={{
            color: `${row?.revenueVariance > 0 ? '#ff0000' : '#33cc33'}`
          }}
        />
      ), 
      renderTotal: () => (
        <PercentageInput 
          value={derivedJobCostingValues?.revenueVariance}
          readOnly
          css={{
            color: `${derivedJobCostingValues?.revenueVariance > 0 ? '#ff0000' : '#33cc33'}`
          }}
        />
      )
    },
    jobType === JobCloseoutTypes.QUOTED && { 
      field: 'profitBudgeted', 
      headerName: 'Estimated Profit',
      width: 200,
      valueFormatter: tableCurrencyFormatter,
      renderCell: ({ row }) => (
        <CurrencyInput 
          value={row?.profitBudgeted}
          readOnly
        />
      ), 
      renderTotal: () => (
        <CurrencyInput 
          value={derivedJobCostingValues?.profitBudgeted}
          readOnly
        />
      )
    },
    { 
      field: 'profitActual', 
      headerName: 'Actual Profit',
      width: 200,
      valueFormatter: tableCurrencyFormatter,
      renderCell: ({ row }) => (
        <CurrencyInput 
          value={row?.profitActual}
          readOnly
        />
      ),
      renderTotal: () => (
        <CurrencyInput 
          value={derivedJobCostingValues?.profitActual}
          readOnly
        />
      )
    },
    jobType === JobCloseoutTypes.QUOTED && { 
      field: 'profitVariance', 
      headerName: 'Variance',
      width: 200,
      valueFormatter: tablePercentageFormatter,
      renderCell: ({ row }) => (
        <PercentageInput 
          value={row?.profitVariance}
          readOnly
          css={{
            color: `${row?.profitVariance > 0 ? '#ff0000' : '#33cc33'}`
          }}
        />
      ),
      renderTotal: () => (
        <PercentageInput 
          value={derivedJobCostingValues?.profitVariance}
          readOnly
          css={{
            color: `${derivedJobCostingValues?.profitVariance > 0 ? '#ff0000' : '#33cc33'}`
          }}
        />
      )
    },
    jobType === JobCloseoutTypes.QUOTED && { 
      field: 'marginBudgeted', 
      headerName: 'Estimated Margin',
      width: 200,
      valueFormatter: tablePercentageFormatter,
      renderCell: ({ row }) => (
        <PercentageInput 
          value={row?.marginBudgeted}
          readOnly
        />
      ),
      renderTotal: () => (
        <PercentageInput 
          value={derivedJobCostingValues?.marginBudgeted}
          readOnly
        />
      )
    },
    { 
      field: 'marginActual', 
      headerName: 'Actual Margin',
      width: 200,
      valueFormatter: tablePercentageFormatter,
      renderCell: ({ row }) => (
        <PercentageInput 
          value={row?.marginActual}
          readOnly
        />
      ),
      renderTotal: () => (
        <PercentageInput 
          value={derivedJobCostingValues?.marginActual}
          readOnly
        />
      )
    },
    jobType === JobCloseoutTypes.QUOTED && { 
      field: 'marginVariance', 
      headerName: 'Variance',
      width: 200,
      valueFormatter: tablePercentageFormatter,
      renderCell: ({ row }) => (
        <PercentageInput 
          value={row?.marginVariance}
          readOnly
          css={{
            color: `${row?.marginVariance > 0 ? '#ff0000' : '#33cc33'}`
          }}
        />
      ),
      renderTotal: () => (
        <PercentageInput 
          value={derivedJobCostingValues?.marginVariance}
          readOnly
          css={{
            color: `${derivedJobCostingValues?.marginVariance > 0 ? '#ff0000' : '#33cc33'}`
          }}
        />
      )
    }
  ];
  return columns.filter(column => column)
};

const mapCostsToRows = ({ costItems, derivedCostCodeValues, derivedJobCostTypeValues }, byCost) => {
  const mappedRows = [];
  if (byCost === 'code') {
    derivedCostCodeValues?.forEach(({ ...values }) => {
      mappedRows.push({ 
        departmentName: '',
        isDerivedCostValue: true,
        ...values
      })
    })
  } else {
    derivedJobCostTypeValues?.forEach(({ ...values }) => {
      mappedRows.push({ 
        departmentName: '',
        isDerivedCostValue: true,
        ...values
      })
    })
  }
  costItems?.forEach(({ ...item }) => {
    mappedRows.push({ 
      costBudgeted: null,
      revenueBudgeted: null,
      revenueActual: null,
      revenueVariance: null,
      profitBudgeted: null,
      profitedActual: null,
      profitVariance: null,
      marginBudgeted: null,
      marginActual: null,
      marginVariance: null,
      ...item 
    })
  })
  if (byCost === 'code') {
    return orderBy(mappedRows, ['costCodeId', 'actual', 'costCodeName'], ['asc', 'desc', 'asc']);
  }
  return orderBy(mappedRows, ['jobCostTypeId', 'actual', 'jobCostTypeName'], ['asc', 'desc', 'asc']);
};

const JobCostingCloseoutTable = ({ jobData, byCost }) => {

  const jobCloseoutType = useMemo(() => getJobCloseoutType(jobData), [jobData]);
  const columns = useMemo(() => getColumns(jobCloseoutType, byCost, jobData?.derivedJobCosting?.derivedJobCostingValues), [jobCloseoutType, byCost, jobData?.derivedJobCosting?.derivedJobCostingValues]);
  const rows = useMemo(() => mapCostsToRows(jobData?.derivedJobCosting, byCost), [jobData, byCost])

  return (
    <ThemeProvider>
      <WrapTable
        rows={rows}
        columns={columns}
        noDataMessage="No costs data"
        numberOfRowsPerPage={25}
        enableTotalsRow
        scrollX
      />
    </ThemeProvider>
  )
}

export default JobCostingCloseoutTable