/* eslint-disable no-return-await */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from 'utils/FeatureFlagConstants';
import { makeStyles, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { pdf } from '@react-pdf/renderer';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

import { DefaultButton, FullScreenModal } from 'components';
import { snackbarOn } from 'redux/actions/globalActions';
import { sentryException } from 'services/Logger';
import { sendEmail, insertEmail, updateEmail } from 'services/API/sendEmail';
import { customerRepSearch } from 'services/API/customerRep';
import { usePayApplicationSummaryTotals, usePayAppTableData } from 'services/APIHooks';
import StorageService from 'services/StorageService';
import { getEmployees, employeeSearch } from 'services/API/employee';
import { withBuildHeroTheme } from 'themes/BuildHeroTheme';
import compose from 'utils/compose';

import { getCloudinaryImageUrl } from '@pm/components/utils';
import SearchBar from '@pm/components/APISearchComponents/SearchBar';
import CKEditor from '@pm/components/CKEditor';
import Attachments from '@pm/components/Attachments';

import { PayApplicationPdf } from '../GeneratePdfModal/GeneratePdfModal.component';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '0px 0px 0px 0px'
  },
  emailContainer: {
    marginLeft: '60px',
    marginRight: '120px',
    padding: '0px 0px 0px 0px'
  },
  attachmentsContainer: {
    marginLeft: '40px',
    marginRight: '60px'
  },
  toContainer: {
    borderBottom: `1px solid ${theme.palette.grayscale(90)}`,
    marginBottom: '10px',
    padding: '0px 0px 10px 0px'
  },
  to: {
    display: 'inline-block',
    fontSize: '14px',
    color: `${theme.palette.grayscale(50)}`
  },
  toContents: {
    maxWidth: '1000px'
  },
  subjectContainer: {
    padding: '0 0px 10px 0px'
  },
  subject: {
    display: 'inline-block',
    fontSize: '14px',
    color: `${theme.palette.grayscale(50)}`
  },
  subjectContents: {
    width: '100%',
    maxWidth: '1000px',
    innerPadding: '0px 0px 0px 0px'
  },
  contents: {
    fontSize: '14px',
    padding: '10px 0px 10px 0px',
    width: '100%'
  }
}));

const PayApplicationEmail = ({ user, snackbar, payAppSummary, open, handleClose, project }) => {
  const payApplication = payAppSummary?.PayApplication;
  const classes = useStyles();
  const flags = useFlags();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subject, setSubject] = useState(
    `${project?.name || null} Pay Application ${payApplication.number}`
  );

  const [sendToCustomers, setSendToCustomers] = useState([payApplication?.sendToCustomer?.email]);
  const [cc, setCC] = useState([]);
  const [bcc, setBCC] = useState([]);
  const sendDisabled = Boolean(!subject || !(sendToCustomers?.length || cc?.length || bcc?.length));

  const [body, setBody] = useState(
    `Please find the attached Pay Application ${payApplication.number}.<br/><br/>${user.displayName}`
  );

  const title = `Pay Application ${payApplication.number || ''}`;
  const currentDayFileFormat = moment().format('MM-DD-YYYY');
  const uploadedPdfFileName = `${title}_${currentDayFileFormat}.pdf`;

  const [attachments, setAttachments] = useState([
    {
      source: null,
      fileUrl: null,
      fileName: uploadedPdfFileName,
      fileType: 'pdf'
    }
  ]);

  const company = useSelector(state => state.company);

  const [payAppTableDataResponse] = usePayAppTableData(
    { projectId: project.id, paNumber: payApplication.number },
    {
      skip: !(open && project.id && payApplication.number),
      depends: [open, project.id, payApplication.number],
      defaultData: [],
      transform: data => {
        return data.filter(li => !li.dateApproved || li.dateApproved <= payApplication.periodTo);
      }
    }
  );

  const [payApplicationSummaryTotals] = usePayApplicationSummaryTotals(
    { projectId: project.id, paNumber: payApplication.number },
    {
      skip: !(open && project.id && payApplication.number),
      depends: [open, project.id, payApplication.number],
      defaultData: []
    }
  );

  const tableData = payAppTableDataResponse.data;
  const payAppTotals = payApplicationSummaryTotals.data;

  const emailSearchBar = (onSelect, field) => {
    return (
      <SearchBar
        className={classes.toContents}
        field={field}
        onSelectionChange={results =>
          onSelect(results.map(result => (typeof result === 'string' ? result : result.email)))
        }
        options={{
          multiline: true,
          freeSolo: true,
          placeholder: '',
          searchFunction: async (term, searchCols) => {
            return await Promise.all([
              customerRepSearch(term, searchCols, project?.customer?.id || undefined, project),
              employeeSearch(term)
            ]).then(result => [...result[0], ...result[1]]);
          },
          emptySearchFunction: async () => {
            return await Promise.all([
              customerRepSearch('', undefined, project?.customer?.id || undefined, project),
              getEmployees()
            ]).then(result => [...result[0], ...result[1]]);
          },
          resultFormatFunction: result => {
            if (typeof result === 'string') {
              return result;
            }
            return result.email && (result.customerName || result.name)
              ? `${result.customerName || result.name} - ${result.email}`
              : null;
          },
          multiple: true,
          noDuplicates: true
        }}
      />
    );
  };

  const handleUploadFileList = (list, selectedImages, selectedFiles) => {
    const remainingAttachments = attachments;
    let i = 0;
    while (i < attachments.length) {
      const currAttachment = attachments[i];
      const isImage = selectedImages.find(element => currAttachment.fileUrl === element.url);
      const isFile = selectedFiles.find(element => currAttachment.fileUrl === element.url);
      if (isFile || isImage) {
        remainingAttachments.push(currAttachment);
      }
      i += 1;
    }
    i = 0;
    const ret = [];
    while (i < list.length) {
      if (list[i].fileName && list[i].fileType && list[i].fileUrl) {
        ret.push({
          fileUrl: list[i].fileUrl || null,
          fileType: list[i].fileType || null,
          fileName: list[i].fileName || null
        });
      }
      i += 1;
    }
    setAttachments(ret.concat(remainingAttachments));
  };

  const handleSendEmail = async () => {
    setIsSubmitting(true);
    try {
      const blobData = await pdf(
        <PayApplicationPdf {...{ payAppSummary, project, tableData, payAppTotals, company }} />
      ).toBlob();

      const storageService = new StorageService();

      const uploadedPdfUrl = await storageService.uploadFile(
        blobData,
        `${user.tenantId}/${uploadedPdfFileName}`,
        e => e,
        'application/pdf'
      );

      const finalAttachments = attachments.reduce((acc, attachment) => {
        return {
          ...acc,
          [attachment.fileName]: {
            ...attachment,
            source: attachment.fileUrl || getCloudinaryImageUrl(uploadedPdfUrl)
          }
        };
      }, {});

      const emailReplies = flags[FeatureFlags.EMAIL_REPLIES];
      const reply = `${user.email.split('@')?.[0]}@${process.env.REACT_APP_env ?? 'dev'}.buildops.com`;

      const initialPayload = {
        from: emailReplies ? reply : 'noreply@buildops.com',
        replyTo: emailReplies ? reply : user.email,
        parentId: payApplication.id,
        fromName: user.displayName || undefined,
        cc,
        bcc,
        importance: 'High',
        subject,
        to: sendToCustomers.filter(element => element != null),
        html: body
      };
      const insertResult = await insertEmail(initialPayload);

      const payload = {
        ...initialPayload,
        ...insertResult.data,
        attachments: finalAttachments
      };
      const { data } = await sendEmail(payload);

      let DBPayload = {
        ...payload,
        sendCc: payload.cc?.length ? payload.cc?.join(';') : undefined,
        sendBcc: payload.bcc?.length ? payload.bcc?.join(';') : undefined,
        cc: undefined,
        bcc: undefined,
        html: undefined,
        to: undefined,
        sendTo: payload.to?.join(';'),
        body: `<!DOCTYPE html><html><body>${body}</body></html>`,
        attachments: attachments
          .filter(attachment => attachment.fileUrl)
          .map(attachment => ({
            ...attachment,
            type: attachment.fileType || attachment.type,
            fileType: undefined,
            source: undefined
          }))
      };
      if (emailReplies) {
        DBPayload = {
          ...DBPayload,
          from: user.email
        };
      }

      if (data && !data.errno) {
        await updateEmail(DBPayload.id, DBPayload);
        snackbar('success', 'Email sent successfully');
      } else {
        snackbar('error', 'Unable to send email');
      }
    } catch (e) {
      sentryException(e, 'Unable to send pay application email');
      snackbar('error', 'Unable to send email');
    } finally {
      handleClose();
    }
  };

  const subjectTextField = () => {
    return (
      <TextField
        className={classes.subjectContents}
        onChange={event => setSubject(event.target.value)}
        defaultValue={subject}
        variant="filled"
      />
    );
  };

  const emailBodyTextField = () => {
    return <CKEditor initialData={body} updateDataFn={setBody} />;
  };

  const displayAttachments = data => {
    return (
      <Attachments
        noTitle
        smallButton
        attachedFiles={data || []}
        attachedFileCount={data?.length || 0}
        uploadFileList={handleUploadFileList}
        chunkSize={5}
        imageWidth={100}
        imageHeight={100}
      />
    );
  };

  return (
    <FullScreenModal
      title="New Pay Application Email"
      open={open}
      handleClose={handleClose}
      modalHeaderButtons={[
        <DefaultButton
          disabled={sendDisabled}
          label="SEND"
          onClick={() => {
            handleSendEmail();
          }}
          showSpinner={isSubmitting}
          variant="containedPrimary"
          style={{ marginRight: '8px' }}
        />
      ]}
    >
      <div className={classes.container}>
        <div className={classes.emailContainer}>
          <Box className={classes.toContainer}>
            <Typography className={classes.to}>To: </Typography>
            {emailSearchBar(setSendToCustomers, {
              name: 'sendToCustomers',
              value: sendToCustomers
            })}
          </Box>
          <Box className={classes.toContainer}>
            <Typography className={classes.to}>CC: </Typography>
            {emailSearchBar(setCC)}
          </Box>
          <Box className={classes.toContainer}>
            <Typography className={classes.to}>BCC: </Typography>
            {emailSearchBar(setBCC)}
          </Box>
          <Box className={classes.subjectContainer}>
            <Typography className={classes.subject}>Subject: </Typography>
            {subjectTextField()}
          </Box>
          {emailBodyTextField()}
        </div>
        <div className={classes.attachmentsContainer}>{displayAttachments(attachments)}</div>
      </div>
    </FullScreenModal>
  );
};

PayApplicationEmail.propTypes = {
  user: PropTypes.object.isRequired,
  snackbar: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ user: state.user });

const mapDispatcherToProps = dispatch => ({
  snackbar: (mode, message) => dispatch(snackbarOn(mode, message))
});

const withModalUnmount = Component => props => (props.open ? <Component {...props} /> : null);

export default compose(
  connect(mapStateToProps, mapDispatcherToProps),
  withModalUnmount,
  withBuildHeroTheme
)(PayApplicationEmail);
