import React, { useMemo } from 'react';
import { MUIForm } from '@buildhero/sergeant';
import { Divider, Box, Typography, Chip } from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import moment from 'moment';
import AppConstants from 'utils/AppConstants';
import layout from './layout';
import { getOverAllSummary } from '../../ManualVisitCreationModal/helper';
import { useStyles } from '../../ManualVisitCreationModal/styles';

function Summary(props) {
  const {
    pageTitle,
    stepData,
    departments,
    techs,
    items = [],
    jobsPreferredTechniciansConfigMap,
    forms,
    crews
  } = props;
  const classes = useStyles();
  const renderTags = item => (
    <Typography className={classes.tags} variant="body2">
      {item}
    </Typography>
  );
  const visitSummary = useMemo(() => {
    const primaryTechnicianId =
      techs.filter(tech => tech.id === stepData.primaryTechnicianId) || [];
    const departmentId =
      departments.filter(department => department.id === stepData.departmentId) || [];
    const additionalTechnicianIds =
      techs
        .filter(item => {
          for (let index = 0; index < stepData?.additionalTechnicianIds?.length; index += 1) {
            const element = stepData.additionalTechnicianIds[index];
            if (element === item.id) return true;
          }
          return false;
        })
        .map(tech => tech.name)
        .join(', ') || '';

    return items.map(summaryData => {
      const { maintenanceTemplate } =
        jobsPreferredTechniciansConfigMap?.[summaryData.jobNumber] || {};
      const dueDate = moment.unix(summaryData?.dueDate).format(AppConstants.DATE_FORMAT) || '';
      const visitDate =
        stepData.days !== null
          ? moment(dueDate)
              .subtract(Number(stepData?.days), 'days')
              .format(AppConstants.DATE_FORMAT)
          : '';

      const formNames = (stepData?.visitFormIds || []).map(
        visitFormId => forms.find(form => form.id === visitFormId)?.name
      );
      const crewName =
        (stepData.selectedCrewId && crews.find(crew => crew.id === stepData.selectedCrewId)) || '';
      // assigning in step data so as to set in visit description
      stepData.description = maintenanceTemplate?.serviceDescription || '';
      return {
        estimatedVisits: maintenanceTemplate?.estimatedVisits,
        budgetedHours: maintenanceTemplate?.budgetedHours,
        jobTypeName: summaryData.jobTypeName,
        propertyName: summaryData.propertyName,
        selectedCrewName: crewName?.name || '',
        tags: summaryData.tags || [],
        agreementName: summaryData?.serviceAgreementName || '',
        interval: summaryData?.interval || '',
        dueDate,
        visitDescription: maintenanceTemplate?.serviceDescription || '',
        forms: formNames.join(', '),
        visitDate,
        startingTime: stepData?.selectedTime || '',
        department: departmentId?.[0]?.tagName || '',
        additionalTechnicianIds: additionalTechnicianIds || [],
        primaryTechnicianId: primaryTechnicianId[0]?.name || ''
      };
    });
  }, [techs, departments, items, stepData, crews, forms]);

  return (
    <div>
      <Typography variant="h4" className={classes.title}>
        {pageTitle}
      </Typography>
      <Divider variant="fullWidth" style={{ marginTop: 16 }} />
      {visitSummary.map(item => (
        <>
          <Box
            display="flex"
            className={classes.container}
            p={1.5}
            pr={2}
            style={{ margin: '30px' }}
            flex={1}
          >
            <ArrowRightIcon />
            <Box display="flex" flex={1} alignItems="flex-start" flexDirection="column">
              <Box display="flex" width="100%" pb={0.5} alignItems="center">
                <Typography variant="body2" style={{ marginRight: '10px' }}>
                  <b>{item.propertyName}</b>
                </Typography>
                <Typography className={classes.overAllCount} variant="body2">
                  {item.jobTypeName || '-'}
                </Typography>
                <Chip
                  size="small"
                  className={classes.titleChip}
                  avatar={<CalendarTodayIcon className={classes.scheduleIcon} />}
                  label={item.interval || '-'}
                />
                <Box display="flex" flex={1} justifyContent="space-between">
                  <Box display="flex">{item.tags.map(renderTags)}</Box>
                  <Box display="flex">
                    <Typography variant="body2">
                      {`Due Date: `}
                      <b>{item.dueDate || '-'}</b>
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Typography className={classes.overAllCount} variant="body2">
                {getOverAllSummary(item)}
              </Typography>
              <Box display="flex" width="100%" flexDirection="column">
                <Box display="flex" flex={1}>
                  <Box className={classes.formContainer}>
                    <Divider variant="fullWidth" style={{ marginTop: 16 }} />
                    <MUIForm configuration={layout} layout="edit" data={item} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      ))}
    </div>
  );
}

export default Summary;
