import React from 'react';

import { Box } from '@material-ui/core';
import { func } from 'prop-types';

import { useSelector } from 'react-redux';

import Labels from 'meta/labels';

import AddButton from '../../../AddButton';

import { useStyles } from './AddAttachmentsFromFilesButton.styles';

const AddAttachmentsFromFilesButton = ({ onClick }) => {
  const styles = useStyles();
  const user = useSelector(store => store.user);
  return (
    <Box css={styles.container}>
      <AddButton onClick={onClick} title={Labels.addAttachmentsFromFiles[user.locale]} />
    </Box>
  );
};

AddAttachmentsFromFilesButton.propTypes = {
  onClick: func.isRequired
};

export default AddAttachmentsFromFilesButton;
