import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { AccountingApp, SyncStatus } from 'utils/constants';
import { useHistory } from 'react-router-dom';
import { snackbarOn } from 'redux/actions/globalActions';
import classNames from 'classnames';

import { withScriptjs } from 'react-google-maps';
import isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import PlacesSearch from 'components/BuildHeroFormComponents/PlacesSearch';
import PageHeader from 'components/PageHeader';
import withLoading from 'components/WithLoading';
import StatusChip from 'components/StatusChip';
import DefaultButton from 'components/Buttons/DefaultButton';
import NamedIcon from 'components/ResponsiveTable/NamedIcon';
import ActionsMenu from 'components/ResponsiveTable/ActionsMenu';
import { getCombinedAddress } from 'utils';
import PageMap from 'meta/PageMap';
import Labels from 'meta/labels';
import getCompanyAddressByParentId from 'services/API/companyAddress';
import buildHeroMuiFormOverrides from 'scenes/ProjectManagement/components/buildHeroMuiFormOverrides';
import { generateDefaultValidationSchema } from 'scenes/ProjectManagement/components/formattingUtils';
import CustomFieldWithLabel from 'scenes/ProjectManagement/components/CustomFieldWithLabel';
import { receiptStatusChips } from 'meta/Procurement/PurchaseOrders/frameStatusChips';
import {
  getAddressObj,
  getAddressByType,
  getReceiptDueDate
} from 'scenes/Procurement/component/utils';
import {
  poFrameSideFormVendorFields,
  poFrameSideFormVendorLayout
} from 'meta/Procurement/PurchaseOrders/poFrameSideFormVendor';
import {
  poFrameSideFormShippingFields,
  poFrameSideFormShippingLayout
} from 'meta/Procurement/PurchaseOrders/poFrameSideFormShipping';
import {
  rbFrameTopFormFields,
  rbFrameTopFormLayout
} from 'meta/Procurement/PurchaseOrders/rbFrameTopForm';
import SearchBar from 'scenes/ProjectManagement/components/APISearchComponents/SearchBar';
import {
  postReceiptPost,
  purchaseOrderReceiptChange,
  addReceiptAttachment
} from 'services/API/purchaseOrderReceipt';
import { attachmentChange, attachmentDelete } from 'services/API/attachment';
import {
  getLabelFromValues,
  getCloudinaryImageUrl
} from 'scenes/ProjectManagement/components/utils';
import {
  ProcurementPurchaseOrderStatus,
  ProcurementPurchaseOrderReceiptStatus
} from 'utils/AppConstants';
import { MUIForm, Field, ThemeProvider, FieldType, ThumbnailSize } from '@buildhero/sergeant';
import { EntityImagesCarousel } from 'components';
import { getCompanyBytenantId } from 'services/API/companies';
import { getTaxRateById } from 'services/API/taxRate';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from 'utils/FeatureFlagConstants';
import { useReceiptSubscription } from './StatusSubscription.gql';
import MuiFormSectionTitle from '../MuiFormSectionTitle';
import LocationView from '../LocationView';
import { ShippingInformation, loadingParams } from '../../constants';
import GeneratePdfModal from './GeneratePdfModal';
import SyncStatusChip from './SyncStatusChip';

const CustomFieldWithLabelNarrow = ({ form, field, options }) => {
  return (
    <CustomFieldWithLabel
      form={form}
      field={field}
      options={options}
      style={{ lineHeight: '16px' }}
    />
  );
};

const CustomFieldWithLabelWrap = ({ field, options }) => {
  return (
    <CustomFieldWithLabel
      field={field}
      options={options}
      style={{ lineHeight: '20px' }}
      nowrap={false}
    />
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    color: '#333333'
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderTop: `1px solid ${theme.palette.grayscale(90)}`
  },
  sidebar: {
    borderRight: `1px solid ${theme.palette.grayscale(90)}`,
    width: 218,
    minWidth: 218
  },
  sidebarInner: {
    margin: '24px 16px 24px 0px',
    width: 202
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    overflow: 'auto'
  },
  topbar: {
    padding: '24px 16px'
  },
  childrenContainer: {
    padding: '24px 16px'
  },
  imageContainer: {
    marginTop: '30px'
  },
  carouselContainer: {
    marginTop: '15px',
    marginRight: '16px'
  },
  formContainer: buildHeroMuiFormOverrides(theme)
}));

const MuiFormWithLoading = withLoading(MUIForm);

const POData = withLoading(({ data }) => {
  const { PurchaseOrder, PurchaseOrderReceipt } = data;
  const style = { margin: '0px 0px 16px' };
  return (
    <ThemeProvider>
      <Field
        label="Purchase Order"
        type={FieldType.LINK}
        value={{
          label: `${PurchaseOrder?.poNumber}`,
          to: `/procurement/purchaseorders/view/${PurchaseOrder?.id}`
        }}
        style={style}
      />
      {PurchaseOrderReceipt && (
        <Field
          label="Receipt"
          type={FieldType.LINK}
          value={{
            label: `${PurchaseOrderReceipt?.receiptNumber}`,
            to: `/procurement/receipts-bills/receipt/view/${PurchaseOrderReceipt?.id}`
          }}
          style={style}
        />
      )}
    </ThemeProvider>
  );
});

const ReceiptBillDetailFrame = withScriptjs(({ children, ...props }) => {
  const {
    id,
    rbData,
    receiptItems,
    type,
    mode,
    user,
    getHandleCreateService,
    getHandleComplete,
    handleSubmitStart,
    setOnSubmitFinal,
    isSubmitting,
    handleFormsSubmit,
    actionButtonHandler,
    actionButtons,
    frameDataReducer,
    isLoading,
    isFileDownloading,
    disableEdit,
    isPurchaseOrderVoid
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const [status, setStatus] = useState(rbData?.status?.toLowerCase() || '');
  const { Geocoder, GeocoderStatus } = google.maps;
  const { current: geocoder } = useRef(new Geocoder());
  const [showGeneratePdfModal, setShowGeneratePdfModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [companyInfo, setCompanyInfo] = useState({});
  const [finalTaxRate, setFinalTaxRate] = useState(0);
  const [manualEntry, setManualEntry] = useState(
    rbData.sidebarShipping.shipTo === ShippingInformation.MANUALLY
  );
  const [latestStatus, setLatestStatus] = useState(rbData?.status?.toLowerCase() || '');
  const [latestSyncStatus, setLatestSyncStatus] = useState(rbData?.syncStatus);
  const [latestSyncLog, setLatestSyncLog] = useState(rbData?.syncLog);
  const statusInfo = useReceiptSubscription(user.tenantId, rbData.id);
  const { accountingApp: currentAccountingApp } = useSelector(state => state.settings);

  const flags = useFlags();
  const departmentsAreLimitedByJob = flags[FeatureFlags.DEPARTMENTS_ARE_LIMITED_BY_JOB];

  document.title = `BuildOps - ${type} #${rbData?.billNumber || rbData?.receiptNumber || ''}`;

  const showActionMenu = event => {
    setAnchorEl({ ref: event.currentTarget });
  };

  const updateReceiptStatus = async (newStatus, syncLog) => {
    if (
      (status === ProcurementPurchaseOrderReceiptStatus.EXPORTED.toLowerCase() ||
        newStatus === ProcurementPurchaseOrderReceiptStatus.EXPORTED.toLowerCase()) &&
      syncLog
    ) {
      await purchaseOrderReceiptChange(rbData.id, {
        status: ProcurementPurchaseOrderReceiptStatus.CLOSED,
        syncStatus: SyncStatus.SYNC_FAILED
      });
      setLatestStatus(ProcurementPurchaseOrderReceiptStatus.CLOSED.toLowerCase());
    } else {
      setLatestStatus(newStatus || status);
    }
  };

  useEffect(() => {
    setOnSubmitFinal(handleFormsSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rbData) {
      if (rbData.status === ProcurementPurchaseOrderStatus.PARTIALLY_FULFILLED) {
        setStatus('partiallyFulfilled');
        setLatestStatus('partiallyFulfilled');
      } else {
        setStatus(rbData?.status?.toLowerCase() || '');
        setLatestStatus(rbData?.status?.toLowerCase() || '');
      }
    }
    setLatestSyncStatus(rbData?.syncStatus || '');
    setLatestSyncLog(rbData?.syncLog || '');
    updateReceiptStatus(rbData?.status?.toLowerCase(), rbData?.syncLog);
  }, [rbData]);

  useEffect(() => {
    setLatestSyncStatus(statusInfo?.newSyncStatus || rbData?.syncStatus);
    setLatestSyncLog(statusInfo?.newSyncLog || rbData?.syncLog);
    updateReceiptStatus(statusInfo?.newStatus?.toLowerCase(), statusInfo.newSyncLog);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusInfo.newStatus, statusInfo.newSyncStatus, statusInfo.newSyncLog]);

  const validatePostReceipt = receipt => {
    if ([AccountingApp.QUICKBOOKS].includes(currentAccountingApp)) {
      const itemsWithNoExpenseAccount = receipt.PurchaseOrderReceiptLine.filter(
        lineItem => !lineItem.Product.glExpenseAccountRefValue
      ).map(lineItem => lineItem.PurchaseOrderLine.itemName);
      if (itemsWithNoExpenseAccount.length > 0) {
        return `Line item(s) ${itemsWithNoExpenseAccount.join(
          ', '
        )} on this Receipt are missing an expense account. Please update them in your item list and try again.`;
      }
    }
    return '';
  };

  const handlePostReceipt = async () => {
    const receipt = { ...rbData };
    const response = await postReceiptPost(receipt?.id);
    const errorMessage = response?.id ? validatePostReceipt(receipt) : 'Unable to post receipt';
    if (errorMessage) {
      props.snackbarOn('error', errorMessage);
    } else {
      history.push(`/procurement/receipts-bills/receipt/view/${id}`);
      props.snackbarOn('success', 'Successfully Posted Receipt');
    }

    setShowGeneratePdfModal(false);
  };

  const handlePreviewReceipt = async () => {
    const [companyData, taxData] = await Promise.all([
      getCompanyBytenantId(user.tenantId),
      getTaxRateById(rbData?.taxRateId)
    ]);
    setCompanyInfo(companyData[0]);
    if (taxData?.taxRate) {
      setFinalTaxRate(parseFloat(taxData?.taxRate));
    }
    setShowGeneratePdfModal(true);
  };

  const headerButtons = [
    <div key="procurementReceiptFrameHeaderButtons">
      {// users should still be able to edit after posting a receipt (even in the exported status)
      !disableEdit &&
        latestStatus?.toLowerCase() !==
          ProcurementPurchaseOrderReceiptStatus.CLOSED.toLowerCase() && (
          <DefaultButton
            variant="outlined"
            label={mode === 'edit' ? Labels.cancelButtonText[user.locale] : 'Edit'}
            style={{ marginRight: 8 }}
            showSpinner={isSubmitting || isFileDownloading}
            handle={
              mode === 'edit'
                ? () => history.push(`/procurement/receipts-bills/receipt/view/${id}`)
                : () => history.push(`/procurement/receipts-bills/receipt/edit/${id}`)
            }
          />
        )}
      {latestStatus?.toLowerCase() !==
        ProcurementPurchaseOrderReceiptStatus.EXPORTED.toLowerCase() && mode === 'edit' ? (
        <DefaultButton
          variant="containedPrimary"
          label={Labels.saveChangesButtonText[user.locale]}
          style={{ marginRight: 8 }}
          handle={handleSubmitStart}
          showSpinner={isSubmitting || isFileDownloading}
        />
      ) : null}
      {// users should still be able to edit and re-post the receipt although the status is Exported
      (((!rbData?.syncStatus ||
        (rbData?.syncStatus === SyncStatus.SYNC_FAILED &&
          latestStatus?.toLowerCase() !==
            ProcurementPurchaseOrderReceiptStatus.CLOSED.toLowerCase())) &&
        latestStatus?.toLowerCase() !==
          ProcurementPurchaseOrderReceiptStatus.EXPORTED.toLowerCase()) ||
        (latestStatus?.toLowerCase() ===
          ProcurementPurchaseOrderReceiptStatus.EXPORTED.toLowerCase() &&
          mode === 'edit')) && (
        <DefaultButton
          variant="contained"
          label={Labels.postReceipt[user.locale]}
          style={{ marginRight: 8 }}
          disabled={
            isSubmitting ||
            (latestStatus?.toLowerCase() !==
              ProcurementPurchaseOrderReceiptStatus.EXPORTED.toLowerCase() &&
              mode === 'edit')
          }
          showSpinner={isSubmitting || isFileDownloading}
          handle={
            latestStatus?.toLowerCase() ===
              ProcurementPurchaseOrderReceiptStatus.EXPORTED.toLowerCase() && mode === 'edit'
              ? handleSubmitStart
              : handlePostReceipt
          }
        />
      )}
      {/* should not be displayed on the Edit mode */}
      {mode !== 'edit' ? (
        <DefaultButton
          variant="contained"
          color="primary"
          label={Labels.previewReceipt[user.locale]}
          disabled={isSubmitting}
          handle={handlePreviewReceipt}
          showSpinner={isSubmitting || isFileDownloading}
        />
      ) : null}
      {!isEmpty(actionButtons) && (
        <>
          <IconButton onClick={showActionMenu}>
            <NamedIcon name="MoreVert" className={classes.iconButton} />
          </IconButton>
          <ActionsMenu
            rowActions={actionButtonHandler}
            handleMenuClose={() => setAnchorEl(null)}
            anchorEl={anchorEl}
            rowActionButtons={actionButtons}
          />
        </>
      )}
    </div>
  ];

  const additionalTitleComponents = [
    latestStatus ? (
      <StatusChip
        label={receiptStatusChips[latestStatus?.toLowerCase()]?.name[user.locale]}
        backgroundColor={receiptStatusChips[latestStatus?.toLowerCase()]?.backgroundColor}
        textColor={receiptStatusChips[latestStatus?.toLowerCase()]?.textColor}
        key="procurementRBFrameTitleStatusChips"
        style={{
          height: 24,
          lineHeight: '24px',
          borderRadius: 2,
          marginLeft: 24
        }}
      />
    ) : null
  ];

  const handleSidebarFormVendorChange = data => {
    const vendorAddress = getCombinedAddress(data.vendor);

    geocoder.geocode({ address: vendorAddress }, (results, statusCode) => {
      if (statusCode === GeocoderStatus.OK) {
        frameDataReducer({
          type: 'updateSidebarVendor',
          payload: {
            vendor: data.vendor,
            vendorName: data.vendor?.name || '',
            vendorAddress: vendorAddress || '',
            phoneNumber: data.vendor?.phone || '',
            vendorEmail: data.vendor?.email || '',
            vendorLocation: {
              latitude: results[0].geometry.location.lat(),
              longitude: results[0].geometry.location.lng()
            }
          }
        });
      }
    });
  };

  const handleTopbarFormChange = data => {
    frameDataReducer({
      type: 'updateTopbar',
      payload: {
        ...data,
        depId: data.Department?.id || data.depId
      }
    });
  };

  const handleSidebarFormShippingChange = data => {
    if (!data.shippingAddress || data.shippingAddress === '-') return;

    geocoder.geocode({ address: data.shippingAddress }, (results, statusCode) => {
      if (statusCode === GeocoderStatus.OK) {
        frameDataReducer({
          type: 'updateSidebarShipping',
          payload: {
            ...data,
            shipTo: data.shipTo || '',
            shippingAddress: data.shippingAddress || '',
            addressLine1: data.addressLine1 || undefined,
            addressLine2: data.addressLine2 || undefined,
            city: data.city || undefined,
            state: data.state || undefined,
            zipcode: data.zipcode || undefined,
            shippingLocation: {
              latitude: results[0].geometry.location.lat(),
              longitude: results[0].geometry.location.lng()
            }
          }
        });
      }
    });
  };

  const matchingShippingAddress = async (userSelect, formData) => {
    let newFormValue = {};
    let result;
    let propertyAddr;

    switch (userSelect) {
      case ShippingInformation.JOB_SITE:
        result = await getCompanyAddressByParentId(
          formData.values.jobAndProject?.customerPropertyId
        );
        propertyAddr = getAddressByType(result, 'propertyAddress');
        newFormValue = {
          ...formData.values,
          shipTo: userSelect,
          shippingAddress: getCombinedAddress(propertyAddr[0] || null),
          shippingAddressObj: getAddressObj(propertyAddr[0])
        };
        formData.setValues(newFormValue);
        break;
      case ShippingInformation.WAREHOUSE:
        propertyAddr = getAddressByType(
          formData.values.department?.companyAddresses || [],
          'shippingAddress'
        );
        newFormValue = {
          ...formData.values,
          shipTo: userSelect,
          shippingAddress: getCombinedAddress(propertyAddr[0] || null),
          shippingAddressObj: getAddressObj(propertyAddr[0])
        };
        formData.setValues(newFormValue);
        break;
      case ShippingInformation.VENDOR_PICKUP:
        newFormValue = {
          ...formData.values,
          shipTo: userSelect,
          shippingAddress: getCombinedAddress(rbData.sidebarVendor.vendor) || null,
          shippingAddressObj: getAddressObj(rbData.sidebarVendor.vendor)
        };
        formData.setValues(newFormValue);
        break;
      case ShippingInformation.MANUALLY:
      default:
        newFormValue = {
          ...formData.values,
          shipTo: userSelect,
          shippingAddress: getCombinedAddress(rbData),
          shippingAddressObj: getAddressObj(rbData)
        };
        formData.setValues(newFormValue);
        break;
    }
  };

  const handleChangeShipToSelect = async (field, form) => {
    await matchingShippingAddress(field.value, form);

    switch (field.value) {
      case ShippingInformation.JOB_SITE:
      case ShippingInformation.WAREHOUSE:
      case ShippingInformation.VENDOR_PICKUP:
        setManualEntry(false);
        break;
      case ShippingInformation.MANUALLY:
      default:
        setManualEntry(true);
        break;
    }
  };

  const dueDate = getReceiptDueDate(rbData);

  const handleAddImage = async (receiptId, data) => {
    const attachment = await addReceiptAttachment(receiptId, data);
    frameDataReducer({
      type: 'updateSidebarAttachments',
      payload: {
        Attachment: [...rbData.Attachment, attachment]
      }
    });
  };

  const handleEditImage = async (attachmentId, index, record) => {
    const payload = {
      fileUrl: record.fileUrl,
      fileName: record.fileName,
      fileSize: record.fileSize,
      comment: record.comment,
      customFileName: record.customFileName
    };
    const attachment = await attachmentChange(attachmentId, payload);
    rbData.Attachment[index] = attachment;
    frameDataReducer({
      type: 'updateSidebarAttachments',
      payload: {
        Attachment: [...rbData.Attachment]
      }
    });
  };

  const handleDeleteImage = async attachmentId => {
    await attachmentDelete(attachmentId);
    frameDataReducer({
      type: 'updateSidebarAttachments',
      payload: {
        Attachment: [...rbData.Attachment.filter(el => el.id !== attachmentId)]
      }
    });
  };

  const formatAttachments = attachments => {
    return attachments?.map(attachment => {
      return {
        ...attachment,
        url: getCloudinaryImageUrl(attachment.fileUrl)
      };
    });
  };

  return (
    <div className={classes.root}>
      <PageHeader
        breadcrumbsArray={[
          {
            link: '',
            title: 'Procurement'
          },
          {
            link: '/procurement/receipts-bills/',
            title: PageMap.procurementReceiptBills.title[user.locale]
          }
        ]}
        pageMapKey="procurementReceiptBills"
        userLocale={user.locale}
        title={
          type === 'Receipt'
            ? `Receipt #${rbData?.receiptNumber || ''}`
            : `Bill #${rbData?.billNumber || ''}`
        }
        overrideHeaderButtons={type === 'Receipt' && !isPurchaseOrderVoid ? headerButtons : null}
        additionalTitleComponents={additionalTitleComponents}
        key="procumentRBFramePageHeader"
      />
      <div className={classes.body}>
        <div className={classes.sidebar}>
          <div className={classNames(classes.sidebarInner, classes.formContainer)}>
            <POData
              data={rbData}
              isLoading={!isLoading}
              loadingParams={loadingParams.leftSection}
            />
            <MuiFormWithLoading
              configuration={poFrameSideFormVendorLayout()}
              data={rbData.sidebarVendor}
              layout={mode}
              onCreateService={getHandleCreateService('sidebarVendor')}
              onComplete={getHandleComplete('sidebarVendor')}
              onFormChange={handleSidebarFormVendorChange}
              customComponents={{
                MuiFormSectionTitle,
                CustomFieldWithLabelNarrow,
                CustomFieldWithLabelWrap,
                LocationView,
                SearchBar
              }}
              validationSchema={generateDefaultValidationSchema(poFrameSideFormVendorFields)}
              isLoading={!isLoading}
              loadingParams={loadingParams.leftSection}
            />
            <MuiFormWithLoading
              configuration={poFrameSideFormShippingLayout({
                shipTo: getLabelFromValues(ShippingInformation),
                manualEntry,
                handleChangeShipToSelect
              })}
              data={rbData.sidebarShipping}
              layout={mode}
              onCreateService={getHandleCreateService('sidebarShipping')}
              onComplete={getHandleComplete('sidebarShipping')}
              onFormChange={handleSidebarFormShippingChange}
              customComponents={{
                MuiFormSectionTitle,
                CustomFieldWithLabelNarrow,
                CustomFieldWithLabelWrap,
                PlacesSearch,
                LocationView,
                SearchBar
              }}
              validationSchema={generateDefaultValidationSchema(poFrameSideFormShippingFields)}
              isLoading={!isLoading}
              loadingParams={loadingParams.leftSection}
            />
            {type === 'Receipt' && (
              <div className={classes.imageContainer}>
                <MuiFormSectionTitle options={{ label: 'Images' }} />
                <div className={classes.carouselContainer}>
                  <EntityImagesCarousel
                    images={formatAttachments(rbData.Attachment)}
                    thumbnailSize={ThumbnailSize.SMALL}
                    onSave={handleAddImage}
                    onDelete={handleDeleteImage}
                    onEdit={handleEditImage}
                    parentId={rbData.id}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={classes.content}>
          <div className={classNames(classes.topbar, classes.formContainer)}>
            <MuiFormWithLoading
              configuration={rbFrameTopFormLayout(rbData, departmentsAreLimitedByJob)}
              data={{ ...rbData, dueDate }}
              layout={mode}
              onCreateService={getHandleCreateService('mainTop')}
              onComplete={getHandleComplete('mainTop')}
              onFormChange={handleTopbarFormChange}
              customComponents={{
                MuiFormSectionTitle,
                CustomFieldWithLabelNarrow,
                SearchBar
              }}
              validationSchema={generateDefaultValidationSchema(rbFrameTopFormFields)}
              isLoading={!isLoading}
              loadingParams={loadingParams.mainSection}
            />
          </div>
          {rbData.receiptNumber && (
            <div className={classes.topbar}>
              <SyncStatusChip syncStatus={latestSyncStatus} syncLog={latestSyncLog} />
            </div>
          )}
          <Divider />
          <div className={classes.childrenContainer}>
            <div>{children}</div>
          </div>
        </div>
      </div>
      <GeneratePdfModal
        open={showGeneratePdfModal}
        user={user}
        pdfData={rbData}
        receiptItems={receiptItems}
        companyInfo={companyInfo}
        taxRate={finalTaxRate}
        handleClose={() => setShowGeneratePdfModal(false)}
        handlePostReceipt={handlePostReceipt}
      />
    </div>
  );
});

CustomFieldWithLabelNarrow.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired
};

CustomFieldWithLabelWrap.propTypes = {
  field: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired
};

ReceiptBillDetailFrame.propTypes = {
  id: PropTypes.string.isRequired,
  rbData: PropTypes.object.isRequired,
  receiptItems: PropTypes.array,
  type: PropTypes.string,
  mode: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  getHandleCreateService: PropTypes.func,
  getHandleComplete: PropTypes.func,
  handleSubmitStart: PropTypes.func,
  setOnSubmitFinal: PropTypes.func,
  isSubmitting: PropTypes.bool,
  handleFormsSubmit: PropTypes.func,
  actionButtonHandler: PropTypes.func,
  actionButtons: PropTypes.object,
  frameDataReducer: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  isFileDownloading: PropTypes.bool,
  isPurchaseOrderVoid: PropTypes.bool
};

ReceiptBillDetailFrame.defaultProps = {
  type: 'Receipt',
  receiptItems: [],
  isSubmitting: false,
  isFileDownloading: false,
  getHandleCreateService: () => {},
  getHandleComplete: () => {},
  handleSubmitStart: () => {},
  setOnSubmitFinal: () => {},
  handleFormsSubmit: () => {},
  actionButtonHandler: () => {},
  frameDataReducer: () => {},
  actionButtons: {},
  isPurchaseOrderVoid: false
};

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = { snackbarOn };
const ReduxConnectedReceiptDetailFrame = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReceiptBillDetailFrame);
export default ReduxConnectedReceiptDetailFrame;
