import React, { useEffect, useRef, memo } from 'react';
import { GoogleMap } from 'react-google-maps';
import { arrayOf, string, bool } from 'prop-types';

import { TECHS_RESPONSE_PROP } from '@dispatch/queries';

import { BOARD_VISIT_RESPONSE_PROP } from '../../queries';
import TechsFilterButton from '../TechsFilterButton';

import { useStyles } from './MapView.styles';
import { useMapProperties, useExtendableBounds } from './MapView.hooks';
import PropertyMarker from './components/PropertyMarker';
import CompanyMarker from './components/CompanyMarker';
import TechMarker from './components/TechMarker';

const kansasCity = {
  lat: 39.0997,
  lng: -94.5786
};

export const ContainerElement = props => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {props.children}
      <TechsFilterButton classes={{ root: classes.boardFilter }} />
    </div>
  );
};

const MapView = ({
  boardVisitsResponse,
  techsResponse,
  visitStatuses,
  showVisits,
  showTechs,
  showLocations,
  showUnscheduled,
  companyOverviewResponse,
  unscheduledVisitsResponse
}) => {
  const map = useRef();
  const { extendBounds, onMapLoad } = useExtendableBounds();
  const { position: companyPosition } = companyOverviewResponse.data;

  const mapProperties = useMapProperties({
    boardVisitsResponse,
    unscheduledVisitsResponse,
    techsResponse,
    visitStatuses,
    showVisits
  });

  const setMapBounds = () => {
    const bounds = new google.maps.LatLngBounds();

    if (bounds) {
      if (companyPosition?.lat && companyPosition?.lng) {
        bounds.extend(companyPosition);
      }

      mapProperties.forEach(({ position }) => {
        if (position?.lat && position?.lng) {
          bounds.extend(position);
        }
      });

      map.current.fitBounds?.(bounds);
    }
  };

  useEffect(() => {
    if (mapProperties.length && map.current) {
      setMapBounds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitStatuses, showVisits, showTechs, showLocations, showUnscheduled]);

  const boundsSet = useRef(false);

  useEffect(() => {
    if (mapProperties.length && map.current && !boundsSet.current) {
      setMapBounds();
      boundsSet.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardVisitsResponse.data.length]);

  const updateBounds = (position = {}) => {
    // TODO: re-implement at some point. Causes zoom change on visit mutation
    // if (map.current && position.lat && position.lng) {
    //   extendBounds(position);
    // }
  };

  const handleSetMapRef = ref => {
    map.current = ref;
    onMapLoad(ref);
  };

  return (
    <GoogleMap ref={handleSetMapRef} defaultZoom={5} defaultCenter={kansasCity}>
      {showTechs &&
        techsResponse.data.map(tech => <TechMarker tech={tech} updateBounds={updateBounds} />)}
      {showLocations && (
        <CompanyMarker company={companyOverviewResponse.data} updateBounds={updateBounds} />
      )}
      {mapProperties.map(property => (
        <PropertyMarker property={property} updateBounds={updateBounds} />
      ))}
    </GoogleMap>
  );
};

MapView.propTypes = {
  techsResponse: TECHS_RESPONSE_PROP.isRequired,
  boardVisitsResponse: BOARD_VISIT_RESPONSE_PROP.isRequired,
  visitStatuses: arrayOf(string).isRequired,
  showVisits: bool.isRequired,
  showTechs: bool.isRequired,
  showLocations: bool.isRequired,
  showUnscheduled: bool.isRequired
};

export default memo(MapView);
