import { searchIndex as defaultSearchIndex } from 'constants/algoliaIndex';
import { Timezone } from 'scenes/Procurement/constants';
import { generateDefaultFieldsObject } from 'scenes/ProjectManagement/components/formattingUtils';
import { departmentSearch, getDepartmentsByJob } from 'services/API/department';
import { employeeSearch } from 'services/API/employee';
import { purchaseOrderTypeSearch } from 'services/API/purchaseOrderType';

const defaultFlexStyle = '0 0 219px';
const margin = '0px 16px 0px 0px';
const marginEdit = '0px 16px 16px 0px';

const poFrameTopFormFields = {
  department: { required: false },
  jobAndProject: { required: false },
  assignedTo: { required: false },
  poType: { required: true },
  requiredByDate: { required: false, nullable: true },
  dateOfPurchase: { required: false },
  description: { required: false, maxCharLimit: 2000 }
};

const poFrameTopFormDefaultContents = hideJobProjectField => {
  return [
    // the section title
    {
      options: {
        size: 1,
        direction: 'row'
      },
      contents: [
        {
          options: {
            margin: '0px 0px 10px 0px'
          },
          contents: [
            {
              component: {
                default: 'MuiFormSectionTitle'
              },
              options: {
                label: 'Purchase Order Information'
              }
            }
          ]
        }
      ]
    },
    {
      options: {
        direction: 'row',
        marginBottom: '16px'
      },
      contents: [
        hideJobProjectField
          ? { options: { display: 'none' }, contents: [] }
          : {
              options: {
                direction: 'column',
                minWidth: '97px',
                margin
              },
              contents: [
                {
                  component: {
                    default: 'CustomFieldWithLabelNarrow'
                  },
                  source: 'jobOrProjectText',
                  options: {
                    label: 'Job / Project',
                    placeholder: '',
                    fullWidth: true,
                    alignment: 'left',
                    variant: 'standard',
                    color: 'secondary',
                    isRequired: false,
                    linkPath: '',
                    linkReference: 'jobOrProjectLink'
                  }
                }
              ]
            },

        {
          options: {
            direction: 'column',
            minWidth: '80px',
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelNarrow'
              },
              source: 'poType',
              options: {
                label: 'PO Type',
                placeholder: '',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            minWidth: '106px',
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelNarrow'
              },
              source: 'dateOfPurchase',
              options: {
                isUtcDate: true,
                label: 'Date of Purchase',
                placeholder: '',
                fullWidth: false,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: true
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            minWidth: '106px',
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelNarrow'
              },
              source: 'requiredByDate',
              options: {
                isUtcDate: true,
                label: 'Required By',
                placeholder: '',
                fullWidth: false,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            minWidth: '91px',
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelNarrow'
              },
              source: 'department',
              options: {
                label: 'department',
                placeholder: '',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            minWidth: '120px',
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelNarrow'
              },
              source: 'assignedTo',
              options: {
                label: 'Assigned To',
                placeholder: '',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false
              }
            }
          ]
        }
      ]
    },
    // the 2nd row
    {
      options: {
        direction: 'row'
      },
      contents: [
        {
          options: {
            direction: 'column',
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelWrap',
                edit: 'TextInput'
              },
              source: 'description',
              options: {
                label: 'Description',
                placeholder: '',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary'
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            margin
          },
          contents: [
            {
              component: {
                default: 'AssociatedQuotesComponent'
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            margin
          },
          contents: [
            {
              component: {
                default: 'purchaseOrderNote'
              },
              source: 'note',
              options: {
                label: 'Office Notes',
                lines: 4
              }
            }
          ]
        }
      ]
    }
  ];
};

const poFrameTopFormEditContents = (
  depId,
  handleJobIdChange,
  canEdit,
  isVistaEnabled,
  departmentsAreLimitedByJob,
  handleSetDefaultValue,
  jobId,
  hideJobProjectField
) => {
  return [
    // the section title
    {
      options: {
        size: 1,
        direction: 'row'
      },
      contents: [
        {
          options: {
            margin: '0px 0px 10px 0px'
          },
          contents: [
            {
              component: {
                edit: 'MuiFormSectionTitle'
              },
              options: {
                label: 'Purchase Order Information'
              }
            }
          ]
        }
      ]
    },

    // the 1st row
    {
      options: {
        direction: 'row',
        grow: 0,
        flexWrap: 'nowrap'
      },
      contents: [
        hideJobProjectField
          ? { options: { display: 'none' }, contents: [] }
          : {
              options: {
                direction: 'column',
                flex: defaultFlexStyle,
                margin
              },
              contents: [
                {
                  component: {
                    edit: 'AlgoliaSearch'
                  },
                  source: 'jobAndProject',
                  options: {
                    searchIndexName: defaultSearchIndex,
                    searchResultDisplayFields: ['jobNumber', 'projectName', 'customerName'],
                    valueField: 'id',
                    delimiter: ' - ',
                    label: 'Job / Project',
                    placeholder: 'Search and Select',
                    fullWidth: true,
                    alignment: 'left',
                    variant: 'standard',
                    color: 'secondary',
                    filters: [
                      {
                        attribute: 'entityType',
                        valueArray: ['Job', 'Project']
                      }
                    ],
                    onChange: handleJobIdChange,
                    select: true,
                    disabled: isVistaEnabled
                  }
                }
              ]
            },
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'SearchBar'
              },
              source: 'poType',
              options: {
                resultFormatFunction: type => type?.name || null,
                searchFunction: purchaseOrderTypeSearch,
                searchColumns: ['name'],
                label: 'PO Type',
                placeholder: 'Select PO Type',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: true,
                disabled: !canEdit
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'DateInput'
              },
              source: 'dateOfPurchase',
              options: {
                label: 'Date of Purchase',
                placeholder: '',
                fullWidth: false,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                disabled: !canEdit,
                timezone: Timezone.GMT
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'DateInput'
              },
              source: 'requiredByDate',
              options: {
                label: 'Required By',
                placeholder: '',
                fullWidth: false,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                disabled: !canEdit,
                timezone: Timezone.GMT
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'SearchBar'
              },
              source: 'department',
              options: {
                resultFormatFunction: department => department?.tagName || null,
                searchFunction:
                  departmentsAreLimitedByJob && jobId
                    ? () => getDepartmentsByJob(jobId)
                    : departmentSearch,
                searchColumns: ['tagName'],
                label: 'Department',
                placeholder: 'Select Department',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false,
                disabled: !canEdit,
                resetDependentDropdownsOnSelection: departmentsAreLimitedByJob,
                searchOnOpen: departmentsAreLimitedByJob,
                needRefresh: departmentsAreLimitedByJob,
                setDefaultValue: ({ searchResults, selection, isOpen, setSelection, form }) =>
                  handleSetDefaultValue({
                    searchResults,
                    selection,
                    isOpen,
                    setSelection,
                    form,
                    departmentsAreLimitedByJob
                  })
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'SearchBar'
              },
              source: 'assignedTo',
              options: {
                resultFormatFunction: employee => employee?.name || null,
                searchFunction: employeeSearch,
                searchColumns: ['name'],
                searchParams: [depId],
                label: 'Assign To',
                placeholder: 'Select Assignee',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false,
                disabled: !canEdit
              }
            }
          ]
        }
      ]
    },
    // the 3rd row
    {
      options: {
        direction: 'row',
        grow: 0,
        flexWrap: 'nowrap'
      },
      contents: [
        {
          options: {
            direction: 'column',
            margin: marginEdit
          },
          contents: [
            {
              component: {
                edit: 'TextInput'
              },
              source: 'description',
              options: {
                label: 'Description',
                placeholder: '',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                disabled: !canEdit,
                lines: 3
              }
            }
          ]
        }
      ]
    }
  ];
};

const poFrameTopFormLayout = ({
  depId,
  handleJobIdChange,
  canEdit,
  isVistaEnabled,
  departmentsAreLimitedByJob,
  handleSetDefaultValue,
  jobId,
  hideJobProjectField
}) => {
  return {
    fields: generateDefaultFieldsObject(poFrameTopFormFields),
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: poFrameTopFormDefaultContents(hideJobProjectField)
      },
      edit: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: poFrameTopFormEditContents(
          depId,
          handleJobIdChange,
          canEdit,
          isVistaEnabled,
          departmentsAreLimitedByJob,
          handleSetDefaultValue,
          jobId,
          hideJobProjectField
        )
      }
    }
  };
};

export { poFrameTopFormFields, poFrameTopFormLayout };
