import { useMemo, useRef } from 'react';

import CompanyPin from '@dispatch/assets/images/pin.black.svg';

export const useMarkerIcon = () => {
  const { Size, Point, MarkerImage } = google.maps;
  const anchor = useRef(new Point(0, 0));
  const origin = useRef(new Point(0, 0));
  const size = useRef(new Size(0, 0));
  const mapPin = CompanyPin;

  return useMemo(() => {
    return new MarkerImage(
      mapPin,
      null /* size is determined at runtime */,
      origin.current,
      anchor.current,
      size.current
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
