import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  DateInput,
  Divider,
  Field,
  FieldType,
  Select,
  Button as SergeantButton,
  ThemeProvider,
  TV,
  TW,
  Typography
} from '@buildhero/sergeant';
import jsx from '@emotion/react';
import { Button, ButtonGroup, useTheme } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import { findLastIndex, isEmpty, noop, take, takeRight } from 'lodash';
import moment from 'moment-timezone';
import * as R from 'ramda';

import { ConfirmLeave, SergeantModal, Spinner } from 'components';
import Placeholder from 'components/Placeholder';
import { secondsToHour } from 'scenes/Payroll/TimeTrackingReport/helpers';
import { AppConstants, TimeCardStatusTypes } from 'utils/AppConstants';

import { statusTabs, timesheetViews } from '../constants';
import {
  usePrevNextTimesheetPeriod,
  useTimesheetPeriod,
  useUpdateTimesheetEntryBinders,
  useUpdateTimesheetEntryHours
} from '../customHooks';

import { updateTimesheetUrl } from '../services';

import { DayCard } from './components/DayCard';
import { generateWeekDataFromBinder } from './helpers';
import requestReasonMetaLayout from './RequestReasonMeta';

const ButtonDirections = {
  PREVIOUS: { value: 'PREV', label: 'previous' },
  NEXT: { value: 'NEXT', label: 'next' }
};

const useStyles = theme => ({
  outerContainer: {
    minHeight: 500
  },
  container: {
    display: 'flex'
  },
  visitsSection: {
    marginTop: 36
  },
  fixedSection: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100vw',
    backgroundColor: theme.palette.grayscale(95),
    borderTop: 'solid 1px',
    borderColor: theme.palette.other.dimGray,
    padding: 24
  },
  totalsSection: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  totalsHourTypes: {
    display: 'flex',
    marginLeft: 24
  },
  approvalButtons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  approvalButtonsTopline: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '25%'
  },
  reopenButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  controlContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: -70
  },
  innerControlContainer: {
    width: 500
  }
});

const formatTimesheetPeriod = (period, timezone) => {
  if (!period || !timezone) return {};

  const start = moment.tz(moment.unix(period.dateStartUTC).format(), timezone);
  const end = moment.tz(moment.unix(period.dateEndUTC).format(), timezone);

  return {
    label: `${start.format(AppConstants.DATE_FORMAT)} - ${end.format(AppConstants.DATE_FORMAT)}`,
    value: period
  };
};

const createAuditLogs = R.pipe(
  R.reduce((acc, cur) => [...acc, ...R.flatten(cur.auditLogs)], []),
  R.sort((a, b) => +b.executedDateTime - +a.executedDateTime)
);

const ToReview = ({
  user,
  selectedEmployee,
  payrollHourTypes,
  timesheetPeriods = [],
  payrollSetting,
  snackbarOn,
  tab = statusTabs.TO_REVIEW, // or statusTabs.APPROVED
  setExportSelectedDate,
  unsubmittedEvents,
  onUpdateDayCard = noop,
  setIsExportDisabled = noop,
  setDirty = noop,
  selectedDate = null,
  setSelectedDate = noop,
  selectedPeriod,
  setSelectedPeriod = noop,
  dayOrWeek = timesheetViews.DAY,
  setDayOrWeek = noop
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const [timesheetEntriesToUpdate, setTimesheetEntriesToUpdate] = useState([]);
  const [auditLogAdditions, setAuditLogAdditions] = useState({});
  const [isRequestReasonLoading, setIsRequestReasonLoading] = useState('');
  const [direction, setDirection] = useState('');
  const [disablePrev, setDisablePrev] = useState(true);
  const [disableNext, setDisableNext] = useState(true);
  const [isRequestReasonModalOpen, setIsRequestReasonModalOpen] = useState(false);
  const [requestReasonDayIndex, setRequestReasonDayIndex] = useState();

  const [period, isLoading, error, refetch] = useTimesheetPeriod({ id: selectedPeriod?.id });

  const availableStatuses =
    tab === statusTabs.TO_REVIEW
      ? [TimeCardStatusTypes.PENDING, TimeCardStatusTypes.SUBMITTED]
      : [TimeCardStatusTypes.APPROVED];

  const weekDates = useMemo(() => {
    if (isEmpty(period)) return [];

    let {
      timesheetEntryBinders: { items: timesheetEntryBinders }
    } = period;

    // add audit log so we don't have to refetch
    if (!R.isEmpty(auditLogAdditions)) {
      timesheetEntryBinders = timesheetEntryBinders.map(binder => ({
        ...binder,
        timesheetEntries: {
          items: binder.timesheetEntries.items.map(entry => ({
            ...entry,
            auditLogs: {
              items: [...entry.auditLogs.items, ...(auditLogAdditions[entry.id] ?? [])]
            }
          }))
        }
      }));
    }

    return generateWeekDataFromBinder(
      timesheetEntryBinders,
      period,
      payrollSetting.timeZone,
      payrollHourTypes,
      availableStatuses,
      timesheetEntriesToUpdate
    );
  }, [
    period,
    payrollSetting.timeZone,
    payrollHourTypes,
    availableStatuses,
    timesheetEntriesToUpdate,
    auditLogAdditions
  ]);

  const weekDate = useMemo(
    () =>
      weekDates.find(
        ({ dayStartUTC, dayEndUTC }) => dayStartUTC <= selectedDate && selectedDate <= dayEndUTC
      ) || {},
    [selectedDate, weekDates]
  );

  const timesheetPeriodOptions = useMemo(
    () => timesheetPeriods.map(p => formatTimesheetPeriod(p, payrollSetting.timeZone)),
    [timesheetPeriods, payrollSetting.timeZone]
  );

  const [updateTimesheetEntryHours, { loading: updateLoading }] = useUpdateTimesheetEntryHours();
  const [updateTimesheetEntryBinders] = useUpdateTimesheetEntryBinders();

  const handleDateSelect = async date => {
    setSelectedDate(date);
    setDisablePrev(false);
    setDisableNext(false);
    if (!isEmpty(weekDate)) {
      setExportSelectedDate({
        dateStartUTC: weekDate.dayStartUTC,
        dateEndUTC: weekDate.dayEndUTC
      });
      setIsExportDisabled(false);
    }
  };

  const handlePeriodSelection = newPeriod => {
    setSelectedPeriod(newPeriod);
    setExportSelectedDate({
      dateStartUTC: newPeriod.dateStartUTC,
      dateEndUTC: newPeriod.dateEndUTC
    });
    setIsExportDisabled(false);
  };

  const [getPrevNextTimesheetPeriod, { loading: prevNextLoading }] = usePrevNextTimesheetPeriod({
    onCompleted: result => {
      const nextPeriod = result?.prevNextTimesheetPeriod;
      if (!nextPeriod) {
        snackbarOn('error', `No ${direction.label} timesheets for review`);
        if (direction === ButtonDirections.PREVIOUS) {
          setDisablePrev(true);
        } else {
          setDisableNext(true);
        }
        return;
      }
      setSelectedPeriod(nextPeriod);
      handleDateSelect(nextPeriod.dateStartUTC);
    }
  });

  const reset = (nextMode = null) => {
    if (nextMode === timesheetViews.DAY || dayOrWeek === timesheetViews.DAY) {
      handleDateSelect(selectedDate);
    }

    if (nextMode === timesheetViews.WEEK || dayOrWeek === timesheetViews.WEEK) {
      handlePeriodSelection(selectedPeriod);
    }

    setTimesheetEntriesToUpdate([]);
  };

  useEffect(() => {
    if (dayOrWeek === timesheetViews.WEEK) setIsExportDisabled(true);
    reset();
  }, [timesheetPeriods]);

  useEffect(() => {
    setDirty(timesheetEntriesToUpdate.length > 0);
  }, [timesheetEntriesToUpdate.length]);

  const updateTimesheetEntries = () => {
    const data = updateTimesheetEntryHours({
      employee: selectedEmployee,
      timesheetEntries: timesheetEntriesToUpdate
    });

    if (data && !updateLoading) {
      setAuditLogAdditions(
        timesheetEntriesToUpdate.reduce((acc, cur) => {
          const { extra, id, ...propsToMutate } = cur;
          return {
            ...acc,
            [cur.id]: [
              ...(auditLogAdditions[id] ?? []),
              {
                executedDateTime: new Date().getTime(),
                executedBy: user?.displayName,
                // hourType: e.extra.hourType,
                executionType: 'Edited',
                // entry: { workEvent: e.extra.workEvent },
                changeLog: JSON.stringify(
                  Object.keys(propsToMutate).map(key => ({
                    field: key,
                    new: propsToMutate[key]
                  }))
                )
              }
            ]
          };
        }, auditLogAdditions)
      );
      setTimesheetEntriesToUpdate([]);
    }
  };

  const queueTimesheetEntriesToUpdate = entry => {
    const unsavedTimesheetEntryIndex = timesheetEntriesToUpdate.findIndex(b => b.id === entry.id);
    if (unsavedTimesheetEntryIndex === -1) {
      setTimesheetEntriesToUpdate([...timesheetEntriesToUpdate, { ...entry }]);
    } else {
      const newArr = [...timesheetEntriesToUpdate];
      newArr[unsavedTimesheetEntryIndex] = { ...newArr[unsavedTimesheetEntryIndex], ...entry };
      setTimesheetEntriesToUpdate(newArr);
    }
  };

  const isSaveEditsDisabled = isLoading === 'saveEdits' || timesheetEntriesToUpdate.length === 0;

  const handleApprove = async (i = '') => {
    if (!isSaveEditsDisabled) return snackbarOn('error', 'Please save your edits before approving');

    const updateData = dayOrWeek === timesheetViews.DAY ? weekDate : weekDates[i];
    updateTimesheetEntryBinders({
      employee: selectedEmployee,
      timesheetEntryBinders: updateData.bindersOnThisDay.map(({ id, timesheetEntries }) => ({
        id,
        manualApprovedBy: user?.displayName,
        manualApprovedDate: moment().unix(),
        manualStatus: TimeCardStatusTypes.APPROVED,
        timesheetEntries: timesheetEntries?.items?.map(entry => ({
          id: entry.id,
          manualApprovedBy: user?.displayName,
          manualApprovedDate: moment().unix(),
          manualStatus: TimeCardStatusTypes.APPROVED
        }))
      })),
      snackbarOn
    });
    onUpdateDayCard(selectedEmployee);
  };

  const handleRequestRevisionOrReopen = async ({ reason }) => {
    setIsRequestReasonModalOpen(false);
    setIsRequestReasonLoading(
      tab === statusTabs.TO_REVIEW
        ? `requestReason${requestReasonDayIndex ?? ''}`
        : `reopen${requestReasonDayIndex ?? ''}`
    );
    const updateData =
      dayOrWeek === timesheetViews.DAY ? weekDate : weekDates[requestReasonDayIndex];
    updateTimesheetEntryBinders({
      employee: selectedEmployee,
      timesheetEntryBinders: updateData.bindersOnThisDay.map(({ id, timesheetEntries }) => ({
        id,
        manualReopenReason: reason,
        manualStatus:
          tab === statusTabs.TO_REVIEW
            ? TimeCardStatusTypes.DISPUTED
            : TimeCardStatusTypes.SUBMITTED,
        timesheetEntries: timesheetEntries?.items?.map(entry => ({
          id: entry.id,
          manualReopenReason: reason,
          manualStatus:
            tab === statusTabs.TO_REVIEW
              ? TimeCardStatusTypes.DISPUTED
              : TimeCardStatusTypes.SUBMITTED
        }))
      })),
      snackbarOn
    });
    onUpdateDayCard(selectedEmployee);
  };

  const switchPeriod = dir => {
    getPrevNextTimesheetPeriod({
      employeeId: selectedEmployee?.id,
      statuses: availableStatuses,
      date: selectedDate,
      dir: dir.value
    });
  };

  const handleClickPrev = () => {
    setDirection(ButtonDirections.PREVIOUS);
    if (!selectedPeriod?.id) {
      switchPeriod(ButtonDirections.PREVIOUS);
    } else {
      const weekDateIndex = weekDates.findIndex(
        ({ dayStartUTC, dayEndUTC }) => dayStartUTC <= selectedDate && selectedDate <= dayEndUTC
      );
      const prevDates = take(weekDates, weekDateIndex);
      const i = findLastIndex(prevDates, d => d.workEvents.length > 0);
      const prevDate = selectedDate - (prevDates.length - i) * 86400; // 86400 seconds in a day

      if (i !== -1) {
        handleDateSelect(prevDate);
      } else {
        switchPeriod(ButtonDirections.PREVIOUS);
      }
    }
  };

  const handleClickNext = () => {
    setDirection(ButtonDirections.NEXT);
    if (!selectedPeriod?.id) {
      switchPeriod(ButtonDirections.NEXT);
    } else {
      const weekDateIndex = weekDates.findIndex(
        ({ dayStartUTC, dayEndUTC }) => dayStartUTC <= selectedDate && selectedDate <= dayEndUTC
      );
      const nextDates = takeRight(weekDates, weekDates.length - weekDateIndex - 1);
      const i = nextDates.findIndex(d => d.workEvents.length > 0);
      const nextDate = selectedDate + (i + 1) * 86400; // 86400 seconds in a day

      if (i !== -1) {
        handleDateSelect(nextDate);
      } else {
        switchPeriod(ButtonDirections.NEXT);
      }
    }
  };

  const noTimesheetsMessage = () => {
    switch (tab) {
      case statusTabs.APPROVED: {
        return 'No approved timesheets';
      }
      default: {
        return 'No timesheets submitted for review';
      }
    }
  };

  if (isLoading) return <Placeholder variant="table" />;

  return (
    <div css={styles.outerContainer}>
      <ConfirmLeave when={timesheetEntriesToUpdate.length !== 0} />
      <div css={styles.controlContainer}>
        <ButtonGroup>
          <Button
            variant={dayOrWeek === timesheetViews.DAY && 'contained'}
            color={dayOrWeek === timesheetViews.DAY && 'primary'}
            onClick={() => {
              if (dayOrWeek === timesheetViews.DAY) return;
              setDayOrWeek(timesheetViews.DAY);
              reset(timesheetViews.DAY);
            }}
          >
            DAY
          </Button>
          <Button
            variant={dayOrWeek === timesheetViews.WEEK && 'contained'}
            color={dayOrWeek === timesheetViews.WEEK && 'primary'}
            onClick={() => {
              if (dayOrWeek === timesheetViews.WEEK) return;
              setIsExportDisabled(true);
              setDayOrWeek(timesheetViews.WEEK);
              reset(timesheetViews.WEEK);
            }}
          >
            WEEK
          </Button>
        </ButtonGroup>
        <div css={styles.innerControlContainer}>
          <ThemeProvider>
            {dayOrWeek === timesheetViews.DAY && (
              <>
                <DateInput
                  value={selectedDate}
                  style={{ marginLeft: 24, height: 36, width: 250 }}
                  onChange={date => {
                    handleDateSelect(date);
                    updateTimesheetUrl({ employee: selectedEmployee, date });
                  }}
                  timezone={payrollSetting.timeZone}
                />
                <SergeantButton
                  type="tertiary"
                  style={{ height: 36, margin: '0 24px', padding: '5px 15px' }}
                  loading={prevNextLoading && direction === ButtonDirections.PREVIOUS}
                  disabled={disablePrev}
                  onClick={handleClickPrev}
                >
                  Previous
                </SergeantButton>
                <SergeantButton
                  type="tertiary"
                  style={{ height: 36, padding: '5px 15px' }}
                  loading={prevNextLoading && direction === ButtonDirections.NEXT}
                  disabled={disableNext}
                  onClick={handleClickNext}
                >
                  Next
                </SergeantButton>
              </>
            )}
            {dayOrWeek === timesheetViews.WEEK && (
              <Select
                menuHeight={300}
                style={{ marginLeft: 24, height: 36, width: 250 }}
                options={timesheetPeriodOptions}
                onChange={({ value }) => handlePeriodSelection(value)}
                defaultValue={timesheetPeriodOptions.find(
                  option => selectedPeriod?.id === option.value.id
                )}
              />
            )}
          </ThemeProvider>
        </div>
      </div>
      {isLoading === 'init' ? (
        <Spinner />
      ) : (
        <>
          <ThemeProvider>
            {dayOrWeek === timesheetViews.DAY && (
              <div css={styles.visitsSection}>
                {!isEmpty(weekDate) && weekDate.workEvents.length !== 0 ? (
                  <DayCard
                    isEditable={tab === statusTabs.TO_REVIEW}
                    day={weekDate}
                    payrollHourTypes={payrollHourTypes}
                    updateTimesheetEntry={queueTimesheetEntriesToUpdate}
                    payrollSetting={payrollSetting}
                    tenantId={selectedEmployee.tenantId}
                    refetchTimesheetPeriod={refetch}
                    employeeId={selectedEmployee.id}
                    newAuditLogs={createAuditLogs(weekDate.workEvents)}
                  />
                ) : (
                  <>{selectedDate && <Typography>{noTimesheetsMessage()}</Typography>}</>
                )}
              </div>
            )}
            {dayOrWeek === timesheetViews.WEEK && (
              <div css={styles.visitsSection}>
                {weekDates.map((day, i) => {
                  return (
                    <>
                      <DayCard
                        isEditable={tab === statusTabs.TO_REVIEW}
                        key={day.dayStartUTC}
                        day={day}
                        payrollHourTypes={payrollHourTypes}
                        updateTimesheetEntry={queueTimesheetEntriesToUpdate}
                        payrollSetting={payrollSetting}
                        tenantId={selectedEmployee.tenantId}
                        refetchTimesheetPeriod={refetch}
                        employeeId={selectedEmployee.id}
                        newAuditLogs={createAuditLogs(day.workEvents)}
                      >
                        {tab === statusTabs.TO_REVIEW && (
                          <div css={styles.approvalButtonsTopline}>
                            <SergeantButton
                              type="tertiary"
                              onClick={() => handleApprove(i)}
                              loading={isLoading === `approve${i}`}
                              disabled={false} // TODO: add disable logic
                            >
                              Approve
                            </SergeantButton>
                            <SergeantButton
                              style={{ marginLeft: 24 }}
                              type="tertiary"
                              onClick={() => {
                                setRequestReasonDayIndex(i);
                                setIsRequestReasonModalOpen(true);
                              }}
                              loading={
                                !isRequestReasonModalOpen &&
                                isRequestReasonLoading === `requestReason${i}`
                              }
                              disabled={false} // TODO: add disable logic
                            >
                              Request Revision
                            </SergeantButton>
                          </div>
                        )}
                        {tab === statusTabs.APPROVED && (
                          <div css={styles.reopenButton}>
                            <SergeantButton
                              type="tertiary"
                              onClick={() => {
                                setRequestReasonDayIndex(i);
                                setIsRequestReasonModalOpen(true);
                              }}
                              loading={
                                !isRequestReasonModalOpen && isRequestReasonLoading === `reopen${i}`
                              }
                            >
                              Reopen
                            </SergeantButton>
                          </div>
                        )}
                      </DayCard>
                      {day.workEvents.length !== 0 &&
                        i !== findLastIndex(weekDates, w => w.workEvents.length !== 0) && (
                          <Divider />
                        )}
                    </>
                  );
                })}
                {selectedPeriod.id && weekDates.every(w => w.workEvents.length === 0) && (
                  <Typography>{noTimesheetsMessage()}</Typography>
                )}
              </div>
            )}
            {((!isEmpty(weekDate) && dayOrWeek === timesheetViews.DAY) ||
              (dayOrWeek === timesheetViews.WEEK &&
                weekDates?.some(w => w.workEvents.length > 0))) && (
              <div css={styles.fixedSection}>
                <div css={styles.totalsSection}>
                  <Typography variant={TV.L} weight={TW.BOLD} style={{ marginTop: 10 }}>
                    {dayOrWeek === timesheetViews.WEEK ? 'Weekly' : 'Daily'} Totals
                  </Typography>
                  <div css={styles.totalsHourTypes}>
                    {payrollHourTypes.map(({ hourTypeAbbreviation, id }, i) => {
                      let validHourTypes = [];
                      if (dayOrWeek === timesheetViews.DAY) {
                        validHourTypes = weekDate.validHourTypes;
                      }
                      if (dayOrWeek === timesheetViews.WEEK) {
                        const unsortedHourTypesToShow = weekDates.reduce(
                          (hourTypes, date) =>
                            date.workEvents.length > 0
                              ? [...hourTypes, ...date.validHourTypes]
                              : hourTypes,
                          []
                        );
                        validHourTypes = payrollHourTypes.reduce(
                          (hourTypes, type) =>
                            unsortedHourTypesToShow.includes(type.hourTypeAbbreviation)
                              ? [...hourTypes, type.hourTypeAbbreviation]
                              : hourTypes,
                          []
                        );
                      }
                      return (
                        validHourTypes.includes(hourTypeAbbreviation) && (
                          <Field
                            key={id}
                            type={FieldType.NUMBER}
                            label={hourTypeAbbreviation}
                            style={{ width: 50, marginLeft: i === 0 ? 0 : 24 }}
                            innerStyle={{ fontWeight: 700 }}
                            value={secondsToHour(
                              dayOrWeek === timesheetViews.DAY
                                ? weekDate.dailyTotals[hourTypeAbbreviation] || 0
                                : weekDates.reduce((acc, day) => {
                                    const { dailyTotals } = day;
                                    return acc + dailyTotals[hourTypeAbbreviation];
                                  }, 0)
                            )}
                          />
                        )
                      );
                    })}
                  </div>
                  {tab === statusTabs.TO_REVIEW && (
                    <div css={styles.approvalButtons}>
                      <SergeantButton
                        type="secondary"
                        onClick={updateTimesheetEntries}
                        disabled={isSaveEditsDisabled}
                        loading={isLoading === 'saveEdits'}
                      >
                        Save Edits
                      </SergeantButton>
                      {dayOrWeek === timesheetViews.DAY && (
                        <>
                          <SergeantButton
                            type="tertiary"
                            style={{ marginLeft: 24 }}
                            onClick={() => handleApprove()}
                            loading={isLoading === 'approve'}
                            disabled={false} // TODO: add disable logic
                          >
                            Approve
                          </SergeantButton>
                          <SergeantButton
                            type="tertiary"
                            style={{ marginLeft: 24 }}
                            onClick={() => {
                              setRequestReasonDayIndex();
                              setIsRequestReasonModalOpen(true);
                            }}
                            loading={
                              !isRequestReasonModalOpen &&
                              isRequestReasonLoading === 'requestReason'
                            }
                            disabled={false} // TODO: add disable logic
                          >
                            Request Revision
                          </SergeantButton>
                        </>
                      )}
                    </div>
                  )}
                  {tab === statusTabs.APPROVED && (
                    <div css={styles.reopenButton}>
                      {dayOrWeek === timesheetViews.DAY && (
                        <SergeantButton
                          type="tertiary"
                          onClick={() => {
                            setRequestReasonDayIndex();
                            setIsRequestReasonModalOpen(true);
                          }}
                          loading={!isRequestReasonModalOpen && isRequestReasonLoading === 'reopen'}
                        >
                          Reopen
                        </SergeantButton>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            <div
              style={{
                height: tab === statusTabs.APPROVED && dayOrWeek === timesheetViews.WEEK ? 131 : 171
              }}
            />
          </ThemeProvider>

          <SergeantModal
            open={isRequestReasonModalOpen}
            title={tab === statusTabs.TO_REVIEW ? 'Request Revision' : 'Reopen Timesheet'}
            layout={requestReasonMetaLayout}
            handleClose={() => setIsRequestReasonModalOpen(false)}
            handlePrimaryAction={handleRequestRevisionOrReopen}
          />
        </>
      )}
    </div>
  );
};

export default ToReview;
