import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  ButtonType,
  MoreButton,
  MUIForm,
  ThemeProvider,
  TV,
  Typography
} from '@buildhero/sergeant';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { makeStyles, withTheme } from '@material-ui/core/styles';

import { NotInterested } from '@material-ui/icons';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { withScriptjs } from 'react-google-maps';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { NotesV2, TagButtons } from 'components';
import AlgoliaSearch from 'components/BuildHeroFormComponents/AlgoliaSearchWrapper';
import PlacesSearch from 'components/BuildHeroFormComponents/PlacesSearch';
import DefaultButton from 'components/Buttons/DefaultButton';
import ManulStatusChangeModal from 'components/ManualStatusChangeModal/index';
import PageHeader from 'components/PageHeader';
import StatusChip from 'components/StatusChip';
import withLoading from 'components/WithLoading';
import { useConfirmModal } from 'customHooks/ConfirmModalContext';
import Labels from 'meta/labels';
import PageMap from 'meta/PageMap';
import { purchaseOrderStatusChips } from 'meta/Procurement/PurchaseOrders/frameStatusChips';
import {
  poFrameSideFormShippingFields,
  poFrameSideFormShippingLayout
} from 'meta/Procurement/PurchaseOrders/poFrameSideFormShipping';
import {
  poFrameSideFormVendorFields,
  poFrameSideFormVendorLayout
} from 'meta/Procurement/PurchaseOrders/poFrameSideFormVendor';
import {
  poFrameTopFormFields,
  poFrameTopFormLayout
} from 'meta/Procurement/PurchaseOrders/poFrameTopForm';
import { snackbarOn } from 'redux/actions/globalActions';

import { getProcurementStatus } from 'scenes/JobCloseout/utils';
import {
  getAddressByType,
  getAddressObj,
  getProjectAddressById
} from 'scenes/Procurement/component/utils';
import { loadingParams, ShippingInformation, ShipToNameTypes } from 'scenes/Procurement/constants';
import ProcurementUtils from 'scenes/Procurement/Procurement.utils';
import GeneratePdfModal from 'scenes/Procurement/PurchaseOrders/CreatePurchaseOrder/components/GeneratePdfModal';
import SearchBar from 'scenes/ProjectManagement/components/APISearchComponents/SearchBar';
import buildHeroMuiFormOverrides from 'scenes/ProjectManagement/components/buildHeroMuiFormOverrides';
import CustomFieldWithLabel from 'scenes/ProjectManagement/components/CustomFieldWithLabel';
import { generateDefaultValidationSchema } from 'scenes/ProjectManagement/components/formattingUtils';
import { getLabelFromValues } from 'scenes/ProjectManagement/components/utils';
import getCompanyAddressByParentId from 'services/API/companyAddress';
import { getJobById } from 'services/API/job';
import { getNotesByPurchaseOrderId } from 'services/API/note';
import { getPurchaseOrderById, purchaseOrderChange } from 'services/API/purchaseOrder';
import { getPurchaseOrderTags } from 'services/API/purchaseOrderTag';
import { JobService } from 'services/core';
import theme from 'themes/BuildHeroTheme';
import { getCombinedAddress } from 'utils';
import {
  ProcurementPurchaseOrderStatus,
  QuoteConstants,
  QuoteStatus,
  TagType
} from 'utils/AppConstants';
import { EnumType, PurchaseOrderStatus } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import PurchaseOrdersUtils from '../../PurchaseOrders/index.utils';
import LocationView from '../LocationView';
import MuiFormSectionTitle from '../MuiFormSectionTitle';

const CustomFieldWithLabelNarrow = ({ form, field, options }) => {
  return (
    <CustomFieldWithLabel
      form={form}
      field={field}
      options={options}
      style={{ lineHeight: '16px' }}
    />
  );
};

const CustomFieldWithLabelWrap = ({ field, options }) => {
  return (
    <CustomFieldWithLabel
      field={field}
      options={options}
      style={{ lineHeight: '20px', wordBreak: 'break-word' }}
      nowrap={false}
    />
  );
};

// handling it this way as changing JobAndProject may have downstream impact
const CustomAlgoliaSearch = ({ field, ...rest }) => {
  const modifiedField = {
    ...field,
    value:
      field.value?.customIdentifier ||
      field.value?.jobNumber ||
      field.value?.projectName ||
      field.value?.projectNumber ||
      field.value?.name ||
      ''
  };
  return <AlgoliaSearch field={modifiedField} {...rest} />;
};

const voidConfirmContent = {
  body:
    'Are you sure you want to void this purchase order? No further actions can be taken once void.',
  title: 'Void Purchase Order',
  buttonLabel: 'Void Purchase Order',
  buttonType: ButtonType.ERROR
};

const changeJobOrProjectConfirmContent = {
  body:
    'Are you sure you want to change Job / Project on the Purchase Order?\n \nOnce changed, the Job / Project fields for all PO line items will be updated.\n \nIf a different project is selected, then Phase, Cost Code, and Cost Type fields for all PO line items will be emptied and needs to be re-selected.',
  title: 'Job / Project Change on Purchase Order',
  buttonLabel: 'Confirm Job / Project Change on Purchase Order',
  buttonType: ButtonType.ERROR
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    color: '#333333'
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderTop: `1px solid ${theme.palette.grayscale(90)}`
  },
  sidebar: {
    borderRight: `1px solid ${theme.palette.grayscale(90)}`,
    width: 218,
    minWidth: 218
  },
  sidebarInner: {
    margin: '24px 16px 24px 0px',
    width: 202
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    overflow: 'auto'
  },
  topbar: {
    padding: '24px 16px'
  },
  childrenContainer: {
    padding: '24px 16px'
  },
  formContainer: {
    ...buildHeroMuiFormOverrides(theme),
    '& .MuiSvgIcon-root': {
      color: 'inherit',
      fontSize: 'inherit'
    },
    '& fieldset .MuiFormControlLabel-label': {
      fontSize: 12
    }
  }
}));

const MuiFormWithLoading = withLoading(MUIForm);

const PurchaseOrderFrame = withScriptjs(({ children, ...props }) => {
  const {
    purchaseOrder,
    purchaseOrderPdfData,
    mode,
    status,
    isFieldOrder,
    user,
    getHandleCreateService,
    getHandleComplete,
    handleSubmitStart,
    setOnSubmitFinal,
    handleFormsSubmit,
    handleGenerateReceipt,
    frameDataReducer,
    isLoading,
    canEdit,
    originalPO,
    setPurchaseOrder,
    isVistaEnabled
  } = props;
  document.title = `BuildOps - Procurement PO ${purchaseOrder.common.poNumber || ''}`;
  const classes = useStyles();
  const history = useHistory();
  const { Geocoder, GeocoderStatus } = google.maps;
  const { current: geocoder } = useRef(new Geocoder());
  const [manualEntry, setManualEntry] = useState(
    purchaseOrder.sidebarShipping.shipTo === ShippingInformation.MANUALLY
  );
  const [tagOptions, setTagOptions] = useState([]);
  const [formRef, setFormRef] = useState(null);
  const [showGeneratePdfModal, setShowGeneratePdfModal] = useState(false);
  const [openProcurementStatusModal, setOpenProcurementStatusModal] = useState(false);
  const [procurementStatusModalData, setProcurementStatusModalData] = useState(null);
  const [canGenerateReceipt, setCanGenerateReceipt] = useState(true);
  const [formattedTags, setFormattedTags] = useState([]);

  const confirmContext = useConfirmModal();
  const flags = useFlags();
  const departmentsAreLimitedByJob = flags[FeatureFlags.DEPARTMENTS_ARE_LIMITED_BY_JOB];
  const jobId =
    purchaseOrder?.topbar?.jobAndProject?.entityType === 'Job' &&
    purchaseOrder?.topbar?.jobAndProject?.id;
  const hideJobProjectField = (purchaseOrder?.topbar?.associatedQuotes || []).some(
    q => q.status === QuoteStatus.DRAFT || q.status === QuoteStatus.APPROVED
  );
  const appPermissionRules = useSelector(state => state.user.appPermissionRules);
  useEffect(() => {
    getPurchaseOrderTags().then(purchaseOrderTags => {
      setTagOptions(
        purchaseOrderTags.map(tag => {
          return { label: tag.tagName, value: tag.id };
        })
      );
    });
    setOnSubmitFinal(handleFormsSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const tags = purchaseOrder?.common?.purchaseOrderTags.map(tag => {
      const tagWithData = tagOptions.find(option => option.value === tag);
      return {
        'purchaseOrderTag.tagName': tagWithData?.label,
        'purchaseOrderTag.id': tagWithData?.value
      };
    });
    setFormattedTags(tags);
  }, [purchaseOrder, tagOptions]);

  const checkLineItemsStatus = () => {
    return purchaseOrderPdfData?.items?.every(item => item.status === 'Fulfilled');
  };

  useEffect(() => {
    setCanGenerateReceipt(checkLineItemsStatus());
  }, [purchaseOrderPdfData]);

  const additionalTitleComponents = [];
  const chipStyle = {
    height: 24,
    lineHeight: '24px',
    borderRadius: 2,
    marginLeft: 24
  };

  if (isFieldOrder) {
    additionalTitleComponents.push(
      <StatusChip
        label={purchaseOrderStatusChips.fieldOrder.name[user.locale]}
        backgroundColor={purchaseOrderStatusChips.fieldOrder.backgroundColor}
        textColor={purchaseOrderStatusChips.fieldOrder.textColor}
        key="procurementPOFrameTitleStatusChips"
        style={chipStyle}
      />
    );
  }

  if (status) {
    additionalTitleComponents.push(
      <StatusChip
        label={purchaseOrderStatusChips[status]?.name[user.locale]}
        backgroundColor={purchaseOrderStatusChips[status]?.backgroundColor}
        textColor={purchaseOrderStatusChips[status]?.textColor}
        key="procurementPOFrameTitleStatusChips"
        style={{ ...chipStyle, marginLeft: additionalTitleComponents.length ? 12 : 24 }}
      />
    );
  }
  additionalTitleComponents.push(
    <TagButtons
      getService={purchaseOrderChange}
      info={{
        id: purchaseOrder.common.id,
        version: purchaseOrder.common.version,
        poTags: purchaseOrder.common.purchaseOrderTags,
        originalPO,
        frameDataReducer,
        getPurchaseOrderById,
        setPurchaseOrder
      }}
      tags={formattedTags}
      TagType={TagType.PURCHASE_ORDER}
      useAPI
    />
  );

  const handleTopbarFormChange = async data => {
    let result;
    let address;
    let propertyAddr;
    let shippingAddress;
    let shippingAddressObj = {};
    switch (data.shipTo) {
      case ShippingInformation.JOB_SITE:
        if (data.jobAndProject?.id && data.jobAndProject?.entityType === 'Project') {
          result = await getProjectAddressById(data.jobAndProject?.id);
          shippingAddress = result.shippingAddress;
          shippingAddressObj = result.shippingAddressObj;
        } else if (data.jobAndProject.id && data.jobAndProject?.entityType === 'Job') {
          result = await getJobById(data.jobAndProject.id, 'propertyAddress');
          address = result?.propertyAddress;
        } else {
          // prevent reducer update when jobAndProject is set as a string from the form
          return;
        }
        propertyAddr = getAddressByType(address, 'propertyAddress');
        break;
      case ShippingInformation.WAREHOUSE:
        if (data.department?.companyAddresses) {
          propertyAddr = getAddressByType(
            data.department.companyAddresses || [],
            'shippingAddress'
          );
          break;
        }
        return;
      default:
        frameDataReducer({
          type: 'updateTopbar',
          payload: {
            ...data,
            depId: data.department?.id || data.depId
          }
        });
        return;
    }
    if (propertyAddr[0]?.addressLine1) {
      shippingAddress = getCombinedAddress(propertyAddr[0] || null);
      shippingAddressObj = getAddressObj(propertyAddr[0]);
    }
    geocoder.geocode({ address: shippingAddress }, (results, statusCode) => {
      let latitude;
      let longitude;
      if (statusCode === GeocoderStatus.OK) {
        latitude = results[0].geometry.location.lat();
        longitude = results[0].geometry.location.lng();
      }
      frameDataReducer({
        type: 'updateTopbarAndSidebarShipping',
        payload: {
          ...data,
          depId: data.department?.id || data.depId,
          shippingAddress,
          shippingAddressObj,
          shippingLocation: {
            latitude,
            longitude
          }
        }
      });
    });
  };

  const handleSidebarFieldShippingChange = (field, value) => {
    if (field === 'shipToNameType' && value === ShipToNameTypes.OTHER) {
      frameDataReducer({
        type: 'updateSidebarShipping',
        payload: {
          ...purchaseOrder,
          shipToName: '',
          shipToNameType: value
        }
      });
    } else if (field === 'shipToNameType') {
      frameDataReducer({
        type: 'updateSidebarShipping',
        payload: {
          ...purchaseOrder,
          shipToNameType: value
        }
      });
    }
  };
  const handleSidebarFormShippingChange = data => {
    geocoder.geocode({ address: data.shippingAddress }, (results, statusCode) => {
      let latitude;
      let longitude;
      if (statusCode === GeocoderStatus.OK) {
        latitude = results[0].geometry.location.lat();
        longitude = results[0].geometry.location.lng();
      }

      frameDataReducer({
        type: 'updateSidebarShipping',
        payload: {
          ...data,
          shipTo: data.shipTo || '',
          shipToName: data.shipToName || '',
          shipToEmployee: data.shipToEmployee || '',
          shipToEmployeeId: data.shipToEmployee?.id || '',
          shipToInstructions: data.shipToInstructions || '',
          shippingAddress: data.shippingAddress || '',
          addressLine1: data.addressLine1 || undefined,
          addressLine2: data.addressLine2 || undefined,
          city: data.city || undefined,
          state: data.state || undefined,
          zipcode: data.zipcode || undefined,
          shippingLocation: {
            latitude,
            longitude
          }
        }
      });
    });
  };

  const handleSidebarFormVendorChange = data => {
    const vendorAddress = getCombinedAddress(data.vendor);
    geocoder.geocode({ address: vendorAddress }, (results, statusCode) => {
      if (statusCode === GeocoderStatus.OK) {
        frameDataReducer({
          type:
            data.shipTo === ShippingInformation.VENDOR_PICKUP
              ? 'updateSidebarVendorAndSidebarShipping'
              : 'updateSidebarVendor',
          payload: {
            vendor: data.vendor,
            vendorName: data.vendor?.name || '',
            vendorAddress: vendorAddress || '',
            phoneNumber: data.vendor?.phone || '',
            vendorEmail: data.vendor?.email || '',
            vendorLocation: {
              latitude: results[0].geometry.location.lat(),
              longitude: results[0].geometry.location.lng()
            },
            shippingAddressObj: getAddressObj(data.vendor)
          }
        });
      }
    });
  };

  const matchingShippingAddress = async (userSelect, formData) => {
    let newFormValue = {};
    let result;
    let propertyAddr;
    const initialAddressValue = {
      shippingAddress: null,
      shippingAddressObj: {},
      shippingLocation: {
        latitude: null,
        longitude: null
      }
    };
    // eslint-disable-next-line default-case
    switch (userSelect) {
      case ShippingInformation.JOB_SITE:
        if (
          formData.values.jobAndProject?.id &&
          formData.values.jobAndProject?.entityType === 'Project'
        ) {
          result = await getProjectAddressById(formData.values.jobAndProject?.id);
          newFormValue = {
            ...formData.values,
            ...result,
            shipTo: userSelect
          };
        } else if (
          formData.values.jobAndProject?.id &&
          formData.values.jobAndProject?.entityType === 'Job'
        ) {
          result = await getJobById(formData.values.jobAndProject?.id, 'propertyAddress');
          propertyAddr = getAddressByType(result?.propertyAddress, 'propertyAddress');
          newFormValue = {
            ...formData.values,
            shipTo: userSelect,
            shippingAddress: getCombinedAddress(propertyAddr[0] || null),
            shippingAddressObj: getAddressObj(propertyAddr[0])
          };
        } else {
          newFormValue = {
            ...formData.values,
            ...initialAddressValue,
            shipTo: userSelect
          };
        }
        formData.setValues(newFormValue);
        break;
      case ShippingInformation.WAREHOUSE:
        result = await getCompanyAddressByParentId(purchaseOrder.topbar?.department?.id);
        propertyAddr = getAddressByType(result, 'shippingAddress');
        if (propertyAddr[0]?.addressLine1) {
          newFormValue = {
            ...formData.values,
            shipTo: userSelect,
            shippingAddress: getCombinedAddress(propertyAddr[0] || null),
            shippingAddressObj: getAddressObj(propertyAddr[0])
          };
        } else {
          newFormValue = {
            ...formData.values,
            ...initialAddressValue,
            shipTo: userSelect
          };
        }
        formData.setValues(newFormValue);
        break;
      case ShippingInformation.VENDOR_PICKUP:
        newFormValue = {
          ...formData.values,
          shipTo: userSelect,
          shippingAddress: getCombinedAddress(purchaseOrder.sidebarVendor.vendor) || null,
          shippingAddressObj: getAddressObj(purchaseOrder.sidebarVendor.vendor)
        };
        formData.setValues(newFormValue);
        break;
      case ShippingInformation.MANUALLY:
        if (purchaseOrder.sidebarShipping.shipTo === ShippingInformation.MANUALLY) {
          newFormValue = {
            ...formData.values,
            shipTo: userSelect,
            shippingAddress: getCombinedAddress(purchaseOrder),
            shippingAddressObj: getAddressObj(purchaseOrder)
          };
        } else {
          newFormValue = {
            ...formData.values,
            ...initialAddressValue,
            shipTo: userSelect
          };
        }
        formData.setValues(newFormValue);
        break;
    }
    // to force refresh the address and map
    handleSidebarFormShippingChange(newFormValue);
  };

  const handleChangeShipToSelect = async (field, form) => {
    await matchingShippingAddress(field.value, form);

    switch (field.value) {
      case ShippingInformation.JOB_SITE:
      case ShippingInformation.WAREHOUSE:
      case ShippingInformation.VENDOR_PICKUP:
        setManualEntry(false);
        break;
      case ShippingInformation.MANUALLY:
      default:
        setManualEntry(true);
        break;
    }
  };

  const customSearchBar = ({ options, field, form }) => {
    return (
      <SearchBar
        options={options}
        field={field}
        form={form}
        onSelectionChange={values => {
          if (values) {
            // eslint-disable-next-line no-param-reassign
            form.values[field.name] = values;
            // to change Job site shipping address automatically
            handleChangeShipToSelect({ value: ShippingInformation.JOB_SITE }, form);
          }
        }}
      />
    );
  };

  const handlePlaceOrder = async () => {
    setShowGeneratePdfModal(false);
    try {
      await purchaseOrderChange(purchaseOrder.common.id, {
        status: 'Ordered',
        orderedById: user.employeeId
      });
      props.snackbarOn('success', 'Updated purchase order');
      history.push(`/procurement/purchaseorders/view/${purchaseOrder.common.id}`);
    } catch (e) {
      props.snackbarOn('error', `Could not update purchase order: ${e}`);
    }
  };

  /**
    Checks if the changed "Job/Project" field is:
     - A Job, and not a project 
     - The procurement status was manually set
     - It's a different job number from the original currently set for the PO
   */
  const checkManualProcurementStatus = async (selectedItem, form, modifiedValues) => {
    if (
      selectedItem &&
      selectedItem?.entityType === 'Job' &&
      selectedItem?.jobNumber !== form.initialValues?.jobNumber
    ) {
      if (selectedItem?.jobNumber !== procurementStatusModalData?.selectedJob?.jobNumber) {
        const jobService = new JobService();
        const jobDetails = await jobService.getJobDetailsByJobNumber(`${selectedItem.jobNumber}`);
        if (jobDetails?.data?.getJobByJobNumber?.procurementStatus) {
          const automatedStatus = getProcurementStatus([
            ...(selectedItem?.purchaseOrders?.items || []),
            { status }
          ]);
          setProcurementStatusModalData({
            manualStatus: selectedItem?.procurementStatus || 'POs Needed',
            automatedStatus
          });
          const updatedProcurementStatusValues = {
            ...modifiedValues,
            jobProcStatusToUpdate: jobDetails?.data?.getJobByJobNumber
          };
          form.setValues(updatedProcurementStatusValues, false);
          if (!formRef) {
            setFormRef({ form, values: updatedProcurementStatusValues });
          }
        }
      }
    } else {
      setProcurementStatusModalData(null);
    }
  };

  const resetJobProcStatusToUpdate = ({ form, values }) => {
    if (form) {
      const modifiedValues = {
        ...values,
        jobProcStatusToUpdate: null
      };
      form.setValues(modifiedValues);
    }
  };

  const handleJobIdChange = async (selectedItem, selectedItemName, form) => {
    if (await confirmContext.confirm(changeJobOrProjectConfirmContent)) {
      const modifiedValues = {
        ...form.values,
        jobAndProject: { ...selectedItem },
        jobProcStatusToUpdate: null
      };
      if (departmentsAreLimitedByJob) {
        modifiedValues.department = null;
      }
      form.setValues(modifiedValues);
      if (flags[FeatureFlags.JOB_PROCUREMENT_STATUS]) {
        checkManualProcurementStatus(selectedItem, form, modifiedValues);
      }
    } else {
      const { jobNumber, jobOrProjectLink, jobOrProjectText, jobAndProject } =
        form.initialValues || {};
      const modifiedValues = {
        ...form.values,
        jobNumber,
        jobOrProjectLink,
        jobOrProjectText,
        jobAndProject,
        jobProcStatusToUpdate: null
      };
      if (departmentsAreLimitedByJob) {
        modifiedValues.department = null;
      }
      form.setValues(modifiedValues);
    }
  };

  const sidebarShipping = useMemo(() => {
    return (
      <MuiFormWithLoading
        configuration={poFrameSideFormShippingLayout({
          shipTo: getLabelFromValues(ShippingInformation),
          manualEntry,
          handleChangeShipToSelect,
          canEdit,
          shipToIsEmployee:
            purchaseOrder.sidebarShipping.shipToNameType === ShipToNameTypes.EMPLOYEE
        })}
        data={purchaseOrder.sidebarShipping}
        layout={mode}
        onCreateService={getHandleCreateService('sidebarShipping')}
        onComplete={getHandleComplete('sidebarShipping')}
        onFormChange={handleSidebarFormShippingChange}
        onFieldChange={handleSidebarFieldShippingChange}
        customComponents={{
          MuiFormSectionTitle,
          CustomFieldWithLabelNarrow,
          CustomFieldWithLabelWrap,
          PlacesSearch,
          LocationView,
          customSearchBar
        }}
        validationSchema={generateDefaultValidationSchema(poFrameSideFormShippingFields)}
        isLoading={!isLoading}
        loadingParams={loadingParams.leftSection}
      />
    );
  }, [
    purchaseOrder.sidebarShipping.shippingAddress,
    purchaseOrder.sidebarShipping.shippingLocation.latitude,
    purchaseOrder.sidebarShipping.shippingLocation.longitude,
    mode,
    isLoading,
    canEdit,
    purchaseOrder.sidebarShipping.shipToNameType
  ]);

  const handleVoidPurchaseOrder = async () => {
    await purchaseOrderChange(purchaseOrder.common.id, {
      status: ProcurementPurchaseOrderStatus.VOID
    });
    props.snackbarOn('success', 'Updated purchase order');
    history.push(`/procurement/purchaseorders/view/${purchaseOrder.common.id}`);
  };

  const AssociatedQuotesComponent = () => {
    const labelStyle = {
      letterSpacing: 0.01,
      lineHeight: '14px',
      marginBottom: '0.35em'
    };

    return (
      <ThemeProvider>
        <Typography caps style={labelStyle} color={theme.palette.grayscale(60)} variant={TV.S2}>
          Associated Quote(s)
        </Typography>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          {purchaseOrder.topbar.associatedQuotes.map(q => {
            const url =
              q.entityType === QuoteConstants.VersionedQuote
                ? `/quote/${q.id}?versionId=${q.versionId}`
                : `/quote/${q.id}`;
            const label = `Version ${q.versionNumber} of ${q.customIdentifier || q.quoteNumber}`;

            return (
              <div style={{ paddingRight: 8 }}>
                <a href={url}>{label}</a>
              </div>
            );
          })}
        </div>
      </ThemeProvider>
    );
  };

  const purchaseOrderNote = useCallback(
    formProps => {
      const refetchTopbarNotes = async () => {
        const data = await getNotesByPurchaseOrderId(purchaseOrder.common.id);
        frameDataReducer({
          type: 'updateNotes',
          payload: data || []
        });
      };

      return (
        <NotesV2
          title={Labels.purchaseOrderNotes[user.locale]}
          linkName={Labels.viewAllPurchaseOrderNotes[user.locale]}
          allNotesFor={`Purchase Order #${purchaseOrder.common.poNumber}`}
          notesData={formProps?.displayValue || []}
          parent={{
            id: purchaseOrder.common.id,
            name: purchaseOrder.common.poNumber,
            entityType: 'PurchaseOrder',
            displayEntityType: 'Purchase Order',
            version: purchaseOrder.version
          }}
          refetch={refetchTopbarNotes}
          shareWithTechsOption={false}
        />
      );
    },
    [frameDataReducer, purchaseOrder.common.id, purchaseOrder.common.poNumber, user.locale]
  );

  return (
    <div className={classes.root}>
      <PageHeader
        breadcrumbsArray={[
          {
            link: '',
            title: 'Procurement'
          },
          {
            link: '/procurement/purchaseorders/',
            title: PageMap.procurement.title[user.locale]
          }
        ]}
        pageMapKey="procurement"
        userLocale={user.locale}
        title={`PO ${purchaseOrder.common.poNumber || ''}`}
        overrideHeaderButtons={
          status !== PurchaseOrderStatus.VOID &&
          (mode === 'edit'
            ? // edit mode buttons
              [
                <Box key="cancelButton" mr={1}>
                  <DefaultButton
                    variant="outlined"
                    label={Labels.cancelButtonText[user.locale]}
                    handle={() =>
                      history.push(`/procurement/purchaseorders/view/${purchaseOrder.common.id}`)
                    }
                  />
                </Box>,
                <Box key="saveButton" mr={1}>
                  <DefaultButton
                    variant="contained"
                    label={Labels.saveChangesButtonText[user.locale]}
                    handle={() => {
                      if (procurementStatusModalData) {
                        setOpenProcurementStatusModal(true);
                      } else {
                        handleSubmitStart();
                      }
                    }}
                  />
                </Box>,
                <Box key="generateReceiptButton">
                  {' '}
                  <DefaultButton
                    variant="contained"
                    color="primary"
                    label="Generate Receipt"
                    disabled
                    handle={handleGenerateReceipt}
                  />
                </Box>
              ]
            : // view/default mode buttons
              [
                PurchaseOrdersUtils.hasUpdatePurchaseOrderPermissions(appPermissionRules) && (
                  <Box key="editButton" mr={1}>
                    <DefaultButton
                      variant="outlined"
                      label="Edit"
                      handle={() =>
                        history.push(`/procurement/purchaseorders/edit/${purchaseOrder.common.id}`)
                      }
                    />
                  </Box>
                ),
                <Box key="generatePdfButton" mr={1}>
                  <DefaultButton
                    label="Generate PDF"
                    color="secondary"
                    handle={() => {
                      setShowGeneratePdfModal(true);
                    }}
                  />
                </Box>,
                <Box key="generateReceiptButton">
                  <DefaultButton
                    variant="contained"
                    color="primary"
                    label="Generate Receipt"
                    disabled={canGenerateReceipt}
                    handle={handleGenerateReceipt}
                  />
                </Box>,
                (status === PurchaseOrderStatus.DRAFT || status === PurchaseOrderStatus.ORDERED) &&
                  canEdit && (
                    <ThemeProvider>
                      <MoreButton
                        options={[
                          {
                            label: 'Void PO',
                            icon: NotInterested,
                            onClick: async () =>
                              (await confirmContext.confirm(voidConfirmContent)) &&
                              handleVoidPurchaseOrder()
                          }
                        ]}
                        style={{ fontSize: '1.2rem' }}
                      />
                    </ThemeProvider>
                  )
              ].filter(Boolean))
        }
        additionalTitleComponents={additionalTitleComponents}
        key="procumentPOFramePageHeader"
      />
      <div className={classes.body}>
        <div className={classes.sidebar}>
          <div className={classNames(classes.sidebarInner, classes.formContainer)}>
            <MuiFormWithLoading
              configuration={poFrameSideFormVendorLayout(canEdit)}
              data={purchaseOrder.sidebarVendor}
              layout={mode}
              onCreateService={getHandleCreateService('sidebarVendor')}
              onComplete={getHandleComplete('sidebarVendor')}
              onFormChange={handleSidebarFormVendorChange}
              customComponents={{
                MuiFormSectionTitle,
                CustomFieldWithLabelNarrow,
                CustomFieldWithLabelWrap,
                LocationView,
                SearchBar
              }}
              validationSchema={generateDefaultValidationSchema(poFrameSideFormVendorFields)}
              isLoading={!isLoading}
              loadingParams={loadingParams.leftSection}
            />
            {sidebarShipping}
          </div>
        </div>
        <div className={classes.content}>
          <div className={classNames(classes.topbar, classes.formContainer)}>
            <MuiFormWithLoading
              configuration={poFrameTopFormLayout({
                ...purchaseOrder.topbar,
                handleJobIdChange,
                canEdit,
                isVistaEnabled,
                departmentsAreLimitedByJob,
                handleSetDefaultValue: ProcurementUtils.handleSetDeptDefaultValue,
                jobId,
                hideJobProjectField
              })}
              data={purchaseOrder.topbar}
              layout={mode}
              onCreateService={getHandleCreateService('mainTop')}
              onComplete={getHandleComplete('mainTop')}
              onFormChange={handleTopbarFormChange}
              customComponents={{
                MuiFormSectionTitle,
                CustomFieldWithLabelNarrow,
                CustomFieldWithLabelWrap,
                SearchBar,
                customSearchBar,
                AlgoliaSearch: CustomAlgoliaSearch,
                purchaseOrderNote,
                AssociatedQuotesComponent
              }}
              validationSchema={generateDefaultValidationSchema(poFrameTopFormFields)}
              isLoading={!isLoading}
              loadingParams={loadingParams.mainSection}
            />
          </div>
          <Divider />
          <div className={classes.childrenContainer}>
            <div>{children}</div>
          </div>
        </div>
      </div>
      {flags[FeatureFlags.JOB_PROCUREMENT_STATUS] && (
        <ManulStatusChangeModal
          title="Update Procurement Status"
          statusEnumType={EnumType.JOB_PROCUREMENT_STATUS}
          data={procurementStatusModalData}
          open={openProcurementStatusModal}
          handleClose={async xButtonClosed => {
            if (!xButtonClosed) {
              resetJobProcStatusToUpdate(formRef);
              await handleSubmitStart();
            }
            setOpenProcurementStatusModal(false);
          }}
          handleSubmit={async () => {
            await handleSubmitStart();
            setOpenProcurementStatusModal(false);
          }}
        />
      )}
      <GeneratePdfModal
        open={showGeneratePdfModal}
        user={user}
        pdfData={purchaseOrderPdfData}
        handleClose={() => setShowGeneratePdfModal(false)}
        handlePlaceOrder={handlePlaceOrder}
        status={status}
        purchaseOrder={purchaseOrder}
      />
    </div>
  );
});

CustomFieldWithLabelNarrow.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired
};

CustomFieldWithLabelWrap.propTypes = {
  field: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired
};

CustomAlgoliaSearch.propTypes = {
  field: PropTypes.object.isRequired
};

PurchaseOrderFrame.propTypes = {
  purchaseOrder: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  isFieldOrder: PropTypes.bool,
  user: PropTypes.object.isRequired,
  getHandleCreateService: PropTypes.func,
  getHandleComplete: PropTypes.func,
  handleSubmitStart: PropTypes.func,
  setOnSubmitFinal: PropTypes.func,
  handleFormsSubmit: PropTypes.func,
  handleGenerateReceipt: PropTypes.func,
  frameDataReducer: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  snackbarOn: PropTypes.func.isRequired
};

PurchaseOrderFrame.defaultProps = {
  getHandleCreateService: () => {},
  getHandleComplete: () => {},
  handleSubmitStart: () => {},
  setOnSubmitFinal: () => {},
  handleFormsSubmit: () => {},
  handleGenerateReceipt: () => {},
  frameDataReducer: () => {}
};

const mapStateToProps = state => ({ user: state.user });
const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});
const ReduxConnectedPurchaseOrderFrame = connect(
  mapStateToProps,
  mapDispatcherToProps
)(PurchaseOrderFrame);
export default ReduxConnectedPurchaseOrderFrame;
