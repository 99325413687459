import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import { useSnackbar } from 'customHooks/useSnackbar';
import { logErrorWithCallback } from 'utils';

import { jobFragment } from '../../gql';

export const getTimesheetBindersByPeriodId = gql`
  query getTimesheetPeriodById($id: String!) {
    getTimesheetPeriodById(id: $id) {
      id
      dateStartUTC
      dateEndUTC
      approved
      approvalStatus
      timesheetEntryBinders {
        items {
          id
          eventId
          eventType
          employeeId
          manualStatus
          manualApprovedBy
          manualApprovedDate
          manualReopenReason
          startDayCompanyTZ
          projectId
          project {
            id
            name
            number
          }
          projectPhaseId
          projectPhase {
            id
            name
          }
          projectPhaseDepartmentId
          projectPhaseDepartment {
            id
            tagName
          }
          projectPhaseDepartmentCostCodeId
          projectPhaseDepartmentCostCode {
            id
            name
          }
          dailyReportId
          dailyReport {
            id
            number
          }
          event {
            ... on NonVisitEvent {
              id
              entityType
              plannedStartTimeUTC
              plannedEndTimeUTC
              isActive
              name
              status
              department {
                tagName
              }
              assignedEntity {
                id
                entityType
                parentId
                ... on Visit {
                  id
                  visitNumber
                  departmentName
                  ${jobFragment}
                }
              }
            }
            ... on Visit {
              id
              entityType
              visitNumber
              departmentName
              ${jobFragment}
            }
            ... on ManDay {
              id
              entityType
              projectId
              project {
                id
                name
                number
              }
            }
          }
          timesheetEntries {
            items {
              id
              hourTypeId
              entityType
              timesheetPeriodId
              actualCreatedDate
              actualTotalDuration
              actualTotalDurationOverride
              actualStartTimeUTC
              actualEndTimeUTC
              actualCreatedDate
              manualApprovedBy
              manualApprovedDate
              manualReopenReason
              manualStatus
              billableEntityId
              billableEntityType
              nonVisitEventId
              auditLogs {
                items {
                  id
                  executedBy
                  changeLog
                  executedDateTime
                  executionType
                }
              }
            }
          }
          timesheetNotes {
            items {
              id
              note
              subject
              createdBy
              employeeId
              createdDateTime
              version
              attachments(entityConnection: "Attachment") {
                items {
                  fileUrl
                  fileName
                  id
                  _deleted
                }
              }
            }
          }
        }
      }
    }
  }
`;

const useTimesheetPeriod = ({ id }, options = {}) => {
  const snackbar = useSnackbar();

  const { data, loading, error, refetch } = useExtendedQuery(getTimesheetBindersByPeriodId, {
    variables: { id },
    transform: result => result?.getTimesheetPeriodById,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !id,
    ...options
  });

  if (error) {
    logErrorWithCallback(error, snackbar, 'Unable to fetch timesheet period');
  }
  return [data || {}, loading, error, refetch];
};

export default useTimesheetPeriod;
