import React from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import JobNumber from '@dispatch/components/JobNumber';
import { JobTypes, VisitStatuses } from '@dispatch/Dispatch.constants';
import { JobPropTypes } from '@dispatch/Dispatch.props';
import { Job, Maintenance } from '@dispatch/icons';

import Routes from 'scenes/Routes';

import { FeatureFlags } from 'utils/FeatureFlagConstants';

import { useStyles } from './JobLink.styles';

const JobLink = ({ job, visit }) => {
  const isMaintenance = job?.jobTypeInternal === JobTypes.MAINTENANCE.value.serverValue;
  let route = isMaintenance ? Routes.maintenance : Routes.job;
  let routeTo = route({ mode: 'view', id: encodeURIComponent(job?.jobNumber) });
  const styles = useStyles();
  const flags = useFlags();
  const completedStates = [
    VisitStatuses.ON_HOLD.key,
    VisitStatuses.CANCELED.key,
    VisitStatuses.COMPLETE.key,
    VisitStatuses.CLOSED.key,
    VisitStatuses.CONVERTED.key
  ];

  if (flags[FeatureFlags.JOB_CLOSEOUT]) {
    if (completedStates.includes(visit?.status)) {
      route = Routes.jobCloseoutVisit;
      routeTo = route({
        jobid: encodeURIComponent(job?.id),
        id: encodeURIComponent(visit.id)
      });
    }
  }

  return (
    <Link to={routeTo}>
      <img alt="job-type" css={styles.icon} src={isMaintenance ? Maintenance : Job} />
      <JobNumber job={job} />
    </Link>
  );
};

JobLink.propTypes = {
  job: JobPropTypes.isRequired,
  // eslint-disable-next-line react/require-default-props
  visit: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string
  })
};

export default JobLink;
