// convert null values as empty string from the json values passed
export const removeNullValues = values => {
  const finalValue = {};
  Object.keys(values).map(key => {
    if (!values[key]) {
      finalValue[key] = '';
      return finalValue;
    }
    if (key === '__typename') {
      // _typename is added by the react apollo query component
      return '';
    }
    finalValue[key] = values[key];
    return finalValue;
  });
  return finalValue;
};

// convert empty string values from the json values passed
export const removeEmptyValues = values => {
  const finalValue = {};
  Object.keys(values).map(key => {
    if (values[key] && values[key] !== '') {
      finalValue[key] = values[key];
    }
    return finalValue;
  });
  return finalValue;
};

export const getNumberValue = value => {
  if (value) {
    return parseInt(value, 10);
  }
  return null;
};

export const getCombinedAddress = address => {
  const addressKeysToRemove = [
    '__typename',
    'addressType',
    'id',
    'entityType',
    'sortKey',
    'hierarchy',
    'tenantId',
    'tenantCompanyId',
    'partitionKey'
  ];

  // no processing if there is no address type
  if (!address.addressType) {
    return address;
  }
  let finalAddress = '';
  Object.keys(address).forEach(key => {
    if (!addressKeysToRemove.includes(key) && address[key]) {
      finalAddress = `${finalAddress} ${address[key]},`;
    }
  });

  // removing last comma
  if (finalAddress[finalAddress.length - 1] === ',') {
    finalAddress = finalAddress.slice(0, finalAddress.length - 1);
  }

  return finalAddress && finalAddress.trim();
};

// address JSON is converted into combined address with address type as key
// "shippingAddress" : "line1, line2, city, state, zipcode"
export const processAddressArrayAsJson = addresses => {
  const addressesJson = {};
  addresses.forEach(address => {
    // // TODO: remove this logic after correcting for onboarding
    // if (address.entityType) {
    //   address.addressType =
    //     address.entityType.charAt(0).toLowerCase() + address.entityType.slice(1);
    // }
    addressesJson[address.addressType] = getCombinedAddress(address);
  });
  return addressesJson;
};

const addressTypeToPrefix = {
  billingAddress: 'billing',
  shippingAddress: 'shipping',
  contactAddress: 'contact',
  homeAddress: 'home',
  propertyAddress: 'property',
  businessAddress: 'business',
  companyAddress: 'company'
};

// maps addresses json to individual address fields. To be used in forms
export const mapAddressJsonToFields = addresses => {
  if (!addresses) {
    return addresses;
  }
  const localAddress = {};
  addresses.forEach(address => {
    const prefix = addressTypeToPrefix[address.addressType];
    Object.keys(address).forEach(key => {
      if (key !== 'addressType' && key !== '__typename' && address[key]) {
        const keyText = prefix + (key.charAt(0).toUpperCase() + key.slice(1));
        localAddress[keyText] = address[key];
      }
    });
  });
  return localAddress;
};

export const convertSchemaTagsToString = values => {
  if (!values) {
    return '';
  }
  let result = '';
  values.forEach(item => {
    result += item.tagName;
  });
  return result;
};

export const removeNestedJson = jsonObject => {
  if (!jsonObject) {
    return;
  }
  const filteredJson = {};
  Object.keys(jsonObject).map(key => {
    if (typeof jsonObject[key] !== 'object') {
      filteredJson[key] = jsonObject[key];
    }
    return '';
  });
  return filteredJson;
};

export const generateSystemEntityMapKeys = record => {
  const sortKey = `${record.hierarchy}_${record.mappedEntityId}_${record.mappedEntityType}${record.systemEntityType}_${record.systemEntityId}`;
  const invertedSortkey = `${record.hierarchy}_${record.systemEntityId}_${record.systemEntityType}${record.mappedEntityType}_${record.mappedEntityId}`;
  return { sortKey, invertedSortkey };
};

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index += 1) {
    await callback(array[index], index, array);
  }
}

export const convertAddressObjToString = addressObj => {
  let addressArray = Object.values(addressObj);
  addressArray = addressArray.filter(attr => !!attr);
  return addressArray.join(', ');
};

export const generateCompanySortKey = (tenantId, companyId) => `${tenantId}_Company_${companyId}`;

export const getMappedEntityJobTagname = entityItems => {
  return (
    entityItems &&
    !_.isEmpty(entityItems.items) &&
    entityItems.items.map(value => value.mappedEntity?.tagName).join(', ')
  );
};
