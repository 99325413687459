import React from 'react';
import { func } from 'prop-types';

import { TECH_PROP } from '@dispatch/queries';

import CustomMarker from '../CustomMarker';

import { useStyles } from './TechMarker.styles';

const TechMarker = ({ tech: { name, position, profilePictureUrl }, updateBounds }) => {
  const classes = useStyles({ profilePictureUrl });

  return (
    <CustomMarker position={position} label={name} updateBounds={updateBounds} zIndex={10}>
      <div className={classes.techPhoto} />
    </CustomMarker>
  );
};

TechMarker.propTypes = {
  tech: TECH_PROP.isRequired,
  updateBounds: func.isRequired
};

export default TechMarker;
