import { useDrag } from 'react-dnd';
import { intersection } from 'lodash';

import { ItemTypes } from '@dispatch/dnd';
import { VisitActions } from '@dispatch/Dispatch.constants';

export const useVisitDrag = ({ visit, srcTech, isDrawer }) => {
  return useDrag({
    item: { type: isDrawer ? ItemTypes.TABLE_VISIT : ItemTypes.BOARD_VISIT, data: visit, srcTech },
    canDrag: () => {
      return Boolean(
        intersection(visit.nextEvents, [VisitActions.SCHEDULE.key, VisitActions.RESCHEDULE.key])[0]
      );
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      canDrag: monitor.canDrag()
    })
  });
};

export const useVisitStartDrag = ({ visit, srcTech, weekView, mapView }) => {
  return useDrag({
    item: { type: ItemTypes.VISIT_START, data: visit, srcTech },
    canDrag: () => {
      return visit.nextEvents?.includes(VisitActions.RESCHEDULE.key) && !(weekView || mapView);
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      canDrag: monitor.canDrag()
    })
  });
};

export const useVisitEndDrag = ({ visit, srcTech, weekView, mapView }) => {
  return useDrag({
    item: { type: ItemTypes.VISIT_END, data: visit, srcTech },
    canDrag: () => {
      return visit.nextEvents?.includes(VisitActions.RESCHEDULE.key) && !(weekView || mapView);
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      canDrag: monitor.canDrag()
    })
  });
};
