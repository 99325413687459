import { formatAddress, getCombinedAddress, logErrorWithCallback } from 'utils';
import { isEmpty, sortBy } from 'lodash';
import { constructSelectOptions } from 'utils/constructSelectOptions';
import { QuoteStatus } from 'utils/AppConstants';

class QuotesUtils {
  static composePageHeaderQuoteInfo(quoteInfo, companyEmployees) {
    return {
      ...quoteInfo,
      soldBy: companyEmployees.find(employee => employee.id === quoteInfo.salesById)
    };
  }

  static async composeAccountMgrOptions({
    customerPropertyService,
    customerService,
    propertyDetails,
    setAccountManagerOptions,
    snackbar
  }) {
    try {
      if (!isEmpty(propertyDetails)) {
        const customerResponse = await customerService.getTenantRepsByCustomerById(
          propertyDetails.customer.id
        );
        const propertyResponse = await customerPropertyService.getTenantRepsByCustomerPropertyById(
          propertyDetails.property.id
        );
        const customerTenantReps = customerResponse.data.getCustomerById.tenantReps.items;
        const propertyTenantReps = propertyResponse.data.getCustomerPropertyById.tenantReps.items;
        const allReps = [...customerTenantReps, ...propertyTenantReps];
        const noDuplicateReps = Array.from(
          new Set(allReps.map(rep => rep.mappedEntityId))
        ).map(id => allReps.find(rep => rep.mappedEntityId === id));
        const options = constructSelectOptions(noDuplicateReps, 'mappedEntity.name');
        setAccountManagerOptions(options);
      }
    } catch (error) {
      logErrorWithCallback(error, snackbar, 'Unable to fetch account manager options');
    }
  }

  static composePropertyDetails(propertyData) {
    let propertyAddress = '';
    let propertyAddressMatch;
    if (propertyData?.companyAddresses?.items?.length) {
      propertyAddressMatch =
        propertyData.companyAddresses.items.find(
          address => address.addressType === 'propertyAddress'
        ) || {};
      propertyAddress = formatAddress(propertyAddressMatch);
    }
    const customerData = propertyData.parentEntity;
    let customerBillingAddress;
    let billTo = '';
    if (customerData?.companyAddresses?.items?.length) {
      const billingAddress =
        customerData.companyAddresses.items.find(
          address => address.addressType === 'billingAddress'
        ) || {};
      customerBillingAddress = getCombinedAddress(billingAddress);
      billTo = billingAddress.billTo
        ? `${billingAddress.billTo},\n${customerBillingAddress} `
        : customerBillingAddress || '';
    }

    return {
      customer: customerData,
      property: { ...propertyData, address: propertyAddressMatch },
      propertyAddress,
      customerBillingAddress,
      billTo: billTo || ''
    };
  }

  static createStatusAlertMsg(alertMsg, signatures) {
    const lastSignature = sortBy(signatures, 'createdDate').at(-1);
    const signeeInfo =
      lastSignature &&
      `${lastSignature.nameOfSignee} - ${new Date(lastSignature.createdDate * 1000).toLocaleString(
        undefined,
        {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        }
      )}`;

    if (alertMsg && signeeInfo) {
      return `${alertMsg}\n${signeeInfo}`;
    }
    if (signeeInfo) {
      return signeeInfo;
    }
    return alertMsg || '';
  }

  static isQuoteReadOnly(hasPermissionToEditQuote, quoteStatus, primaryQuoteStatus) {
    return (
      !hasPermissionToEditQuote ||
      [
        QuoteStatus.JOB_ADDED,
        QuoteStatus.CANCELLED,
        QuoteStatus.APPROVED,
        QuoteStatus.PROJECT_ADDED
      ].includes(quoteStatus) ||
      (quoteStatus === QuoteStatus.DISCARDED && primaryQuoteStatus === QuoteStatus.JOB_ADDED)
    );
  }

  static composeQuoteAttachments(attachments) {
    if (!attachments) return null;
    const attachmentItems = (attachments?.items || [])
      .map(item => ({
        ...item,
        shareWithTechniciansOnMobile: item.hideFromTechniciansOnMobile ? 'No' : 'Yes',
        internalFile: item.isInternalFile ? 'Yes' : 'No'
      }))
      .sort((a, b) => a.createdDate - b.createdDate);
    return { items: attachmentItems };
  }
}

export default QuotesUtils;
