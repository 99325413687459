import { gql } from '@apollo/client/core';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import { getState } from 'redux/store';

import { inventoryPartFragment } from '../queries/queryFragment';

import { createOptimisticInventoryItem } from './useAddInventoryParts';

const TOGGLE_INVENTORY_PART = gql`
  mutation updateInventoryPart($partitionKey: String, $data: UpdateInventoryPartInput!) {
    updateInventoryPart(partitionKey: $partitionKey, data: $data) {
      id
      version
      includeInInvoice
    }
  }
`;

export const useToggleInventoryPart = () => {
  return useExtendedMutation(TOGGLE_INVENTORY_PART, {
    serializer: updateFragment => ({
      variables: {
        partitionKey: getState()?.company?.partitionKey,
        data: updateFragment
      }
    }),
    optimisticResponseFactory: updateFragment => ({
      updateInventoryPart: {
        ...updateFragment,
        __typename: 'InventoryPart'
      }
    })
  });
};

const UPDATE_INVENTORY_PART = gql`
  mutation updateInventoryPart($partitionKey: String, $data: UpdateInventoryPartInput!) {
    updateInventoryPart(partitionKey: $partitionKey, data: $data) {
      ${inventoryPartFragment}
    }
  }
`;

export const useUpdateInventoryPart = (options = {}) => {
  return useExtendedMutation(UPDATE_INVENTORY_PART, {
    optimisticResponseFactory: ({ inventoryItem }) => ({
      updateInventoryPart: createOptimisticInventoryItem(inventoryItem, 0)
    }),
    ...options
  });
};
