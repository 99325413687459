import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import * as R from 'ramda';

import { useSelector } from 'react-redux';

import {
  isImageFile,
  toFileAttachment,
  toImageAttachment,
  uploadFiles
} from './Attachments.helpers';
import { useStyles } from './Attachments.styles';
import AddAttachmentButton from './components/AddAttachmentButton';
import AddAttachmentsModal from './components/AddAttachmentsModal';
import AddFilesModal from './components/AddFilesModal';
import EmptyAttachmentsList from './components/EmptyAttachmentsList';
import FilesList from './components/FilesList';
import ImagesCarousel from './components/ImagesCarousel';
import Title from './components/Title';

const Attachments = props => {
  const {
    attachedFiles,
    smallType,
    noTitle,
    smallButton,
    downloadable,
    noAddButton,
    uploadFileList,
    chunkSize,
    imageWidth,
    imageHeight
  } = props;
  const styles = useStyles();
  const tenantId = useSelector(store => store?.user?.tenantId);

  const [attachments, setAttachments] = useState(attachedFiles);

  useEffect(() => {
    setAttachments(attachedFiles);
  }, [attachedFiles]);

  const [imageAttachments, fileAttachments] = R.partition(attachment =>
    isImageFile(attachment?.file || attachment)
  )(attachments);

  const images = R.map(toImageAttachment)(imageAttachments);
  const files = R.map(toFileAttachment)(fileAttachments);

  const [attachmentsModalOpen, setAttachmentsModalOpen] = useState(false);
  const openAttachmentsModal = useCallback(() => setAttachmentsModalOpen(true), []);
  const closeAttachmentsModal = useCallback(() => setAttachmentsModalOpen(false), []);

  const [filesModalOpen, setFilesModalOpen] = useState(false);
  const openFilesModal = () => {
    closeAttachmentsModal();
    setFilesModalOpen(true);
  };
  const closeFilesModal = () => {
    openAttachmentsModal();
    setFilesModalOpen(false);
  };

  const handleFilesSelected = async (data, stopLoading) => {
    const selectedImages = data.images.filter(item => item.selected === true);
    const selectedFiles = data.files.filter(item => item.selected === true);

    const uploadedFiles = await uploadFiles({
      images: selectedImages,
      files: selectedFiles,
      tenantId
    });

    await uploadFileList(uploadedFiles, selectedImages, selectedFiles);
    setAttachments([...selectedImages, ...selectedFiles]);
    closeAttachmentsModal();
    stopLoading();
  };

  const handleFilesSelectedFromFilesManagement = async data => {
    const uploadedFiles = await uploadFiles({
      images: [],
      files: data.map(file => ({ file })),
      tenantId
    });

    await uploadFileList(uploadedFiles, images, files);
    setAttachments([...attachments, ...uploadedFiles]);
    closeFilesModal();
    closeAttachmentsModal();
  };

  return (
    <div css={styles.root}>
      <Title noTitle={noTitle} smallType={smallType} />
      <ImagesCarousel
        images={images}
        chunkSize={chunkSize}
        downloadable={downloadable}
        imageHeight={imageHeight}
        imageWidth={imageWidth}
      />
      <FilesList files={files} downloadable={downloadable} />
      <EmptyAttachmentsList images={images} files={files} noTitle={noTitle} />
      {!noAddButton && (
        <AddAttachmentButton onClick={openAttachmentsModal} smallButton={smallButton} />
      )}
      <AddFilesModal
        open={filesModalOpen}
        onClose={closeFilesModal}
        onFilesSelected={handleFilesSelectedFromFilesManagement}
      />
      <AddAttachmentsModal
        open={attachmentsModalOpen}
        images={images}
        files={files}
        onFilesSelected={handleFilesSelected}
        onOpenFilesModal={openFilesModal}
        onClose={closeAttachmentsModal}
      />
    </div>
  );
};

Attachments.propTypes = {
  attachedFiles: PropTypes.array,
  smallType: PropTypes.bool,
  smallButton: PropTypes.bool,
  noTitle: PropTypes.bool,
  downloadable: PropTypes.bool,
  noAddButton: PropTypes.bool,
  chunkSize: PropTypes.number,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  uploadFileList: PropTypes.func.isRequired
};

Attachments.defaultProps = {
  attachedFiles: [],
  smallType: false,
  noTitle: false,
  smallButton: false,
  downloadable: false,
  noAddButton: false,
  chunkSize: 5,
  imageWidth: 200,
  imageHeight: 180
};

export default Attachments;
