// TODO: split this into /constants dir with multiple files. Have hit tipping point
/* eslint-disable global-require */
import { bool, shape, string, number, any } from 'prop-types';
import { theme } from '@buildhero/sergeant';
import ENV from 'configs/env';
import configForEnvironment from 'configs/aws-exports';
import EnhancedEnum from 'utils/EnhancedEnum';
import * as Icons from './icons';

// config

export const CONFIG = configForEnvironment(ENV);

// props

export const ERROR_PROP = shape({
  message: string
});

export const RANGE_PROP = shape({
  start: any,
  end: any
});

// visit

export const VisitActions = new EnhancedEnum({
  CANCEL: 'Cancel',
  CLOSE: 'Close',
  COMPLETE: 'Complete',
  CONTINUE_IN_NEW_VISIT: 'Continue in New Visit',
  CONVERT: 'Convert',
  DRAG_AND_DROP: 'Drag and Drop',
  PAUSE: 'Pause',
  PUT_ON_HOLD: 'Put on Hold',
  RELEASE_TO_TECHS: 'Release to Techs',
  REOPEN: 'Reopen',
  RESCHEDULE: 'Reschedule',
  SCHEDULE: 'Schedule',
  STAY_ON_HOLD: 'Stay on Hold',
  TECH_PUT_ON_HOLD: 'Tech Put on Hold',
  TRAVEL: 'Travel',
  UPDATE_ON_HOLD: 'Update on Hold',
  UPDATE_PAUSED: 'Update Paused',
  UPDATE_SCHEDULED: 'Update Scheduled',
  UPDATE_TRAVELING: 'Update Traveling',
  UPDATE_UNASSIGNED: 'Update Unassigned',
  UPDATE_VISIT: 'Update Visit',
  UPDATE_WORKING: 'Update Working',
  WORK: 'Work',
  WORK_DONE: 'Work Done'
});

export const VisitStatusAttributes = {
  displayPriority: 'displayPriority',
  displayValue: 'displayValue',
  mapPin: 'mapPin',
  primaryColor: 'primaryColor',
  secondaryColor: 'secondaryColor',
  serverValue: 'serverValue',
  showOnBoard: 'showOnBoard',
  showOnMap: 'showOnMap'
};

const {
  displayPriority,
  displayValue,
  mapPin,
  primaryColor,
  secondaryColor,
  serverValue,
  showOnBoard,
  showOnMap
} = VisitStatusAttributes;

export const VisitStatuses = new EnhancedEnum({
  UNASSIGNED: {
    [displayPriority]: 1,
    [displayValue]: 'Unassigned',
    [mapPin]: require('./assets/images/pin.white.svg').default,
    [primaryColor]: theme.palette.grayscale(60),
    [secondaryColor]: theme.palette.grayscale(94),
    [serverValue]: 'Unassigned',
    [showOnBoard]: false,
    [showOnMap]: true
  },
  SCHEDULED: {
    [displayPriority]: 3,
    [displayValue]: 'Scheduled',
    [mapPin]: require('./assets/images/pin.gray.dark.svg').default,
    [primaryColor]: theme.palette.grayscale(60),
    [secondaryColor]: theme.palette.grayscale(94),
    [serverValue]: 'Scheduled',
    [showOnBoard]: true,
    [showOnMap]: true
  },
  TRAVELING: {
    [displayPriority]: 5,
    [displayValue]: 'Traveling',
    [mapPin]: require('./assets/images/pin.blue.dark.svg').default,
    [primaryColor]: theme.palette.support.blue.dark,
    [secondaryColor]: theme.palette.support.blue.light,
    [serverValue]: 'Traveling',
    [showOnBoard]: true,
    [showOnMap]: true
  },
  WORKING: {
    [displayPriority]: 6,
    [displayValue]: 'Working',
    [mapPin]: require('./assets/images/pin.blue.dark.svg').default,
    [primaryColor]: theme.palette.support.blue.dark,
    [secondaryColor]: theme.palette.support.blue.light,
    [serverValue]: 'Working',
    [showOnBoard]: true,
    [showOnMap]: true
  },
  ON_HOLD: {
    [displayPriority]: 2,
    [displayValue]: 'On Hold',
    [mapPin]: require('./assets/images/pin.orange.dark.svg').default,
    [primaryColor]: theme.palette.support.orange.dark,
    [secondaryColor]: theme.palette.support.orange.light,
    [serverValue]: 'On hold',
    [showOnBoard]: true,
    [showOnMap]: true
  },
  PAUSED: {
    [displayPriority]: 4,
    [displayValue]: 'Paused',
    [mapPin]: require('./assets/images/pin.yellow.dark.svg').default,
    [primaryColor]: theme.palette.support.yellow.dark,
    [secondaryColor]: theme.palette.support.yellow.light,
    [serverValue]: 'Paused',
    [showOnBoard]: true,
    [showOnMap]: true
  },
  CANCELED: {
    [displayPriority]: 8,
    [displayValue]: 'Canceled',
    [mapPin]: require('./assets/images/pin.red.dark.svg').default,
    [primaryColor]: theme.palette.support.red.dark,
    [secondaryColor]: theme.palette.support.red.light,
    [serverValue]: 'Canceled',
    [showOnBoard]: false,
    [showOnMap]: false
  },
  COMPLETE: {
    [displayPriority]: 7,
    [displayValue]: 'Complete',
    [mapPin]: require('./assets/images/pin.green.dark.svg').default,
    [primaryColor]: theme.palette.support.green.dark,
    [secondaryColor]: theme.palette.support.green.light,
    [serverValue]: 'Complete',
    [showOnBoard]: true,
    [showOnMap]: true
  },
  CLOSED: {
    [displayPriority]: 9,
    [displayValue]: 'Closed',
    [mapPin]: require('./assets/images/pin.red.dark.svg').default,
    [primaryColor]: theme.palette.support.red.dark,
    [secondaryColor]: theme.palette.support.red.light,
    [serverValue]: 'Closed',
    [showOnBoard]: true,
    [showOnMap]: false
  },
  CONVERTED: {
    [displayPriority]: 10,
    [displayValue]: 'Converted',
    [mapPin]: require('./assets/images/pin.green.dark.svg').default,
    [primaryColor]: theme.palette.support.green.dark,
    [secondaryColor]: theme.palette.support.green.light,
    [serverValue]: 'Converted',
    [showOnBoard]: true,
    [showOnMap]: false
  },
  // non-real status used to defensively code against bad visit data (i.e. no status)
  UNKNOWN: {
    [displayPriority]: 11,
    [displayValue]: 'Unknown',
    [mapPin]: require('./assets/images/pin.gray.dark.svg').default,
    [primaryColor]: theme.palette.grayscale(60),
    [secondaryColor]: theme.palette.grayscale(94),
    [serverValue]: '',
    [showOnBoard]: false,
    [showOnMap]: false
  }
});

export const ManDayStatuses = new EnhancedEnum({
  SCHEDULED: 'Scheduled',
  COMPLETE: 'Complete'
});

export const TechStatuses = new EnhancedEnum({
  INACTIVE: {
    displayValue: ''
  },
  TRAVELING: {
    serverValue: 'Traveling',
    displayValue: 'Traveling',
    icon: Icons.TechStatusTravelingIcon,
    color: theme.palette.support.blue.dark
  },
  WORKING: {
    serverValue: 'Working',
    displayValue: 'Working',
    icon: Icons.TechStatusWorkingIcon,
    color: theme.palette.support.blue.dark
  },
  ON_BREAK: {
    serverValue: 'OnABreak',
    displayValue: 'On-Break',
    icon: Icons.TechStatusOnBreakIcon,
    color: theme.palette.support.yellow.dark
  },
  PAUSED: {
    serverValue: 'Paused',
    displayValue: 'Paused',
    icon: Icons.TechStatusPausedIcon,
    color: theme.palette.support.yellow.dark
  },
  ON_HOLD: {
    serverValue: 'On hold',
    displayValue: 'On-Hold',
    icon: Icons.TechStatusOnHoldIcon,
    color: theme.palette.support.orange.dark
  },
  WORK_DONE: {
    serverValue: 'WorkDone',
    displayValue: 'Work Done',
    icon: Icons.TechStatusCompleteIcon,
    color: theme.palette.support.green.dark
  },
  COMPLETE: {
    serverValue: 'Complete',
    displayValue: 'Work Complete',
    icon: Icons.TechStatusCompleteIcon,
    color: theme.palette.support.green.dark
  }
});

export const STATUS_PROP = shape({
  key: string,
  value: shape({
    [displayPriority]: number,
    [displayValue]: string,
    [mapPin]: string,
    [serverValue]: string,
    [primaryColor]: string,
    [secondaryColor]: string,
    [showOnBoard]: bool,
    [showOnMap]: bool
  })
});

// event

export const EVENT_TYPES = {
  VISIT: 'visit',
  BILLABLE_EVENT: 'billableEvent',
  NON_BILLABLE_EVENT: 'nonBillableEvent',
  MAN_DAY: 'manDay'
};

export const EVENT_NAMES = {
  [EVENT_TYPES.VISIT]: 'Visit',
  [EVENT_TYPES.BILLABLE_EVENT]: 'Billable Event',
  [EVENT_TYPES.NON_BILLABLE_EVENT]: 'Non-Billable Event',
  [EVENT_TYPES.MAN_DAY]: 'Project Visit'
};

export const EventEntities = new EnhancedEnum({
  BILLABLE_EVENT: {
    serverValue: 'BillableNonVisit',
    clientValue: 'billableEvent'
  },
  NON_BILLABLE_EVENT: {
    serverValue: 'NonBillable',
    clientValue: 'nonBillableEvent'
  },
  VISIT: {
    clientValue: 'visit'
  },
  CONTINUED_VISIT: {
    clientValue: 'continuedVisit'
  },
  MAN_DAY: {
    serverValue: 'manDay',
    clientValue: 'manDay'
  }
});

// time

export const DEFAULT_VISIT_DURATION_IN_MIN = 60;

export const DEFAULT_EVENT_DURATION_IN_MIN = 60;

export const TIME_FORMAT = 'h:mm a';

export const DURATION_FORMAT = 'H:mm';

export const ONE_DAY_UNIX_DURATION = 86400;

export const JobTypes = new EnhancedEnum({
  JOB: {
    serverValue: 'Job',
    displayValue: 'Job'
  },
  MAINTENANCE: {
    serverValue: 'Maintenance',
    displayValue: 'Maintenance'
  }
});

export const PriorityTypes = new EnhancedEnum({
  URGENT: {
    serverValue: 'Urgent'
  },
  HIGH: {
    serverValue: 'High'
  },
  NORMAL: {
    serverValue: 'Normal'
  },
  LOW: {
    serverValue: 'Low'
  },
  NONE: {
    serverValue: 'None'
  }
});

// job

export const JOB_COMPLETE_STATUS = 'Complete';

export const DEFAULT_START_TIME = 8;
