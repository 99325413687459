import React from 'react';

import Box from '@material-ui/core/Box';
import { DetailsView, FileManagerComponent, Inject } from '@syncfusion/ej2-react-filemanager';
import { func, string } from 'prop-types';

import ErrorBoundaries from 'scenes/Error';

import {
  beforeDownload,
  beforeImageLoad,
  beforeSend,
  getFilesApiUrl,
  useJwtToken
} from '../FileManagement.hooks';

import FileViewer from '../FileViewer';

import { useStyles } from './SelectOnlyFileManager.styles';

const SelectOnlyFileManager = ({ projectId, onSelectFile, onUnselectFile }) => {
  const styles = useStyles();
  const jwtToken = useJwtToken();
  const fsApiUrl = getFilesApiUrl(projectId);
  return (
    <ErrorBoundaries>
      <Box css={styles.root}>
        <div className="control-section">
          {jwtToken && (
            <FileViewer>
              {handleFileOpen => (
                <FileManagerComponent
                  id="select_file_storage"
                  ajaxSettings={{
                    url: fsApiUrl
                  }}
                  contextMenuSettings={{
                    folder: ['Open'],
                    file: ['Open'],
                    layout: ['SortBy', 'View', 'Refresh', '|', 'SelectAll']
                  }}
                  beforeSend={beforeSend(jwtToken)}
                  beforeDownload={beforeDownload(jwtToken)}
                  beforeImageLoad={beforeImageLoad}
                  fileOpen={handleFileOpen}
                  fileSelect={event => {
                    if (!event?.fileDetails?.isFile) {
                      return;
                    }

                    const { name, s3Url } = event?.fileDetails || {};
                    if (event.action === 'select') {
                      onSelectFile({ name, s3Url });
                    } else if (event.action === 'unselect') {
                      onUnselectFile({ name, s3Url });
                    }
                  }}
                >
                  <Inject services={[DetailsView]} />
                </FileManagerComponent>
              )}
            </FileViewer>
          )}
        </div>
      </Box>
    </ErrorBoundaries>
  );
};

SelectOnlyFileManager.propTypes = {
  projectId: string.isRequired,
  onSelectFile: func.isRequired,
  onUnselectFile: func.isRequired
};

export default SelectOnlyFileManager;
