/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';
import { CompanyService } from 'services/core';

export async function getPurchaseOrders() {
  return await instance
    .get(`purchaseorder`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getNextPurchaseOrderNumber() {
  return await instance
    .get(`purchaseorder/counter`)
    .then(({ data }) => {
      return data.toString();
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getAllPurchaseOrders(include = '*', options = { limit: 10, offset: 0 }) {
  return await instance
    .get(`purchaseorder`, {
      params: { include, ...options }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getPurchaseOrderById(id, noInclude) {
  return await instance
    .get(`purchaseorder/${id}`, {
      params: {
        // eslint-disable-next-line prettier/prettier
        include: noInclude
          ? []
          : [
              'purchaseOrderTags',
              'purchaseOrderLines',
              'purchaseOrderReceipts',
              'purchaseOrderBills',
              'department',
              'vendor',
              'poType',
              'assignedTo',
              'job',
              'project',
              'taxRate',
              'note',
              'quote',
              'versionedQuote',
              'shipToEmployee'
            ]
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getPurchaseOrderByProjectId(projectId) {
  return await instance
    .get(`purchaseorder/byproject/${projectId}`, {
      params: {
        include: ['job', 'purchaseOrderLines', 'purchaseOrderReceipts', 'purchaseOrderBills']
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getPurchaseOrderByJobId(jobId) {
  return await instance
    .get(`purchaseorder/byjob/${jobId}`, {
      params: {
        include: ['purchaseOrderTags', 'assignedTo']
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function purchaseOrderCreate(purchaseOrder) {
  return await instance
    .post('purchaseorder', purchaseOrder, {
      params: { include: ['purchaseOrderLines', 'purchaseOrderTags'] }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function purchaseOrderChange(id, purchaseOrder) {
  return await instance
    .put(`purchaseorder/${id}`, purchaseOrder)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function purchaseOrderSearch(term, searchColumns, relations) {
  let relationString;
  if (relations) {
    relationString = relations.join(',');
  }
  return await instance
    .post(`purchaseorder/search/${term}${relationString ? `?include=${relationString}` : ''}`, {
      searchColumns: searchColumns || ['poNumber']
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}
export async function fetchPOTags(user) {
  try {
    const sortKey = `${user.tenantId}_Company_${user.tenantCompanyId}`;
    const { data } = await new CompanyService().getCustomFieldsDefinitions(user.tenantId, sortKey);
    if (data?.getCompany) {
      const purchaseOrderTags = data.getCompany.purchaseOrderTags.items.map(tag => ({
        label: tag.tagName,
        value: { tagName: tag.tagName, id: tag.id }
      }));
      return purchaseOrderTags;
    }
  } catch (err) {
    console.error({ err });
  }
}

export async function getPurchaseOrderPdfData(purchaseOrderId) {
  try {
    const response = await instance.get(`purchaseorder/${purchaseOrderId}/pdfdata/`);
    return response.data;
  } catch (err) {
    console.error({ err });
  }
}
