import moment from 'moment';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';

import { dispatch, getState } from 'redux/store';
import { snackbarOn } from 'redux/actions/globalActions';
import { Logger } from 'services/Logger';

import {
  DEFAULT_EVENT_DURATION_IN_MIN,
  VisitActions,
  VisitStatuses
} from '@dispatch/Dispatch.constants';

import { watchedQueries } from '@dispatch/Dispatch.store';

export const prepareNonVisitData = eventData => {
  const { scheduledFor, actualDuration } = eventData;
  const from = scheduledFor ?? moment().unix();
  const to = scheduledFor + (actualDuration || DEFAULT_EVENT_DURATION_IN_MIN) * 60;
  return {
    eventName: '',
    from,
    to
  };
};

export const transformNonVisitEventData = data => {
  const { name, plannedStartTimeUTC, plannedEndTimeUTC, ...event } =
    data?.getNonVisitEventById || {};

  return {
    ...event,
    eventName: name,
    from: plannedStartTimeUTC,
    to: plannedEndTimeUTC
  };
};

export const getTenantIdAndSortKey = () => {
  const { tenantId, tenantCompanyId } = getState().user;
  const sortKey = `${tenantId}_Company_${tenantCompanyId}`;
  return {
    tenantId,
    sortKey
  };
};

export { handleError } from 'utils/handleError';

const updateContinueInNewVisit = ({ cache, updatedVisit, originalVisit }) => {
  const previousVisit = {
    ...originalVisit.serverVisit,
    status: VisitStatuses.CLOSED.value.serverValue
  };

  if (watchedQueries.useDispatchBoardVisits) {
    const prevBoardVisits = cache.readQuery(watchedQueries.useDispatchBoardVisits);
    if (prevBoardVisits) {
      const { variables } = watchedQueries.useDispatchBoardVisits;
      let newItems = prevBoardVisits?.scheduledVisits?.visitsInRange?.items || [];

      const isUpdatedInRange = moment
        .unix(updatedVisit.scheduledFor)
        .isBetween(moment.unix(variables.startTime), moment.unix(variables.endTime), 'day', '[]');

      newItems = isUpdatedInRange
        ? [updatedVisit, ...newItems.filter(visit => visit.id !== updatedVisit.id)]
        : [...newItems.filter(visit => visit.id !== updatedVisit.id)];

      const isPreviousInRange = moment
        .unix(previousVisit.scheduledFor)
        .isBetween(moment.unix(variables.startTime), moment.unix(variables.endTime), 'day', '[]');

      newItems = isPreviousInRange
        ? [previousVisit, ...newItems.filter(visit => visit.id !== previousVisit.id)]
        : [...newItems.filter(visit => visit.id !== previousVisit.id)];

      const newData = {
        scheduledVisits: {
          ...prevBoardVisits.scheduledVisits,
          visitsInRange: {
            ...prevBoardVisits.scheduledVisits.visitsInRange,
            items: newItems
          }
        }
      };

      const dispatchTechs = cache.readQuery(watchedQueries.useDispatchTechs);

      cache.writeQuery({
        ...watchedQueries.useDispatchBoardVisits,
        data: newData
      });

      cache.writeQuery({ ...watchedQueries.useDispatchTechs, data: dispatchTechs });
    }
  }
};

// updatedVisit: visitDetailsFragment
export const updateWatchedVisitQueries = ({
  cache,
  updatedVisit,
  transitionAction,
  originalVisit
}) => {
  if (!updatedVisit) return;

  if (transitionAction === VisitActions.CONTINUE_IN_NEW_VISIT.key) {
    return updateContinueInNewVisit({ cache, updatedVisit, originalVisit });
  }

  // update board visits
  if (watchedQueries.useDispatchBoardVisits) {
    const prevBoardVisits = cache.readQuery(watchedQueries.useDispatchBoardVisits);
    if (prevBoardVisits) {
      const { variables } = watchedQueries.useDispatchBoardVisits;

      const isInRange = moment
        .unix(updatedVisit.scheduledFor)
        .isBetween(moment.unix(variables.startTime), moment.unix(variables.endTime), 'day', '[]');

      const prevVisitsItems = prevBoardVisits?.scheduledVisits?.visitsInRange?.items || [];

      const newItems = isInRange
        ? [updatedVisit, ...prevVisitsItems.filter(visit => visit.id !== updatedVisit.id)]
        : [...prevVisitsItems.filter(visit => visit.id !== updatedVisit.id)];

      const newData = {
        scheduledVisits: {
          ...prevBoardVisits.scheduledVisits,
          visitsInRange: {
            ...prevBoardVisits.scheduledVisits.visitsInRange,
            items: newItems
          }
        }
      };

      // Optimistic writing of the board visits inadvertently updates dispatch techs
      // to an incomplete fragment. To fix we must read and restore the original value.
      const dispatchTechs = cache.readQuery(watchedQueries.useDispatchTechs);

      cache.writeQuery({
        ...watchedQueries.useDispatchBoardVisits,
        data: newData
      });

      cache.writeQuery({ ...watchedQueries.useDispatchTechs, data: dispatchTechs });
    }
  }

  // update table visits
  //
  // The following code updates the cache correctly, but the current responsive table is
  // incapable of optimistic ui. Saving the following code for the useQuery table re-write.
  //
  // if (watchedQueries.listDataService) {
  //   const currentTabStatus = watchedQueries.listDataService?.variables?.filter?.stringFilters?.find?.(
  //     stingFilter => stingFilter?.fieldName === 'status'
  //   )?.filterInput?.eq;
  //
  //   const isCurrentTabStatus = !currentTabStatus || currentTabStatus === updatedVisit.status;
  //
  //   if (isCurrentTabStatus) {
  //     const prevTableVisits = cache.readQuery(watchedQueries.listDataService);
  //     if (prevTableVisits) {
  //       const updatedTableVisit = transformVisitDetailToTable(updatedVisit);
  //
  //       const newData = {
  //         visitsListData: {
  //           ...prevTableVisits.visitsListData,
  //           items: patchArray(prevTableVisits.visitsListData.items, updatedTableVisit)
  //         }
  //       };
  //
  //       cache.writeQuery({ ...watchedQueries.listDataService, data: newData });
  //     }
  //   }
  // }
};

export const updateWatchedNonVisitQueries = (cache, updatedNonVisit) => {
  if (watchedQueries.useDispatchBoardNonVisitEvents) {
    const prevNonVisits = cache.readQuery(watchedQueries.useDispatchBoardNonVisitEvents);

    if (prevNonVisits) {
      const { variables } = watchedQueries.useDispatchBoardNonVisitEvents;

      const isInRange = moment
        .unix(updatedNonVisit.plannedStartTimeUTC)
        .isBetween(moment.unix(variables.startTime), moment.unix(variables.endTime), 'day', '[]');

      const prevNonVisitsItems = prevNonVisits?.getCompany?.nonVisitEventsView?.items || [];

      const newItems = isInRange
        ? [updatedNonVisit, ...prevNonVisitsItems.filter(visit => visit.id !== updatedNonVisit.id)]
        : [...prevNonVisitsItems.filter(visit => visit.id !== updatedNonVisit.id)];

      const newData = {
        getCompany: {
          ...prevNonVisits.getCompany,
          nonVisitEventsView: {
            ...prevNonVisits.getCompany.nonVisitEventsView,
            items: newItems
          }
        }
      };

      cache.writeQuery({ ...watchedQueries.useDispatchBoardNonVisitEvents, data: newData });
    }
  }
};

const isManDayInRange = ({ manDay, startDateTime, endDateTime }) => {
  const range = moment.range(moment.unix(startDateTime), moment.unix(endDateTime));
  const manDayRange = moment.range(
    moment.unix(manDay.startDateTime),
    moment.unix(manDay.endDateTime)
  );

  return range.overlaps(manDayRange);
};

export const getManDayUpdatedItems = ({ prevItems, query, data }) => {
  const {
    variables: { startDateTime, endDateTime }
  } = query;
  const updatedManDays = isArray(data) ? data : [data];
  const inRangeManDays = updatedManDays.filter(
    manDay => isManDayInRange({ manDay, startDateTime, endDateTime }) && manDay?.isActive
  );
  const updatedManDayIds = updatedManDays.reduce(
    (result, manDay) => ({
      ...result,
      [manDay.id]: true
    }),
    {}
  );
  return [...inRangeManDays, ...prevItems.filter(item => !updatedManDayIds[item.id])];
};

export const updateManDayQuery = (prev, { subscriptionData }) => {
  const data = subscriptionData?.data?.manDayUpdateNotification;
  const query = watchedQueries.useDispatchBoardManDayItems;
  if (!query || !data) {
    return prev;
  }
  const next = getManDayUpdatedItems({
    prevItems: prev?.listManDay || [],
    query,
    data
  });

  return {
    listManDay: next
  };
};

export const updateWatchedManDayVisits = (cache, data) => {
  const query = watchedQueries.useDispatchBoardManDayItems;
  if (!query || !data) {
    return;
  }

  const prevItems = cache.readQuery(query)?.listManDay || [];
  const nextItems = getManDayUpdatedItems({ prevItems, query, data });

  cache.writeQuery({
    ...watchedQueries.useDispatchBoardManDayItems,
    data: {
      listManDay: nextItems
    }
  });
};

export const isToday = date => {
  if (!date) {
    return true;
  }

  return moment(date, 'MM-DD-YYYY').isSame(moment(), 'day');
};

export const visitsHaveSameSchedule = (firstVisit, secondVisit) => {
  return (
    firstVisit.actualDuration === secondVisit.actualDuration &&
    firstVisit.primaryTechId === secondVisit.primaryTechId &&
    firstVisit.scheduledFor === secondVisit.scheduledFor &&
    firstVisit.extraTechsNumber === secondVisit.extraTechsNumber &&
    isEqual(firstVisit.extraTechs?.sort(), secondVisit.extraTechs?.sort())
  );
};
