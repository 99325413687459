import React, { useEffect, useState } from 'react';

import { array, bool, func, shape } from 'prop-types';

import SergeantModal from 'components/SergeantModal';

import { isImageFile } from '../../Attachments.helpers';
import FilesList from '../FilesList';
import ImagesCarousel from '../ImagesCarousel';

import attachmentsLayout from './AddAttachmentsModal.layout';
import AddAttachmentsButton from './components/AddAttachmentsButton';
import AddAttachmentsFromFilesButton from './components/AddAttachmentsFromFilesButton';
import SectionTitle from './components/SectionTitle';

const FormComponentPropTypes = {
  field: shape({
    value: shape({
      images: array.isRequired
    }).isRequired
  }).isRequired,
  options: shape({
    onSelectImage: func.isRequired
  }).isRequired
};

const ImagesCarouselWrapper = ({ field, options }) => (
  <ImagesCarousel
    images={field.value.images}
    imageWidth={172}
    imageHeight={139}
    chunkSize={4}
    margin={15}
    timeout={200}
    titleText="Select Field Photos"
    selectable
    onSelect={options.onSelectImages}
  />
);
ImagesCarouselWrapper.propTypes = FormComponentPropTypes;

const FilesListWrapper = ({ field, options }) => (
  <FilesList
    files={field.value.files}
    selectable
    titleText="Select Files"
    onSelect={options.onSelectFiles}
  />
);
FilesListWrapper.propTypes = FormComponentPropTypes;

const AddAttachmentsModal = ({
  open,
  images,
  files,
  onFilesSelected,
  onOpenFilesModal,
  onClose
}) => {
  const [selectedImages, setSelectedImages] = useState(images);
  const [selectedFiles, setSelectedFiles] = useState(files);

  useEffect(() => setSelectedImages(images), [images]);
  useEffect(() => setSelectedFiles(files), [files]);

  const handleFieldChange = event => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const fileData = {
      file,
      name: file.name,
      description: '',
      newName: '',
      selected: true
    };

    if (isImageFile(file)) {
      setSelectedImages([
        ...selectedImages,
        {
          ...fileData,
          url: URL.createObjectURL(file)
        }
      ]);
    } else {
      setSelectedFiles([...selectedFiles, fileData]);
    }
  };

  const disablePrimaryButton =
    !selectedImages.length && !selectedFiles.length && !images.length && !files.length;

  const handleSelectImages = newSelectedImages => {
    const newSelectedImageUrls = newSelectedImages.map(({ url }) => url);
    setSelectedImages(
      selectedImages.map(image => ({
        ...image,
        selected: newSelectedImageUrls.includes(image.url)
      }))
    );
  };

  const handleSelectFiles = newSelectedFiles => {
    const newSelectedFileUrls = newSelectedFiles.map(({ fileUrl }) => fileUrl);
    setSelectedFiles(
      selectedFiles.map(file => ({
        ...file,
        selected: newSelectedFileUrls.includes(file.fileUrl)
      }))
    );
  };

  return (
    <SergeantModal
      open={open}
      title="Add Attachment(s)"
      customPrimaryButtonLabel="Add Selected Attachment(s)"
      data={{ images: selectedImages, files: selectedFiles }}
      dataType="attachment"
      mode="activate"
      layout={attachmentsLayout({
        onSelectImages: handleSelectImages,
        onSelectFiles: handleSelectFiles
      })}
      handlePrimaryAction={onFilesSelected}
      handleClose={onClose}
      maxWidth={859}
      customComponents={{
        SectionTitle,
        ImagesCarousel: ImagesCarouselWrapper,
        FilesList: FilesListWrapper,
        AddAttachmentsButton: () => <AddAttachmentsButton onFilesChange={handleFieldChange} />,
        AddAttachmentsFromFilesButton: () => (
          <AddAttachmentsFromFilesButton onClick={onOpenFilesModal} />
        )
      }}
      disablePrimaryButton={disablePrimaryButton}
      alignCloseRight
    />
  );
};

AddAttachmentsModal.propTypes = {
  open: bool.isRequired,
  images: array.isRequired,
  files: array.isRequired,
  onFilesSelected: func.isRequired,
  onOpenFilesModal: func.isRequired,
  onClose: func.isRequired
};

export default AddAttachmentsModal;
