import React, { useEffect, useMemo, useRef, useState } from 'react';
import { number, string, shape, func } from 'prop-types';
import MarkerWithLabel from 'react-google-maps/lib/components/addons/MarkerWithLabel';

import { Typography } from '@buildhero/sergeant';

import CompanyPin from '@dispatch/assets/images/pin.black.svg';

import { useGeocoder } from '../../MapView.hooks';

import { markerShape } from '../PropertyMarker/PropertyMarker.constants';
import { selectAddressString } from '../PropertyMarker/PropertyMarker.selectors';
import { useStyles } from '../PropertyMarker/PropertyMarker.styles';

export const useMarkerIcon = ({ scale = 1 }) => {
  const { Size, Point, MarkerImage } = google.maps;
  const anchor = useRef(new Point(23 * scale, 40 * scale));
  const origin = useRef(new Point(0, 0));
  const size = useRef(new Size(46 * scale, 60 * scale));
  // const size = useRef(new Size(46 * scale, 60 * scale));
  const mapPin = CompanyPin;

  return useMemo(() => {
    return new MarkerImage(
      mapPin,
      null /* size is determined at runtime */,
      origin.current,
      anchor.current,
      size.current
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const ComapnyMarker = ({ company, updateBounds }) => {
  const scale = 0.6;
  const classes = useStyles();
  const { Point } = google.maps;
  const labelAnchor = useRef(new Point(80, -20 * scale));
  const [position, setPosition] = useState(company.position);
  const { loading, geocode } = useGeocoder();
  const icon = useMarkerIcon({ scale });

  useEffect(() => {
    setPosition(company.position);
  }, [company.position]);

  const handlePositionUpdate = newPosition => {
    setPosition(newPosition);
    updateBounds(newPosition);
  };

  if (!(position?.lat && position?.lng)) {
    if (!loading) geocode(selectAddressString(company.address), handlePositionUpdate);
    return null;
  }

  return (
    <MarkerWithLabel
      position={position}
      labelAnchor={labelAnchor.current}
      labelStyle={{ width: '160px' }}
      shape={markerShape}
      icon={icon}
    >
      <div className={classes.labelWrapper}>
        <Typography className={classes.label}>{company.companyName}</Typography>
      </div>
    </MarkerWithLabel>
  );
};

const ADDRESS_PROP = shape({
  addressLine1: string,
  addressLine2: string,
  addressType: string,
  city: string,
  latitude: number,
  longitude: number,
  state: string,
  zipcode: string
});

ComapnyMarker.propTypes = {
  company: shape({
    id: string,
    address: ADDRESS_PROP,
    companyName: string,
    position: shape({
      lat: number,
      lng: number
    })
  }).isRequired,
  updateBounds: func.isRequired
};

export default ComapnyMarker;
