import gql from 'graphql-tag';
import updateJobTags from 'services/core/graphql/jobs/mutations/updateJobTags';
import AmplifyService from 'services/AmplifyService';
import getNotes from '../../graphql/queries/getNotes';
import getJobDetails from '../../graphql/jobs/queries/getJobDetails';
import getJobDetailsByJobNumber from '../../graphql/jobs/queries/getJobDetailsByNumber';
import getVisitsByJob from '../../graphql/jobs/queries/getVisitsByJob';
import getVisitsByJobNumber from '../../graphql/jobs/queries/getVisitsByJobNumber';
import getAttachmentsByJob from '../../graphql/jobs/queries/getAttachmentsByJob';
import getAttachmentsByJobNumber from '../../graphql/jobs/queries/getAttachmentsByJobNumber';
import getPropertyAssetsByJob from '../../graphql/jobs/queries/getPropertyAssetsByJob';
import getPropertyAssetsByJobNumber from '../../graphql/jobs/queries/getPropertyAssetsByJobNumber';
import getPropertyAttachmentsByJob from '../../graphql/jobs/queries/getPropertyAttachmentsByJob';
import getPropertyAttachmentsByJobNumber from '../../graphql/jobs/queries/getPropertyAttachmentsByJobNumber';
import getPropertyInfoByJob from '../../graphql/jobs/queries/getPropertyInfoByJob';
import getPropertyInfoByJobNumber from '../../graphql/jobs/queries/getPropertyInfoByJobNumber';
import getJobDetailsInfo from '../../graphql/jobs/queries/getJobDetailsInfo';
import getJobDetailsInfoByJobNumber from '../../graphql/jobs/queries/getJobDetailsInfoByJobNumber';
import getInvoicesByJob from '../../graphql/jobs/queries/getInvoicesByJob';
import getInvoicesByJobNumber from '../../graphql/jobs/queries/getInvoicesByJobNumber';
import getTimesheetsByJobNumber from '../../graphql/jobs/queries/getTimesheetsByJobNumber';
import getJobNumber from '../../graphql/jobs/queries/getJobNumber';
import getCurrentCounterJob from '../../graphql/jobs/queries/getCurrentCounterJob';
import getJobTypeCurrentCounterJob from '../../graphql/jobs/queries/getJobTypeCurrentCounterJob';
import getAllAuditLogsByJobNumber from '../../graphql/jobs/queries/getAllAuditLogsByJobNumber';
import checkCustomJobNumberExists from '../../graphql/jobs/queries/checkCustomJobNumberExists';
import createJobSchema from '../../mutation-schema/jobs/create-job-schema';
import attachmentSchema from '../../mutation-schema/common/attachment-schema';
import jobUpdateNotification from '../../graphql/jobs/subscriptions/jobUpdateNotification';
import jobNoteUpdateNotification from '../../graphql/jobs/subscriptions/jobNoteUpdateNotification';
import jobAttachmentUpdateNotification from '../../graphql/jobs/subscriptions/jobAttachmentUpdateNotification';
import addJobsToCustomerProperty from '../../graphql/jobs/mutations/addJobsToCustomerProperty';
import updateJob from '../../graphql/jobs/mutations/updateJob';
import updateJobAndRelated from '../../graphql/jobs/mutations/updateJobAndRelated';
import saveJobNotes from '../../graphql/jobs/mutations/saveJobNotes';
import saveJobAttachments from '../../graphql/jobs/mutations/saveJobAttachments';
import noteMutationSchema from '../../mutation-schema/common/note-schema';
import getPONumber from '../../graphql/jobs/mutations/generatePO';
import addPurchaseOrderNumberToJob from '../../graphql/jobs/mutations/addPurchaseOrderNumberToJob';
import addJobTasksToJob from '../../graphql/jobs/mutations/addJobTasksToJob';
import getReviewReportPartsByJob from '../../graphql/jobs/queries/getReviewReportPartsByJob';
import getReviewReportPartsByJobNumber from '../../graphql/jobs/queries/getReviewReportPartsByJobNumber';
import getReviewReportPhotosByJob from '../../graphql/jobs/queries/getReviewReportPhotosByJob';
import getReviewReportPhotosByJobNumber from '../../graphql/jobs/queries/getReviewReportPhotosByJobNumber';
import getReviewReportReceiptsByJob from '../../graphql/jobs/queries/getReviewReportReceiptsByJob';
import getReviewReportReceiptsByJobNumber from '../../graphql/jobs/queries/getReviewReportReceiptsByJobNumber';

import addNotesToJob from '../../graphql/jobs/mutations/addNotesToJob';

import getAllAttachmentsByJob from '../../graphql/jobs/queries/getAllAttachmentsByJob';
import getAllAttachmentsByJobNumber from '../../graphql/jobs/queries/getAllAttachmentsByJobNumber';
import getAllTasksByJobNumber from '../../graphql/jobs/queries/getAllTasksByJobNumber';

import getFormDataByJob from '../../graphql/jobs/queries/getFormDataByJob';
import getFormDataByJobNumber from '../../graphql/jobs/queries/getFormDataByJobNumber';

import getPONumberForJob from '../../graphql/jobs/queries/getPONumberForJob';
import updateJobStatus from '../../graphql/jobs/mutations/updateJobStatus';
import getInvoiceInfoFromJob from '../../graphql/jobs/queries/getInvoiceInfoFromJob';

import getJobReportForVisitsByJobNumber from '../../graphql/jobs/queries/getJobReportForVisitsByJobNumber';
import SubscriptionClient from '../helper';
import deleteJobJobTag from '../../graphql/jobs/mutations/deleteJobJobTag';
import getBillItemsByJobId from '../../graphql/jobs/queries/getBillItemsByJobId';
import getPartsPurchasingTablesData from '../../graphql/jobs/queries/getPartsPurchasingTablesData';
import getTechMultipleVisitCost from '../../graphql/jobs/queries/getTechMultipleVisitCost';
import addAttachmentsToJob from '../../graphql/jobs/mutations/addAttachmentsToJob';

export default class JobService {
  constructor() {
    this.api = AmplifyService.appSyncClient();
    this.subscriptionClient = SubscriptionClient.getClient(AmplifyService.config);
  }

  getJobDetails = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getJobDetails, params);
    return response;
  };

  getJobDetailsByJobNumber = async jobNumber => {
    const params = {
      jobNumber
    };

    const response = await this.api.query(getJobDetailsByJobNumber, params);
    return response;
  };

  getPartsPurchasingTablesData = async (jobId, jobSortKey) => {
    const params = {
      jobId,
      jobSortKey
    };
    const response = await this.api.query(getPartsPurchasingTablesData, params);
    return response;
  };

  getCurrentCounterJob = async () => {
    const response = await this.api.client.query({
      query: getCurrentCounterJob,
      fetchPolicy: 'network-only'
    });
    return response;
  };

  getJobTypeCurrentCounterJob = async (jobTypeId, paritionKey) => {
    const response = await this.api.client.query({
      query: getJobTypeCurrentCounterJob,
      variables: {
        jobTypeId,
        paritionKey
      },
      fetchPolicy: 'network-only'
    });
    return response;
  };

  getJobNumber = async () => {
    const response = await this.api.client.query({
      query: getJobNumber,
      fetchPolicy: 'network-only'
    });
    return response;
  };

  getPONumber = async () => {
    const response = await this.api.client.query({
      query: getPONumber,
      fetchPolicy: 'network-only'
    });
    return response;
  };

  addPurchaseOrderNumberToJob = async values => {
    const response = await this.api.mutate(addPurchaseOrderNumberToJob, values);
    return response;
  };

  getPropertyInfoByJob = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getPropertyInfoByJob, params);
    return response;
  };

  getPropertyInfoByJobNumber = async jobNumber => {
    const params = {
      jobNumber
    };

    const response = await this.api.query(getPropertyInfoByJobNumber, params);
    return response;
  };

  getJobDetailsInfo = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getJobDetailsInfo, params);
    return response;
  };

  getJobDetailsInfoByJobNumber = async jobNumber => {
    const params = {
      jobNumber
    };

    const response = await this.api.query(getJobDetailsInfoByJobNumber, params);
    return response;
  };

  getVisitsByJob = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getVisitsByJob, params);
    return response;
  };

  getVisitsByJobNumber = async jobNumber => {
    const params = {
      jobNumber
    };

    const response = await this.api.query(getVisitsByJobNumber, params);
    return response;
  };

  getAttachmentsByJob = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getAttachmentsByJob, params);
    return response;
  };

  getAttachmentsByJobNumber = async jobNumber => {
    const params = {
      jobNumber
    };

    const response = await this.api.query(getAttachmentsByJobNumber, params);
    return response;
  };

  getReviewReportPartsByJob = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getReviewReportPartsByJob, params);
    return response;
  };

  getReviewReportPartsByJobNumber = async jobNumber => {
    const params = {
      jobNumber
    };

    const response = await this.api.query(getReviewReportPartsByJobNumber, params);
    return response;
  };

  getReviewReportPhotosByJob = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getReviewReportPhotosByJob, params);
    return response;
  };

  getReviewReportPhotosByJobNumber = async jobNumber => {
    const params = {
      jobNumber
    };

    const response = await this.api.query(getReviewReportPhotosByJobNumber, params);
    return response;
  };

  getAllAttachmentsByJob = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getAllAttachmentsByJob, params);
    return response;
  };

  getPONumberForJob = async (partitionKey, sortKey, poNumber) => {
    const params = {
      partitionKey,
      sortKey,
      poNumber
    };

    const response = await this.api.query(getPONumberForJob, params);
    return response;
  };

  getAllAttachmentsByJobNumber = async jobNumber => {
    const params = {
      jobNumber
    };

    const response = await this.api.query(getAllAttachmentsByJobNumber, params);
    return response;
  };

  getReviewReportReceiptsByJob = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getReviewReportReceiptsByJob, params);
    return response;
  };

  getReviewReportReceiptsByJobNumber = async jobNumber => {
    const params = {
      jobNumber
    };

    const response = await this.api.query(getReviewReportReceiptsByJobNumber, params);
    return response;
  };

  getPropertyAssetsByJob = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getPropertyAssetsByJob, params);
    return response;
  };

  getPropertyAssetsByJobNumber = async jobNumber => {
    const params = {
      jobNumber
    };

    const response = await this.api.query(getPropertyAssetsByJobNumber, params);
    return response;
  };

  getPropertyAttachmentsByJob = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getPropertyAttachmentsByJob, params);
    return response;
  };

  getPropertyAttachmentsByJobNumber = async jobNumber => {
    const params = {
      jobNumber
    };

    const response = await this.api.query(getPropertyAttachmentsByJobNumber, params);
    return response;
  };

  getInvoicesByJob = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getInvoicesByJob, params);
    return response;
  };

  getInvoicesByJobNumber = async jobNumber => {
    const params = {
      jobNumber
    };

    const response = await this.api.query(getInvoicesByJobNumber, params);
    return response;
  };

  addJobsToCustomerProperty = async (paritionKey, values, pageState) => {
    const payload = createJobSchema(values, pageState);
    const params = {
      paritionKey,
      data: payload
    };

    const response = await this.api.mutate(addJobsToCustomerProperty, params);
    return response;
  };

  updateJob = async data => {
    const params = {
      data
    };

    const response = await this.api.mutate(updateJob, params);
    return response;
  };

  updateJobAndRelated = async (paritionKey, data) => {
    const params = {
      paritionKey,
      data
    };

    const response = await this.api.mutate(updateJobAndRelated, params);
    return response;
  };

  updateJobTags = async params => {
    if (params?.paritionKey || !params?.data) return;
    const response = await this.api.mutate(updateJobTags, params);
    return response;
  };

  mutateJobNotes = async values => {
    const structuredValues = noteMutationSchema(values);
    const dataSet = {
      instructionSet: JSON.stringify(structuredValues.instructionSet),
      data: JSON.stringify(structuredValues.data)
    };

    const { data } = await this.api.mutate(saveJobNotes, dataSet);
    return data && data.saveJobNotes;
  };

  mutateJobNotesOffline = async values => {
    const structuredValues = noteMutationSchema(values);
    const dataSet = {
      instructionSet: JSON.stringify(structuredValues.instructionSet),
      data: JSON.stringify(structuredValues.data)
    };
    const response = await this.api.offlineMutate(saveJobNotes, dataSet, {
      optimisticResponse: () => ({
        saveJobNotes: {
          id: 'optimistic',
          createdBy: 'optimisticUpdate',
          lastUpdatedBy: 'optimisticUpdate',
          version: values.version + 1,
          lastUpdatedDate: Math.floor(Date.now() / 1000),
          lastUpdatedDateTime: Date.now().toString(),
          partitionKey: values.parent.partitionKey,
          sortKey: `${values.parent.sortKey}-optimistic`,
          hierarchy: `${values.parent.hierarchy}-optimistic`,
          __typename: 'Note',
          ...values
        }
      }),
      update: (cache, { data: { saveJobNotes } }) => {
        const query = getNotes;
        const variables = {
          partitionKey: values.parent.partitionKey,
          sortKey: values.parent.sortKey
        };
        // we need to update the cached response for getNotes when called for the notes parent
        const cacheData = cache.readQuery({
          query,
          variables
        });
        // we might(?) get null when there's no data in the cache so make sure we mock that so
        // we don't throw an error below
        const cachedJob = cacheData.getJob || { notes: { items: [] } };
        // create an updated shallow copy of the data we got, removing all former notes with the
        // same id (eg. for when we are updating)
        const newData = {
          ...cacheData,
          getJob: {
            ...cachedJob,
            notes: {
              ...cachedJob.notes,
              items: [...cachedJob.notes.items.filter(e => e.id !== saveJobNotes.id), saveJobNotes]
            }
          }
        };
        // and write it back to the cache.
        cache.writeQuery({ query, variables, data: newData });
      }
    });
    return response && response.data && response.data.saveJobNotes;
  };

  addNotesToJob = async (partitionKey, values) => {
    const payload = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(addNotesToJob, payload);
    return response;
  };

  mutateJobAttachment = async values => {
    const structuredValues = attachmentSchema(values);
    const dataSet = {
      instructionSet: JSON.stringify(structuredValues.instructionSet),
      data: JSON.stringify(structuredValues.data)
    };

    const { data } = await this.api.mutate(saveJobAttachments, dataSet);
    return data.saveJobAttachments;
  };

  addJobAttachments = async (tenantId, jobId, attachments) => {
    const params = {
      partitionKey: tenantId,
      data: {
        jobId,
        attachments
      }
    };

    const response = await this.api.mutate(addAttachmentsToJob, params);
    return response;
  };

  jobUpdated = tenantId => {
    const params = { partitionKey: tenantId };
    return this.subscriptionClient.subscribe({
      query: jobUpdateNotification,
      variables: params
    });
  };

  jobNotesUpdated = tenantId => {
    const params = { partitionKey: tenantId };
    return this.subscriptionClient.subscribe({
      query: jobNoteUpdateNotification,
      variables: params
    });
  };

  jobAttachmentsUpdated = tenantId => {
    const params = { partitionKey: tenantId };
    return this.subscriptionClient.subscribe({
      query: jobAttachmentUpdateNotification,
      variables: params
    });
  };

  getFormDataByJob = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getFormDataByJob, params);
    return response;
  };

  getFormDataByJobNumber = async jobNumber => {
    const params = {
      jobNumber
    };

    const response = await this.api.query(getFormDataByJobNumber, params);
    return response;
  };

  addJobTasksToJob = async (partitionKey, data) => {
    const payload = {
      partitionKey,
      data
    };

    const response = await this.api.mutate(addJobTasksToJob, payload);
    return response;
  };

  getAllTasksByJobNumber = async jobNumber => {
    const params = {
      jobNumber
    };

    const response = await this.api.query(getAllTasksByJobNumber, params);
    const formattedResult = { items: [] };
    if (response && response.data && response.data.getJobByJobNumber) {
      const result = response.data.getJobByJobNumber;
      ((result.jobTasks || {}).items || []).forEach(item => {
        const formattedTask = {
          id: item.id,
          version: item.version,
          entityType: item.entityType,
          name: item.name,
          description: item.description,
          markupType: item.markupType,
          markupValue: item.markupValue,
          unitPrice: item.unitPrice,
          unitCost: item.unitCost,
          quantity: item.quantity,
          productId: item.productId
        };
        let countOfProducts = 0;
        if (item.products && item.products.items && item.products.items.length > 0) {
          countOfProducts = item.products.items.length;
          const products = item.products.items;
          const formattedProduct = [];
          products.forEach(product => {
            formattedProduct.push({
              id: product.id,
              version: product.version,
              entityType: product.entityType,
              name: product.name,
              description: product.description,
              markupType: product.markupType,
              markupValue: product.markupValue,
              unitPrice: product.unitPrice,
              unitCost: product.unitCost,
              quantity: product.quantity,
              amount: (product.quantity || 0) * (product.unitPrice || 0)
            });
          });
          formattedTask.products = { items: formattedProduct };
          formattedTask.countOfProducts = countOfProducts;
        } else {
          formattedTask.products = { items: [] };
          formattedTask.countOfProducts = 0;
        }

        formattedResult.items.push(formattedTask);
      });
      if (result.nextToken) {
        formattedResult.nextToken = result.nextToken;
      }
    }
    return formattedResult;
  };

  deleteJobJobTag = async (partitionKey, id) => {
    const params = { partitionKey, id };
    await this.api.mutate(deleteJobJobTag, params);
    return { success: true };
  };

  updateJobStatus = async (id, action) => {
    const params = {
      data: {
        id,
        action
      }
    };

    const response = await this.api.mutate(updateJobStatus, params);
    return response;
  };

  getInvoiceInfoFromJob = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getInvoiceInfoFromJob, params);
    return response;
  };

  getAllAuditLogByJobNumber = async jobNumber => {
    const params = {
      jobNumber
    };
    const { data, error } = await this.api.query(getAllAuditLogsByJobNumber, params);
    if (data && data.getJobByJobNumber && data.getJobByJobNumber.auditLogs) {
      const {
        auditLogs: { items },
        visitsView: { items: visits }
      } = data.getJobByJobNumber;
      const entries = [...items, ...visits.map(v => v.auditLogs.items).flat()];

      return entries;
    }
    if (error) {
      throw error;
    }
  };

  getJobReportForVisitIdByJobNumber = async (jobNumber, visitIdList, pdfOptions) => {
    // v1 will not have these optiojs, so it will always fetch everything
    const {
      showJobSummary = true,
      showHoursWorked = true,
      showHoursBilled = true,
      showInventoryItems = true,
      showPurchasedItems = true,
      showTaskCompleted = true,
      showBeforeAfterPhotos = true,
      showForms = true,
      showCustomerSignature = true
    } = pdfOptions;
    const showLabor = showHoursWorked || showHoursBilled;
    const params = {
      jobNumber,
      visitIdList,
      showJobSummary,
      showInventoryItems,
      showPurchasedItems,
      showTaskCompleted,
      showBeforeAfterPhotos,
      showForms,
      showLabor,
      showCustomerSignature
    };
    const { data, error } = await this.api.query(getJobReportForVisitsByJobNumber, params);
    if (data && data.getJobByJobNumber && data.getJobByJobNumber) {
      return data.getJobByJobNumber || {};
    }
    if (error) {
      throw error;
    }
  };

  getTechCostForJobReport = async visitIdArray => {
    const params = {
      visitIdArray
    };
    const { data, error } = await this.api.query(getTechMultipleVisitCost, params);
    if (error) {
      throw error;
    }
    return data?.getTechMultipleVisitCost || [];
  };

  getAllBillItemsByJobId = async (id, partitionKey) => {
    const params = {
      id,
      partitionKey
    };

    const { data, error } = await this.api.query(getBillItemsByJobId, params);

    if (error) {
      throw error;
    }
    return data?.getJobById || {};
  };

  checkCustomJobNumberExists = async customJobNumber => {
    const params = {
      CustomIdentifier: customJobNumber
    };

    const { data, error } = await this.api.query(checkCustomJobNumberExists, params);
    if (data) {
      return data.isCustomIdentifierAlreadyTaken || false;
    }
    if (error) {
      throw error;
    }
  };

  getTimesheetsByJobNumber = async jobNumber => {
    const params = {
      jobNumber
    };

    const response = await this.api.query(getTimesheetsByJobNumber, params);
    return response;
  };
}
