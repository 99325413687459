import React from 'react';
import { FieldType } from '@buildhero/sergeant';
import { InputAdornment } from '@material-ui/core';
import { sgtItemCalc } from 'utils/onCalcChange';
import { AccountingApp } from 'utils/constants';

export default ({
  accountingApp,
  priceBook,
  ledgerAccountOptions,
  costCodeOptions,
  costTypeOptions,
  revenueTypeOptions,
  departmentOptions
}) => {
  const rowStyle = {
    gap: 16,
    flexDirection: 'row',
    marginBottom: 16
  };
  const colStyle = {
    width: 200
  };

  return {
    layouts: {
      default: {
        contents: [
          // row 1
          {
            props: { style: rowStyle },
            contents: [
              accountingApp !== AccountingApp.INTACCT && {
                component: 'PriceBookEntrySearch',
                source: 'name',
                props: {
                  style: { width: 416 },
                  label: 'Product',
                  required: true,
                  priceBookId: priceBook?.value,
                  costCodeOptions,
                  costTypeOptions,
                  revenueTypeOptions
                }
              },
              accountingApp === AccountingApp.INTACCT && {
                component: 'Select',
                source: 'ledgerAccount',
                props: {
                  style: { width: 416 },
                  label: 'GL Account',
                  required: true,
                  options: ledgerAccountOptions,
                  searchable: true
                }
              },
              {
                component: 'Select',
                source: 'department',
                props: {
                  style: colStyle,
                  label: 'Department',
                  required: true,
                  options: departmentOptions,
                  searchable: true
                }
              }
            ]
          },
          // row 2
          {
            props: { style: rowStyle },
            contents: [
              {
                component: 'Input',
                source: 'description',
                props: { label: 'Description', multiline: true, rows: 4 }
              }
            ]
          },
          // row 3
          {
            props: { style: rowStyle },
            contents: [
              {
                component: 'NumberInput',
                source: 'quantity',
                resolveProps: ({ product }) => ({
                  endAdornment: (
                    <InputAdornment position="end">
                      {product?.unitOfMeasure?.name || 'units'}
                    </InputAdornment>
                  )
                }),
                props: {
                  style: colStyle,
                  label: 'Quantity',
                  required: true,
                  onChange: sgtItemCalc
                }
              },
              {
                component: 'CurrencyInput',
                source: 'unitCost',
                props: {
                  style: colStyle,
                  label: 'Unit Cost',
                  slowField: true,
                  onChange: sgtItemCalc
                }
              },
              {
                component: 'PercentageInput',
                source: 'markupValue',
                props: {
                  style: colStyle,
                  label: 'Markup',
                  slowField: true,
                  onChange: sgtItemCalc
                }
              },
              {
                component: 'CurrencyInput',
                source: 'unitPrice',
                props: {
                  style: colStyle,
                  label: 'Unit Price',
                  required: true,
                  slowField: true,
                  onChange: sgtItemCalc
                }
              }
            ]
          },
          // row 4
          {
            props: { style: rowStyle },
            contents: [
              {
                component: 'Select',
                source: 'costCode',
                props: {
                  style: colStyle,
                  label: 'Cost Code',
                  options: costCodeOptions,
                  portal: true,
                  menuHeight: 150,
                  searchable: true,
                  clearable: true
                }
              },
              {
                component: 'Select',
                source: 'jobCostType',
                props: {
                  style: colStyle,
                  label: 'Cost Type',
                  options: costTypeOptions,
                  portal: true,
                  menuHeight: 150,
                  searchable: true,
                  clearable: true
                }
              },
              {
                component: 'Select',
                source: 'revenueType',
                props: {
                  style: colStyle,
                  label: 'Revenue Type',
                  options: revenueTypeOptions,
                  portal: true,
                  menuHeight: 150,
                  searchable: true,
                  clearable: true
                }
              }
            ]
          },
          // row 5
          {
            props: { style: { flexDirection: 'row', gap: 16 } },
            contents: [
              {
                component: 'Field',
                source: 'amount',
                props: {
                  style: colStyle,
                  label: 'Subtotal',
                  slowField: true,
                  type: FieldType.CURRENCY
                }
              },
              {
                component: 'Checkbox',
                source: 'taxable',
                props: {
                  style: colStyle,
                  label: 'Taxable'
                }
              }
            ]
          }
        ]
      }
    }
  };
};
