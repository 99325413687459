import React from 'react';

import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { func, string } from 'prop-types';

import { camelCaseToTitleCase } from 'utils';

import { useStyles } from './AddButton.styles';

const AddButton = ({ title, onClick }) => {
  const styles = useStyles();

  return (
    <ButtonBase focusRipple onClick={onClick}>
      <div css={styles.container}>
        <AddCircleOutlineIcon css={styles.icon} />
        <Typography css={styles.text}>{camelCaseToTitleCase(title)}</Typography>
      </div>
    </ButtonBase>
  );
};

AddButton.propTypes = {
  title: string.isRequired,
  onClick: func.isRequired
};

export default AddButton;
