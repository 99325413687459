import React, { useState } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import PropTypes from 'prop-types';

import AttachmentsV1 from '@pm/components/Attachments';
import AttachmentsV2 from '@pm/components/AttachmentsV2';
import {
  getCloudinaryImageUrl,
  getFormattedActivityData,
  getFormattedEmailData,
  handleUploadAttachments
} from '@pm/components/utils';
import { DefaultButton, UserPermission } from 'components';
import FullScreenModal from 'components/FullScreenModal';
import ProjectPDF from 'scenes/ProjectManagement/components/ProjectPDF';
import Sidebar from 'scenes/ProjectManagement/components/Sidebar';
import {
  useFetchCustomerReps,
  useFetchEmployees,
  useGetAuditLogsForEntity
} from 'services/APIHooks';
import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';
import { PermissionConstants } from 'utils/AppConstants';

import { FeatureFlags } from 'utils/FeatureFlagConstants';

import Activity from '../Activity';

import Communications from './components/Communications';
import useStyles from './DetailView.styles';

/**
 * This component handles everything related to certain detail views in the PM module.
 * A PDF is generated and uploaded when this component is rendered.
 *
 * @param {object} projectData Object containing data for the current project
 * @param {object} initialData Object containing data on the item whose data will be displayed in this component. (RFI, Submittal, etc)
 * @param {object} dataForPdf Formatted data for the PDF component.
 * @param {object} getPdfConfiguration Function that returns the PDFConfiguration
 * @param {function} getAttachments Function to retreive the attachments from the initialData object.
 * @param {object} user User object
 * @param {object} editData Object containing a label and render key. render() should return a component (ex: rfi edit modal)
 * @param {string} title Title of the detail modal.
 * @param {array} headerButtons Any additional buttons to go into the header.
 * @param {boolean} isSubmitting
 * @param {object} emailData Object containing title, body, and subject keys to populate the fields of the email modal.
 * @param {array} sidebarConfiguration Configuration object for the Sidebar component
 * @param {object} sidebarData Data formatted for the Sidebar component.
 * @param {function} renderAttachments Function that returns an Attachments component.
 * @param {array} children Any elements here are rendered BEFORE the Communications component.
 * @returns
 */

const DetailView = props => {
  const classes = useStyles();
  const {
    open,
    handleClose,
    projectData,
    initialData,
    attachments,
    onAttachmentsChange,
    noAttachmentAddButton,
    getPdfFormatComponent,
    showChildSpacers,
    getPDFBlob,
    user,
    editData,
    title,
    headerButtons,
    isSubmitting,
    emailData,
    sidebarConfiguration,
    sidebarData,
    children,
    onEmailSent
  } = props;
  const currentDayFileFormat = moment().format('MM-DD-YYYY');

  const [pdfIsLoading, setPdfIsLoading] = useState(false);
  const [isOpenedPdfModal, setIsOpenedPdfModal] = useState(false);
  const [isOpenedEditModal, setIsOpenedEditModal] = useState(false);
  const [pdfBlob, setPdfBlob] = useState();
  const [{ data: customerReps }] = useFetchCustomerReps({}, { include: '*' });
  const [{ data: employees }] = useFetchEmployees();

  const [{ data: auditLogs }] = useGetAuditLogsForEntity(initialData?.id, {
    skip: !initialData || !initialData.AuditLogEntry
  });

  const triggerGeneratePdf = async () => {
    if (!pdfBlob) {
      setPdfIsLoading(true);
      const pdfDocumentBlob = await getPDFBlob();
      setPdfBlob(pdfDocumentBlob);
      return pdfDocumentBlob;
    }
    return Promise.resolve();
  };

  const handlePreviewButton = () => {
    triggerGeneratePdf().then(() => {
      setPdfIsLoading(false);
      setIsOpenedPdfModal(true);
    });
  };

  const uploadPDF = async pdfFile => {
    const poUploadFileName = `${title}_${currentDayFileFormat}.pdf`;

    try {
      const storageService = new StorageService();
      const s3Response = await storageService.uploadFile(
        pdfFile,
        `${user.tenantId}/${poUploadFileName}`,
        e => e,
        'application/pdf'
      );
      return { fileUrl: s3Response, fileName: poUploadFileName, type: 'application/pdf' };
    } catch (error) {
      Logger.error(error);
    }
  };

  const generateAndUploadPDF = blobData => {
    const blobSource = pdfBlob || blobData;
    if (blobSource) {
      return uploadPDF(blobSource).then(pdfDetails => {
        const formattedPdf = {
          source: getCloudinaryImageUrl(pdfDetails.fileUrl),
          fileUrl: getCloudinaryImageUrl(pdfDetails.fileUrl),
          type: 'application/pdf',
          fileName: pdfDetails.fileName
        };
        setPdfIsLoading(false);
        return formattedPdf;
      });
    }
    setPdfIsLoading(false);
    return {};
  };

  const handleAttachmentUpload = (newAttachments, selectedImages, selectedFiles) => {
    // Trigger the getPdfComponent() function to generate updated PDF
    setPdfBlob(null);
    const combinedAttachments = handleUploadAttachments(
      newAttachments,
      selectedImages,
      selectedFiles,
      attachments,
      user
    );
    onAttachmentsChange(combinedAttachments);
  };

  const Attachments = useFlags()[FeatureFlags.PM_FILE_SYSTEM] ? AttachmentsV2 : AttachmentsV1;

  const displayAttachments = () => {
    return (
      <Attachments
        smallButton
        noAddButton={noAttachmentAddButton}
        attachedFiles={attachments || []}
        attachedFileCount={attachments?.length || 0}
        uploadFileList={handleAttachmentUpload}
        chunkSize={5}
        imageWidth={200}
        imageHeight={180}
      />
    );
  };

  const beforeSendEmail = () => {
    if (!pdfBlob) {
      return triggerGeneratePdf().then(blobData => generateAndUploadPDF(blobData));
    }
    return generateAndUploadPDF();
  };

  return (
    <UserPermission I="read" action={PermissionConstants.OBJECT_PM_RFI}>
      <FullScreenModal
        title={title}
        open={open}
        contentStyle={{ maxWidth: '100%', width: '100%', margin: 0, flexGrow: 2 }}
        handleClose={handleClose}
        modalHeaderButtons={[
          <DefaultButton
            label="PREVIEW"
            variant="outlined"
            key="previewRFIButton"
            disabled={isSubmitting || pdfIsLoading}
            showSpinner={isSubmitting || pdfIsLoading}
            style={{ marginRight: 8 }}
            onClick={handlePreviewButton}
          />,
          editData && (
            <DefaultButton
              label={editData.label}
              variant="containedSecondary"
              key="editButton"
              disabled={isSubmitting}
              showSpinner={isSubmitting}
              style={{ marginRight: headerButtons && headerButtons.length ? 8 : 0 }}
              onClick={() => setIsOpenedEditModal(true)}
            />
          ),
          ...headerButtons
        ]}
      >
        <div className={classes.mainContainer}>
          <div className={classes.sidebarContainer}>
            <Sidebar source={sidebarData} sidebarData={sidebarConfiguration} />
          </div>
          <div className={classes.container}>
            {children &&
              (Array.isArray(children) ? (
                children.map(child => {
                  return (
                    <>
                      {child}
                      {showChildSpacers && <div className={classes.spacer} />}
                    </>
                  );
                })
              ) : (
                <>
                  {children}
                  {showChildSpacers && <div className={classes.spacer} />}
                </>
              ))}
            {initialData && (
              <Communications
                pdfIsLoading={pdfIsLoading}
                beforeSendEmail={beforeSendEmail}
                parentId={initialData?.id}
                showEmailButton
                attachmentItems={attachments}
                user={user}
                emailData={emailData}
                previewPdfTitle={`${title}_${currentDayFileFormat}.pdf`}
                project={projectData}
                initialData={initialData}
                onEmailSent={onEmailSent}
                messagesTransform={data =>
                  getFormattedEmailData(
                    data,
                    customerReps.concat(employees),
                    initialData?.SendTo?.name || initialData?.SendTo?.customerName
                  )
                }
              />
            )}
            <div className={classes.spacer} />
            <div>{displayAttachments()}</div>
            <div className={classes.spacer} />
            {getPdfFormatComponent && (
              <>
                <div style={{ padding: 17 }}>{getPdfFormatComponent()}</div>
                <div className={classes.spacer} />
              </>
            )}
            <div className={classes.activityContainer}>
              <Activity
                activityData={getFormattedActivityData(
                  initialData?.AuditLogEntry ? initialData : { AuditLogEntry: auditLogs }
                )}
              />
            </div>
          </div>
        </div>

        {/* Generates the PDF for the Preview and Email */}

        <ProjectPDF
          open={isOpenedPdfModal}
          user={user}
          projectData={projectData}
          pdfBlob={pdfBlob}
          handleClose={() => {
            setIsOpenedPdfModal(false);
          }}
          title={title}
        />

        {editData &&
          editData.render(isOpenedEditModal, () => {
            setPdfBlob(null);
            setIsOpenedEditModal(false);
          })}
      </FullScreenModal>
    </UserPermission>
  );
};

DetailView.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  projectData: PropTypes.object.isRequired,
  getPDFBlob: PropTypes.func.isRequired,
  initialData: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  sidebarConfiguration: PropTypes.array.isRequired,
  sidebarData: PropTypes.object.isRequired,
  getPdfFormatComponent: PropTypes.func,
  showChildSpacers: PropTypes.bool,
  attachments: PropTypes.array,
  onAttachmentsChange: PropTypes.func,
  noAttachmentAddButton: PropTypes.bool,
  editData: PropTypes.object,
  headerButtons: PropTypes.array,
  isSubmitting: PropTypes.bool,
  children: PropTypes.array,
  emailData: PropTypes.object,
  onEmailSent: PropTypes.func
};

DetailView.defaultProps = {
  editData: undefined,
  getPdfFormatComponent: undefined,
  showChildSpacers: true,
  attachments: [],
  onAttachmentsChange: () => {},
  noAttachmentAddButton: false,
  headerButtons: [],
  isSubmitting: false,
  children: [],
  emailData: {},
  onEmailSent: () => {}
};

export default DetailView;
