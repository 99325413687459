import { gql } from '@apollo/client/core';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import { getState } from 'redux/store';
import { roundCurrency, roundFloat } from 'utils';
import mergeDeep from 'utils/mergeDeep';

import { inventoryPartFragment } from '../queries/queryFragment';
import { GET_REVIEW_REPORT_BY_ID } from '../queries';

const ADD_INVENTORY_PARTS_TO_REVIEW_REPORT = gql`
  mutation addInventoryPartsToReviewReport($partitionKey: String, $data: AddInventoryPartsToReviewReportInput!) {
    addInventoryPartsToReviewReport(partitionKey: $partitionKey, data: $data) {
      ${inventoryPartFragment}
    }
  }
`;

export const createOptimisticInventoryItem = (inventoryFormData, i) => ({
  id: `optimistic-inventory-id_${i}`,
  addedBy: null,
  amount: null,
  createdBy: getState()?.user?.displayName || '...',
  departmentName: inventoryFormData.departmentName || null,
  description: inventoryFormData.description,
  includeInInvoice: true,
  markup: roundFloat(inventoryFormData?.markupValue),
  name: inventoryFormData.name,
  quantity: inventoryFormData.quantity,
  taxable: inventoryFormData.taxable,
  unitCost: roundFloat(inventoryFormData?.unitCost),
  unitPrice: roundCurrency(inventoryFormData?.unitPrice),
  vendorName: inventoryFormData.vendorName || null,
  version: 1,
  costCode: !inventoryFormData.costCode
    ? null
    : {
        id: inventoryFormData.costCode.value,
        name: inventoryFormData.costCode.label,
        __typename: 'CostCode'
      },
  jobCostType: !inventoryFormData.jobCostType
    ? null
    : {
        id: inventoryFormData.jobCostType.value,
        name: inventoryFormData.jobCostType.label,
        type: '',
        __typename: 'JobCostType'
      },
  priceBookEntry: !inventoryFormData.priceBookEntry
    ? null
    : {
        id: inventoryFormData.priceBookEntry.id,
        product: {
          ...inventoryFormData.product,
          id: null,
          unitOfMeasure: null,
          __typename: 'Product'
        },
        __typename: 'PriceBookEntry'
      },
  revenueType: !inventoryFormData.revenueType
    ? null
    : {
        id: inventoryFormData.revenueType.value,
        name: inventoryFormData.revenueType.label,
        type: '',
        __typename: 'JobCostType'
      },
  truck: !inventoryFormData.truck
    ? null
    : {
        id: inventoryFormData.truck.value,
        name: inventoryFormData.truck.label,
        __typename: 'Truck'
      },
  __typename: 'InventoryPart'
});

const optimisticResponseFactory = ({ inventoryParts }) => ({
  addInventoryPartsToReviewReport: inventoryParts.map(createOptimisticInventoryItem)
});

export const useAddInventoryParts = (reviewReportId, options = {}) => {
  return useExtendedMutation(ADD_INVENTORY_PARTS_TO_REVIEW_REPORT, {
    optimisticResponseFactory,
    update: (cache, { data: { addInventoryPartsToReviewReport } }) => {
      const query = {
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        }
      };

      const cachedData = cache.readQuery(query);

      const newItems = [
        ...cachedData.getReviewReportById.inventoryParts.items,
        ...addInventoryPartsToReviewReport
      ];

      const update = { getReviewReportById: { inventoryParts: { items: newItems } } };

      cache.writeQuery({ ...query, data: mergeDeep(cachedData, update) });
    },
    ...options
  });
};
