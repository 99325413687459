import { VisitStatuses } from '@dispatch/Dispatch.constants';

const hasPrimaryTech = (context, { originalVisit = {}, inputData = {} }) => {
  const visit = { ...originalVisit, ...inputData };
  return Boolean(visit.primaryTechId);
};

const hasEnoughExtraTechs = (context, { originalVisit = {}, inputData = {} }) => {
  const visit = { ...originalVisit, ...inputData };
  if (!visit.extraTechsNumber) return true;
  return visit.extraTechs.length >= visit.extraTechsNumber;
};

const hasNoOfflineCrew = () => {
  // the crew must not have off time when the visit is scheduled
  return true;
};

const hasScheduledDate = (context, { originalVisit = {}, inputData = {} }) => {
  const visit = { ...originalVisit, ...inputData };
  return Boolean(visit.scheduledFor);
};

const hasActualDuration = (context, { originalVisit = {}, inputData = {} }) => {
  const visit = { ...originalVisit, ...inputData };
  return Boolean(visit.actualDuration);
};

export const hasScheduleWorkDone = (context, { originalVisit = {}, inputData = {} }) => {
  const visit = { ...originalVisit, ...inputData };
  return !visit.schedules.find(schedule => schedule.status === 'WorkDone');
};

export const isFullyScheduled = (context, event) => {
  return (
    hasScheduledDate(context, event) &&
    hasPrimaryTech(context, event) &&
    hasEnoughExtraTechs(context, event) &&
    hasNoOfflineCrew(context, event) &&
    hasActualDuration(context, event)
  );
};

export const atLeastOneIsTraveling = (context, { originalVisit, inputData }) => {
  const visit = { ...originalVisit, inputData };
  return visit.schedules.find(schedule => schedule.status === 'Traveling');
};

export const atLeastOneIsWorking = (context, { originalVisit = {}, inputData = {} }) => {
  const visit = { ...originalVisit, ...inputData };
  return visit.schedules.find(schedule => schedule.status === 'Working');
};

export const allArePaused = (context, { originalVisit = {}, inputData = {} }) => {
  const visit = { ...originalVisit, ...inputData };
  return !visit.schedules.find(schedule => schedule.status !== 'Paused');
};

export const canCompleteVisit = (context, { originalVisit = {}, inputData = {} }) => {
  const visit = { ...originalVisit, ...inputData };
  return !visit.schedules.find(schedule => schedule.status !== 'WorkDone');
};

export const primaryTechIsOnHold = (context, { originalVisit = {}, inputData = {} }) => {
  const visit = { ...originalVisit, ...inputData };

  if (!visit.primaryTechId) return false;

  return (
    visit.status === VisitStatuses.SCHEDULED.key ||
    visit.status === VisitStatuses.WORKING.key ||
    visit.status === VisitStatuses.TRAVELING.key ||
    visit.status === VisitStatuses.PAUSED.key
  );
};
