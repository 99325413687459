import useExtendedMutation from 'customHooks/useExtendedMutation';
import { useSnackbar } from 'customHooks/useSnackbar';
import { logErrorWithCallback } from 'utils';

import { addTimesheetNotesToTimesheetEntryBinderMutation } from '../../gql';

const serializer = data => {
  const {
    id,
    parent: { tenantId, binderId },
    customFileName,
    comment,
    employeeId,
    fileUrl,
    fileName,
    attachmentId
  } = data;

  return {
    variables: {
      partitionKey: tenantId,
      data: {
        binderId,
        timesheetNotes: [
          {
            id,
            subject: customFileName,
            note: comment,
            employeeId,
            attachments: fileUrl
              ? [
                  {
                    id: attachmentId,
                    fileUrl,
                    fileName: fileName || customFileName
                  }
                ]
              : []
          }
        ]
      }
    }
  };
};

const useUpsertTimesheetNotes = () => {
  const snackbar = useSnackbar();

  return useExtendedMutation(addTimesheetNotesToTimesheetEntryBinderMutation, {
    serializer,
    // TODO: update cache
    onError: error => {
      logErrorWithCallback(error, snackbar, 'Failed to add Timesheet Note');
    },
    onCompleted: () => {
      snackbar('success', 'Successfully added Timesheet Notes');
    }
  });
};

export default useUpsertTimesheetNotes;
