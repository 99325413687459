import React, { useEffect, useState } from 'react';
import { QuoteStatus, QuoteAddedItem } from 'utils/AppConstants';
import { getProjectById } from 'services/API/project';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from 'utils/FeatureFlagConstants';
import Labels from 'meta/labels';

const AssociatedQuoteAddedItem = ({ quoteInfo, locale }) => {
  const [project, setProject] = useState(null);
  const flags = useFlags();

  useEffect(() => {
    if (
      !project &&
      quoteInfo &&
      quoteInfo.addedItem === QuoteAddedItem.PROJECT &&
      quoteInfo.projectId
    ) {
      getProjectById(quoteInfo.projectId).then(proj => {
        setProject(proj);
      });
    }
  }, [quoteInfo]);

  return (
    <>
      {((quoteInfo?.addedItem &&
        quoteInfo.addedItem !== QuoteAddedItem.NONE &&
        flags[FeatureFlags.PROJECT_MANAGEMENT]) ||
        quoteInfo?.status === QuoteStatus.JOB_ADDED) && (
        <div data-testid="associated-quote-added-item-container">
          {quoteInfo.addedItem === QuoteAddedItem.PROJECT && quoteInfo.projectId && project && (
            <p style={{ margin: 0 }} data-testid="associated-quote-added-item-project">
              {Labels.quoteAssociatedProject[locale]}:{' '}
              <a href={`/project/view/${quoteInfo.projectId}/dashboard`}>{project?.name}</a>
            </p>
          )}
          {quoteInfo?.status === QuoteStatus.JOB_ADDED &&
            quoteInfo.quoteJobs?.items[0]?.job?.jobNumber && (
              <p style={{ margin: 0 }} data-testid="associated-quote-added-item-job">
                {Labels.quoteAssociatedJob[locale]}:{' '}
                <a href={`/job/view/${quoteInfo.quoteJobs?.items[0]?.job?.jobNumber}`}>
                  {quoteInfo.quoteJobs?.items[0]?.job?.customIdentifier ||
                    quoteInfo.quoteJobs?.items[0]?.job?.jobNumber}
                </a>
              </p>
            )}
        </div>
      )}
    </>
  );
};

export default AssociatedQuoteAddedItem;
