import React, { useEffect, useRef, useState } from 'react';

import {
  DateInput,
  Divider,
  Field,
  FieldType,
  Select,
  Button as SergeantButton,
  ThemeProvider,
  TV,
  TW,
  Typography
} from '@buildhero/sergeant';
import jsx from '@emotion/react';
import { Button, ButtonGroup, useTheme } from '@material-ui/core';

import { findLastIndex, isEmpty, noop, take, takeRight } from 'lodash';
import moment from 'moment-timezone';
import { connect } from 'react-redux';

import { ConfirmLeave, SergeantModal, Spinner } from 'components';

import { secondsToHour } from 'scenes/Payroll/TimeTrackingReport/helpers';
import { sentryMessage } from 'services/Logger';
import AppConstants, { TimeCardStatusTypes } from 'utils/AppConstants';

import {
  getTimesheetPeriodById,
  prevNextTimesheetPeriod,
  updateTimesheetEntryHours,
  updateTimesheetUrl
} from '../services';

import { DayCard } from './components/DayCard';
import { generateWeekData } from './helpers';

import requestReasonMetaLayout from './RequestReasonMeta';

const ButtonDirections = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT'
};

const ToReview = ({
  user,
  selectedEmployee,
  payrollHourTypes,
  timesheetPeriods = [],
  payrollSetting,
  snackbarOn,
  tab = 'toReview', // or 'approved'
  setExportSelectedDate,
  unsubmittedEvents,
  onUpdateDayCard = noop,
  setIsExportDisabled = noop,
  setDirty = noop,
  selectedDate = null,
  setSelectedDate = noop,
  weekSelectedPeriod,
  setWeekSelectedPeriod = noop,
  dayOrWeek = 'day',
  setDayOrWeek = noop
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const loadingDateRef = useRef();

  const [selectedPeriod, setSelectedPeriod] = useState({});
  const [weekDate, setWeekDate] = useState({});
  const [weekDates, setWeekDates] = useState([]);
  const [timesheetEntriesToUpdate, setTimesheetEntriesToUpdate] = useState([]);

  /*
    need to store newAuditLogs after a mutation completes since
    the newly generated auditLogs
    do not get returned from successful mutations. 
    This will not be nessessary after BUOP-7921
  */
  const [newAuditLogs, setNewAuditLogs] = useState([]);

  const [isLoading, setIsLoading] = useState('');
  const [isRequestReasonLoading, setIsRequestReasonLoading] = useState('');
  const [disablePrev, setDisablePrev] = useState(true);
  const [disableNext, setDisableNext] = useState(true);
  const [isRequestReasonModalOpen, setIsRequestReasonModalOpen] = useState(false);
  const [requestReasonDayIndex, setRequestReasonDayIndex] = useState();

  const generateWeekDataHelper = period => {
    const {
      timesheetEntriesView: { items: manualTimesheetEntries }
    } = period;

    return generateWeekData(
      manualTimesheetEntries,
      period,
      payrollSetting.timeZone,
      payrollHourTypes,
      tab === 'toReview' ? TimeCardStatusTypes.SUBMITTED : TimeCardStatusTypes.APPROVED,
      selectedEmployee,
      unsubmittedEvents
    );
  };

  const loadTimesheetPeriodById = async id => {
    return getTimesheetPeriodById({ id, snackbarOn });
  };

  const getDayFromWeekDates = wDates => {
    const formatDate = d => moment.unix(d).format('D');
    const day = wDates.find(w => formatDate(w.dayStartUTC) === formatDate(selectedDate));
    return day;
  };

  const handleDateSelect = async date => {
    setIsLoading('init');
    setSelectedDate(date);
    loadingDateRef.current = date;

    const id = timesheetPeriods.find(
      ({ dateStartUTC, dateEndUTC }) => dateStartUTC <= date && date <= dateEndUTC
    )?.id;

    if (!id) {
      setWeekDate({});
      setSelectedPeriod({});
      setIsLoading('');
      setDisablePrev(false);
      setDisableNext(false);
      setIsExportDisabled(true);
      return;
    }

    let period;
    if (id === selectedPeriod?.id) {
      period = selectedPeriod;
    } else {
      period = await loadTimesheetPeriodById(id);
    }

    if (date !== loadingDateRef.current) return; // the user was quickly moving through the dates, this one is no longer relevant
    const weekDatesData = generateWeekDataHelper(period);

    const day = weekDatesData.find(
      ({ dayStartUTC, dayEndUTC }) => dayStartUTC <= date && date <= dayEndUTC
    );

    setDisablePrev(false);
    setDisableNext(false);
    setSelectedPeriod(period);
    setExportSelectedDate({ dateStartUTC: day.dayStartUTC, dateEndUTC: day.dayEndUTC });
    setWeekDate(day.workEvents.length > 0 ? day : {});
    setIsExportDisabled(false);
    setIsLoading('');
  };

  const handlePeriodSelection = async ({ id }) => {
    setIsLoading('init');
    const period = await loadTimesheetPeriodById(id);
    const weekDatesData = generateWeekDataHelper(period);
    const day = getDayFromWeekDates(weekDatesData);
    setWeekDate(day);
    setWeekDates(weekDatesData);
    setSelectedPeriod(period);
    setWeekSelectedPeriod(period);
    setExportSelectedDate({ dateStartUTC: period.dateStartUTC, dateEndUTC: period.dateEndUTC });
    setIsExportDisabled(false);
    setIsLoading('');
  };

  const reset = (nextMode = null) => {
    if (nextMode === 'day' || dayOrWeek === 'day') {
      handleDateSelect(selectedDate);
    }

    setSelectedPeriod({});
    setWeekDates([]);
    if (nextMode === 'week') setWeekDate({});
    if (weekSelectedPeriod && (nextMode === 'week' || dayOrWeek === 'week')) {
      handlePeriodSelection({ id: weekSelectedPeriod.id });
    }

    setTimesheetEntriesToUpdate([]);
    setNewAuditLogs([]);
  };

  useEffect(() => {
    if (dayOrWeek === 'week') setIsExportDisabled(true);
    reset();
  }, [timesheetPeriods]);

  useEffect(() => {
    setDirty(timesheetEntriesToUpdate.length > 0);
  }, [timesheetEntriesToUpdate.length]);

  const formatTimesheetPeriod = period => {
    if (!period) return;

    const start = moment.tz(moment.unix(period.dateStartUTC).format(), payrollSetting.timeZone);
    const end = moment.tz(moment.unix(period.dateEndUTC).format(), payrollSetting.timeZone);

    return {
      label: `${start.format(AppConstants.DATE_FORMAT)} - ${end.format(AppConstants.DATE_FORMAT)}`,
      value: period
    };
  };
  const timesheetPeriodOptions = timesheetPeriods.map(formatTimesheetPeriod);

  const updateTimesheetEntries = async () => {
    setIsLoading('saveEdits');

    const timesheetEntries = timesheetEntriesToUpdate.map(e => {
      const { extra, ...props } = e;
      return props;
    });

    const data = await updateTimesheetEntryHours({
      employee: selectedEmployee,
      timesheetEntries,
      snackbarOn
    });

    if (data) {
      const newAuditLogEntries = timesheetEntriesToUpdate.map(e => {
        const { extra, id, ...propsToMutate } = e;

        return {
          executedDateTime: new Date().getTime(),
          executedBy: user.displayName,
          hourType: e.extra.hourType,
          executionType: 'Edited',
          entry: { workEvent: e.extra.workEvent },
          changeLog: JSON.stringify(
            Object.keys(propsToMutate).map(key => ({
              field: key,
              new: propsToMutate[key]
            }))
          )
        };
      });

      setNewAuditLogs([...newAuditLogEntries, ...newAuditLogs]);

      setTimesheetEntriesToUpdate([]);
    }

    setIsLoading('');
  };

  const queueTimesheetEntriesToUpdate = entry => {
    const unsavedTimesheetEntryIndex = timesheetEntriesToUpdate.findIndex(e => e.id === entry.id);
    if (unsavedTimesheetEntryIndex === -1) {
      setTimesheetEntriesToUpdate([...timesheetEntriesToUpdate, { ...entry }]);
    } else {
      const newArr = [...timesheetEntriesToUpdate];
      newArr[unsavedTimesheetEntryIndex] = { ...newArr[unsavedTimesheetEntryIndex], ...entry };
      setTimesheetEntriesToUpdate(newArr);
    }

    const {
      timesheetEntriesView: { items: manualTimesheetEntries }
    } = selectedPeriod;

    const newEntries = manualTimesheetEntries.map(e => {
      return e.id === entry.id ? { ...e, ...entry } : e;
    });

    setSelectedPeriod({
      ...selectedPeriod,
      timesheetEntriesView: {
        items: newEntries
      }
    });

    const weekDatesData = generateWeekData(
      newEntries,
      selectedPeriod,
      payrollSetting.timeZone,
      payrollHourTypes,
      TimeCardStatusTypes.SUBMITTED,
      selectedEmployee,
      unsubmittedEvents
    );
    return weekDatesData;
  };

  const handleTimesheetEntryDayChange = entry => {
    const weekDatesData = queueTimesheetEntriesToUpdate(entry);
    const day = getDayFromWeekDates(weekDatesData);
    setWeekDate(day);
  };

  const handleTimesheetEntryChange = (entry, i) => {
    const weekDatesData = queueTimesheetEntriesToUpdate(entry);
    setWeekDates(weekDatesData);
  };

  const isSaveEditsDisabled = isLoading === 'saveEdits' || timesheetEntriesToUpdate.length === 0;

  const handleApprove = async (i = '') => {
    if (!isSaveEditsDisabled) return snackbarOn('error', 'Please save your edits before approving');

    setIsLoading(`approve${i}`);
    const w = dayOrWeek === 'day' ? weekDate : weekDates[i];

    await updateTimesheetEntryHours({
      employee: selectedEmployee,
      timesheetEntries: w.entriesOnThisDay.map(e => ({
        id: e.id,
        manualApprovedBy: user.displayName,
        manualApprovedDate: moment().unix(),
        manualStatus: TimeCardStatusTypes.APPROVED
      })),
      snackbarOn
    });
    if (dayOrWeek === 'day') {
      setWeekDate({});
      setSelectedPeriod({});
    } else {
      handlePeriodSelection({ id: selectedPeriod.id });
    }
    setIsLoading('');
    onUpdateDayCard(selectedEmployee);
  };

  const handleRequestRevisionOrReopen = async ({ reason }, stopLoading) => {
    setIsRequestReasonModalOpen(false);
    setIsRequestReasonLoading(
      tab === 'toReview'
        ? `requestReason${requestReasonDayIndex ?? ''}`
        : `reopen${requestReasonDayIndex ?? ''}`
    );
    const w = dayOrWeek === 'day' ? weekDate : weekDates[requestReasonDayIndex];
    await updateTimesheetEntryHours({
      employee: selectedEmployee,
      timesheetEntries: w.entriesOnThisDay.map(e => ({
        id: e.id,
        manualReopenReason: reason,
        manualStatus:
          tab === 'toReview' ? TimeCardStatusTypes.DISPUTED : TimeCardStatusTypes.SUBMITTED
      })),
      snackbarOn
    });
    if (dayOrWeek === 'day') {
      setWeekDate(null);
      setSelectedPeriod({});
    } else {
      handlePeriodSelection({ id: selectedPeriod.id });
    }
    setIsRequestReasonLoading('');
    stopLoading();
    onUpdateDayCard(selectedEmployee);
  };

  const switchPeriod = async dir => {
    const newTimesheetPeriod = await prevNextTimesheetPeriod({
      employee: selectedEmployee,
      status: tab === 'toReview' ? TimeCardStatusTypes.SUBMITTED : TimeCardStatusTypes.APPROVED,
      date: selectedDate,
      dir,
      snackbarOn
    });
    if (newTimesheetPeriod === null) throw new Error();
    const period = await loadTimesheetPeriodById(newTimesheetPeriod.id);
    const newTimesheetPeriodWeeksData = generateWeekDataHelper(period);
    let j;
    if (dir === ButtonDirections.PREVIOUS) {
      j = findLastIndex(newTimesheetPeriodWeeksData, d => d.workEvents.length > 0);
    } else if (dir === ButtonDirections.NEXT) {
      j = newTimesheetPeriodWeeksData.findIndex(d => d.workEvents.length > 0);
    }

    if (j === -1) {
      sentryMessage('Corrupt Next/Prev Timesheet Period', {
        newTimesheetPeriodWeeksData,
        period,
        selectedEmployee
      });

      return snackbarOn(
        'error',
        'There was an issue - navigate using datepicker - BuildOps has been informed. Please contact BuildOps for further assistance: support@buildops.com 323-487-8882'
      );
    }
    await handleDateSelect(newTimesheetPeriodWeeksData[j].dayStartUTC);
  };

  const handleClickPrev = async () => {
    setIsLoading('prev');

    try {
      if (!selectedPeriod?.id) {
        await switchPeriod(ButtonDirections.PREVIOUS);
      } else {
        const weekDatesData = generateWeekDataHelper(selectedPeriod);
        const weekDateIndex = weekDatesData.findIndex(
          ({ dayStartUTC, dayEndUTC }) => dayStartUTC <= selectedDate && selectedDate <= dayEndUTC
        );
        const prevDates = take(weekDatesData, weekDateIndex);
        const i = findLastIndex(prevDates, d => d.workEvents.length > 0);

        if (i !== -1) {
          const prevDate = selectedDate - (prevDates.length - i) * 86400; // 86400 seconds in a day
          await handleDateSelect(prevDate);
        } else {
          await switchPeriod(ButtonDirections.PREVIOUS);
        }
      }
    } catch (error) {
      snackbarOn('error', 'No previous timesheets for review');
      setDisablePrev(true);
    } finally {
      setIsLoading('');
    }
  };

  const handleClickNext = async () => {
    setIsLoading('next');

    try {
      if (!selectedPeriod?.id) {
        await switchPeriod(ButtonDirections.NEXT);
      } else {
        const weekDatesData = generateWeekDataHelper(selectedPeriod);
        const weekDateIndex = weekDatesData.findIndex(
          ({ dayStartUTC, dayEndUTC }) => dayStartUTC <= selectedDate && selectedDate <= dayEndUTC
        );
        const nextDates = takeRight(weekDatesData, weekDatesData.length - weekDateIndex - 1);
        const i = nextDates.findIndex(d => d.workEvents.length > 0);

        if (i !== -1) {
          const nextDate = selectedDate + (i + 1) * 86400; // 86400 seconds in a day
          await handleDateSelect(nextDate);
        } else {
          await switchPeriod(ButtonDirections.NEXT);
        }
      }
    } catch (error) {
      snackbarOn('error', 'No further timesheets for review');
      setDisableNext(true);
    } finally {
      setIsLoading('');
    }
  };

  const noTimesheetsMessage = () => {
    switch (tab) {
      case 'approved': {
        return 'No approved timesheets';
      }
      default: {
        return 'No timesheets submitted for review';
      }
    }
  };

  return (
    <div css={styles.outerContainer}>
      <ConfirmLeave when={timesheetEntriesToUpdate.length !== 0} />
      <div css={styles.controlContainer}>
        <ButtonGroup>
          <Button
            variant={dayOrWeek === 'day' && 'contained'}
            color={dayOrWeek === 'day' && 'primary'}
            onClick={() => {
              if (dayOrWeek === 'day') return;
              setDayOrWeek('day');
              reset('day');
            }}
          >
            DAY
          </Button>
          <Button
            variant={dayOrWeek === 'week' && 'contained'}
            color={dayOrWeek === 'week' && 'primary'}
            onClick={() => {
              if (dayOrWeek === 'week') return;
              setIsExportDisabled(true);
              setDayOrWeek('week');
              reset('week');
            }}
          >
            WEEK
          </Button>
        </ButtonGroup>
        <div css={styles.innerControlContainer}>
          <ThemeProvider>
            {dayOrWeek === 'day' && (
              <>
                <DateInput
                  value={selectedDate}
                  style={{ marginLeft: 24, height: 36, width: 250 }}
                  onChange={date => {
                    handleDateSelect(date);
                    updateTimesheetUrl({ employee: selectedEmployee, date });
                  }}
                  timezone={payrollSetting.timeZone}
                />
                <SergeantButton
                  type="tertiary"
                  style={{ height: 36, margin: '0 24px', padding: '5px 15px' }}
                  loading={isLoading === 'prev'}
                  disabled={disablePrev}
                  onClick={handleClickPrev}
                >
                  Previous
                </SergeantButton>
                <SergeantButton
                  type="tertiary"
                  style={{ height: 36, padding: '5px 15px' }}
                  loading={isLoading === 'next'}
                  disabled={disableNext}
                  onClick={handleClickNext}
                >
                  Next
                </SergeantButton>
              </>
            )}
            {dayOrWeek === 'week' && (
              <Select
                menuHeight={300}
                style={{ marginLeft: 24, height: 36, width: 250 }}
                options={timesheetPeriodOptions}
                onChange={({ value }) => handlePeriodSelection(value)}
                defaultValue={formatTimesheetPeriod(weekSelectedPeriod)}
              />
            )}
          </ThemeProvider>
        </div>
      </div>
      {isLoading === 'init' ? (
        <Spinner />
      ) : (
        <>
          <ThemeProvider>
            {dayOrWeek === 'day' && (
              <div css={styles.visitsSection}>
                {!isEmpty(weekDate) && weekDate.workEvents.length !== 0 ? (
                  <DayCard
                    isEditable={tab === 'toReview'}
                    day={weekDate}
                    payrollHourTypes={payrollHourTypes}
                    updateTimesheetEntry={handleTimesheetEntryDayChange}
                    payrollSetting={payrollSetting}
                    newAuditLogs={newAuditLogs}
                    tenantId={selectedEmployee.tenantId}
                    refetchTimesheetPeriod={() => handlePeriodSelection({ id: selectedPeriod.id })}
                    employeeId={selectedEmployee.id}
                  />
                ) : (
                  <>{selectedDate && <Typography>{noTimesheetsMessage()}</Typography>}</>
                )}
              </div>
            )}
            {dayOrWeek === 'week' && (
              <div css={styles.visitsSection}>
                {weekDates.map((day, i) => {
                  return (
                    <>
                      <DayCard
                        isEditable={tab === 'toReview'}
                        key={day.dayStartUTC}
                        day={day}
                        payrollHourTypes={payrollHourTypes}
                        updateTimesheetEntry={entry => handleTimesheetEntryChange(entry, i)}
                        payrollSetting={payrollSetting}
                        newAuditLogs={newAuditLogs}
                        tenantId={selectedEmployee.tenantId}
                        refetchTimesheetPeriod={() =>
                          handlePeriodSelection({ id: selectedPeriod.id })
                        }
                        employeeId={selectedEmployee.id}
                      >
                        {tab === 'toReview' && (
                          <div css={styles.approvalButtonsTopline}>
                            <SergeantButton
                              type="tertiary"
                              onClick={() => handleApprove(i)}
                              loading={isLoading === `approve${i}`}
                              disabled={day.dailyUnsubmittedEvents.length > 0}
                            >
                              Approve
                            </SergeantButton>
                            <SergeantButton
                              style={{ marginLeft: 24 }}
                              type="tertiary"
                              onClick={() => {
                                setRequestReasonDayIndex(i);
                                setIsRequestReasonModalOpen(true);
                              }}
                              loading={
                                !isRequestReasonModalOpen &&
                                isRequestReasonLoading === `requestReason${i}`
                              }
                              disabled={
                                day.dailyUnsubmittedEvents.length > 0 ||
                                !!day.workEvents.find(e => e.disableRequestRevision)
                              }
                            >
                              Request Revision
                            </SergeantButton>
                          </div>
                        )}
                        {tab === 'approved' && (
                          <div css={styles.reopenButton}>
                            <SergeantButton
                              type="tertiary"
                              onClick={() => {
                                setRequestReasonDayIndex(i);
                                setIsRequestReasonModalOpen(true);
                              }}
                              loading={
                                !isRequestReasonModalOpen && isRequestReasonLoading === `reopen${i}`
                              }
                            >
                              Reopen
                            </SergeantButton>
                          </div>
                        )}
                      </DayCard>
                      {day.workEvents.length !== 0 &&
                        i !== findLastIndex(weekDates, w => w.workEvents.length !== 0) && (
                          <Divider />
                        )}
                    </>
                  );
                })}
                {selectedPeriod.id && weekDates.every(w => w.workEvents.length === 0) && (
                  <Typography>{noTimesheetsMessage()}</Typography>
                )}
              </div>
            )}
            {((!isEmpty(weekDate) && dayOrWeek === 'day') ||
              (dayOrWeek === 'week' && weekDates?.some(w => w.workEvents.length > 0))) && (
              <div css={styles.fixedSection}>
                <div css={styles.totalsSection}>
                  <Typography variant={TV.L} weight={TW.BOLD} style={{ marginTop: 10 }}>
                    {dayOrWeek === 'week' ? 'Weekly' : 'Daily'} Totals
                  </Typography>
                  <div css={styles.totalsHourTypes}>
                    {payrollHourTypes.map(({ hourTypeAbbreviation, id }, i) => {
                      let validHourTypes = [];
                      if (dayOrWeek === 'day') {
                        validHourTypes = weekDate.validHourTypes;
                      }
                      if (dayOrWeek === 'week') {
                        const unsortedHourTypesToShow = weekDates.reduce(
                          (hourTypes, date) =>
                            date.workEvents.length > 0
                              ? [...hourTypes, ...date.validHourTypes]
                              : hourTypes,
                          []
                        );
                        validHourTypes = payrollHourTypes.reduce(
                          (hourTypes, type) =>
                            unsortedHourTypesToShow.includes(type.hourTypeAbbreviation)
                              ? [...hourTypes, type.hourTypeAbbreviation]
                              : hourTypes,
                          []
                        );
                      }
                      return (
                        validHourTypes.includes(hourTypeAbbreviation) && (
                          <Field
                            key={id}
                            type={FieldType.NUMBER}
                            label={hourTypeAbbreviation}
                            style={{ width: 50, marginLeft: i === 0 ? 0 : 24 }}
                            innerStyle={{ fontWeight: 700 }}
                            value={secondsToHour(
                              dayOrWeek === 'day'
                                ? weekDate.dailyTotals[hourTypeAbbreviation] || 0
                                : weekDates.reduce((acc, day) => {
                                    const { dailyTotals } = day;
                                    return acc + dailyTotals[hourTypeAbbreviation];
                                  }, 0)
                            )}
                          />
                        )
                      );
                    })}
                  </div>
                  {tab === 'toReview' && (
                    <div css={styles.approvalButtons}>
                      <SergeantButton
                        type="secondary"
                        onClick={updateTimesheetEntries}
                        disabled={isSaveEditsDisabled}
                        loading={isLoading === 'saveEdits'}
                      >
                        Save Edits
                      </SergeantButton>
                      {dayOrWeek === 'day' && (
                        <>
                          <SergeantButton
                            type="tertiary"
                            style={{ marginLeft: 24 }}
                            onClick={() => handleApprove()}
                            loading={isLoading === 'approve'}
                            disabled={weekDate.dailyUnsubmittedEvents.length > 0}
                          >
                            Approve
                          </SergeantButton>
                          <SergeantButton
                            type="tertiary"
                            style={{ marginLeft: 24 }}
                            onClick={() => {
                              setRequestReasonDayIndex();
                              setIsRequestReasonModalOpen(true);
                            }}
                            loading={
                              !isRequestReasonModalOpen &&
                              isRequestReasonLoading === 'requestReason'
                            }
                            disabled={
                              weekDate.dailyUnsubmittedEvents.length > 0 ||
                              !!weekDate.workEvents.find(e => e.disableRequestRevision)
                            }
                          >
                            Request Revision
                          </SergeantButton>
                        </>
                      )}
                    </div>
                  )}
                  {tab === 'approved' && (
                    <div css={styles.reopenButton}>
                      {dayOrWeek === 'day' && (
                        <SergeantButton
                          type="tertiary"
                          onClick={() => {
                            setRequestReasonDayIndex();
                            setIsRequestReasonModalOpen(true);
                          }}
                          loading={!isRequestReasonModalOpen && isRequestReasonLoading === 'reopen'}
                        >
                          Reopen
                        </SergeantButton>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            <div style={{ height: tab === 'approved' && dayOrWeek === 'week' ? 131 : 171 }} />
          </ThemeProvider>

          <SergeantModal
            open={isRequestReasonModalOpen}
            title={tab === 'toReview' ? 'Request Revision' : 'Reopen Timesheet'}
            layout={requestReasonMetaLayout}
            handleClose={() => setIsRequestReasonModalOpen(false)}
            handlePrimaryAction={handleRequestRevisionOrReopen}
          />
        </>
      )}
    </div>
  );
};

const useStyles = theme => ({
  outerContainer: {
    minHeight: 500
  },
  container: {
    display: 'flex'
  },
  visitsSection: {
    marginTop: 36
  },
  fixedSection: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100vw',
    backgroundColor: theme.palette.grayscale(95),
    borderTop: 'solid 1px',
    borderColor: theme.palette.other.dimGray,
    padding: 24
  },
  totalsSection: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  totalsHourTypes: {
    display: 'flex',
    marginLeft: 24
  },
  approvalButtons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  approvalButtonsTopline: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '25%'
  },
  reopenButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  controlContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: -70
  },
  innerControlContainer: {
    width: 500
  }
});

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(ToReview);
