import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Grid, IconButton, withStyles } from '@material-ui/core';
import _, { pick, reduce } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import AmplifyService from 'services/AmplifyService';
import {
  validations,
  CustomerService,
  ListService,
  CommonService,
  CustomerPropertyService
} from 'services/core';
import ErrorBoundaries from 'scenes/Error';
import { customerLayout } from 'meta/Customer';
import { sendMixpanelEvent } from 'services/mixpanel';
import { MIXPANEL_EVENT, MIXPANEL_PAGE_NAME } from 'constants/mixpanelEvents';
import { Logger } from 'services/Logger';
import {
  processAddressArrayAsJson,
  capitalizeFirstLetter,
  sortArrayWithCaseInsensitive,
  checkPermission,
  logErrorWithCallback,
  getTenantSettingValueForKey,
  getAddressAndLocation
} from 'utils';
import {
  Notes,
  UserPermission,
  SergeantModal,
  StatusChip,
  FullScreenModal,
  TagButtons
} from 'components';
import PlacesSearch from 'components/BuildHeroFormComponents/PlacesSearch';
import LeftSidebarWithContent from 'components/Layouts/LeftSidebarWithContent';
import Context from 'components/Context';
import Labels from 'meta/labels';
import { snackbarOn, setOpenQuickAddModal } from 'redux/actions/globalActions';
import { AppConstants, PermissionConstants, TagType } from 'utils/AppConstants';
import {
  mainLayoutWithInlineForm,
  sidebarSection,
  EditCustomerNameForm
} from 'meta/Customer/CustomerDetail/index';
import EditIcon from '@material-ui/icons/Edit';
import CreateServiceAgreement from 'scenes/ServiceAgreements/CreateServiceAgreement';
import { StyledSectionDivider } from 'components/Divider';
import { NewCustomerLayout } from 'meta/Customer/NewCustomer/index';
import { MUIForm, Button, ButtonType, ThemeProvider } from '@buildhero/sergeant';
import { EditAddressModal } from 'components/Modal/EditAddressModal';
import {
  Mode,
  JobStatus,
  AccountingApp,
  TenantSetting,
  AddressType,
  PaymentTermType,
  EmailInvoiceTypeLabel,
  AccountType
} from 'utils/constants';
import LocationView from 'scenes/Maintenance/DetailView/CustomComponents/LocationView';
import AddressEditAndView from 'components/BuildHeroFormComponents/AddressEditAndView';
import { GetAdjustments } from 'scenes/Adjustments/Adjustments.gql';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from 'utils/FeatureFlagConstants';
import { constructSelectOptions } from 'utils/constructSelectOptions';
import { CustomerTabs } from './CustomerTabs';
import { fetchInlineForm, getHeaderButtons } from './utils';
import styles from './styles';

/**
 * Creates customer page. new customer fields & layouts are generated from the meta file
 * labels are fetched from application level
 * locale of the user is referred from user context
 */

const SCHEDULED_FOR_TODAY = 'Scheduled for today';
const ALL = 'All';
const MY_JOBS = 'My jobs';
const { TAX_RATE_ASSIGN_DISABLED } = TenantSetting;

const buttonTypes = {
  ACTIVATE: 'activate',
  DEACTIVATE: 'deactivate',
  CREATE_JOB: 'createJob',
  CREATE_SA: 'createServiceAgreement'
};

export class CustomerDetail extends Component {
  constructor(props) {
    super(props);
    this.mounted = props.mounted;
    this.CustomerService = new CustomerService();
    this.ListService = new ListService();
    this.CommonService = new CommonService();
    this.CustomerPropertyService = new CustomerPropertyService();

    let customerTypes = {};
    let customerTags = {};
    let taxRates = {};
    let paymentTerms = {};
    let companyAddresses = {};
    let company;
    let companyState = '';
    this.primaryContactsRef = React.createRef();
    this.propertyRef = React.createRef();
    let isCustomJobNumberEnabled;
    let isExtendedFieldsEnabled;
    let isQuickbookEnabled;
    let isIntacctEnabled;
    let isIntegrationEnabled;
    let syncNow;
    let title;
    let isTaxRateAutoAssignDisabled = false;
    let priceBooks = {};
    if (Context.getCompanyContext()) {
      ({
        companyAddresses,
        customerTypes,
        customerTags,
        taxRates,
        priceBooks,
        paymentTerms
      } = Context.getCompanyContext().getCompany);

      // Sort the customerTags by the tagName
      customerTags?.items.sort((a, b) => a.sortOrder - b.sortOrder);
      company = Context.getCompanyContext().getCompany;

      if (companyAddresses && companyAddresses.items && companyAddresses.items.length === 1) {
        companyState = companyAddresses.items[0].state;
      }

      const { listTenantSettings } = Context.getCompanyContext();

      const customJobNumberSetting =
        listTenantSettings &&
        listTenantSettings.filter(
          setting => setting.settingKey === 'job_customJobNumber' && setting.settingValue === 'true'
        );
      const extendedAddressFields =
        listTenantSettings &&
        listTenantSettings.filter(
          setting =>
            setting.settingKey === 'customer_extendedFields' && setting.settingValue === 'true'
        );
      const accountingIntegration = getTenantSettingValueForKey('accountingApp');
      isIntegrationEnabled = !!accountingIntegration;
      isQuickbookEnabled = accountingIntegration === AccountingApp.QUICKBOOKS;
      isIntacctEnabled = accountingIntegration === AccountingApp.INTACCT;

      const instantSyncSetting =
        listTenantSettings &&
        listTenantSettings.filter(
          setting => setting.settingKey === 'syncImmediately' && setting.settingValue === 'true'
        );
      syncNow = instantSyncSetting && instantSyncSetting.length > 0;
      isCustomJobNumberEnabled = customJobNumberSetting && customJobNumberSetting.length > 0;
      isExtendedFieldsEnabled = extendedAddressFields && extendedAddressFields.length > 0;
    }
    isTaxRateAutoAssignDisabled = getTenantSettingValueForKey(TAX_RATE_ASSIGN_DISABLED) === 'true';

    this.state = {
      company,
      title,
      customerTypes,
      customerTags,
      taxRates: taxRates?.items?.filter(t => t.accountType !== AccountType.AP),
      priceBooks,
      paymentTerms,
      openCustomerRep: false,
      openTenantRep: false,
      createProperty: false,
      dataRecord: '',
      modalMode: '',
      confirmDialog: false,
      confirmAction: '',
      confirmMessage: '',
      logoURL: '',
      customerData: '',
      customerRepsData: '',
      tenantRepsData: '',
      propertiesData: '',
      upcomingEvents: '',
      pastEvents: '',
      companyState: companyState || 'CA',
      collapsePanel: {
        primaryContacts: 'OFF',
        customerProperties: 'OFF',
        newJob: 'OFF',
        upcomingEvents: 'OFF',
        pastEvents: 'OFF'
      },
      isCustomJobNumberEnabled,
      isExtendedFieldsEnabled,
      isQuickbookEnabled,
      isIntacctEnabled,
      isLoading: true,
      syncNow,
      isIntegrationEnabled,
      globalFilters: '',
      globalFilterName: ALL,
      refreshCounter: 0,
      isTaxRateAutoAssignDisabled,
      adjustmentModalOpen: false,
      deactivateConfirmationModal: {
        open: false,
        data: null,
        primaryButtonLabel: 'Deactivate Customer and Its Properties',
        dataType: 'Customer',
        mode: Mode.DEACTIVATE,
        // eslint-disable-next-line no-console
        handlePrimaryAction: () => console.log('no primary action set'),
        layout: null,
        deleteItemLabel: '',
        deactivateStatement:
          '\nDeactivating this customer will also deactivate all of its properties.' +
          ' However, all pre-existing jobs, visits, payments, and invoices will remain open.'
      },
      activateConfirmationModal: {
        open: false,
        data: null,
        primaryButtonLabel: 'Activate Customer Only',
        secondaryButtonLabel: 'Activate Customer and Its Properties',
        dataType: 'Customer',
        mode: Mode.ACTIVATE,
        // eslint-disable-next-line no-console
        handlePrimaryAction: () => console.log('no primary action set'),
        layout: null,
        deleteItemLabel: ''
      },
      formService: () => console.log('No form service'),
      showBusinessAddress: false,
      isSubmitting: false
    };
  }

  async componentDidMount(sortKey) {
    let recordSortKey = '';
    this.setState({ isLoading: true });
    if (this.props.history.location.state && this.props.history.location.state.recordSortKey) {
      ({ recordSortKey } = this.props.history.location.state);
    }
    recordSortKey = recordSortKey || sortKey;
    if (
      this.props.user.appPermissionRules &&
      checkPermission('read', PermissionConstants.OBJECT_CUSTOMER, this.props.user)
    ) {
      this.queryAndSetCustomerReps(recordSortKey);
      this.queryAndSetTenantReps(recordSortKey);
      this.queryAndSetProperties(recordSortKey);
      this.queryAndSetAttachments(recordSortKey);
      this.queryAndSetCustomFormFields();
      await this.queryAndSetCustomerInfo(recordSortKey);
      await this.queryAndSetCustomerCustomFieldData(recordSortKey);
    }

    this.setState({ isLoading: false });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleOpenPopUp = (popUpKey, mode, record) => {
    this.setState({ [popUpKey]: true, modalMode: mode, dataRecord: record });
  };

  handleClosePopUp = popUpKey => {
    this.setState({ [popUpKey]: false });
  };

  handleChange = panel => {
    const toggle = { ON: 'OFF', OFF: 'ON' };
    const val = toggle[this.state.collapsePanel[panel]];
    const localCollapsePanel = { ...this.state.collapsePanel, [panel]: val };
    this.setState(prevState => ({ ...prevState, collapsePanel: localCollapsePanel }));
  };

  deactivateRecord = async (values, companyName) => {
    try {
      const { data } = await this.CustomerService.deactivateCustomer(values);
      if (data) {
        await this.queryAndSetCustomerInfo(this.state.customerData.sortKey);
        this.props.snackbarOn('success', `Successfully deleted property: ${companyName}`);
        this.setState({
          confirmDialog: false,
          confirmAction: '',
          confirmMessage: ''
        });
      }
    } catch (error) {
      logErrorWithCallback(error, this.props.snackbarOn, 'Unable to delete customer');
    }
  };

  handleEventsRowActions = (mode, record) => {
    if (mode === 'view') {
      this.props.history.push(`/job/view/${encodeURIComponent(record.jobNumber)}`, {
        recordSortKey: record.jobSortKey,
        customerSortKey: record.customerSortKey
      });
    }
  };

  handleCancelConfirmation = () => {
    this.setState({ confirmDialog: false, confirmAction: '', confirmMessage: '' });
  };

  // eslint-disable-next-line consistent-return
  queryAndSetCustomerReps = async recordSortKey => {
    // on first load, the tenant id was not set, hence the check
    if (this.props.user && this.props.user.tenantId === '') {
      return null;
    }
    let data;
    let localCustomerData;
    const customerId = this.props.computedMatch.params && this.props.computedMatch.params.id;
    try {
      if (recordSortKey) {
        ({ data } = await this.CustomerService.getCustomerRepsByCustomer(
          this.props.user.tenantId,
          recordSortKey
        ));
        if (data) localCustomerData = data.getCustomer;
      } else if (customerId) {
        ({ data } = await this.CustomerService.getCustomerRepsByCustomerById(customerId));
        if (data) localCustomerData = data.getCustomerById;
      }
    } catch (error) {
      Logger.error(`Error in fetching customer details ${JSON.stringify(error)}`);
      this.props.snackbarOn('error', 'Unable to fetch data');
    }

    if (localCustomerData) {
      if (localCustomerData?.customerReps?.items?.length) {
        localCustomerData.customerReps.items.forEach(rep => {
          const localRep = rep;
          localRep.status = localRep.status ? capitalizeFirstLetter(localRep.status) : '';
          localRep.repNotes = rep.notes.items.sort(
            (left, right) => right.lastUpdatedDateTime - left.lastUpdatedDateTime
          );
          localRep.notesCount = rep.notes.items.length || 0;
          localRep.emailInvoiceLabel =
            EmailInvoiceTypeLabel[localRep.emailInvoice] || EmailInvoiceTypeLabel.NonBilling;
        });
      }

      if (this.mounted) {
        this.setState(prevState => ({
          ...prevState,
          customerRepsData: localCustomerData.customerReps
        }));
      }
    }
  };

  // eslint-disable-next-line consistent-return
  queryAndSetTenantReps = async recordSortKey => {
    // on first load, the tenant id was not set, hence the check
    if (this.props.user && this.props.user.tenantId === '') {
      return null;
    }

    let data;
    let localCustomerData;
    const customerId = this.props.computedMatch.params && this.props.computedMatch.params.id;
    try {
      if (recordSortKey) {
        ({ data } = await this.CustomerService.getTenantRepsByCustomer(
          this.props.user.tenantId,
          recordSortKey
        ));
        if (data) localCustomerData = data.getCustomer;
      } else if (customerId) {
        ({ data } = await this.CustomerService.getTenantRepsByCustomerById(customerId));
        if (data) localCustomerData = data.getCustomerById;
      }
    } catch (error) {
      Logger.error(`Error in fetching customer details ${JSON.stringify(error)}`);
      this.props.snackbarOn('error', 'Unable to fetch data');
    }

    if (localCustomerData) {
      let tenantReps = [];
      if (localCustomerData?.tenantReps?.items?.length) {
        localCustomerData.tenantReps.items.forEach(rep => {
          const localRep = rep;
          const employee = localRep.mappedEntity || {};
          localRep.name = employee.name || `${employee.firstName} ${employee.lastName}`;
          localRep.status = localRep.status ? capitalizeFirstLetter(localRep.status) : '';

          if (employee?.appRoles?.items?.length) {
            localRep.contactRole = employee.appRoles.items[0].mappedEntity.tagName;
          }
          localRep.repNotes =
            employee?.notes?.items?.sort(
              (left, right) => right.lastUpdatedDateTime - left.lastUpdatedDateTime
            ) || [];
          localRep.notesCount = employee?.notes?.items?.length || 0;
          tenantReps.push(localRep);
        });
        tenantReps = sortArrayWithCaseInsensitive('name', tenantReps);
      }

      // from customer
      localCustomerData.processedTenantReps = tenantReps;

      if (this.mounted) {
        this.setState(prevState => ({
          ...prevState,
          tenantRepsData: localCustomerData.processedTenantReps
        }));
      }
    }
  };

  queryAndSetProperties = async recordSortKey => {
    // on first load, the tenant id was not set, hence the check
    if (this.props.user && this.props.user.tenantId === '') {
      return null;
    }

    let data;
    let localCustomerData;
    const incompleteJobs = new Map();
    const customerId = this.props.computedMatch.params && this.props.computedMatch.params.id;
    try {
      if (recordSortKey) {
        ({ data } = await this.CustomerService.getPropertiesByCustomer(
          this.props.user.tenantId,
          recordSortKey
        ));
        localCustomerData = data.getCustomer;
      } else if (customerId) {
        ({ data } = await this.CustomerService.getPropertiesByCustomerById(customerId));
        if (data) localCustomerData = data.getCustomerById;
      }
    } catch (error) {
      Logger.error(`Error in fetching customer details ${JSON.stringify(error)}`);
      this.props.snackbarOn('error', 'Unable to fetch data');
    }

    if (localCustomerData) {
      let noOfProperties;
      // adding no of properties
      localCustomerData.noOfProperties =
        localCustomerData.customerProperties && localCustomerData.customerProperties.items
          ? localCustomerData.customerProperties.items.length
          : 0;
      if (
        localCustomerData.customerProperties &&
        localCustomerData.customerProperties.items &&
        localCustomerData.customerProperties.items.length > 0
      ) {
        noOfProperties = localCustomerData.customerProperties.items.length;
        localCustomerData.customerProperties.items.forEach((property, index) => {
          if (property?.companyAddresses?.items?.length) {
            const localCombinedAddress = processAddressArrayAsJson(property.companyAddresses.items);
            localCustomerData.customerProperties.items[index] = {
              ...property,
              ...localCombinedAddress
            };
          }
          // get completed jobs count
          localCustomerData.customerProperties.items[index].jobCompletedCount = (
            (property.jobs &&
              property.jobs.items.filter(job => job.status === JobStatus.COMPLETE)) ||
            []
          ).length;

          const propertyIncompleteJobs = property.jobs.items.filter(
            job =>
              ![JobStatus.COMPLETE, JobStatus.CANCELED, JobStatus.CONVERTED].includes(job.status)
          );
          incompleteJobs.set(
            property.id,
            propertyIncompleteJobs.map(job => job.jobNumber)
          );
        });
      }
      if (this.mounted) {
        this.setState(prevState => ({
          ...prevState,
          propertiesData: localCustomerData.customerProperties,
          noOfProperties: noOfProperties || 0,
          incompleteJobNumbers: incompleteJobs
        }));
      }
    }

    return localCustomerData;
  };

  queryAndSetCustomerInfo = async () => {
    // on first load, the tenant id was not set, hence the check
    if (this.props.user && this.props.user.tenantId === '') {
      return null;
    }

    const recordId = this.props.computedMatch.params && this.props.computedMatch.params.id;

    let data;
    let localCustomerData;
    try {
      if (recordId) {
        ({ data } = await this.CustomerService.getCustomerById(`${recordId}`));
        localCustomerData = data.getCustomerById;
      }
    } catch (error) {
      logErrorWithCallback(error, this.props.snackbarOn, 'Unable to fetch data');
      this.props.history.push('/customer/list');
    }

    const customerTagArray = [];

    if (localCustomerData) {
      // replace None with empty string to have 'None' option selected in dropdown
      localCustomerData.taxRateId =
        localCustomerData.taxRateId === 'None' ? '' : localCustomerData.taxRateId;
      localCustomerData.priceBookId =
        localCustomerData.priceBookId === 'None' ? '' : localCustomerData.priceBookId;
      localCustomerData.customerTypeValue =
        localCustomerData.customerTypeValue === null ? '' : localCustomerData.customerTypeValue;
      localCustomerData.status = localCustomerData.status
        ? capitalizeFirstLetter(localCustomerData.status)
        : '';
      if (localCustomerData.sameAddress) {
        localCustomerData.sameAddress = `${localCustomerData.sameAddress}`;
      }

      localCustomerData.customerNotes = localCustomerData.notes.items.sort(
        (left, right) => right.lastUpdatedDateTime - left.lastUpdatedDateTime
      );

      // get customer tags from mappedEntity and sort by tagName
      if (localCustomerData?.customerTags?.items?.length) {
        localCustomerData.customerTags.items.forEach(tag => {
          const { mappedEntity } = tag;
          if (mappedEntity) {
            customerTagArray.push({ id: mappedEntity.id, label: `${mappedEntity.tagName}` });
          }
        });
      }
      customerTagArray.sort((a, b) => {
        const labelA = a.label.toLowerCase(); // ignore upper and lowercase
        const labelB = b.label.toLowerCase(); // ignore upper and lowercase
        return labelA.localeCompare(labelB);
      });
      // processedCustomerTags for ChipList and selectedCustomerTags for SelectInput
      localCustomerData.processedCustomerTags = customerTagArray;
      localCustomerData.selectedCustomerTags = customerTagArray.map(tag => tag.id);

      // from company - master data
      const { company } = this.state;
      localCustomerData.customerTagsList = this.state.customerTags;
      localCustomerData.customerPropertyTypes = ((company || {}).customerPropertyTypes || {}).items;

      if (this.state.noOfProperties) {
        data.noOfProperties = this.state.noOfProperties;
      }

      localCustomerData.isExtendedFieldsEnabled = this.state.isExtendedFieldsEnabled;

      localCustomerData.taxRateLabel = localCustomerData.taxRate
        ? `${localCustomerData.taxRate.name} - ${localCustomerData.taxRate.taxRate}`
        : null;
      localCustomerData.paymentTermLabel = localCustomerData.paymentTerm?.name;
      localCustomerData.invoicePresetLabel = localCustomerData.invoicePreset?.displayName;

      if (this.mounted) {
        this.setState(prevState => {
          const tempCustomerData = prevState.customerData || {};
          return {
            ...prevState,
            customerData: { ...tempCustomerData, ...localCustomerData },
            confirmDialog: false,
            confirmAction: '',
            confirmMessage: '',
            selectedCustomerTags: localCustomerData.selectedCustomerTags,
            mainSectionFormFields: {
              customerTypeValue: localCustomerData?.customerTypeValue,
              isTaxable: localCustomerData?.isTaxable,
              taxRateId: localCustomerData?.taxRateId || '',
              priceBookId: localCustomerData?.priceBookId
            }
          };
        });
      }
    }

    return localCustomerData;
  };

  queryAndSetCustomerCustomFieldData = async recordSortKey => {
    // on first load, the tenant id was not set, hence the check
    if (this.props.user && this.props.user.tenantId === '') {
      return null;
    }

    const recordId = this.props.computedMatch.params && this.props.computedMatch.params.id;

    let data;
    let localCustomerData;
    try {
      if (recordSortKey) {
        ({ data } = await this.CustomerService.getCustomerFieldData(
          this.props.user.tenantId,
          recordSortKey
        ));
        localCustomerData = data.getCustomer;
      } else if (recordId) {
        ({ data } = await this.CustomerService.getCustomerFieldDataById(`${recordId}`));
        localCustomerData = data.getCustomerById;
      }
    } catch (error) {
      logErrorWithCallback(error, this.props.snackbarOn, 'Unable to fetch custom form data');
    }
    let customFields; // inline fields key array
    let customFieldsData; // inline fields data
    if (localCustomerData) {
      // Custom form data
      let customerCustomFieldsData;
      if (localCustomerData?.formData?.items?.length) {
        localCustomerData.formData.items.forEach(fdata => {
          const { id, version, form, ...localData } = fdata;
          customFieldsData = localData;
          customFields = Object.keys(localData);
          customerCustomFieldsData = {
            ...localData,
            formDataId: id,
            formDataVersion: version
          };
        });
      }

      if (this.mounted) {
        this.setState(prevState => {
          const tempCustomerData = prevState.customerData || {};
          return {
            ...prevState,
            customerData: { ...tempCustomerData, ...customerCustomFieldsData },
            customFields,
            confirmDialog: false,
            confirmAction: '',
            confirmMessage: '',
            mainSectionFormFields: {
              ...prevState.mainSectionFormFields,
              ...customFieldsData
            }
          };
        });
      }
    }
    return localCustomerData;
  };

  queryAndSetAttachments = async recordSortKey => {
    // on first load, the tenant id was not set, hence the check
    if (this.props.user && this.props.user.tenantId === '') {
      return null;
    }

    let data;
    let localCustomerData;
    const customerId = this.props.computedMatch.params && this.props.computedMatch.params.id;
    try {
      if (recordSortKey) {
        data = await this.CustomerService.getCustomerAttachments(
          this.props.user.tenantId,
          recordSortKey
        );
      } else if (customerId) {
        data = await this.CustomerService.getCustomerAttachmentsById(customerId);
      }
      // backward compatibility
      if (data && data.items) {
        data.items.map(item => {
          const localItem = item;
          if (!item.comment) {
            localItem.comment = item.description || '';
          }
          return localItem;
        });
      }
    } catch (error) {
      Logger.error(`Error in fetching customer details ${JSON.stringify(error)}`);
      this.props.snackbarOn('error', 'Unable to fetch attachments data');
    }
    if (data && this.mounted) {
      this.setState(prevState => ({
        ...prevState,
        customerAttachments: data || { items: [] }
      }));
    }

    return localCustomerData;
  };

  processVisitData = customerVisits => {
    const processedVisit = [];
    customerVisits.forEach(visit => {
      let primaryTech;
      if (visit?.primaryTechs?.items?.length) {
        const employee = visit.primaryTechs.items[0].mappedEntity || {};
        primaryTech = employee.name || `${employee.firstName} ${employee.lastName}`;
      }

      let date;
      if (!visit.scheduledFor) {
        date = visit.tentativeDate ? moment(visit.tentativeDate) : '';
      } else {
        date = moment.unix(visit.scheduledFor);
      }

      const processedJson = {
        eventType: visit.parentEntity.jobTypeInternal,
        status: visit.status,
        date,
        description: visit.description,
        assignedTo: primaryTech || '-',
        propertyName: visit.parentEntity.customerPropertyName,
        jobNumber: visit.parentEntity.jobNumber,
        jobTypeInternal: visit.parentEntity.jobTypeInternal,
        customerSortKey: visit.parentEntity.customerSortKey,
        jobSortKey: visit.parentEntity.sortKey,
        companyState: this.state.companyState,
        // To support custom job number on and off scenarios
        customIdentifier: visit.parentEntity.customIdentifier || visit.parentEntity.jobNumber
      };
      processedVisit.push(processedJson);
    });
    return processedVisit;
  };

  queryJobsByCustomer = async (filter, limit, offset, sortBy, sortOrder) => {
    if (!this.props.user.tenantId) {
      return { items: [], nextToken: '0' };
    }
    let data;
    const { sortKey } = this.state.customerData;
    try {
      data = await this.CustomerService.getJobsByCustomer(
        this.props.user.tenantId,
        sortKey,
        filter,
        limit,
        offset,
        sortBy,
        sortOrder
      );

      return data;
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to fetch Jobs', error);
    }
    return data || [];
  };

  queryUpcomingVisit = async (filter, limit, offset, sortBy, sortOrder) => {
    const { sortKey } = this.state.customerData;
    try {
      const { data } = await this.CustomerService.getUpcomingVisitsByCustomer(
        this.props.user.tenantId,
        sortKey,
        filter,
        limit,
        offset,
        sortBy,
        sortOrder
      );

      if (data?.getCustomer?.visits?.items?.length > 0) {
        const processedVisits = this.processVisitData(data.getCustomer.visits.items);
        const returnData = { items: processedVisits };
        return returnData;
      }
    } catch (error) {
      logErrorWithCallback(error, this.props.snackbarOn, 'Unable to fetch visits');
    }
    return [];
  };

  queryPastVisit = async (filter, limit, offset, sortBy, sortOrder) => {
    const { sortKey } = this.state.customerData;
    try {
      const { data } = await this.CustomerService.getPastVisitsByCustomer(
        this.props.user.tenantId,
        sortKey,
        filter,
        limit,
        offset,
        sortBy,
        sortOrder
      );

      if (data && data.getCustomer) {
        const processedVisits = this.processVisitData(data.getCustomer.visits.items);
        return { items: processedVisits };
      }
    } catch (error) {
      logErrorWithCallback(error, this.props.snackbarOn, 'Unable to fetch visits');
    }
    return [];
  };

  handleOnComplete = async (data, refresh = true) => {
    data = { ...data, ...data.billingAddressFields, ...data.businessAddressFields };
    // If editing an existing customer, we need to get updated tags from state
    if (this.state.selectedCustomerTags?.length) {
      data.processedCustomerTags = this.state.selectedCustomerTags;
    }
    const mode = this.props.computedMatch.params && this.props.computedMatch.params.mode;
    data.isTaxable = data.isTaxable && JSON.parse(data.isTaxable); // for some reason isTaxable is a string, convert to boolean
    data.receiveSMS = data.receiveSMS && JSON.parse(data.receiveSMS); // for some reason receiveSMS is a string, convert to boolean
    // validation - if isTaxable then require taxRate
    if (data.isTaxable && !data.taxRateId) {
      return this.props.snackbarOn(
        'error',
        'Tax rate required for a taxable customer.\nPlease select a tax rate.'
      );
    }

    if (!data.taxRateId) {
      // override the previous taxRateId if None is selected. Otherwise, it won't update the field
      // eslint-disable-next-line no-param-reassign
      data.taxRateId = 'None';
    }

    this.setState({ isSubmitting: true });
    try {
      let response;
      let customerDataObj;
      if (mode === 'new') {
        const localData = data;
        const customerNumberResponse = await this.CustomerService.getCustomerNumber();
        if (customerNumberResponse) {
          const result = customerNumberResponse.data.getCurrentCounter;
          localData.customerNumber = JSON.parse(result).customer;
        }
        localData.defaultProperty = !!(
          localData.defaultProperty && localData.defaultProperty.toString() === 'true'
        );

        response = await this.CustomerService.addCustomer(
          this.props.user.tenantId,
          this.props.user.tenantCompanyId,
          localData,
          this.state
        );
        if (response?.data?.addCustomersToCompany?.length) {
          const addCustomerResponse = response.data.addCustomersToCompany;
          [customerDataObj] = addCustomerResponse;
          sendMixpanelEvent(MIXPANEL_EVENT.CREATED_CUSTOMER, MIXPANEL_PAGE_NAME.CUSTOMER_DETAIL);

          // Add notes to the new created customer
          if (localData?.customerNotes?.length) {
            const notesData = _.sortBy(localData.customerNotes, ['noteKey']);
            await Promise.all(
              notesData.map(async note => {
                const finalNote = note;
                finalNote.parent = { ...customerDataObj, tenantId: this.props.user.tenantId };
                await this.CommonService.mutateNote(finalNote);
              })
            );
          }

          // Here if creating a new customer and checkbox is selected so address is also a property.
          // Adding a billingCustomerId to the new property
          if (localData.defaultProperty) {
            const { id: customerId, partitionKey } = addCustomerResponse[0];
            const newProperty = addCustomerResponse[0].customerProperties?.items[0];
            const payload = {
              ...newProperty,
              billTo: localData.billingBillTo || null,
              billingCustomerId: customerId,
              parent: { id: customerId }
            };
            try {
              await this.CustomerPropertyService.updateCustomerPropertyAndRelated(
                partitionKey,
                payload,
                customerId,
                this.state.syncNow
              );
            } catch (error) {
              Logger.error(error);
            }
          }
        }
      } else {
        response = await this.CustomerService.updateCustomerAndRelated(
          this.props.user.tenantId,
          this.props.user.tenantCompanyId,
          data,
          this.state
        );
        if (response?.data?.updateCustomerAndRelated?.length) {
          [customerDataObj] = response.data.updateCustomerAndRelated;
        }
      }
      const pathname = `/customer/view/${customerDataObj.id}`;
      if (refresh) {
        this.props.history.push(
          { pathname },
          {
            recordSortKey: customerDataObj.sortKey
          }
        );
        this.componentDidMount(customerDataObj.sortKey);
      }

      this.props.snackbarOn('success', `Successfully updated ${data.customerName}`);
    } catch (error) {
      logErrorWithCallback(error, this.props.snackbarOn, 'Unable to update customer');
      this.setState({ isSubmitting: false });
      return null;
    }
    this.setState({ isSubmitting: false });
    return data;
  };

  handleActivateClick = async data => {
    this.setState(prevState => {
      const updatedModal = {
        ...prevState.activateConfirmationModal,
        open: true,
        data,
        handlePrimaryAction: this.handleActivateModalClickNoCascade,
        handleSecondaryAction: this.handleActivateModalClickWithCascade
      };
      return { activateConfirmationModal: updatedModal };
    });
  };

  handleDeactivateClick = async data => {
    this.setState(prevState => {
      const updatedModal = {
        ...prevState.deactivateConfirmationModal,
        deactivateItemLabel: `${data.customerName}`,
        open: true,
        data,
        handlePrimaryAction: this.handleDeactivateModalClickWithCascade
      };
      return { deactivateConfirmationModal: updatedModal };
    });
  };

  createJob = () => {
    this.props.history.push('/job/new', {
      customerName: this.state.customerData.customerName,
      customerSortKey: this.state.customerData.sortKey
    });
  };

  getServiceAgreements = async (filter, limit, offset, sortBy, sortOrder) => {
    const { user } = this.props;
    const { customerData } = this.state;
    if (!user.tenantId) return { items: [], nextToken: '0' };

    const sortKey = `${user.tenantId}_Company_${user.tenantCompanyId}`;
    const hardFilter = {
      stringFilters: [
        {
          fieldName: 'ServiceAgreement.customerId',
          filterInput: { eq: customerData.id }
        }
      ]
    };
    let data;
    try {
      data = await this.CustomerService.getServiceAgreementsByCustomer(
        user.tenantId,
        sortKey,
        hardFilter,
        limit,
        offset,
        sortBy,
        sortOrder
      );
      return data || [];
    } catch (error) {
      logErrorWithCallback(error, snackbarOn, 'Unable to fetch invoices');
    }
    return data || [];
  };

  getCustomerAdjustments = async (
    filter,
    limit,
    offset,
    sortField = 'createdDate',
    sortDirection = 'DESC'
  ) => {
    const { user } = this.props;
    const { customerData } = this.state;
    const { client } = AmplifyService.appSyncClient();
    const customerFilter = {
      stringFilters: [
        {
          fieldName: 'billingCustomerId',
          filterInput: { eq: customerData.id }
        }
      ]
    };
    try {
      const {
        data: {
          getCompany: { adjustments }
        }
      } = await client.query({
        query: GetAdjustments,
        variables: {
          partitionKey: user.tenantId,
          sortKey: `${user.tenantId}_Company_${user.tenantCompanyId}`,
          filter: customerFilter,
          limit,
          offset,
          sort: [{ sortField, sortDirection }]
        }
      });
      return adjustments;
    } catch (error) {
      logErrorWithCallback(error, snackbarOn, 'Unable to fetch Customer Adjustments.');
    }
    return { items: [], nextToken: 0 };
  };

  getCustomerInvoices = async (
    filter,
    limit,
    offset,
    sortBy,
    sortOrder,
    status,
    paymentId,
    unpaidInvoiceIds
  ) => {
    const { user } = this.props;
    const { customerData } = this.state;
    if (!user.tenantId) return { items: [], nextToken: '0' };

    const sortKey = `${user.tenantId}_Company_${user.tenantCompanyId}`;
    const hardFilter = {
      stringFilters: [
        {
          fieldName: 'BillingCustomer.id',
          filterInput: { eq: customerData.id }
        }
      ]
    };
    let data;
    try {
      data = await this.ListService.getAllInvoices(
        user.tenantId,
        sortKey,
        hardFilter,
        limit,
        offset,
        sortBy,
        sortOrder,
        status,
        paymentId,
        unpaidInvoiceIds,
        this.props.flags.adjustments
      );
      return data;
    } catch (error) {
      logErrorWithCallback(error, snackbarOn, 'Unable to fetch invoices');
    }
    return data || [];
  };

  getCustomerPayments = async (filter, limit, offset, sortBy, sortOrder, status) => {
    const { user } = this.props;
    const { customerData } = this.state;
    const noResponse = { items: [], nextToken: '0' };
    if (!user?.tenantId) return noResponse;

    const { tenantId, tenantCompanyId } = user;
    const sortKey = `${tenantId}_Company_${tenantCompanyId}`;
    const hardFilter = {
      stringFilters: [
        {
          fieldName: 'BillingCustomer.id',
          filterInput: { eq: customerData.id }
        }
      ]
    };
    try {
      const resp = await this.ListService.getAllPayments(
        tenantId,
        sortKey,
        hardFilter,
        limit,
        offset,
        sortBy,
        sortOrder,
        status
      );
      const { items, ...rest } = resp;
      if (!items) return resp;
      return {
        items: this.formatPaymentListData(items),
        ...rest
      };
    } catch (error) {
      logErrorWithCallback(error, this.props.snackbarOn, 'Unable to fetch Payments');
    }
    return noResponse;
  };

  formatPaymentListData = payments =>
    payments.map(({ billingCustomer, paymentType, ...rest }) => ({
      billingCustomerName: billingCustomer?.customerName,
      billingCustomerId: billingCustomer?.id,
      paymentTypeName: paymentType?.name,
      paymentTypeId: paymentType?.id,
      ...rest
    }));

  toggleCustomerActivity = (data, isActive) =>
    this.CustomerService.toggleCustomerActivity(data.partitionKey, {
      id: data.id,
      isActive,
      status: isActive ? AppConstants.ACTIVE : AppConstants.INACTIVE,
      version: data.version,
      syncStatus: 'Syncing'
    });

  updateCustomerStatus = (data, isActive, cascade) => {
    const params = {
      customerId: data.id,
      status: isActive ? AppConstants.ACTIVE : AppConstants.INACTIVE,
      isActive,
      cascade
    };
    if (this.state.syncNow) {
      params.syncStatus = 'Syncing';
    } else {
      params.syncStatus = null;
    }
    return this.CustomerService.updateCustomerStatus(data.partitionKey, params);
  };

  deactivateCustomerActivity = async data => {
    await this.toggleCustomerActivity(data, false);
    await this.queryAndSetCustomerInfo(data.sortKey);
    this.props.snackbarOn('success', `Successfully deactivated - ${data.customerName}`);
  };

  handleCustomerAttachment = async values => {
    const { customerData } = this.state;
    const { user } = this.props;
    let response;
    if (values.id) {
      response = await this.CommonService.updateAttachment(user.tenantId, values);
    } else {
      response = await this.CustomerService.addAttachmentsToCustomer(
        user.tenantId,
        customerData.id,
        values
      );
    }
    return response;
  };

  queryAndSetCustomFormFields = async () => {
    const companySortKey = `${this.props.user.tenantId}_Company_${this.props.user.tenantCompanyId}`;
    try {
      const { formAttributes, inlineForm } = await fetchInlineForm(
        this.props.user.tenantId,
        companySortKey,
        'Customer'
      );

      if (this.mounted && inlineForm) {
        this.setState(prevState => ({
          ...prevState,
          formAttributes,
          inlineForm
        }));
      }
    } catch (err) {
      Logger.error(err);
      this.props.snackbarOn('error', 'Unable to fetch custom fields in the form', err);
    }
  };

  getLayoutTitle = () => {
    const { customerData } = this.state;
    return customerData.customerName || '';
  };

  setGlobalFilters = async value => {
    let { globalFilters } = this.state;
    const { globalFilterName } = this.state;

    if (value === globalFilterName) {
      return;
    }

    if (value === 'All') {
      this.setState(prevState => ({
        ...prevState,
        globalFilters: '',
        globalFilterName: value,
        refreshCounter: prevState.refreshCounter + 1
      }));
      return;
    }

    if (value === MY_JOBS) {
      let { stringFilters } = globalFilters || {};

      if (!stringFilters) {
        stringFilters = [];
      }

      stringFilters.push({
        fieldName: 'Job.ownerId',
        filterInput: { eq: this.props.user.employeeId }
      });
      if (!globalFilters) {
        globalFilters = {};
      }
      globalFilters.stringFilters = stringFilters;

      if (globalFilters.subQueryFilters) {
        delete globalFilters.subQueryFilters;
      }
    }

    if (value === SCHEDULED_FOR_TODAY) {
      const currentStartOfDay = moment()
        .startOf('day')
        .unix();
      const currentEndOfDay = moment()
        .endOf('day')
        .unix();

      globalFilters = {};
      globalFilters.subQueryFilters = [
        {
          fieldName: 'exists',
          fieldComparator: 'exists',
          subQueryFieldName: 'Visit.id',
          entityConnection: 'Visit',
          filter: {
            stringFilters: [
              {
                fieldName: 'Visit.parentId',
                filterInput: {
                  eq: 'Job.id'
                },
                literal: true
              }
            ],
            integerFilters: [
              {
                fieldName: 'Visit.scheduledFor',
                filterInput: {
                  between: [currentStartOfDay, currentEndOfDay]
                }
              }
            ]
          }
        }
      ];
    }
    this.setState(prevState => ({
      globalFilters,
      globalFilterName: value,
      refreshCounter: prevState.refreshCounter + 1
    }));
  };

  getNameOfPropertiesLeft = (properties = [], isActive) =>
    properties
      .filter(property =>
        isActive ? property.isActive : !property.isActive && property.isActive !== null
      )
      .map(property => property.companyName);

  handleEdit = () => {
    const mode = this.props.computedMatch.params?.mode;
    if (mode !== 'view') return;

    const data = this.state.customerData;
    if (!data.isActive) {
      this.props.snackbarOn('error', 'Please activate customer before editing');
    }
    if (data.isActive) {
      this.props.history.push(`/customer/edit/${data.id}`);
    }
  };

  handleAddressEdit = addressType => {
    this.setState({ openEditPropertyAddressModal: true, editAddressType: addressType });
  };

  handleEditNameModal = isOpen => {
    this.setState({ openEditCustomerNameModal: isOpen });
  };

  setOpenAdjustmentModal = isOpen => {
    this.setState({ adjustmentModalOpen: isOpen });
  };

  getAdditionalHeaderComponents = (mode, isActive) => {
    const { classes } = this.props;
    const { customerData } = this.state;
    const labelObj = isActive ? Labels.active : Labels.deactivated;
    const chipClass = isActive ? classes.activeStatusChip : classes.deactivatedStatusChip;

    return [
      mode === Mode.EDIT ? (
        <IconButton edge="start" onClick={() => this.handleEditNameModal(true)} size="small">
          <EditIcon />
        </IconButton>
      ) : null,
      typeof isActive === 'boolean' ? (
        <StatusChip
          label={labelObj[this.props.user.locale]}
          className={chipClass}
          key={`status${labelObj[this.props.user.locale]}`}
        />
      ) : null,
      customerData?.customerTags ? (
        <TagButtons
          info={{
            id: customerData?.id,
            version: customerData?.version,
            companyId: this.props.user.tenantCompanyId
          }}
          tags={customerData.customerTags?.items}
          getService={() => ({
            updateCustomerTags: payload => {
              const selectedTags = payload.data.customers[0].customerTags;
              this.setState({ selectedCustomerTags: selectedTags.map(tag => tag.id) });
              return this.CustomerService.updateCustomerTags(payload);
            },
            deleteCustomerTags: async (tenantId, tagToDelete, tagId) => {
              this.setState(prevState => ({
                ...prevState,
                selectedCustomerTags: prevState.selectedCustomerTags.filter(tag => tag !== tagId)
              }));
              await this.CommonService.deleteEntityMap(tenantId, tagToDelete);
            }
          })}
          TagType={TagType.CUSTOMER}
        />
      ) : null
    ];
  };

  handleDeactivateModalClickNoCascade = async (data, loaded) => {
    if (data?.id) {
      try {
        const { propertiesData } = this.state;
        const { items: properties } = propertiesData;
        let message = `Successfully deactivated - ${data.customerName}.`;
        if (properties) {
          let propertiesLeft = '';
          properties.forEach(property => {
            if (property.isActive && property.isActive !== null) {
              propertiesLeft += `${property.companyName}, `;
            }
          });
          if (propertiesLeft.length > 0) {
            message =
              `Successfully deactivated - ${data.customerName}. These properties ` +
              `are stil active: ${propertiesLeft.slice(0, -2)}.`;
          }
        }
        await this.updateCustomerStatus(data, false, false);
        await this.queryAndSetCustomerInfo(data.sortKey);
        this.props.snackbarOn('success', message);
      } catch (error) {
        logErrorWithCallback(error, this.props.snackbarOn, 'Unable to deactivate Customer');
      }
    }
    loaded();
    this.handleDeactivateModalClose();
  };

  handleDeactivateModalClickWithCascade = async (data, loaded) => {
    if (data?.id) {
      try {
        const { propertiesData } = this.state;
        const { items: properties, propertiesWithOpenQuotes } = propertiesData;
        let message = `Successfully deactivated - ${data.customerName}.`;
        const activePropertiesLeft = this.getNameOfPropertiesLeft(properties, true);
        if (activePropertiesLeft.length > 0) {
          message =
            `Successfully deactivated - ${data.customerName}. These properties ` +
            `are also now deactivated: ${activePropertiesLeft.join(', ')}.`;
        }
        if (propertiesWithOpenQuotes?.length > 0) {
          message =
            `Cannot deactivate customers that have properties with draft quotes open. ` +
            `${propertiesWithOpenQuotes.join(
              ', '
            )}. Please cancel or convert these quotes into jobs in ` +
            `order to deactivate this customer.`;
          this.props.snackbarOn('error', message);
        } else {
          await this.updateCustomerStatus(data, false, true);
          await this.queryAndSetCustomerInfo(data.sortKey);
          this.props.snackbarOn('success', message);
        }
      } catch (error) {
        logErrorWithCallback(error, this.props.snackbarOn, 'Unable to deactivate Customer');
      }
    }
    loaded();
    this.handleDeactivateModalClose();
  };

  handleActivateModalClickNoCascade = async (data, loaded) => {
    if (data?.id) {
      try {
        const { propertiesData } = this.state;
        const { items: properties } = propertiesData;
        let message = `Successfully activated - ${data.customerName}.`;
        const propertiesLeft = this.getNameOfPropertiesLeft(properties, false);
        if (propertiesLeft.length > 0) {
          message =
            `Successfully activated - ${data.customerName}. These properties ` +
            `are still deactivated: ${propertiesLeft.join(', ')}.`;
        }
        await this.updateCustomerStatus(data, true, false);
        await this.queryAndSetCustomerInfo(data.sortKey);
        this.props.snackbarOn('success', message);
      } catch (error) {
        logErrorWithCallback(error, this.props.snackbarOn, 'Unable to deactivate Customer');
      }
    }
    loaded();
    this.handleActivateModalClose();
  };

  handleActivateModalClickWithCascade = async (data, loaded) => {
    if (data?.id) {
      try {
        const { propertiesData } = this.state;
        const { items: properties } = propertiesData;
        let message = `Successfully activated - ${data.customerName}.`;
        const propertiesLeft = this.getNameOfPropertiesLeft(properties, false);
        if (propertiesLeft.length > 0) {
          message =
            `Successfully activated - ${data.customerName}. These properties ` +
            `are also now activated: ${propertiesLeft.join(', ')}.`;
        }
        await this.updateCustomerStatus(data, true, true);
        await this.queryAndSetCustomerInfo(data.sortKey);
        this.props.snackbarOn('success', message);
      } catch (error) {
        logErrorWithCallback(error, this.props.snackbarOn, 'Unable to deactivate Customer');
      }
    }
    loaded();
    this.handleActivateModalClose();
  };

  handleDeactivateModalClose = () => {
    const { deactivateConfirmationModal } = this.state;
    const updatedModal = { ...deactivateConfirmationModal };
    updatedModal.open = false;
    this.setState({ deactivateConfirmationModal: updatedModal });
  };

  handleActivateModalClose = () => {
    const { activateConfirmationModal } = this.state;
    const updatedModal = { ...activateConfirmationModal };
    updatedModal.open = false;
    this.setState({ activateConfirmationModal: updatedModal });
  };

  handleSaveNewCustomer = () => {
    this.state.formService.submit();
  };

  setModalHeaderButtons = () => (
    <ThemeProvider>
      <Button
        type={ButtonType.PRIMARY}
        onClick={this.handleSaveNewCustomer}
        loading={this.state.isSubmitting}
        disabled={this.state.isSubmitting}
      >
        Save Customer
      </Button>
    </ThemeProvider>
  );

  handleAddBusinessAddress = () => {
    this.setState(prevState => ({ showBusinessAddress: !prevState.showBusinessAddress }));
  };

  handleSAModalClose = (close, data) => {
    this.setState({ createAgreementModal: close });
    if (data?.id) this.props.history.push(`/serviceAgreement/edit/${data.id}`);
  };

  handleCreateAgreementModal = () => {
    this.setState({ createAgreementModal: true });
  };

  render() {
    const mode = this.props.computedMatch.params && this.props.computedMatch.params.mode;
    let noteKey = 1; // To identify notes in new customer page
    const {
      noOfProperties,
      customerData,
      isIntegrationEnabled,
      createAgreementModal,
      isIntacctEnabled
    } = this.state;

    if (customerData && customerData.customerName) {
      document.title = `BuildOps - ${customerData.customerName}`;
      customerData.isIntegrationEnabled = isIntegrationEnabled;
    }

    let data = customerData;

    const pageTitle =
      // eslint-disable-next-line no-nested-ternary
      mode === 'new'
        ? [
            { link: '/customer/list', title: 'Customers' },
            { link: '', title: 'New customer' }
          ]
        : mode === 'edit'
        ? [
            { link: '/customer/list', title: 'Customers' },
            { link: '', title: 'Edit customer' }
          ]
        : [
            { link: '/customer/list', title: 'Customers' },
            { link: '', title: 'View customer' }
          ];

    if (noOfProperties && data) {
      data.noOfProperties = noOfProperties;
    }

    let renderPageForm = false;
    // TODO: clear up below logics
    if (mode === 'new') {
      // customerData really needs cleaning up. could be false or "" or an object
      data = {
        ...data,
        isTaxable: 'true'
      };
      renderPageForm = true;
    }

    if (mode !== 'new' && data) {
      renderPageForm = true;
    }

    // Will be Remove when update Add Customer
    const { buttons } = customerLayout.entity.layouts.web;

    if (buttons) {
      if (buttons.cancel) {
        buttons.cancel.action = () => this.props.history.goBack();
      }
    }

    // The handlers of header buttons that are provided to ActionsMenu
    const headerButtonHandlers = (buttonKey = '') => {
      const { ACTIVATE, DEACTIVATE, CREATE_JOB, CREATE_SA } = buttonTypes;
      switch (buttonKey) {
        case ACTIVATE:
          this.handleActivateClick(this.state.customerData);
          break;
        case DEACTIVATE:
          this.handleDeactivateClick(this.state.customerData);
          break;
        case CREATE_JOB:
          this.createJob();
          break;
        case CREATE_SA:
          this.setState({ createAgreementModal: true });
          break;
        default:
          throw new Error('Wrong button key');
      }
    };

    // Props required for customer tabs
    const tabProps = {
      ...this.props,
      data,
      mode,
      renderPageForm,
      ...this.state,
      queryAndSetProperties: this.queryAndSetProperties,
      queryAndSetCustomerReps: this.queryAndSetCustomerReps,
      queryAndSetTenantReps: this.queryAndSetTenantReps,
      setGlobalFilters: this.setGlobalFilters,
      queryJobsByCustomer: this.queryJobsByCustomer,
      handleEventsRowActions: this.handleEventsRowActions,
      queryUpcomingVisit: this.queryUpcomingVisit,
      queryPastVisit: this.queryPastVisit,
      getServiceAgreements: this.getServiceAgreements,
      getCustomerAdjustments: this.getCustomerAdjustments,
      getCustomerInvoices: this.getCustomerInvoices,
      getCustomerPayments: this.getCustomerPayments,
      queryAndSetAttachments: this.queryAndSetAttachments,
      handleCustomerAttachment: this.handleCustomerAttachment,
      handleCreateAgreementModal: this.handleCreateAgreementModal,
      setOpenAdjustmentModal: this.setOpenAdjustmentModal
    };

    // Options for customer type SelectInput with None
    let customerTypeOptions = _.sortBy(this.state.customerTypes?.items, 'sortOrder').map(type => ({
      label: type.tagName,
      value: type.tagName
    }));
    customerTypeOptions = [{ label: 'None', value: '' }].concat(customerTypeOptions);

    // Options for tax rate SelectInput with None
    let taxOptions = this.state.taxRates?.map(taxRate => ({
      ...taxRate,
      label: `${taxRate.name} - ${taxRate.taxRate}`,
      value: taxRate.id
    }));
    taxOptions = _.sortBy(taxOptions, [o => o.label.toLowerCase()]);
    taxOptions = [{ label: 'None', value: '' }].concat(taxOptions);

    const paymentTermOptions = this.state.paymentTerms?.items
      ?.filter(({ type }) => [PaymentTermType.BOTH, PaymentTermType.INVOICING].includes(type))
      ?.map(({ name, id }) => ({
        label: name,
        value: id
      }));

    const priceBookOptions = constructSelectOptions(this.state.priceBooks?.items, 'name');

    // Options for customer tags multi-SelectInput
    const tagOptions = this.state.customerTags?.items?.map(tag => ({
      label: tag.tagName,
      value: tag.id
    }));

    // Options for invoice preset
    const invoicePresetOptions = this.state.company?.userSettings?.items?.map(item => ({
      label: item.displayName,
      value: item.id
    }));

    const AddNotesView = ({ form }) => {
      const formValues = form.values;
      const notesData = formValues.customerNotes || [];
      const customerName = formValues.customerName || '';
      const sharedNotesProps = {
        title: Labels.customerNotes[this.props.user.locale],
        subtitle: Labels.notesOnCustomer[this.props.user.locale],
        linkName: Labels.viewEditAllNotes[this.props.user.locale],
        allNotesFor: customerName || 'New Customer',
        notesData,
        useAddCircleOutlineIcon: true
      };
      if (mode === 'new') {
        const handleDeleteNote = note => {
          const modifiedNotesData = _.without(notesData, note);
          form.setValues({ ...formValues, customerNotes: modifiedNotesData });
          return modifiedNotesData;
        };
        const handleMutateNote = note => {
          // If the note does not have a noteKey, it is a new note
          if (!note.noteKey) {
            form.setValues({
              ...formValues,
              customerNotes: [...notesData, { ...note, noteKey }]
            });
            noteKey += 1;
          }
          // otherwise, an existing note is to be updated
          else {
            // Find and update the note with the same noteKey
            notesData.forEach((noteData, index) => {
              if (noteData.noteKey === note.noteKey) {
                notesData[index] = note;
              }
            });
            form.setValues({ ...formValues, customerNotes: notesData });
          }
          return notesData;
        };
        return (
          <Notes
            {...sharedNotesProps}
            mutateService={handleMutateNote}
            deleteService={handleDeleteNote}
            addSpacingBetweenNotes
          />
        );
      }
      return (
        <Notes
          {...sharedNotesProps}
          parent={customerData}
          noteLimit={196}
          refetch={() => this.queryAndSetCustomerInfo(customerData.sortKey)}
          isActive={this.state.customerData.isActive}
        />
      );
    };

    const AddBusinessAddressBtn = ({ form }) => {
      const handleClick = () => {
        if (this.state.showBusinessAddress) {
          form.setValues({
            ...form.values,
            businessShipTo: '',
            businessAddressLine1: '',
            businessAddressLine2: '',
            businessCity: '',
            businessState: '',
            businessZipcode: '',
            businessLatitude: '',
            businessLongitude: ''
          });
        }
        this.handleAddBusinessAddress();
      };

      return (
        <ThemeProvider>
          <Button type="leading" startIcon={<AddCircleOutlineIcon />} onClick={handleClick}>
            {' '}
            Add a Business Address
          </Button>
        </ThemeProvider>
      );
    };

    const prepareLeftSectionData = () => {
      // using address Line1 null check as in DB, we have null business address, and in some cases there are more than one business address
      let billingAddressFields = data.companyAddresses?.items.find(
        item => item.addressType === AddressType.BILLING && item.addressLine1
      );
      let businessAddressFields = data.companyAddresses?.items.find(
        item => item.addressType === AddressType.BUSINESS && item.addressLine1
      );

      // View source for address label & MapView
      const { address: billingAddress, location: billingLocation } = getAddressAndLocation(
        billingAddressFields
      );
      const { address: businessAddress, location: businessLocation } = getAddressAndLocation(
        businessAddressFields
      );
      if (billingAddressFields) {
        billingAddressFields = reduce(
          billingAddressFields,
          (acc, value, key) => {
            return { ...acc, [`billing${capitalizeFirstLetter(key)}`]: value };
          },
          {}
        );
      }
      if (businessAddressFields) {
        businessAddressFields = reduce(
          businessAddressFields,
          (acc, value, key) => {
            return { ...acc, [`business${capitalizeFirstLetter(key)}`]: value };
          },
          {}
        );
      }

      const leftSectionData = (({ companyAddresses, phonePrimary, email }) => ({
        companyAddresses: companyAddresses?.items.sort((a, b) => {
          return a.addressType !== AddressType.BILLING ? 1 : -1;
        }),
        phonePrimary,
        email,
        noOfProperties,
        billingAddress,
        businessAddress,
        billingLocation,
        businessLocation,
        billingAddressFields,
        businessAddressFields
      }))(data);
      return leftSectionData;
    };

    // Props required for left sidebar
    const leftSectionProps = () => {
      return {
        data: prepareLeftSectionData(),
        configuration: sidebarSection({
          handleBillingAddressEdit: () => this.setState({ showBillingAddressModal: true }),
          handleBusinessAddressEdit: () => this.setState({ showBusinessAddressModal: true }),
          billingAddressProps: {
            taxOptions: this.state.taxRates || [],
            updateTaxRateId: id =>
              this.setState(prevState => ({
                ...prevState,
                mainSectionFormFields: {
                  ...prevState.mainSectionFormFields,
                  taxRateId: id
                }
              })),
            addressTypeLabel: 'billing',
            showAddressModal: this.state.showBillingAddressModal,
            closeAddressModal: () => this.setState({ showBillingAddressModal: false }),
            addressLocationSource: 'billingLocation',
            addressViewSource: 'billingAddress',
            addressEditSource: 'billingAddressFields',
            showLineTo: 'billingBillTo',
            disableTaxRateAutoAssign: this.state.isTaxRateAutoAssignDisabled
          },
          businessAddressProps: {
            taxOptions: this.state.taxRates || [],
            updateTaxRateId: id => {},
            addressTypeLabel: 'business',
            showAddressModal: this.state.showBusinessAddressModal,
            closeAddressModal: () => this.setState({ showBusinessAddressModal: false }),
            addressLocationSource: 'businessLocation',
            addressViewSource: 'businessAddress',
            addressEditSource: 'businessAddressFields',
            showLineTo: 'businessShipTo',
            disableTaxRateAutoAssign: this.state.isTaxRateAutoAssignDisabled
          }
        }),
        validationSchema: validations.companyPhoneAndEmailSchema(isIntacctEnabled),
        customComponents: { LocationView, AddNotesView, AddressEditAndView }
      };
    };

    const handleMainSectionFormChange = mainSectionData => {
      const { customFields = [] } = this.state;
      // Save the current main section values
      const mainSectionFormFields = pick(mainSectionData, [
        'customerTypeValue',
        'isTaxable',
        'taxRateId',
        'invoicePresetId',
        'priceBookId',
        ...customFields
      ]);
      this.setState({ mainSectionFormFields });
    };

    // Props required for main section
    const mainSectionProps = () => {
      const customFormMeta = this.state.inlineForm && this.state.inlineForm.formMeta;
      const formOptions = {
        customerTypeOptions,
        taxOptions,
        paymentTermOptions,
        invoicePresetOptions,
        priceBookOptions
      };
      const mainConfiguration = mainLayoutWithInlineForm(formOptions, customFormMeta);

      return {
        configuration: mainConfiguration,
        data: { ...data, ...this.state.mainSectionFormFields },
        layout: mode,
        customComponents: { AddNotesView },
        onFormChange: handleMainSectionFormChange
      };
    };

    const isServiceAgreementEnabled = this.props.flags?.[FeatureFlags.SERVICE_AGREEMENTS];

    return (
      <ErrorBoundaries>
        <UserPermission I={mode} action={[PermissionConstants.OBJECT_CUSTOMER]}>
          {mode === 'new' ? (
            <FullScreenModal
              fixedHeader
              open
              handleClose={() => {
                this.props.history.goBack();
              }}
              modalHeaderButtons={this.setModalHeaderButtons()}
              title="New Customer"
            >
              <MUIForm
                data={data}
                layout="edit"
                configuration={NewCustomerLayout(
                  {
                    customerTypeOptions,
                    taxOptions,
                    paymentTermOptions,
                    tagOptions,
                    invoicePresetOptions,
                    priceBookOptions
                  },
                  this.state.showBusinessAddress,
                  this.handleAddBusinessAddress,
                  this.state?.inlineForm?.formMeta
                )}
                onCreateService={service => this.setState(() => ({ formService: service }))}
                customComponents={{
                  StyledSectionDivider,
                  PlacesSearch,
                  AddNotesView,
                  AddBusinessAddressBtn
                }}
                onComplete={this.handleOnComplete}
                validationSchema={validations.customerSchema(isIntacctEnabled)}
              />
            </FullScreenModal>
          ) : (
            <LeftSidebarWithContent
              handleFormsSubmit={this.handleOnComplete}
              buttonLabel={{ view: 'Edit', edit: 'Save Customer' }}
              mode={mode}
              isLoading={this.state.isLoading}
              mainSectionProps={mainSectionProps()}
              leftSectionProps={leftSectionProps()}
              headerProps={{
                title: this.getLayoutTitle(),
                breadcrumbsArray: pageTitle,
                caslKey: [PermissionConstants.OBJECT_CUSTOMER],
                additionalTitleComponents: this.getAdditionalHeaderComponents(mode, data.isActive),
                icon: 'customerIcon'
              }}
              actionButtons={getHeaderButtons(data, mode, isServiceAgreementEnabled)}
              actionButtonHandler={headerButtonHandlers}
              handleEdit={this.handleEdit}
              useNewMenuStyle
              caslKey={PermissionConstants.OBJECT_CUSTOMER}
            >
              <SergeantModal
                open={this.state.deactivateConfirmationModal.open}
                data={this.state.deactivateConfirmationModal.data}
                dataType={this.state.deactivateConfirmationModal.dataType}
                customPrimaryButtonLabel={this.state.deactivateConfirmationModal.primaryButtonLabel}
                mode={this.state.deactivateConfirmationModal.mode}
                maxWidth={500}
                warningMessageFont
                confirmRemoveStatement={this.state.deactivateConfirmationModal.deactivateStatement}
                confirmRemoveItemLabel={this.state.deactivateConfirmationModal.deactivateItemLabel}
                layout={this.state.deactivateConfirmationModal.layout}
                handlePrimaryAction={this.state.deactivateConfirmationModal.handlePrimaryAction}
                handleClose={this.handleDeactivateModalClose}
              />

              <SergeantModal
                open={this.state.activateConfirmationModal.open}
                data={this.state.activateConfirmationModal.data}
                dataType={this.state.activateConfirmationModal.dataType}
                customPrimaryButtonLabel={this.state.activateConfirmationModal.primaryButtonLabel}
                customSecondaryButtonLabel={
                  this.state.activateConfirmationModal.secondaryButtonLabel
                }
                mode={this.state.activateConfirmationModal.mode}
                maxWidth={350}
                confirmRemoveItemLabel={this.state.activateConfirmationModal.deactivateItemLabel}
                layout={this.state.activateConfirmationModal.layout}
                handlePrimaryAction={this.state.activateConfirmationModal.handlePrimaryAction}
                handleSecondaryAction={this.state.activateConfirmationModal.handleSecondaryAction}
                handleClose={this.handleActivateModalClose}
              />
              <div id="customerDetail" ref={this.primaryContactsRef} />
              <CustomerTabs {...tabProps} isServiceAgreementEnabled={isServiceAgreementEnabled} />
              <Grid style={{ paddingTop: 72 }} />
            </LeftSidebarWithContent>
          )}

          {this.state.editAddressType && (
            <EditAddressModal
              open={this.state.openEditPropertyAddressModal}
              onClose={() => this.setState({ openEditPropertyAddressModal: false })}
              data={data}
              editAddressType={this.state.editAddressType}
              onComplete={this.handleOnComplete}
              dataType={Labels[`${this.state.editAddressType}Address`][this.props.user.locale]}
              taxOptions={taxOptions}
            />
          )}
          <SergeantModal
            data={data}
            dataType="customerName"
            open={
              this.state.openEditCustomerNameModal ? this.state.openEditCustomerNameModal : false
            }
            handleClose={() => this.handleEditNameModal(false)}
            handlePrimaryAction={async (completed, stopLoading) => {
              if (await this.handleOnComplete(completed, false)) {
                this.setState(prevState => ({
                  ...prevState,
                  customerData: { ...prevState.customerData, customerName: completed.customerName }
                }));
                this.handleEditNameModal(false);
              }
              stopLoading();
            }}
            layout={EditCustomerNameForm()}
            mode={Mode.EDIT}
            title={Labels.editCustomerName[this.props.user.locale]}
          />
          {customerData?.id && isServiceAgreementEnabled && (
            <CreateServiceAgreement
              showModal={createAgreementModal}
              onModalClose={this.handleSAModalClose}
              user={this.props.user}
              customer={customerData}
            />
          )}
        </UserPermission>
      </ErrorBoundaries>
    );
  }
}

CustomerDetail.propTypes = {
  mounted: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    tenantId: PropTypes.string.isRequired,
    employeeId: PropTypes.string.isRequired,
    tenantCompanyId: PropTypes.string.isRequired,
    appPermissionRules: PropTypes.string.isRequired
  }).isRequired,
  snackbarOn: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  computedMatch: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      mode: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  application: state.application,
  menu: state.menu
});

const mapDispatcherToProps = { snackbarOn, setOpenQuickAddModal };
const StyledCustomerDetail = withStyles(styles)(CustomerDetail);
const connectedCustomerDetails = connect(
  mapStateToProps,
  mapDispatcherToProps
)(StyledCustomerDetail);
export default withLDConsumer()(connectedCustomerDetails);
