import { calculateMarginFromMarkup } from '@buildhero/math';

import { isString } from 'lodash';

import Context from 'components/Context';
import {
  ActiveStatus,
  JobBillingStatus,
  JOB_CLOSEOUT_STATUS as jobCloseoutStatus,
  JobProcurementStatus,
  jobQuoteStatus,
  JobStatus,
  MaintenanceStatus,
  MultiSelectTypes,
  OpenTaskStatus,
  PermissionConstants,
  PriorityStatus,
  ProcurementPurchaseOrderReceiptStatus,
  ProcurementPurchaseOrderStatus,
  QuoteStatus,
  ReviewReportStatus,
  ServiceAgreementStatus,
  VISIT_REVIEW_STATUS as visitReviewStatus,
  VisitStatus
} from 'utils/AppConstants';
import {
  EnumType,
  ExportStatus,
  InvoiceStatus,
  PaymentStatus,
  ServiceChannelWorkOrderStatus,
  WIPReportStatus
} from 'utils/constants';
import { convertForMathLib } from 'utils/mathLibrary';

const getTagNames = (options = []) => {
  if (Array.isArray(options)) {
    return options.filter(o => o?.tagName).map(o => o.tagName);
  }
  return [];
};

const {
  JOB_TYPES,
  JOB_TAGS,
  JOB_STATUS,
  QUOTE_STATUS,
  DEPARTMENTS,
  CUSTOMER_TAGS,
  CUSTOMER_TYPES,
  CUSTOMER_PROPERTY_TYPES,
  INVOICE_STATUS,
  VISIT_STATUS,
  REVIEW_REPORT_STATUS,
  INVOICE_TAGS,
  PAYMENT_STATUS,
  EXPORT_STATUS,
  WIP_REPORT_STATUS,
  PRIORITY_STATUS,
  QUOTE_TAGS,
  SERVICE_AGREEMENT_STATUS,
  CUSTOMER_STATUS,
  PROPERTY_STATUS,
  PURCHASE_ORDER_TAGS,
  PURCHASE_ORDER_STATUS,
  PURCHASE_ORDER_RECEIPT_STATUS,
  SERVICE_CHANNEL_WORK_ORDER_STATUS,
  OPEN_TASK_STATUS,
  JOB_PROCUREMENT_STATUS,
  JOB_BILLING_STATUS,
  VISIT_REVIEW_STATUS,
  JOB_AND_MAINTENANCE_STATUS,
  MAINTENANCE_STATUS,
  JOB_CLOSEOUT_STATUS,
  INVOICE_BILLING_STATUS,
  JOB_QUOTE_STATUS
} = MultiSelectTypes;

const StatusTypesMap = {
  [PRIORITY_STATUS]: PriorityStatus,
  [INVOICE_STATUS]: InvoiceStatus,
  [JOB_STATUS]: JobStatus,
  [QUOTE_STATUS]: QuoteStatus,
  [VISIT_STATUS]: VisitStatus,
  [VISIT_REVIEW_STATUS]: visitReviewStatus,
  [REVIEW_REPORT_STATUS]: ReviewReportStatus,
  [PAYMENT_STATUS]: PaymentStatus,
  [EXPORT_STATUS]: ExportStatus,
  [WIP_REPORT_STATUS]: WIPReportStatus,
  [SERVICE_AGREEMENT_STATUS]: ServiceAgreementStatus,
  [CUSTOMER_STATUS]: ActiveStatus,
  [PROPERTY_STATUS]: ActiveStatus,
  [PURCHASE_ORDER_STATUS]: ProcurementPurchaseOrderStatus,
  [PURCHASE_ORDER_RECEIPT_STATUS]: ProcurementPurchaseOrderReceiptStatus,
  [SERVICE_CHANNEL_WORK_ORDER_STATUS]: ServiceChannelWorkOrderStatus,
  [OPEN_TASK_STATUS]: OpenTaskStatus,
  [JOB_PROCUREMENT_STATUS]: JobProcurementStatus,
  [JOB_BILLING_STATUS]: JobBillingStatus,
  [JOB_AND_MAINTENANCE_STATUS]: { ...JobStatus, ...MaintenanceStatus },
  [MAINTENANCE_STATUS]: MaintenanceStatus,
  [JOB_CLOSEOUT_STATUS]: jobCloseoutStatus,
  [INVOICE_BILLING_STATUS]: JobBillingStatus,
  [JOB_QUOTE_STATUS]: jobQuoteStatus
};

const companyContextOptionTypes = [
  JOB_TYPES,
  DEPARTMENTS,
  CUSTOMER_TYPES,
  CUSTOMER_PROPERTY_TYPES,
  JOB_TAGS,
  CUSTOMER_TAGS,
  INVOICE_TAGS,
  QUOTE_TAGS,
  PURCHASE_ORDER_TAGS
];

export const getMultiSelectOptions = (type = '') => {
  if (!isString(type) && !type) return [];
  const company = Context?.getCompanyContext()?.getCompany;
  const statusTypes = StatusTypesMap[type];
  const companyHasContextOptionType = companyContextOptionTypes.includes(type);

  if (companyHasContextOptionType) {
    return getTagNames(company?.[type]?.items);
  }

  if (statusTypes) {
    return Object.keys(statusTypes).map(key => statusTypes[key]);
  }
  return [];
};

export const techReportRowsMeta = () => {
  const jobTypeOptions = getMultiSelectOptions(JOB_TYPES);
  const jobStatusOptions = getMultiSelectOptions(JOB_STATUS);
  const jobTagOptions = getMultiSelectOptions(JOB_TAGS);

  return [
    {
      id: 'techReportLabel',
      disableFilter: true,
      disableSort: true,
      label: 'Technician Report',
      showLink: 'view',
      linkPath: '/technicianreport/view',
      linkReference: 'id',
      bold: true
    },
    {
      id: 'jobNumber',
      filterKey: 'Job.jobNumber',
      filterType: 'number',
      label: 'Job',
      type: 'jobLink'
    },
    {
      id: 'visitNumber',
      filterKey: 'Visit.visitNumber',
      filterType: 'number',
      label: 'Visit'
    },
    {
      id: 'reviewReportLabel',
      disableFilter: true,
      disableSort: true,
      label: 'Review Report',
      showLink: 'view',
      linkPath: '/reviewreport/view',
      linkReference: 'reviewReportId'
    },
    {
      id: 'customerName',
      filterKey: 'Customer.customerName',
      filterType: 'string',
      label: 'Customer',
      showLink: 'view',
      linkPath: '/customer/view',
      linkReference: 'customerId',
      linkStateKey: 'recordSortKey',
      linkStateValue: 'customerSortKey'
    },
    {
      id: 'customerPropertyName',
      filterKey: 'CustomerProperty.companyName',
      filterType: 'string',
      label: 'Property',
      showLink: 'view',
      linkPath: '/property/view',
      linkReference: 'customerPropertyId',
      linkStateKey: 'recordSortKey',
      linkStateValue: 'customerPropertySortKey'
    },
    {
      id: 'status',
      filterKey: 'Visit.status',
      filterType: 'multi-select',
      options: getMultiSelectOptions(VISIT_STATUS),
      label: 'Status'
    },
    {
      id: 'jobTags',
      filterKey: 'JobJobTag.jobTagId',
      filterType: {
        uiType: 'multi-select',
        filterType: 'stringFilters',
        customFieldConditionOptions: [
          { label: 'Is', value: 'in' },
          { label: 'Is not', value: 'in', id: 'notIn' },
          { label: 'Is empty', value: 'empty', defaultValue: 'noFilter' },
          { label: 'Is not empty', value: 'notEmpty', defaultValue: 'noFilter' }
        ]
      },
      options: jobTagOptions,
      convertToSubQuery: true,
      subQueryCondition: {
        fieldName: 'Job.id',
        fieldComparator: {
          'Is not': 'notExists',
          Is: 'exists',
          'Is empty': 'notExists',
          'Is not empty': 'exists'
        },
        entityConnection: 'JobJobTag',
        subQueryFieldName: 'JobJobTag.id',
        filter: {
          stringFilters: [
            {
              fieldName: 'JobJobTag.parentId',
              filterInput: {
                eq: 'Job.id'
              },
              literal: true
            }
          ]
        }
      },
      disableSort: true,
      label: 'Tags',
      type: 'chip'
    },
    {
      id: 'employeeName',
      disableFilter: true,
      filterKey: 'Visit.employeeName',
      type: 'string',
      disableSort: true,
      label: 'Primary Technician'
    },
    {
      id: 'visitSubmittedDate', // tech report is created when the visit's is complete
      filterKey: 'Visit.submittedDate',
      filterType: 'date',
      type: 'dateOnly',
      label: 'Created On'
    },
    {
      id: 'completedDate',
      filterKey: 'Job.completedDate',
      filterType: 'date',
      type: 'dateOnly',
      label: 'Job Completion Date'
    },
    {
      id: 'reviewReportCreatedBy',
      filterKey: 'ReviewReport.createdBy',
      filterType: 'string',
      label: 'Reviewed By'
    },
    {
      id: 'reviewReportCreatedDate',
      disableFilter: true,
      filterKey: 'ReviewReport.createdDate',
      filterType: 'string',
      type: 'dateOnly',
      label: 'Reviewed On'
    },
    {
      id: 'billingAddress',
      disableFilter: true,
      disableSort: true,
      type: 'bigtext',
      prewrap: true,
      maxTextLen: 150,
      label: 'Billing Address'
    },
    {
      id: 'jobDescription',
      disableFilter: true,
      disableSort: true,
      label: 'Job Description',
      type: 'bigtext',
      prewrap: true,
      maxTextLen: 200
    },
    {
      id: 'jobStatus',
      filterKey: 'Job.status',
      filterType: 'multi-select',
      options: jobStatusOptions,
      label: 'Job Status',
      enumType: EnumType.JOB_STATUS,
      type: 'enum',
      showIcon: true
    },
    {
      id: 'jobType',
      filterKey: 'Job.jobTypeName',
      filterType: 'multi-select',
      options: jobTypeOptions,
      label: 'Job Type',
      type: 'enum'
    },
    {
      id: 'ownerValue',
      filterKey: 'Employee.name',
      filterType: 'string',
      disableSort: true,
      label: 'Project Manager'
    },
    {
      id: 'propertyAddress',
      disableFilter: true,
      disableSort: true,
      type: 'bigtext',
      prewrap: true,
      maxTextLen: 150,
      label: 'Property Address'
    },
    {
      id: 'description',
      disableFilter: true,
      disableSort: true,
      label: 'Visit Description',
      type: 'bigtext',
      prewrap: true,
      maxTextLen: 200
    },
    {
      id: 'summary',
      disableFilter: true,
      disableSort: true,
      label: 'Visit Summary',
      type: 'bigtext',
      prewrap: true,
      maxTextLen: 200
    }
  ];
};

export const customerRowMeta = () => {
  const customerTypes = getMultiSelectOptions(CUSTOMER_TYPES);
  const customerTagOptions = getMultiSelectOptions(CUSTOMER_TAGS);

  return [
    {
      id: 'customerName',
      filterKey: 'Customer.customerName',
      filterType: 'string',
      label: 'Customer',
      showLink: 'view',
      linkPath: '/customer/view',
      linkReference: 'id',
      linkStateKey: 'recordSortKey',
      linkStateValue: 'sortKey',
      bold: true
    },
    {
      id: 'customerStatus',
      filterKey: 'Customer.isActive',
      filterType: 'boolean',
      options: [
        { label: 'Active', value: true },
        { label: 'Deactivated', value: false }
      ],
      type: 'enum',
      numeric: false,
      label: 'Status'
    },
    {
      id: 'propertyCount',
      disableFilter: true,
      disableSort: true,
      label: 'Number of Properties',
      numeric: true
    },
    {
      id: 'openJobs',
      disableFilter: true,
      disableSort: true,
      numeric: true,
      label: 'Open Jobs'
    },
    {
      id: 'openJobValue',
      disableFilter: true,
      disableSort: true,
      type: 'currency',
      numeric: true,
      caslKey: PermissionConstants.DATA_VIEW_PRICE_DATA,
      caslAction: 'allow',
      label: 'Open Jobs Value'
    },
    {
      id: 'arBalance',
      disableFilter: true,
      disableSort: true,
      type: 'balance',
      numeric: true,
      bold: true,
      caslKey: PermissionConstants.DATA_VIEW_PRICE_DATA,
      caslAction: 'allow',
      label: 'Outstanding Balance',
      align: 'right'
    },
    {
      id: 'overdueBalance',
      disableSort: true,
      disableFilter: true,
      type: 'currency',
      numeric: true,
      caslKey: PermissionConstants.DATA_VIEW_PRICE_DATA,
      caslAction: 'allow',
      label: 'Overdue Balance'
    },
    {
      id: 'billingAddress',
      disableFilter: true, // all addressFilters are disabled until @TODO below is solved
      disableSort: true,
      /*
        @TODO this props should allow for an array entry,
        where if any of the filterKey array entries match the inptut, it should pass the filter
        in this case it would be:
        filterKey: ["CompanyAddress.addressLine1", "CompanyAddress.addressLine2", "CompanyAddress.zipcode", ...]
      */
      filterKey: 'CompanyAddress.addressLine1',
      filterType: 'string',
      convertToSubQuery: true,
      subQueryCondition: {
        fieldName: 'Company.id',
        fieldComparator: 'exists',
        entityConnection: 'CompanyAddress',
        subQueryFieldName: 'CompanyAddress.id',
        filter: {
          stringFilters: [
            {
              fieldName: 'CompanyAddress.parentId',
              filterInput: {
                eq: 'Customer.id'
              },
              literal: true
            },
            {
              fieldName: 'CompanyAddress.addressType',
              filterInput: {
                eq: 'billingAddress'
              }
            }
          ]
        }
      },
      type: 'bigtext',
      prewrap: true,
      maxTextLen: 150,
      label: 'Billing Address'
    },
    {
      id: 'businessAddress',
      disableFilter: true,
      disableSort: true,
      // @TODO - bug - selecting Business Address as the filter actually selects Billing Address because they have the same filterKey.
      filterKey: 'CompanyAddress.addressLine1',
      filterType: 'string',
      convertToSubQuery: true,
      subQueryCondition: {
        fieldName: 'Company.id',
        fieldComparator: 'exists',
        entityConnection: 'CompanyAddress',
        subQueryFieldName: 'CompanyAddress.id',
        filter: {
          stringFilters: [
            {
              fieldName: 'CompanyAddress.parentId',
              filterInput: {
                eq: 'Customer.id'
              },
              literal: true
            },
            {
              fieldName: 'CompanyAddress.addressType',
              filterInput: {
                eq: 'businessAddress'
              }
            }
          ]
        }
      },
      type: 'bigtext',
      prewrap: true,
      maxTextLen: 150,
      label: 'Business Address'
    },
    {
      id: 'createdBy',
      filterKey: 'Customer.createdBy',
      filterType: 'string',
      label: 'Created By'
    },
    {
      id: 'createdDate',
      filterKey: 'Customer.createdDate',
      filterType: 'date',
      type: 'dateOnly',
      label: 'Created Date'
    },
    {
      id: 'customerType',
      filterKey: 'Customer.customerTypeValue',
      filterType: 'multi-select',
      options: customerTypes,
      type: 'enum',
      label: 'Customer Type'
    },
    {
      id: 'email',
      filterKey: 'Customer.email',
      filterType: 'string',
      label: 'Email'
    },
    {
      id: 'phonePrimary',
      filterKey: 'Customer.phonePrimary',
      filterType: 'string',
      label: 'Phone'
    },
    // @TODO - unhide this eventually - currently not possible to fill this field out in customer edit or create forms
    // {
    //   id: 'phoneAlternate',
    //   disableFilter: true,
    //   disableSort: true,
    //   label: 'Alternate Phone'
    // },
    {
      id: 'tags',
      disableSort: true,
      filterKey: 'SystemEntityMap.mappedEntityId',
      filterType: {
        uiType: 'multi-select',
        filterType: 'stringFilters',
        customFieldConditionOptions: [
          { label: 'Is', value: 'in' },
          { label: 'Is not', value: 'in', id: 'notIn' },
          { label: 'Is empty', value: 'empty', defaultValue: 'noFilter' },
          { label: 'Is not empty', value: 'notEmpty', defaultValue: 'noFilter' }
        ]
      },
      options: customerTagOptions,
      label: 'Tags',
      type: 'chip',
      convertToSubQuery: true,
      subQueryCondition: {
        entityConnection: 'SystemEntityMap',
        fieldComparator: {
          'Is not': 'notExists',
          Is: 'exists',
          'Is empty': 'notExists',
          'Is not empty': 'exists'
        },
        fieldName: 'Customer.id',
        subQueryFieldName: 'SystemEntityMap.id',
        filter: {
          stringFilters: [
            {
              fieldName: 'SystemEntityMap.parentId',
              filterInput: {
                eq: 'Customer.id'
              },
              literal: true
            }
          ]
        }
      }
    }
  ];
};

export const quoteRowMeta = () => {
  const quoteStatusOptions = getMultiSelectOptions(QUOTE_STATUS);
  const quoteTagOptions = getMultiSelectOptions(QUOTE_TAGS);

  return [
    {
      id: 'quoteNumber',
      filterKey: 'Quote.quoteNumber',
      filterType: 'number',
      bold: true,
      label: 'Quote #',
      showLink: 'view',
      linkPath: '/quote',
      linkReference: 'id'
    },
    {
      id: 'name',
      filterKey: 'Quote.name',
      filterType: 'string',
      label: 'Title'
    },
    {
      id: 'customerName',
      filterKey: 'Customer.customerName',
      filterType: 'string',
      label: 'Customer',
      showLink: 'view',
      linkPath: '/customer/view',
      linkReference: 'customerId',
      linkStateKey: 'recordSortKey',
      linkStateValue: 'customerSortKey'
    },
    {
      id: 'customerPropertyName',
      disableFilter: true, // @TODO - investigate - filter not working properly
      disableSort: true,
      filterKey: 'Job.customerPropertyName',
      filterType: 'string',
      label: 'Property',
      showLink: 'view',
      linkPath: '/property/view',
      linkReference: 'customerPropertyId',
      linkStateKey: 'recordSortKey',
      linkStateValue: 'customerPropertySortKey'
    },
    {
      id: 'createdDate',
      filterKey: 'Quote.createdDate',
      filterType: 'date',
      type: 'dateOnly',
      label: 'Created On'
    },
    {
      id: 'dueDate',
      filterKey: 'Quote.dueDate',
      filterType: 'date',
      type: 'dateOnly',
      label: 'Due Date'
    },
    {
      id: 'totalAmountComputed',
      filterKey: 'totalAmountComputed',
      filterType: 'computed',
      type: 'currency',
      numeric: true,
      label: 'Amount',
      disableSort: true,
      caslKey: [PermissionConstants.DATA_VIEW_PRICE_DATA, PermissionConstants.DATA_VIEW_COST_DATA],
      caslAction: 'allow'
    },
    {
      id: 'status',
      filterKey: 'Quote.status',
      filterType: 'multi-select',
      options: quoteStatusOptions,
      label: 'Status',
      enumType: EnumType.QUOTE_STATUS
    },
    {
      id: 'quoteTags',
      filterKey: 'QuoteQuoteTag.quoteTagId',
      filterType: {
        uiType: 'multi-select',
        filterType: 'stringFilters',
        customFieldConditionOptions: [
          { label: 'Is', value: 'in' },
          { label: 'Is not', value: 'in', id: 'notIn' },
          { label: 'Is empty', value: 'empty', defaultValue: 'noFilter' },
          { label: 'Is not empty', value: 'notEmpty', defaultValue: 'noFilter' }
        ]
      },
      options: quoteTagOptions,
      convertToSubQuery: true,
      subQueryCondition: {
        fieldName: 'Quote.id',
        fieldComparator: {
          'Is not': 'notExists',
          Is: 'exists',
          'Is empty': 'notExists',
          'Is not empty': 'exists'
        },
        entityConnection: 'QuoteQuoteTag',
        subQueryFieldName: 'QuoteQuoteTag.id',
        filter: {
          stringFilters: [
            {
              fieldName: 'QuoteQuoteTag.quoteId',
              filterInput: {
                eq: 'Quote.id'
              },
              literal: true
            }
          ]
        }
      },
      disableSort: true,
      label: 'Tags',
      type: 'chip'
    },
    {
      id: 'ownerValue',
      filterKey: 'Quote.ownerValue',
      filterType: 'string',
      label: 'Project Manager'
    },
    {
      id: 'accountManagerValue',
      filterKey: 'Quote.accountManagerValue',
      filterType: 'string',
      label: 'Account Manager'
    },
    {
      id: 'salesByValue',
      filterKey: 'Quote.salesByValue',
      filterType: 'string',
      label: 'Sold By'
    },
    /**
     * !TODO billingAddress is supposed to be a string filter
     */
    {
      id: 'billingAddress',
      disableFilter: true,
      disableSort: true,
      label: 'Billing Address',
      type: 'bigtext',
      prewrap: true,
      maxTextLen: 150
    },
    {
      id: 'rejectedReason',
      filterKey: 'Quote.rejectedReason',
      filterType: 'string',
      type: 'bigtext',
      prewrap: true,
      label: 'Comments',
      maxTextLen: 200
    },
    {
      id: 'createdBy',
      filterKey: 'Quote.createdBy',
      filterType: 'string',
      label: 'Created By'
    },
    {
      id: 'customerPoNumber',
      filterKey: 'Quote.customerPoNumber',
      filterType: 'string',
      label: 'Customer PO#',
      align: 'center'
    },
    {
      id: 'propertyRep',
      disableSort: true,
      filterKey: 'CustomerRep.name',
      filterType: 'stringNoEmpty',
      convertToSubQuery: true,
      subQueryCondition: {
        fieldName: 'Quote.propertyRepId',
        fieldComparator: 'exists',
        entityConnection: 'CustomerRep',
        subQueryFieldName: 'CustomerRep.id',
        filter: {
          stringFilters: [
            {
              fieldName: 'CustomerRep.id',
              filterInput: {
                eq: 'Quote.propertyRepId'
              },
              literal: true
            }
          ]
        }
      },
      label: 'Property Representative',
      align: 'center'
    },
    {
      id: 'orderedBy',
      disableSort: true,
      disableFilter: true,
      filterKey: 'CustomerRep.name',
      filterType: 'string',
      convertToSubQuery: true,
      subQueryCondition: {
        fieldName: 'Quote.orderedById',
        fieldComparator: 'exists',
        entityConnection: 'CustomerRep',
        subQueryFieldName: 'CustomerRep.id',
        filter: {
          stringFilters: [
            {
              fieldName: 'CustomerRep.id',
              filterInput: {
                eq: 'Quote.orderedById'
              },
              literal: true
            }
          ]
        }
      },
      label: 'Customer Representative',
      align: 'center'
    },

    /**
     * !TODO Jobs is supposed to be a string filter
     */
    {
      id: 'jobs',
      type: 'LinkList',
      label: 'Jobs',
      itemLabel: 'Job ',
      itemLabelReference: 'job.jobNumber',
      linkPath: '/job/view',
      linkReference: 'job.jobNumber',
      disableSort: true,
      filterKey: 'Job.jobNumber',
      filterType: 'stringNoEmpty',
      convertToSubQuery: true,
      subQueryCondition: {
        fieldName: 'Quote.id',
        fieldComparator: 'exists',
        entityConnection: 'Job',
        subQueryFieldName: 'Job.quoteId',
        filter: {
          stringFilters: [
            {
              fieldName: 'Job.quoteId',
              filterInput: {
                eq: 'Quote.id'
              },
              literal: true
            }
          ]
        }
      }
    },
    {
      id: 'versionNumber',
      type: 'string',
      label: 'Version',
      filterKey: 'versionNumber',
      filterType: 'string',
      align: 'center'
    },
    {
      id: 'versionLabel',
      type: 'string',
      label: 'Version Title',
      filterKey: 'versionLabel',
      filterType: 'string'
    },
    {
      id: 'department',
      filterKey: 'Quote.departmentValue',
      filterType: 'string',
      label: 'Department'
    },
    {
      id: 'description',
      filterKey: 'Quote.description',
      filterType: 'string',
      label: 'Notes'
    },
    {
      id: 'issueDescription',
      filterKey: 'Quote.issueDescription',
      filterType: 'string',
      label: 'Issue Description'
    }
  ];
};

export const assetRowMeta = [
  {
    id: 'assetName',
    filterKey: 'PropertyAsset.assetName',
    filterType: 'string',
    label: 'Name',
    showLink: 'view',
    linkPath: '/property/view',
    linkReference: 'customerPropertyId',
    linkStateKey: 'recordSortKey',
    linkStateValue: 'customerPropertySortKey',
    bold: true
  },
  {
    id: 'customerName',
    filterKey: 'Customer.customerName',
    filterType: 'string',
    label: 'Customer',
    showLink: 'view',
    linkPath: '/customer/view',
    linkReference: 'customerId',
    linkStateKey: 'recordSortKey',
    linkStateValue: 'customerSortKey'
  },
  {
    id: 'customerPropertyName',
    filterKey: 'CustomerProperty.companyName',
    filterType: 'string',
    label: 'Property',
    showLink: 'view',
    linkPath: '/property/view',
    linkReference: 'customerPropertyId',
    linkStateKey: 'recordSortKey',
    linkStateValue: 'customerPropertySortKey'
  },
  {
    id: 'make',
    filterKey: 'PropertyAsset.make',
    filterType: 'string',
    label: 'Make'
  },
  {
    id: 'modelNumber',
    filterKey: 'PropertyAsset.modelNumber',
    filterType: 'number',
    label: 'Model Number'
  },
  {
    id: 'serialNo',
    filterKey: 'PropertyAsset.serialNo',
    filterType: 'number',
    label: 'Serial Number'
  },
  {
    id: 'installDate',
    filterKey: 'PropertyAsset.installDate',
    filterType: 'string',
    type: 'dateOnly',
    label: 'Install Date'
  }
];

export const formRowMeta = [
  {
    id: 'id',
    filterKey: 'asset.id',
    fitlerType: 'string',
    label: 'ID',
    bold: true
  }
];

export const jobVisitsMeta = [
  {
    id: 'visitNumber',
    label: 'Visit Number'
  },
  {
    id: 'description',
    label: 'Description'
  },
  {
    id: 'departmentName',
    label: 'Department'
  },
  {
    id: 'scheduledFor',
    label: 'Scheduled on',
    type: 'dateOnly'
  },
  {
    id: 'status',
    label: 'Status'
  },
  {
    id: 'primaryTechName',
    label: 'Primary Tech'
  }
];

export const propertyRowMeta = () => {
  const customerTypeOptions = getMultiSelectOptions(CUSTOMER_TYPES);
  const propertyTypeOptions = getMultiSelectOptions(CUSTOMER_PROPERTY_TYPES);

  return [
    {
      id: 'customerPropertyName',
      filterKey: 'CustomerProperty.companyName',
      filterType: 'string',
      label: 'Property',
      showLink: 'view',
      linkPath: '/property/view',
      linkReference: 'customerPropertyId',
      linkStateKey: 'recordSortKey',
      linkStateValue: 'customerPropertySortKey',
      bold: true
    },
    {
      id: 'propertyStatus',
      filterKey: 'CustomerProperty.isActive',
      filterType: 'boolean',
      options: [
        { label: 'Active', value: true },
        { label: 'Deactivated', value: false }
      ],
      type: 'enum',
      numeric: false,
      label: 'Status'
    },
    {
      id: 'customerName',
      filterKey: 'Customer.companyName',
      filterType: 'string',
      disableFilter: true,
      disableSort: true,
      label: 'Customer',
      showLink: 'view',
      linkPath: '/customer/view',
      linkReference: 'customerId',
      linkStateKey: 'recordSortKey',
      linkStateValue: 'customerSortKey'
    },
    {
      id: 'customerPropertyTypeValue',
      filterKey: 'CustomerProperty.customerPropertyTypeValue',
      filterType: 'multi-select',
      options: propertyTypeOptions,
      type: 'enum',
      label: 'Property Type'
    },
    {
      id: 'openJobs',
      disableFilter: true,
      disableSort: true,
      label: 'Open Jobs'
    },
    {
      id: 'openJobValue',
      disableFilter: true,
      disableSort: true,
      caslKey: PermissionConstants.DATA_VIEW_PRICE_DATA,
      caslAction: 'allow',
      label: 'Open Jobs Value',
      type: 'currency'
    },
    {
      id: 'outstandingBalance',
      disableFilter: true,
      disableSort: true,
      caslKey: PermissionConstants.DATA_VIEW_PRICE_DATA,
      caslAction: 'allow',
      label: 'Outstanding Balance',
      type: 'currency'
    },
    {
      id: 'overdueBalance',
      disableSort: true,
      disableFilter: true,
      type: 'currency',
      numeric: true,
      caslKey: PermissionConstants.DATA_VIEW_PRICE_DATA,
      caslAction: 'allow',
      label: 'Overdue Balance'
    },
    {
      id: 'propertyAddress',
      disableFilter: true,
      disableSort: true,
      label: 'Property Address',
      type: 'bigtext',
      prewrap: true,
      maxTextLen: 150
    },
    {
      id: 'accountNumber',
      filterKey: 'accountNumber',
      filterType: 'string',
      label: 'Account Number'
    },
    {
      id: 'billingAddress',
      disableFilter: true,
      disableSort: true,
      label: 'Billing Address',
      type: 'bigtext',
      prewrap: true,
      maxTextLen: 150
    },
    {
      id: 'billingCustomerName',
      disableSort: true,
      filterKey: 'Customer.customerName',
      filterType: 'stringNoEmpty',
      convertToSubQuery: true,
      subQueryCondition: {
        fieldName: 'billingCustomerId',
        fieldComparator: 'exists',
        entityConnection: 'Customer',
        subQueryFieldName: 'Customer.id',
        filter: {
          stringFilters: [
            {
              fieldName: 'Customer.id',
              filterInput: {
                eq: 'billingCustomerId'
              },
              literal: true
            }
          ]
        }
      },
      label: 'Billing Customer',
      maxTextLen: 50
    },
    {
      id: 'createdBy',
      filterKey: 'createdBy',
      filterType: 'string',
      label: 'Created By'
    },
    {
      id: 'createdDate',
      filterKey: 'createdDate',
      filterType: 'date',
      label: 'Created Date',
      type: 'dateOnly'
    },
    {
      id: 'customerTypeValue',
      disableSort: true,
      filterKey: 'Customer.customerTypeValue',
      filterType: 'multi-select',
      convertToSubQuery: true,
      subQueryCondition: {
        fieldName: 'billingCustomerId',
        fieldComparator: 'exists',
        entityConnection: 'Customer',
        subQueryFieldName: 'Customer.id',
        filter: {
          stringFilters: [
            {
              fieldName: 'Customer.id',
              filterInput: {
                eq: 'billingCustomerId'
              },
              literal: true
            }
          ]
        }
      },
      label: 'Customer Type',
      type: 'enum',
      options: customerTypeOptions
    }
  ];
};

export const reviewReportRowMeta = () => {
  const jobTypeOptions = getMultiSelectOptions(JOB_TYPES);
  const jobStatusOptions = getMultiSelectOptions(JOB_STATUS);
  const jobTagOptions = getMultiSelectOptions(JOB_TAGS);

  return [
    {
      id: 'reviewReportLabel',
      disableFilter: true,
      disableSort: true,
      label: 'Review Report',
      showLink: 'view',
      linkPath: '/reviewreport/view',
      linkReference: 'id',
      bold: true
    },
    {
      id: 'jobNumber',
      filterKey: 'Job.jobNumber',
      filterType: 'number',
      label: 'Job',
      type: 'jobLink'
    },
    {
      id: 'visitNumber',
      filterKey: 'Visit.visitNumber',
      filterType: 'number',
      label: 'Visit'
    },
    {
      id: 'techReportLabel',
      disableFilter: true,
      disableSort: true,
      label: 'Technician Report',
      showLink: 'view',
      linkPath: '/technicianreport/view',
      linkReference: 'techReportId'
    },
    {
      id: 'invoiceNumber',
      disableSort: true,
      filterKey: 'Invoice.invoiceNumber',
      filterType: 'number',
      convertToSubQuery: true,
      conditionalSubQuery: {
        condition: 'empty',
        subQueryFields: {
          fieldComparator: 'notExists'
        }
      },
      subQueryCondition: {
        fieldName: 'ReviewReport.parentId',
        subQueryFieldName: 'InvoiceVisit.id',
        fieldComparator: 'exists',
        entityConnection: 'InvoiceVisit',
        filter: {
          stringFilters: [
            {
              fieldName: 'InvoiceVisit.visitId',
              filterInput: { eq: 'ReviewReport.parentId' },
              literal: true
            }
          ]
        }
      },
      label: 'Invoice',
      showLink: 'view',
      linkPath: '/invoice/view',
      linkReference: 'invoiceId'
    },
    {
      id: 'customerName',
      filterKey: 'Customer.customerName',
      filterType: 'string',
      label: 'Customer',
      showLink: 'view',
      linkPath: '/customer/view',
      linkReference: 'customerId',
      linkStateKey: 'recordSortKey',
      linkStateValue: 'customerSortKey'
    },
    {
      id: 'customerPropertyName',
      filterKey: 'CustomerProperty.companyName',
      filterType: 'string',
      label: 'Property',
      showLink: 'view',
      linkPath: '/property/view',
      linkReference: 'customerPropertyId',
      linkStateKey: 'recordSortKey',
      linkStateValue: 'customerPropertySortKey'
    },
    {
      id: 'status',
      filterKey: 'ReviewReport.status',
      filterType: 'multi-select',
      options: getMultiSelectOptions(REVIEW_REPORT_STATUS),
      label: 'Status'
    },
    {
      id: 'jobTags',
      filterKey: 'JobJobTag.jobTagId',
      filterType: {
        uiType: 'multi-select',
        filterType: 'stringFilters',
        customFieldConditionOptions: [
          { label: 'Is', value: 'in' },
          { label: 'Is not', value: 'in', id: 'notIn' },
          { label: 'Is empty', value: 'empty', defaultValue: 'noFilter' },
          { label: 'Is not empty', value: 'notEmpty', defaultValue: 'noFilter' }
        ]
      },
      options: jobTagOptions,
      convertToSubQuery: true,
      subQueryCondition: {
        fieldName: 'Job.id',
        fieldComparator: {
          'Is not': 'notExists',
          Is: 'exists',
          'Is empty': 'notExists',
          'Is not empty': 'exists'
        },
        entityConnection: 'JobJobTag',
        subQueryFieldName: 'JobJobTag.id',
        filter: {
          stringFilters: [
            {
              fieldName: 'JobJobTag.parentId',
              filterInput: {
                eq: 'Job.id'
              },
              literal: true
            }
          ]
        }
      },
      disableSort: true,
      label: 'Tags',
      type: 'chip'
    },
    {
      id: 'submittedBy',
      filterKey: 'ReviewReport.lastUpdatedBy',
      filterType: 'stringNoEmpty',
      label: 'Submitted By'
    },
    {
      id: 'submittedDate',
      filterKey: 'ReviewReport.lastUpdatedDate',
      filterType: 'date',
      type: 'dateOnly',
      label: 'Submitted On'
    },
    // anchor
    {
      id: 'billingAddress',
      disableFilter: true,
      disableSort: true,
      type: 'bigtext',
      prewrap: true,
      maxTextLen: 150,
      label: 'BillingAddress'
    },
    {
      id: 'createdBy',
      filterKey: 'ReviewReport.createdBy',
      filterType: 'string',
      label: 'Created By'
    },
    {
      id: 'createdDate',
      filterKey: 'ReviewReport.createdDate',
      filterType: 'date',
      label: 'Created On',
      type: 'dateOnly'
    },
    {
      id: 'departmentName',
      filterKey: 'Visit.departmentName',
      filterType: 'string',
      label: 'Department'
    },
    {
      id: 'jobDescription',
      disableFilter: true,
      disableSort: true,
      label: 'Job Description',
      type: 'bigtext',
      prewrap: true,
      maxTextLen: 200
    },
    {
      id: 'jobStatus',
      filterKey: 'Job.status',
      filterType: 'multi-select',
      options: jobStatusOptions,
      label: 'Job Status',
      enumType: EnumType.JOB_STATUS,
      type: 'enum',
      showIcon: true
    },
    {
      id: 'jobType',
      filterKey: 'Job.jobTypeName',
      filterType: 'multi-select',
      options: jobTypeOptions,
      label: 'Job Type',
      type: 'enum'
    },
    {
      id: 'ownerValue',
      filterKey: 'Employee.name',
      filterType: 'string',
      type: 'text',
      label: 'Project Manager'
    },
    {
      id: 'propertyAddress',
      disableFilter: true,
      disableSort: true,
      type: 'bigtext',
      prewrap: true,
      maxTextLen: 150,
      label: 'Property Address'
    },
    {
      id: 'techReportSubmittedBy',
      filterKey: 'Visit.submittedBy',
      filterType: 'string',
      label: 'Technician Report Submitted By'
    },
    {
      id: 'techReportSubmittedOn',
      filterKey: 'Visit.submittedDate',
      filterType: 'date',
      label: 'Technician Report Submitted On',
      type: 'dateOnly'
    },
    {
      id: 'technicians',
      disableFilter: true,
      disableSort: true,
      label: 'Technicians'
    },
    {
      id: 'visitDescription',
      disableFilter: true,
      disableSort: true,
      label: 'Visit Description',
      type: 'bigtext',
      prewrap: true,
      maxTextLen: 200
    },
    {
      id: 'visitSummary',
      disableFilter: true,
      disableSort: true,
      label: 'Visit Summary',
      type: 'bigtext',
      prewrap: true,
      maxTextLen: 200
    }
  ];
};

const syncStatus = {
  id: 'syncStatus',
  disableFilter: true,
  disableSort: true,
  isCustom: true,
  type: 'syncStatus',
  label: 'Sync status'
};

export const invoiceRowsMeta = (
  isAccountIntegrationEnabled = false,
  adjustmentsFlag = false,
  projectFlag = false
) => {
  const invoiceTagOptions = getMultiSelectOptions(INVOICE_TAGS);

  return [
    {
      id: 'invoiceNumber',
      filterKey: 'Invoice.invoiceNumber',
      filterType: 'number',
      label: 'Invoice',
      showLink: 'view',
      linkPath: '/invoice/view',
      linkReference: 'id',
      linkStateKey: 'recordSortKey',
      linkStateValue: 'sortKey',
      bold: true
    },
    // If custom job number is enabled, it will be overriden in the respective page
    // as the filter key also needs to change
    {
      id: 'jobNumber',
      filterKey: 'Job.jobNumber',
      filterType: 'number',
      label: 'Job',
      type: 'jobLink'
    },
    projectFlag && {
      id: 'projectName',
      filterKey: 'Invoice.projectName',
      filterType: 'string',
      label: 'Project',
      showLink: 'view',
      linkPath: '/project/view',
      linkReference: 'projectId'
    },
    {
      id: 'billingCustomerName',
      filterKey: 'BillingCustomer.customerName',
      filterType: 'string',
      label: 'Billing Customer',
      showLink: 'view',
      linkPath: '/customer/view',
      linkReference: 'billingCustomerId'
    },
    {
      id: 'customerPropertyName',
      filterKey: 'CustomerPropertyDirect.companyName',
      filterType: 'string',
      label: 'Property',
      showLink: 'view',
      linkPath: '/property/view',
      linkReference: 'customerPropertyId',
      linkStateKey: 'recordSortKey',
      linkStateValue: 'customerPropertySortKey'
    },
    {
      id: 'departmentName',
      filterKey: 'Department.tagName',
      filterType: 'multi-select',
      options: getMultiSelectOptions(DEPARTMENTS),
      label: 'Department'
    },
    {
      id: 'status',
      filterKey: 'Invoice.status',
      filterType: 'multi-select',
      options: getMultiSelectOptions(INVOICE_STATUS),
      type: 'enum',
      enumType: EnumType.INVOICE_STATUS,
      label: 'Status'
    },
    {
      id: 'invoiceTags',
      filterKey: 'InvoiceInvoiceTag.invoiceTagId',
      filterType: {
        uiType: 'multi-select',
        filterType: 'stringFilters',
        customFieldConditionOptions: [
          { label: 'Is', value: 'in' },
          { label: 'Is not', value: 'in', id: 'notIn' },
          { label: 'Is empty', value: 'empty', defaultValue: 'noFilter' },
          { label: 'Is not empty', value: 'notEmpty', defaultValue: 'noFilter' }
        ]
      },
      options: invoiceTagOptions,
      convertToSubQuery: true,
      subQueryCondition: {
        fieldName: 'Invoice.id',
        fieldComparator: {
          'Is not': 'notExists',
          Is: 'exists',
          'Is empty': 'notExists',
          'Is not empty': 'exists'
        },
        entityConnection: 'InvoiceInvoiceTag',
        subQueryFieldName: 'InvoiceInvoiceTag.id',
        filter: {
          stringFilters: [
            {
              fieldName: 'InvoiceInvoiceTag.parentId',
              filterInput: {
                eq: 'Invoice.id'
              },
              literal: true
            }
          ]
        }
      },
      disableSort: true,
      label: 'Tags',
      type: 'chip'
    },
    {
      id: 'dueDate',
      filterKey: 'Invoice.dueDate',
      filterType: 'date',
      type: 'dateOnly',
      label: 'Due'
    },
    {
      id: 'issuedDate',
      filterKey: 'Invoice.issuedDate',
      filterType: 'date',
      type: 'dateOnly',
      label: 'Issued'
    },
    {
      id: 'totalAmount',
      filterKey: 'Invoice.totalAmount',
      filterType: 'currency',
      numeric: true,
      type: 'currency',
      label: 'Amount'
    },
    {
      id: 'balance',
      disableFilter: true, // @TODO - investigate - this filter is throwing error when used
      disableSort: true,
      filterKey: 'Invoice.balance',
      filterType: 'currency',
      numeric: true,
      type: 'balance',
      bold: true,
      label: 'Balance'
    },
    adjustmentsFlag && {
      id: 'adjustedBalance',
      disableFilter: true,
      disableSort: true,
      filterKey: 'Invoice.adjustedBalance',
      filterType: 'currency',
      numeric: true,
      type: 'balance',
      bold: true,
      label: 'Adjusted Balance'
    },
    {
      id: 'createdBy',
      filterKey: 'Invoice.createdBy',
      filterType: 'string',
      label: 'Created By'
    },
    {
      id: 'createdDate',
      filterKey: 'Invoice.createdDate',
      filterType: 'date',
      type: 'dateOnly',
      label: 'Created Date'
    },
    {
      id: 'customerProvidedPONumber',
      filterKey: 'Invoice.customerProvidedPONumber',
      filterType: 'string',
      label: 'Customer PO#'
    },
    {
      id: 'amountNotToExceed',
      filterKey: 'Invoice.amountNotToExceed',
      filterType: 'currency',
      label: 'Amount Not To Exceed'
    },
    {
      id: 'propertyAddress',
      disableFilter: true,
      disableSort: true,
      label: 'Property Address',
      type: 'bigtext',
      prewrap: true,
      maxTextLen: 150
    },
    isAccountIntegrationEnabled && syncStatus,
    {
      id: 'download',
      disableFilter: true,
      disableSort: true,
      isCustom: true,
      type: 'download',
      label: 'Download'
    }
  ].filter(Boolean);
};

export const paymentRowsMeta = ({ isAccountIntegrationEnabled = false, paymentTypes = [] }) =>
  [
    {
      id: 'paymentNumber',
      filterKey: 'Payment.paymentNumber',
      filterType: 'number',
      label: 'Payment',
      showLink: 'view',
      linkPath: '/payments/view',
      linkReference: 'id',
      linkStateKey: 'recordSortKey',
      linkStateValue: 'sortKey',
      bold: true,
      numeric: false
    },
    {
      id: 'billingCustomerName',
      filterKey: 'BillingCustomer.customerName',
      filterType: 'string',
      label: 'Billing Customer',
      showLink: 'view',
      linkPath: '/customer/view',
      linkReference: 'billingCustomerId',
      linkStateKey: 'recordSortKey',
      linkStateValue: 'customerSortKey'
    },
    {
      id: 'paymentStatus',
      filterKey: 'Payment.paymentStatus',
      filterType: 'multi-select',
      type: 'enum',
      options: getMultiSelectOptions(PAYMENT_STATUS),
      enumType: EnumType.PAYMENT_STATUS,
      numeric: false,
      label: 'Status'
    },
    {
      id: 'exportStatus',
      filterKey: 'Payment.exportStatus',
      filterType: 'multi-select',
      type: 'enum',
      options: getMultiSelectOptions(EXPORT_STATUS),
      enumType: EnumType.EXPORT_STATUS,
      numeric: false,
      label: 'Accounting Status'
    },
    {
      id: 'paymentInvoices',
      filterKey: 'Invoice.invoiceNumber',
      filterType: 'number',
      label: 'Invoices',
      type: 'LinkList',
      itemLabel: 'Invoice ',
      itemLabelReference: 'invoice.invoiceNumber',
      linkPath: '/invoice/view',
      linkReference: 'invoice.id',
      linkStateKey: 'recordSortKey',
      linkStateValue: 'customerSortKey',
      moreLinkReference: 'id',
      moreLinkPath: '/payments/view'
    },
    {
      id: 'paymentTypeName',
      filterKey: 'PaymentType.name',
      filterType: 'multi-select',
      type: 'enum',
      options: paymentTypes,
      numeric: false,
      label: 'Payment Type'
    },
    {
      id: 'paymentDate',
      filterKey: 'Payment.paymentDate',
      filterType: 'number',
      type: 'dateOnly',
      label: 'Payment Date'
    },
    {
      id: 'paymentAmount',
      filterKey: 'Payment.paymentAmount',
      filterType: 'currency',
      numeric: true,
      type: 'currency',
      label: 'Amount',
      bold: true
    },
    {
      id: 'appliedAmount',
      disableFilter: true,
      disableSort: true,
      label: 'Payment Applied Amount',
      type: 'currency'
    },
    {
      id: 'createdBy',
      filterKey: 'Payment.createdBy',
      filterType: 'string',
      label: 'Created By'
    },
    {
      id: 'createdDate',
      filterKey: 'Payment.createdDate',
      filterType: 'date',
      label: 'Payment Created Date',
      type: 'dateOnly'
    },
    // @TODO - discuss - hiding this column since there may be multiple invoices per payment and it may confuse the customer to only see one date.
    // {
    //   id: 'invoiceCreatedDate',
    //   filterKey: 'Invoice.createdDate',
    //   filterType: 'date',
    //   label: 'Invoice Created Date',
    //   type: 'dateOnly'
    // },

    isAccountIntegrationEnabled && syncStatus
  ].filter(Boolean);

export const getPriceBookRows = (showMargin, showMarkup) => {
  const rowMeta = [
    {
      headerStyle: {
        width: '20%'
      },
      id: 'name',
      filterKey: 'PriceBook.name',
      filterType: 'string',
      label: 'Name',
      linkPath: '/settings/pricebooks/view',
      linkReference: 'id',
      linkStateKey: 'recordSortKey',
      linkStateValue: 'id',
      numeric: false,
      showLink: 'view',
      underline: true,
      maxTextLen: 50
    },
    {
      id: 'description',
      filterKey: 'PriceBook.description',
      filterType: 'string',
      label: 'Description',
      numeric: false,
      maxTextLen: 100
    },
    {
      headerStyle: {
        width: '10%'
      },
      id: 'isActive',
      label: 'Status',
      filterKey: 'PriceBook.isActive',
      filterType: 'boolean',
      options: [
        { label: 'Active', value: true },
        { label: 'Deactivated', value: false }
      ],
      type: 'active',
      numeric: false
    }
  ];

  const markupRow = showMarkup && {
    headerStyle: {
      width: '10%'
    },
    align: 'left',
    id: 'markupValue',
    label: 'Default Markup',
    filterKey: 'PriceBook.markupValue',
    filterType: 'float',
    type: 'percentage'
  };

  const marginRow = showMargin && {
    headerStyle: {
      width: '10%'
    },
    align: 'left',
    id: 'markupValue',
    label: 'Default Margin',
    valueFormatter: markup => convertForMathLib(calculateMarginFromMarkup, markup),
    disableFilter: true,
    filterKey: 'PriceBook.markupValue',
    filterType: 'float',
    type: 'percentage'
  };

  return [
    ...rowMeta,
    markupRow,
    marginRow,
    {
      headerStyle: {
        width: '5%',
        minWidth: 190
      },
      id: 'isDefault',
      disableFilter: true,
      disableSort: true,
      isCustom: true,
      type: 'setDefaultButton',
      label: 'Default'
    }
  ].filter(Boolean);
};

export const WIPReportMeta = [
  {
    id: 'recordNumber',
    filterKey: 'WIPReport.recordNumber',
    filterType: 'number',
    label: 'Record Number',
    linkPath: '/wipReports/view',
    linkReference: 'id',
    linkStateKey: 'recordSortKey',
    linkStateValue: 'id',
    showLink: 'view',
    numeric: true
  },
  {
    id: 'createdDate',
    filterKey: 'WIPReport.createdDate',
    filterType: 'date',
    type: 'dateTime',
    label: 'As Of Date'
  },
  {
    id: 'status',
    filterKey: 'WIPReport.status',
    filterType: 'multi-select',
    options: getMultiSelectOptions(WIP_REPORT_STATUS),
    type: 'enum',
    enumType: EnumType.WIP_REPORT_STATUS,
    label: 'Status'
  },
  {
    id: 'syncStatus',
    filterKey: 'WIPReport.syncStatus',
    filterType: 'multi-select',
    isCustom: true,
    type: 'syncStatus',
    label: 'Sync Status'
  },
  {
    id: 'createdBy',
    filterKey: 'WIPReport.createdBy',
    filterType: 'string',
    label: 'Created By'
  },
  {
    id: 'updatedContractsAmount',
    filterKey: 'WIPReport.updatedContractsAmount',
    filterType: 'currency',
    numeric: true,
    type: 'currency',
    label: 'Updated Contracts Amount'
  },
  {
    id: 'updatedCostBudgets',
    filterKey: 'WIPReport.updatedCostBudgets',
    filterType: 'currency',
    numeric: true,
    type: 'currency',
    label: 'Updated Cost Budgets'
  },
  {
    id: 'updatedEstimatedGrossProfits',
    filterKey: 'WIPReport.updatedEstimatedGrossProfits',
    filterType: 'currency',
    numeric: true,
    type: 'currency',
    label: 'Updated Estimated Gross Profits'
  },
  {
    id: 'actualCostsToDate',
    filterKey: 'WIPReport.actualCostsToDate',
    filterType: 'currency',
    numeric: true,
    type: 'currency',
    label: 'Actual Costs To Date'
  },
  {
    id: 'revenueCompletedToDate',
    filterKey: 'WIPReport.revenueCompletedToDate',
    filterType: 'currency',
    numeric: true,
    type: 'currency',
    label: 'Revenue Completed To Date'
  },
  {
    id: 'billedToDate',
    filterKey: 'WIPReport.billedToDate',
    filterType: 'currency',
    numeric: true,
    type: 'currency',
    label: 'Billed To Date'
  },
  {
    id: 'costToComplete',
    filterKey: 'WIPReport.costToComplete',
    filterType: 'currency',
    numeric: true,
    type: 'currency',
    label: 'Cost To Complete'
  },
  {
    id: 'overBilled',
    filterKey: 'WIPReport.overBilled',
    filterType: 'currency',
    numeric: true,
    type: 'currency',
    label: 'Over Billed'
  },
  {
    id: 'underBilled',
    filterKey: 'WIPReport.underBilled',
    filterType: 'currency',
    numeric: true,
    type: 'currency',
    label: 'Under Billed'
  },
  {
    id: 'downloadWIPReport',
    disableFilter: true,
    isCustom: true,
    type: 'DownloadWIPReport',
    label: 'Download'
  }
];
