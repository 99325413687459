import React from 'react';
import isUndefined from 'lodash/isUndefined';
import { withScriptjs, withGoogleMap } from 'react-google-maps';

import compose from 'utils/compose';
import { GOOGLE_MAPS_API_KEY } from 'utils/AppConstants';

import { useCompanyOverview } from '@dispatch/queries';
import { withQueryStringStore } from '@dispatch/Dispatch.store';

import { useUnscheduledVisits } from './MapView.queries';
import MapView, { ContainerElement } from './MapView.component';

const withProps = hocProps => Component => props => <Component {...hocProps} {...props} />;

const GOOGLE_MAPS_BASE_URL =
  'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=';

const googleMapsProps = {
  googleMapURL: GOOGLE_MAPS_BASE_URL + GOOGLE_MAPS_API_KEY,
  loadingElement: <div style={{ height: `100%` }} />,
  containerElement: <ContainerElement />,
  mapElement: <div style={{ height: `100%` }} />
};

const mapQueryStringToProps = ({
  state: { visitStatuses, showVisits, showTechs, showLocations, showUnscheduled }
}) => ({
  visitStatuses,
  showVisits: isUndefined(showVisits) ? true : showVisits,
  showTechs: isUndefined(showTechs) ? true : showTechs,
  showLocations: isUndefined(showLocations) ? true : showLocations,
  showUnscheduled: Boolean(showUnscheduled)
});

const DataProvider = props => {
  const unscheduledVisitsResponse = useUnscheduledVisits({ skip: !props.showUnscheduled });
  const companyOverviewResponse = useCompanyOverview();

  return (
    <MapView
      {...props}
      unscheduledVisitsResponse={unscheduledVisitsResponse}
      companyOverviewResponse={companyOverviewResponse}
    />
  );
};

export default compose(
  withProps(googleMapsProps),
  withScriptjs,
  withGoogleMap,
  withQueryStringStore(mapQueryStringToProps)
)(DataProvider);
